import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { getCampaignCampaignMessagesByCampaignId } from "redux/selector"
import { updateCampaignCampaignMessages } from "redux/slice/campaign-campaign-messages"
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { styles } from "./styles";
import { connect } from "react-redux";
import { getCampaignById } from "redux/selector";
import { Messages } from "./messages";
import { EditCampaign } from "./edit-campaign";
import { Subscribers } from "./subscribers";


interface Props extends
	PageProps,
	Router.RouteComponentProps<RouteParams>,
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
}
interface RouteParams {
	id: string;
}

const getId = (props: Router.RouteComponentProps<RouteParams>) => {
	return parseInt(props.match.params.id);
};

const mapStateToProps = (state: RootState, ownProps: Router.RouteComponentProps<RouteParams>) => {
	const campaignId = getId(ownProps);
	return {
		campaignCampaignMessages: getCampaignCampaignMessagesByCampaignId(state, campaignId),
		campaign: getCampaignById(state, campaignId),
	}	
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateCampaignCampaignMessages,
}, dispatch)

interface State {
	dndOrder: number[];
	dragging: string | null;
	tabIndex: number;
	tabs: Tab[];
}

interface Tab {
	label: string;
	view: React.ReactElement;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			dndOrder: [],
			dragging: null,
			tabIndex: 0,
			tabs: [],
		};
	}

	public componentDidMount() {
	}

	public componentDidUpdate(prevProps: Props) {
		if (this.props.campaign) {
			if(this.state.tabs.length === 0) {
				this.initTabs();
			}
		}
		if (this.props.campaign !== prevProps.campaign) {
			this.initTabs();
		}
	}

	private initTabs() {
		const { campaign } = this.props;
		const tabs: Tab[] = [
			{ label: "Messages", view: <Messages campaignId={campaign && campaign.id} /> },
			{ label: "Settings", view: <EditCampaign campaign={campaign} /> },
			{ label: "Subscribers", view: <Subscribers campaign={campaign} /> }
		];
		this.setState({tabs});
	}

	public render() {
		const { user, classes, campaign } = this.props;
		const { tabIndex} = this.state;
		const tabs = this.state.tabs;
		const title = "Campaign";
		return (
			<DashboardLayout
				permitted={user && user.permissions.campaigns}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Send />
						</FeatherIcon>
						{title}{campaign ? ": " + campaign.name : ""}
					</Mui.Typography>
				}
			>
				<Mui.Grid container direction="column" wrap="nowrap" justifyContent="center">
					<Mui.Grid item className={classes.timeline}>
						<Mui.Tabs
							value={tabIndex}
							variant="standard"
							onChange={(event, tabIndex) => this.setState({ tabIndex })}
							centered={false}
							scrollButtons="off"
							textColor="secondary"
						>
							{tabs.map((tab, index) => {
								return (
									<Mui.Tab key={index} label={tab.label}
								/>);
							})}
						</Mui.Tabs>
					</Mui.Grid>
					<Mui.Grid item>
						{tabs[tabIndex] &&
							<>{tabs[tabIndex].view}</>
						}
					</Mui.Grid>
				</Mui.Grid>
			</DashboardLayout>
		);
	}
}

export const CampaignPage = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);