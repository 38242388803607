import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { FullPageCard } from "component/shared/full-page-card";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { styles } from "./style";
import { connect } from "react-redux";
import { User } from "model/user";
import { validate } from "shared/yup";
import { CancelAccount } from "component/layout/dashboard/cancel-account";
import * as yup from "yup";
import { saveUser } from "redux/slice/authentication";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		authLoading: state.authentication.loading,
		authError: state.authentication.error,
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveUser,
}, dispatch);

interface Props extends
	PageProps,
	Router.RouteComponentProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
	user: User;
}

interface State {
	showValidation: boolean;
	saving: boolean;
	contactName: string;
	contactEmail: string;
	contactPhone: string;
	companyAddress: string;
	companyCity: string;
	companyState: string;
	companyZip: string;
	companyPhone: string;
	companyFax: string;
	baseUrl: string;
}

interface OwnProps {
	user: User;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {...props.user, showValidation: false, saving: false};
	}

	private validationSchema = {
		contactEmail: yup.string()
			.email("Invalid Email")
	};

	public handleSave = (event: any) => {
		const { saveUser } = this.props;
		const errors = validate(this.validationSchema, this.state);
		console.log(this.state.contactEmail)
		if(!errors) {
			const user = {...this.state};
			saveUser({user});
			this.setState({saving: true});
		} else {
			this.setState({showValidation: true})
		}
	}

	public handleCancel = (event: any) => {
		this.setState({...this.props.user, showValidation: false, saving: false});
	}

	public handleSaveMessageClose = (event: any) => {
		this.setState({ saving: false })
	}

	public render() {
		const { user, authLoading, authError } = this.props;
		const { showValidation, saving } = this.state;
		const errors = validate(this.validationSchema, this.state);
		return (
			<DashboardLayout
				permitted={user.admin || (user.type.agent && user.permissions.modifyBio)}
				fullScreen={false}
				title="Account Info"
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						Account Info
					</Mui.Typography>
				}
			>
				<FullPageCard>
					<Mui.Grid container spacing={2}>
						<Mui.Grid item> 
							<h3>Address Details</h3>
						</Mui.Grid>
						<Mui.Grid container item>
							<Mui.Grid item xs={12}>
								<Mui.TextField
									label="Address"
									placeholder="123 Main Street"
									fullWidth
									variant="outlined"
									value={this.state.companyAddress}
									onChange={event => this.setState({companyAddress: event.target.value})}
									InputLabelProps={{ shrink: true }} />
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid item container spacing={1}>
							<Mui.Grid item xs={4}>
								<Mui.TextField
									label="City"
									placeholder="Berkeley"
									fullWidth
									variant="outlined"
									value={this.state.companyCity}
									onChange={event => this.setState({companyCity: event.target.value})}
									InputLabelProps={{ shrink: true }} />
							</Mui.Grid>
							<Mui.Grid item xs={4}>
								<Mui.TextField
									label="State"
									placeholder="CA"
									fullWidth
									variant="outlined"
									value={this.state.companyState}
									onChange={event => this.setState({companyState: event.target.value})}
									InputLabelProps={{ shrink: true }} />
							</Mui.Grid>
							<Mui.Grid item xs={4}>
								<Mui.TextField
									label="Zipcode"
									placeholder="00000"
									fullWidth
									variant="outlined"
									value={this.state.companyZip}
									onChange={event => this.setState({companyZip: event.target.value})}
									InputLabelProps={{ shrink: true }} />
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid item>
							<h3>Company Contact</h3>
						</Mui.Grid>
						<Mui.Grid item container spacing={1}>
							<Mui.Grid item xs={6}>
								<Mui.TextField
									label="Phone"
									placeholder="415-000-0000"
									fullWidth
									variant="outlined"
									value={this.state.companyPhone}
									onChange={event => this.setState({companyPhone: event.target.value})}
									InputLabelProps={{ shrink: true }} />
							</Mui.Grid>
							<Mui.Grid item xs={6}>
								<Mui.TextField
									label="Fax"
									placeholder="510-000-0000"
									fullWidth
									variant="outlined"
									value={this.state.companyFax}
									onChange={event => this.setState({companyFax: event.target.value})}
									InputLabelProps={{ shrink: true }} />
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid item xs={12}>
							<Mui.TextField
								label="Website URL"
								placeholder="http://example.com"
								fullWidth
								variant="outlined"
								value={this.state.baseUrl}
								onChange={event => this.setState({baseUrl: event.target.value})}
								InputLabelProps={{ shrink: true }} />
						</Mui.Grid>
						<Mui.Grid item>
							<h3>Contact Person Details</h3>
						</Mui.Grid>
						<Mui.Grid item xs={12}>
							<Mui.TextField
								label="Contact Person"
								placeholder="John Doe"
								fullWidth
								variant="outlined"
								value={this.state.contactName}
								onChange={event => this.setState({contactName: event.target.value})}
								InputLabelProps={{ shrink: true }} />
						</Mui.Grid>
						<Mui.Grid item container spacing={1}>
							<Mui.Grid item xs={6}>
								<Mui.TextField
									label="Contact Email"
									placeholder="jdoe@example.com"
									fullWidth
									variant="outlined"
									value={this.state.contactEmail}
									helperText={showValidation && errors && errors.contactEmail}
									onChange={event => this.setState({contactEmail: event.target.value})}
									InputLabelProps={{ shrink: true }} />
							</Mui.Grid>
							<Mui.Grid item xs={6}>
								<Mui.TextField
									label="Contact Phone"
									placeholder="415-000-0000"
									fullWidth
									variant="outlined"
									value={this.state.contactPhone}
									onChange={event => this.setState({contactPhone: event.target.value})}
									InputLabelProps={{ shrink: true }} />
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid item container spacing={1} style={{marginTop:24}}>
							<Mui.Grid item container>
								<Mui.Button
									color="secondary"
									variant="contained"
									onClick={this.handleSave}
									style={{ marginRight: 32 }}
									>Save Changes</Mui.Button>
								<Mui.Button 
									variant="contained"
									onClick={this.handleCancel}>Cancel</Mui.Button>
							</Mui.Grid>
						</Mui.Grid>
						{user.reseller.direct && (
							<Mui.Grid item xs>
								<CancelAccount user={user} />
							</Mui.Grid>
						)}
						<Mui.Snackbar 
							open={saving && !authLoading && !authError} 
							message="Account saved" 
							autoHideDuration={6000}
							onClose={this.handleSaveMessageClose}
							action={
								<Mui.IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSaveMessageClose}>
									<Icons.X fontSize="small" />
								</Mui.IconButton>
							}
						/>
					</Mui.Grid>
				</FullPageCard>
			</DashboardLayout>
		);
	}

}

export const AccountInfo = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
