import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { NewGeographicRoute, GeographicRoute } from "model/geographic-route";
import { arrayToById, objectToById } from "redux/normalize";

export interface GeographicRoutesState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: GeographicRouteState;
	};
};

export interface GeographicRouteState {
	id: number;
	agentId: number;
	postalCode: string;
	createdOn: string;
	updatedOn: string;
};

const initialState: GeographicRoutesState = {
	byId: {},
};

const fetchGeographicRoutes = createAsyncThunk("geographicRoutes/fetchGeographicRoutes", async (payload, thunkAPI) => {
	thunkAPI.dispatch(geographicRoutesLoading());
	const result = api.getGeographicRoutes();
	result
		.then((geographicRoutes) => thunkAPI.dispatch(geographicRoutesLoaded(geographicRoutes)))
		.catch(error => thunkAPI.dispatch(geographicRoutesFailed(error)));
	return result;
});

const saveGeographicRoute = createAsyncThunk("geographicRoutes/saveGeographicRoute", async (payload:{geographicRoute: NewGeographicRoute}, thunkAPI) => {
	thunkAPI.dispatch(geographicRoutesLoading());
	const result = api.createGeographicRoute(payload.geographicRoute);
	result
		.then(geographicRoute => thunkAPI.dispatch(geographicRouteLoaded(geographicRoute)))
		.catch(error => thunkAPI.dispatch(geographicRoutesFailed(error)));
	return result;
});

const deleteGeographicRoute = createAsyncThunk("geographicRoutes/deleteGeographicRoute", async (payload: {geographicRoute: GeographicRoute}, thunkAPI) => {
    thunkAPI.dispatch(geographicRoutesLoading());
    const result = api.deleteGeographicRoute(payload.geographicRoute);
    result
        .then((geographicRoute) => thunkAPI.dispatch(geographicRouteDeleted(geographicRoute)))
        .catch((error) => thunkAPI.dispatch(geographicRoutesFailed(error)));
    return result;
});


export const geographicRouteslice = createSlice({
  name: "geographicRoutes",
  initialState,
  reducers: {
	geographicRoutesLoading: (state) => {
		state.loading = true;
	},
	geographicRoutesLoaded: (state, action: PayloadAction<any[]>) => {
		state.loading = false;
		state.error = null;
		state.byId = arrayToById(action.payload, "id");
	},
	geographicRoutesFailed: (state, action: PayloadAction<any>) => {
		state.loading = false;
		state.error = action.payload.message;
	},
	geographicRouteLoaded: (state, action: PayloadAction<any>) => {
		const byId = objectToById(action.payload, "id");
		state.loading = false;
		state.error = null;
		state.byId = { 
			...state.byId,
			...byId,
		};
	}, 
	geographicRouteDeleted: (state, action: PayloadAction<any>) => {
		const { id } = action.payload;
		const result = {
			...state,
			loading: false,
			error: null,
			byId: {
				...state.byId,
			}
		}
		delete result.byId[id];
		return result;
	},
	default:() => {
		return;
	}
  }, 
  extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});

const { geographicRoutesLoaded, geographicRoutesFailed, geographicRoutesLoading, geographicRouteLoaded, geographicRouteDeleted } = geographicRouteslice.actions;
export { fetchGeographicRoutes, saveGeographicRoute, deleteGeographicRoute };



