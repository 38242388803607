import React from "react";
import * as Mui from "@material-ui/core";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { fetchLeadActivities } from "redux/slice/lead-activities";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { PercentWithActivity } from "./percent-with-activity";
import { PercentSubscribed } from "./percent-subscribed";
import { PipelineStatus } from "./pipeline-status";
import { LeadSources } from "./lead-sources";
import { MonthlyStats } from "./monthly-stats";
import { LeadActivity } from "./lead-activity";
import { ActionCenter } from "./action-center";
import { UpcomingDates } from "./upcoming-dates";
import { Tasks } from "./tasks";
import { withMediaQuery, WithMediaQuery } from "component/shared/with-media-query";
import { RoundRobin } from "./round-robin";
import { AgentSelector } from "component/layout/dashboard/agent-selector";
import * as env from "shared/env"

const mapStateToProps = (state: RootState) => {
	return {
		agentId: state.agents.value,
		unassigned: localStorage.getItem("agentId") === "unassigned"
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	fetchLeadActivities
}, dispatch);

interface Props
	extends ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		PageProps,
		Router.RouteComponentProps,
		WithMediaQuery {}

class Component extends React.Component<Props> {
	public componentDidMount() {
		this.props.fetchLeadActivities({});
	}

	public render() {
		const { user, mediaQuery, agentId, unassigned } = this.props;
		const title = "Dashboard";
		const showMonthlyStats = !agentId && !unassigned

		return (
			<DashboardLayout
				permitted={user && user.permissions.crm}
				title={title}
				header={
					<Mui.Grid container spacing={10}>
						<Mui.Grid item xs={3}>
							<Mui.Typography variant="h1">
								<FeatherIcon>
									<Icons.User />
								</FeatherIcon>
								{title}
							</Mui.Typography>
						</Mui.Grid>
						{!env.agentSelectorDev && user.permissions.crm && user.permissions.broker && (
							<Mui.Grid item>
								<AgentSelector/>
							</Mui.Grid>
						)}
					</Mui.Grid>
				}
			>
				<Mui.Grid
					container
					spacing={4}
					direction={mediaQuery ? "column-reverse" : "row"}
				>
					<Mui.Grid container item xs={12} md={7}>
						<Mui.Grid
							container
							item
							spacing={4}
							direction="row"
							justifyContent="space-between"
						>
							<Mui.Grid item xs={12}>
								<LeadActivity />
							</Mui.Grid>
							<Mui.Grid item xs={12} md={6}>
								<PercentWithActivity />
							</Mui.Grid>
							<Mui.Grid item xs={12} md={6}>
								<PercentSubscribed />
							</Mui.Grid>
							<Mui.Grid item xs={12} md={6}>
								<PipelineStatus />
							</Mui.Grid>
							<Mui.Grid item xs={12} md={6}>
								<LeadSources />
							</Mui.Grid>
							{showMonthlyStats && (
								<Mui.Grid item xs={12}>
									<MonthlyStats />
								</Mui.Grid>
							)}
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid container item xs={12} md={5}>
						<Mui.Grid container item spacing={4} alignContent="flex-start">
							<Mui.Grid item xs={12}>
								<RoundRobin/>
							</Mui.Grid>
							<Mui.Grid item xs={12}>
								<ActionCenter />
							</Mui.Grid>
							{user.permissions.crmTasks && (
								<Mui.Grid item xs={12}>
									<Tasks />
								</Mui.Grid>
							)}
							{user.permissions.crmAnniversary && (
								<Mui.Grid item xs={12}>
									<UpcomingDates />
								</Mui.Grid>
							)}
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</DashboardLayout>
		);
	}
}

export const LeadDashboardPage = withMediaQuery("(max-width:960px)")(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
