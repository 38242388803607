import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { RootState } from "redux/store";
import { getOfficeById } from "redux/selector";
import { connect } from "react-redux";
import { Content } from "./content";

interface Props extends PageProps, 
	Router.RouteComponentProps<RouteParams>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
}

interface RouteParams {
	id: string;
}

const getId = (props: Router.RouteComponentProps<RouteParams>) => {
	return parseInt(props.match.params.id);
}

const mapStateToProps = (state: RootState, ownProps: Router.RouteComponentProps<RouteParams>) => {
	const id = getId(ownProps);
	const office = getOfficeById(state, id);
	return {
		office,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

class Component extends React.Component<Props> {

	public render() {
		const { user, office } = this.props;
		const title = "Office Profile";
		return (
			<DashboardLayout
				permitted={user && user.permissions.broker}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<>
					{office && (
						<Content office={office} />
					)}
				</>
				
			</DashboardLayout>
		);
	}

}

export const SettingsOfficePage =  connect(mapStateToProps, mapDispatchToProps)(Component);