import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./styles";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { withMediaQuery, WithMediaQuery } from "component/shared/with-media-query";
import { getCampaignSubscriptionsForCampaign } from "redux/selector";
import { Campaign } from "model/campaign";
import { Table } from "component/shared/table";
import { CampaignSubscription } from "model/campaign-subscription";
import { getSubscriptionStats } from "redux/selector";
import { getLeads } from "redux/selector";
import { Lead } from "model/lead";
import * as Icons from "react-feather";
import { CampaignSubscriptionStatus } from "type/campaign-subscription-status";
import { AddSubscription } from "./add-subscription";
import { updateCampaignSubscription } from "redux/slice/campaign-subscriptions";
import { StatusMenu } from "./status-menu";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { urls } from "routes/urls";
import { CampaignSubscriptionStat } from "model/campaign-subscription-stat";


interface Props extends
	OwnProps,
	WithMediaQuery,
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
}

interface OwnProps {
	campaign: Campaign;
}

interface State {
	campaignSubscriptionsWithStats: CampaignSubscription[],
	statusFilter: boolean,
	openAddNew: boolean,
	subscriberFilter: string | undefined,
}

const mapStateToProps = (state: RootState, props: OwnProps) => {
	return {
		campaignSubscriptions: getCampaignSubscriptionsForCampaign(state, props.campaign.id),
		campaignSubscriptionStats: getSubscriptionStats(state),
		leads: getLeads(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateCampaignSubscription,
}, dispatch);

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			campaignSubscriptionsWithStats: [],
			statusFilter: true,
			openAddNew: false,
			subscriberFilter: undefined,
		};
	}
	public componentDidMount() {
		this.addFieldsToSubscription();
	}

	public componentDidUpdate(prevProps: Props) {
		if(prevProps.campaignSubscriptionStats !== this.props.campaignSubscriptionStats) {
			this.addFieldsToSubscription();
		}
	}

	public get filteredCampaignSubscriptionsWithStats() {
		let campaignSubscriptions: CampaignSubscription[] = [];
		campaignSubscriptions = Array.from(this.state.campaignSubscriptionsWithStats);
		const filterValue = this.state.subscriberFilter;
		if (filterValue) {
			return campaignSubscriptions.filter((subscription) => {
				return subscription.lead && subscription.lead.name && 
					subscription.lead.name.toLowerCase().includes(filterValue.toLowerCase());
				});		
		}
		return campaignSubscriptions;
	}

	/**
	 * Updating stats in component to work around selector issue
	 */
	private addFieldsToSubscription() {
		let campaignSubscriptionsWithStats: CampaignSubscription[] = []; 		
		this.props.campaignSubscriptions.forEach((campaignSubscription: CampaignSubscription) => {
			const stat = this.getCampaignSubscriptionStat(campaignSubscription.id);
			const campaignSubscriptionWithStats: CampaignSubscription = {
				...campaignSubscription,
				stat: stat,
			}
			campaignSubscriptionsWithStats.push(campaignSubscriptionWithStats);
		});
		if(this.state.statusFilter) {
			campaignSubscriptionsWithStats = campaignSubscriptionsWithStats.filter(
				(campaignSubscription) => campaignSubscription.status === CampaignSubscriptionStatus.ACTIVE
			)
		}
		this.setState({campaignSubscriptionsWithStats});
	}

	private getCampaignSubscriptionStat(campaignSubscriptionId: number) {
		return this.props.campaignSubscriptionStats.find((campaignSubscriptionStat: CampaignSubscriptionStat) => { 
			return campaignSubscriptionStat.campaignSubscription.id === campaignSubscriptionId;
		});
	}


	private getSubscribedLeads() {
		const leads: Lead[] = [];
		 this.state.campaignSubscriptionsWithStats.forEach((campaignSubscription) => {
			 if(campaignSubscription.lead) {
				leads.push(campaignSubscription.lead);
			 }
		 });
		 return leads;
	}

	private handleStatusFilter() {
		this.setState({statusFilter: !this.state.statusFilter}, () => this.addFieldsToSubscription());
	}

	private handleAdd() {
		this.setState({openAddNew: true});
	}

	public updateStatus(campaignSubscription: CampaignSubscription, campaignSubscriptionStatus: CampaignSubscriptionStatus) {
		campaignSubscription.status = campaignSubscriptionStatus;
		this.props.updateCampaignSubscription({subscription: campaignSubscription});		
	}

	public render() {
		const { openAddNew } = this.state;
		return (
			<>
				<AddSubscription open={openAddNew} onClose={() => this.setState({openAddNew: false})} subscribedLeads={this.getSubscribedLeads()} campaign={this.props.campaign}/>
				<Mui.Grid container direction="column" spacing={1} style={{marginTop: 15}}>
					<Mui.Grid item>					
						<Mui.Grid container direction="row" alignItems="center">
							<Mui.Grid item xs={12} md={6}>
								{this.renderToolsLeft()}
							</Mui.Grid>
							<Mui.Grid item xs={12} md={6}>
								{this.renderToolsRight()}
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<Table 
							items={this.filteredCampaignSubscriptionsWithStats}
							columns={[
								{
									id: "Subscriber",
									title: "Subscriber",
									sort: (value1, value2) => {
										let direction = 1;
										if((value1.lead && value1.lead.name) && (value2.lead && value2.lead.name)) {
											if(value1.lead.name.toLowerCase() < value2.lead.name.toLowerCase()) {
												direction = -1;
											}
										}
										return direction;
									},
									value: (campaignSubscription) => {
										if (!campaignSubscription.lead) {
											return "Error Getting Name";
										}
										const url = urls.lead(campaignSubscription.lead)
										return (
											<Mui.Grid container direction="column">
												<Mui.Link component={RouterLinkWrapper} to={url}>
													{campaignSubscription.lead.label}
												</Mui.Link>						
											</Mui.Grid>
										);
									},
								},
								{
									id: "Added",
									title: "Added",
									sort: (value1, value2) => {
										return value1.createdOn.valueOf() < value2.createdOn.valueOf() ? -1 : 1;
									},
									value: (campaignSubscription) => {
										return (
											<Mui.Grid container direction="column">
												{campaignSubscription.createdOn.format("MM/DD/YYYY")}
											</Mui.Grid>
										);
									}
								},
								{ 
									id: "Status",
									title: "Status",
									sort: (value1, value2) => {
										return value1.status.label < value2.status.label ? -1 : 1;
									},
									value: (campaignSubscription) => {
										return (
											<Mui.Grid container direction="column" style={{alignContent: "flex-start"}}>
												<StatusMenu campaignSubscriptionStatus={campaignSubscription.status} onChange={(status) => this.updateStatus(campaignSubscription, status)} />
											</Mui.Grid>
										);
									}
								},
								{
									id: "Source",
									title: "Source",
									value: (campaignSubscription) => {
										return (
											<Mui.Grid container direction="column">
												{campaignSubscription.lead && campaignSubscription.lead.source ? campaignSubscription.lead.source : "N/A"}
											</Mui.Grid>
										);
									}
								},
								{
									id: "Opens",
									title: "Opens*",
									sort: (value1, value2) => {
										let direction = 1;
										if(value1.stat && value2.stat) {
											if(value1.stat.open < value2.stat.open) {
												direction = -1;
											}
										}
										return direction;
									},
									value: (campaignSubscription) => {
										return (
											<Mui.Grid container direction="column">
												{campaignSubscription.stat && campaignSubscription.stat.open ? campaignSubscription.stat.open : "N/A"}
											</Mui.Grid>
										);
									}
								},
								{
									id: "Clicks",
									title: "Clicks*",
									sort: (value1, value2) => {
										let direction = 1;
										if(value1.stat && value2.stat) {
											if(value1.stat.click < value2.stat.click) {
												direction = -1;
											}
										}
										return direction;
									},
									value: (campaignSubscription) => {
										return (
											<Mui.Grid container direction="column">
												{campaignSubscription.stat && campaignSubscription.stat.click ? campaignSubscription.stat.click : "N/A"}
											</Mui.Grid>
										);
									}
								},
								{
									id: "Stage",
									title: "Stage*",
									sort: (value1, value2) => {
										let direction = 1;
										if(value1.stat && value2.stat) {
											if(value1.stat.step < value2.stat.step) {
												direction = -1;
											}
										}
										return direction;
									},
									value: (campaignSubscription) => {
										return (
											<Mui.Grid container direction="column">
												{campaignSubscription.stat && campaignSubscription.stat.step ? campaignSubscription.stat.step : "N/A"}
											</Mui.Grid>
										);
									}
								},
								{
									id: "sent",
									title: "Last Sent*",
									sort: (value1, value2) => {
										let direction = 1;
										if((value1.stat && value2.stat) && (value1.stat.lastSent && value2.stat.lastSent)) {
											if(value1.stat.lastSent.valueOf() < value2.stat.lastSent.valueOf()) {
												direction = -1;
											}
										}
										return direction;
									},
									value: (campaignSubscription) => {
										return (
											<Mui.Grid container direction="column">
												{campaignSubscription.stat && campaignSubscription.stat.lastSent ? campaignSubscription.stat.lastSent.format("MM/DD/YYYY") : "N/A"}
											</Mui.Grid>
										);
									}
								},
							]}
						/>
					</Mui.Grid>	
				</Mui.Grid>
				
			</>
		);
	}

	private renderToolsLeft() {
		return (
			<>
				<Mui.Grid container direction="row" alignItems="center" wrap="nowrap">
					<Mui.Grid item> 
						<Mui.Button
							variant="contained"
							color="secondary"
							onClick={() => this.handleAdd()}
						>
							Add New
						</Mui.Button>
					</Mui.Grid>
					<Mui.Grid item style={{paddingLeft: 20}}>						
						<Mui.Button 
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={() => this.handleStatusFilter()}
							style={{textAlign: "center"}}							
							>
								{this.state.statusFilter ? (
										<>
											Showing Active
											<Icons.Maximize2 size={14} style={{marginLeft: 5}} />
										</>
									) : (
										<>
											Showing All
											<Icons.Minimize2 size={14} style={{marginLeft: 5}} />
										</>
									)
								}
						</Mui.Button>
					</Mui.Grid>
				</Mui.Grid>
			</>
		);
	}

	public renderToolsRight() {
		const { mediaQuery } = this.props
		return (
			<>
				<Mui.Grid
					container
					direction="column"
					alignItems="flex-end" 
					style={{marginTop: mediaQuery ? 15 : undefined}} 
					justifyContent={mediaQuery ? "flex-start" : "flex-end"}
					wrap="nowrap"
				>
					<Mui.Grid item>
						<Mui.Grid item>
							<Mui.TextField
								placeholder="Search Subscribers"
								onChange={(element) => this.setState({subscriberFilter: element.target.value})}
							/>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Typography color="textSecondary">*Data provided only within the last 30 days is shown</Mui.Typography>
					</Mui.Grid>
				</Mui.Grid>
			</>
		);
	}
}

export const Subscribers = withMediaQuery("(max-width:600px)")(Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component))
);