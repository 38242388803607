import React from "react";
import * as Mui from "@material-ui/core";
import { FullPageCard } from "component/shared/full-page-card";
import { styles } from "./styles";
import { settingsWidgets, settingsListingResults, settingsMapSearch, settingsLocationCustomization, settingsVersion, supportCenter } from "routes/paths";
import { User } from "model/user";
import * as Icon from "react-feather"

interface Props extends
	Mui.WithStyles<typeof styles> {
		user: User
}

class Component extends React.Component<Props> {

	public render() {
		const { classes, user } = this.props;
		return (
				<FullPageCard>
					<Mui.Grid container direction="column" alignItems="center" spacing={3} className={classes.body}>
						<Mui.Grid item>
							<Mui.Typography>Your Control Panel is where you manage your iHomefinder account, including your listings and leads, as well as how property search and results appear on your website.</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Typography className={classes.boldText}>
								WIX App Shortcuts
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Typography>
								Follow the links below to configure your account for display within the Wix App.
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item>
								<Mui.Button href={settingsWidgets} color="secondary" variant="outlined" className={classes.button}>
									<Mui.Grid container direction="column" alignItems="center">
										<Icon.Grid fontSize="large"/>
										<Mui.Typography className={classes.typography} variant="button">
											Configure Widgets
										</Mui.Typography>
									</Mui.Grid>
								</Mui.Button>
								<Mui.Button href={settingsListingResults} color="secondary" variant="outlined" className={classes.button}>
									<Mui.Grid container direction="column" alignItems="center">
										<Icon.Home fontSize="large"/>
										<Mui.Typography className={classes.typography} variant="button">
											Listing Settings
										</Mui.Typography>
									</Mui.Grid>
								</Mui.Button>
								<Mui.Button href={user.permissions.eurekaSearch ? settingsMapSearch : settingsLocationCustomization} color="secondary" variant="outlined" className={classes.button}>
									<Mui.Grid container direction="column" alignItems="center">
										<Icon.Search fontSize="large"/>
										<Mui.Typography className={classes.typography} variant="button">
											Search Settings
										</Mui.Typography>
									</Mui.Grid>
								</Mui.Button>
								<Mui.Button href={settingsVersion} color="secondary" variant="outlined" className={classes.button}>
									<Mui.Grid container direction="column" alignItems="center">
										<Icon.Layout fontSize="large"/>
										<Mui.Typography className={classes.typography} variant="button">
											Site Settings
										</Mui.Typography>
									</Mui.Grid>
								</Mui.Button>
								<Mui.Button href={supportCenter} color="secondary" variant="outlined" className={classes.button}>
									<Mui.Grid container direction="column" alignItems="center">
										<Icon.HelpCircle fontSize="large"/>
										<Mui.Typography className={classes.typography} variant="button">
											Support Center
										</Mui.Typography>
									</Mui.Grid>
								</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
				</FullPageCard>
		);
	}

}

export const WixLandingPage = Mui.withStyles(styles)(Component);