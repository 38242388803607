import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { Lead } from "model/lead";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { getMarkets, getUnsubscribedReportsByLeadId } from "redux/selector";
import { createMarketReportSubscription } from "redux/slice/market-report-subscriptions";
import { Dialog } from "component/shared/dialog";
import Autocomplete from "@material-ui/lab/Autocomplete";

const mapStateToProps = (state: RootState, ownProps: any) => {
	return {
		markets: getMarkets(state),
		unsubscribedReports: getUnsubscribedReportsByLeadId(state,ownProps.lead.id)
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createMarketReportSubscription,
}, dispatch);

interface Props extends
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
	open: boolean;
	onClose: Function;
	lead: Lead;
}

interface State {
	selectedMarketId?: number;
	listingChecked: boolean;
	openHomesChecked: boolean;
	marketChecked: boolean;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = { 
			selectedMarketId: undefined,
			listingChecked: true,
			openHomesChecked: true,
			marketChecked: true,
		};
	}

	public componentDidUpdate(prevProps: Props) {
		if(!prevProps.open && this.props.open) {
			this.setState({
				selectedMarketId: undefined,
				listingChecked: true,
				openHomesChecked: true,
				marketChecked: true,
			})
		}
	}

	public createSubscription = () => {
		const { lead, createMarketReportSubscription, markets, unsubscribedReports } = this.props;
		const { selectedMarketId, listingChecked, openHomesChecked, marketChecked } = this.state;
		const market = markets.find(market => market.id === selectedMarketId);
		if(market && unsubscribedReports) {
			const unsubscribedIds = unsubscribedReports.map((report) => report.id)
			if(listingChecked && market.listingReport && unsubscribedIds.includes(market.listingReport.id)) {
				createMarketReportSubscription({ subscription: {
					lead,
					report: market.listingReport,
					inactive: false,
			}});
			}
			if(openHomesChecked && market.openHomesReport && unsubscribedIds.includes(market.openHomesReport.id)) {
				createMarketReportSubscription({ subscription: {
					lead,
					report: market.openHomesReport,
					inactive: false,
				}});
			}
			if(marketChecked && market.marketReport && unsubscribedIds.includes(market.marketReport.id)) {
				createMarketReportSubscription({ subscription: {
					lead,
					report: market.marketReport,
					inactive: false,
				}});
			}
		}
	}

	public render() {

		const { selectedMarketId, listingChecked, openHomesChecked, marketChecked } = this.state;
		const { classes, open, onClose, markets } = this.props;
		const market = markets.find(market => market.id === selectedMarketId);
		const marketSorted = markets.sort((a, b) => a.name.localeCompare(b.name)).map(elem => (
			{
			  name: elem.name,
			  id: elem.id
			} 
		  ));
		return (
			<Dialog open={open} onClose={() => onClose()} fullWidth={true}>
				<Mui.DialogTitle>Add to Market</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<Mui.FormControl className={classes.formControl}>
					<Autocomplete
						options={marketSorted}	
						getOptionLabel={(option:any) => option.name}
						className={classes.searchFilter}
						renderOption={(option) => (
							<>
							  {option.name}
							</>
						)}
						renderInput={(params) => <Mui.TextField {...params} label="Select a Market" variant="outlined" />}
						onChange={(event, marketId) => {
							this.setState({ selectedMarketId: marketId?.id })
						}}
						
					/>
						{market &&
							<div>
								{market.listingReport &&
									<Mui.FormControlLabel
										control={
											<Mui.Checkbox
												checked={listingChecked}
												onChange={() => this.setState({ listingChecked: !listingChecked })}
												color="primary"
											/>
										}
										label={market.listingReport.type.label}
									/>
								}
								{market.openHomesReport && 
									<Mui.FormControlLabel
										control={
											<Mui.Checkbox
												checked={openHomesChecked}
												onChange={() => this.setState({ openHomesChecked: !openHomesChecked })}
												color="primary"
											/>
										}
										label={market.openHomesReport.type.label}
									/>
								}
								{market.marketReport && 
									<Mui.FormControlLabel
										control={
											<Mui.Checkbox
												checked={marketChecked}
												onChange={() => this.setState({ marketChecked: !marketChecked })}
												color="primary"
											/>
										}
										label={market.marketReport.type.label}
									/>
								}
							</div>
						}
					</Mui.FormControl>
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
					<Mui.Button
						onClick={() => {
							onClose()
							this.createSubscription()
						}}
						variant="contained"
						color="secondary"
						autoFocus
						className={classes.button}
						disabled={this.isSaveable}
					>
						Save
					</Mui.Button>
					<Mui.Button
						onClick={() => onClose()}
						variant="outlined"
						color="primary"
						className={classes.button}
					>
						Cancel
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
		);
	}
	
	public get isSaveable() {
		const { selectedMarketId, listingChecked, openHomesChecked, marketChecked } = this.state;
		const { markets } = this.props;
		const market = markets.find(market => market.id === selectedMarketId);
		if(!selectedMarketId) {
			return true;
		} else if(!listingChecked && !openHomesChecked && !marketChecked) {
			return true;
		} else if (market) {
			if(
				(listingChecked && market.listingReport) || 
				(openHomesChecked && market.openHomesReport) ||
				(marketChecked && market.marketReport)) {
					return false
				}
		} 
		return true;
	}
}

export const MarketDialog = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);