import React from "react";
import * as Mui from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FullPageCard } from "component/shared/full-page-card";
import { FullWidthDropdown } from "component/shared/full-width-dropdown";
import { DashboardLayout } from "component/layout/dashboard";
import { YesNoRadioField } from "component/shared/yes-no-radio-field";
import { User } from "model/user";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { saveUser } from "redux/slice/authentication";
import { RootState } from "redux/store";

interface Props
	extends PageProps,
		Router.RouteComponentProps,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {
	user: User;
}

interface State {
	autoDisableDelete: boolean;
	autoDisableEmailClient: boolean;
	autoDisableEmailRange: number;
	autoDisableEmailSummary: boolean;
	autoDisableLoginRange: number;
	autoDisableSignupRange: number;
	saving: boolean;
}

const mapStateToProps = (state: RootState) => {
	return {
		authLoading: state.authentication.loading,
		authError: state.authentication.error,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			saveUser,
		},
		dispatch
	);

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			...props.user,
			saving: false,
		};
	}

	public save() {
		const { saveUser } = this.props;

		const user = {
			...this.state,
		};
		saveUser({user});
		this.setState({ saving: true });
	}

	getRange = () => {
		let range = [];
		for (let i = 30; i <= 180; i = i + 30) {
			range.push(i);
		}
		return range;
	};

	public render() {
		const { authError, authLoading, user } = this.props;
		const {
			autoDisableDelete,
			autoDisableEmailClient,
			autoDisableEmailRange,
			autoDisableEmailSummary,
			autoDisableLoginRange,
			autoDisableSignupRange,
			saving,
		} = this.state;
		const title = "Low Activity";
		return (
			<DashboardLayout
				permitted={user && user.permissions.leads}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.User />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<FullPageCard>
					<Mui.Grid container direction="column" spacing={3}>
						<Mui.Grid item>
							<FullWidthDropdown<number>
								label="Hasn't received an email update within"
								onChange={(value) => {
									this.setState({
										autoDisableEmailRange: value,
									});
								}}
								value={autoDisableEmailRange}
							>
								{[
									<Mui.MenuItem key={0} value={0}>
										Ignore Emails
									</Mui.MenuItem>,
									...this.getRange().map((value) => (
										<Mui.MenuItem key={value} value={value}>
											{`Last ${value} Days`}
										</Mui.MenuItem>
									)),
								]}
							</FullWidthDropdown>
						</Mui.Grid>
						<Mui.Grid item>
							<FullWidthDropdown<number>
								label="Hasn't logged into Property Organizer within"
								onChange={(value) => {
									this.setState({
										autoDisableLoginRange: value,
									});
								}}
								value={autoDisableLoginRange}
							>
								{this.getRange().map((value) => (
									<Mui.MenuItem key={value} value={value}>
										{`Last ${value} Days`}
									</Mui.MenuItem>
								))}
							</FullWidthDropdown>
						</Mui.Grid>
						<Mui.Grid item>
							<FullWidthDropdown<number>
								label="Signed up for Property Organizer or Email Updates"
								onChange={(value) => {
									this.setState({
										autoDisableSignupRange: value,
									});
								}}
								value={autoDisableSignupRange}
							>
								{this.getRange().map((value) => (
									<Mui.MenuItem key={value} value={value}>
										{`Over ${value} Days Ago`}
									</Mui.MenuItem>
								))}
							</FullWidthDropdown>
						</Mui.Grid>
						<Mui.Grid item>
							<YesNoRadioField
								label="Send an email to each lead who has been auto-disabled with instructions for re-enabling."
								onChange={(value) => {
									this.setState({
										autoDisableEmailClient: value,
									});
								}}
								value={autoDisableEmailClient}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<YesNoRadioField
								label="Send me a daily email summarizing leads which have been disabled."
								onChange={(value) => {
									this.setState({
										autoDisableEmailSummary: value,
									});
								}}
								value={autoDisableEmailSummary}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<YesNoRadioField
								label="Permanently delete leads who have been auto-disabled."
								onChange={(value) => {
									this.setState({
										autoDisableDelete: value,
									});
								}}
								value={autoDisableDelete}
							/>
						</Mui.Grid>
					</Mui.Grid>{" "}
					<Mui.Grid container spacing={1} style={{ marginTop: 24 }}>
						<Mui.Grid item>
							<Mui.Button
								color="secondary"
								disabled={saving}
								onClick={() => this.save()}
								style={{ marginRight: 32 }}
								variant="contained"
							>
								Save Changes
							</Mui.Button>
							<Mui.Button
								onClick={() =>
									this.setState({
										saving: this.state.saving,
									})
								}
								variant="contained"
							>
								Cancel
							</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Snackbar
						open={saving && !authLoading && !authError}
						message="Settings saved"
						autoHideDuration={6000}
						onClose={() => this.setState({ saving: false })}
						action={
							<Mui.IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={() => this.setState({ saving: false })}
							>
								<Icons.X fontSize="small" />
							</Mui.IconButton>
						}
					>
						<MuiAlert severity="info">
							<Mui.Typography>Settings saved</Mui.Typography>
						</MuiAlert>
					</Mui.Snackbar>
				</FullPageCard>
			</DashboardLayout>
		);
	}
}

export const LeadLowActivityPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(Component);
