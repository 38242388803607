import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import * as Router from "react-router-dom";
import { MenuItem } from "./menu-item";
import * as paths from "routes/paths";
import * as Icons from "react-feather";
import { SidebarMenuItem } from "./sidebar-menu-item";
import { User } from "model/user";
import { activeChange } from "redux/slice/sidebar-menu";
import { ViewType } from "type/view-type";
import * as env from "shared/env";
import { getActive } from "redux/selector";
import * as credentials from "shared/credentials";
import { KestrelVersion } from "type/kestrel-version";
import { canShowSupplementalListings } from "redux/selector";

const mapStateToProps = (state: RootState) => {
	return {
		active: getActive(state),
		canShowSupplementalListings: canShowSupplementalListings(state)
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	activeChange,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles>
{
	user: User;
	viewType: ViewType;
}

class Component extends React.Component<Props> {

	public constructor(props: Props) {
		super(props);
		const { items } = this;
		const item = this.recursivelyFindActiveItem(items);
		if(item && item.id) {
			this.props.activeChange(item.id);
		}
	}

	public render() {
		const { items } = this;
		const { active, user, classes } = this.props;
		const displayUpgrade = user.permissions.crmUpgrade && active && (active.startsWith("0") || active.startsWith("4"));
		const creds = credentials.get()
		let upgradeUrl;
		let crmUpgradeUsername;
		let crmUpgradePassword;
		if(creds) {
			const { username, password } = creds;
			upgradeUrl = `${env.javaUrl}/account/upgrade/client/${user.clientId}`;
			crmUpgradeUsername = `${username}`;
			crmUpgradePassword = `${password}`;
		}

		return (
			<>
				{items.map((item, index) => (
					<SidebarMenuItem key={index} item={item} user={this.props.user} />
				))}
				{displayUpgrade && 
					<form action={creds && upgradeUrl} method="POST" target="_blank">
						<input type="hidden" name="crmUpgradeUsername" value={crmUpgradeUsername} />
						<input type="hidden" name="crmUpgradePassword" value={crmUpgradePassword} />
						<div className={classes.upgradeCrmText}>
							<Mui.Typography>Try our CRM</Mui.Typography>
						</div>
						<Mui.Button  
							type="submit" 
							className={classes.upgradeCrmButton}
						>
							Learn more
						</Mui.Button>
					</form>
				}
			</>
		) 
	}

	private get items() {
		const { user, canShowSupplementalListings } = this.props;
		const showSupplementalListings = user.permissions.supplementalListings && canShowSupplementalListings
		let items: MenuItem[] = [];
		if (!user.permissions.oneCrm) {
			if(user.admin) {
				items = [
					(user.permissions.leads) && {
						label: "Leads",
						className: "ihf-leads",
						icon: Icons.User,
						children: [
							{
								path: paths.lead,
							},
							user.permissions.crm && {
								label: "Dashboard",
								className: "ihf-leaddashboard",
								path: paths.leadDashboard,
							},
							user.permissions.crm && {
								label: "Lead List",
								className: "ihf-searchleads",
								path: paths.leads,
							},
							user.permissions.crmTasks && {
								label: "To Do",
								path: paths.tasks,
							},				
							{
								label: "Lead Activity",
								className: "ihf-leadactivity",
								path: paths.leadActivity,
							},
							(user.permissions.geographicRouting || user.permissions.roundRobinRouting) && {
								label: "Lead Routing",
								className: "ihf-leadrouting",
								path: paths.leadRouting,
							},
							!user.permissions.crm && {
								label: "Lead List",
								className: "ihf-searchleads",
								path: paths.leads,
							},
							{
								label: "Export Leads",
								className: "ihf-leadexport",
								path: paths.leadExport,
							},
							{
								label: "Import Leads",
								className: "ihf-leadimport",
								path: paths.LeadImport,
							},
							{
								label: "Low Activity Settings",
								className: "ihf-lowactivity",
								path: paths.leadLowActivity,
							},
							user.reseller.direct && {
								label: "Support Center",
								className: "ihf-supportCenter",
								path: paths.supportCenter,
							},
						],
					},
					user.permissions.markets && {
						label: "Markets",
						icon: Icons.MapPin,
						children: [
							{
								path: paths.market,
							},
							{
								label: "Markets",
								className: "ihf-savedsearchpages",
								path: paths.markets,
							},
							!env.marketsDev && {
								label: "Add New Market",
								className: "ihf-savedsearchpages",
								path: paths.marketCreate,
							},
							user.permissions.report && user.permissions.version === 9 && {
								label: "Market Settings",
								className: "ihf-savedsearchpages",
								path: paths.marketSettings,
							},
							user.reseller.direct && {
								label: "Support Center",
								className: "ihf-supportCenter",
								path: paths.supportCenter,
							},
						],
					},
					{
						label: "Listings",
						icon: Icons.Home,
						children: [
							{
								label: "Active",
								className: "ihf-activelistings",
								path: paths.listingsActive,
							},
							user.permissions.soldPendingListings && {
								label: "Sold/Off-Market",
								className: "ihf-soldpending",
								path: paths.listingsOffMarket,
							},
							showSupplementalListings && {
								label: "Supplemental",
								className: "ihf-supplemental",
								path: paths.listingsSupplemental,
							},
							user.permissions.markets && {
								label: "Markets (Saved Searches)",
								className: "ihf-savedsearchpages",
								path: paths.markets,
							},
							user.reseller.direct && {
								label: "Support Center",
								className: "ihf-supportCenter",
								path: paths.supportCenter,
							},
						],
					},
					user.permissions.reporting && {
						label: "Reports",
						icon: Icons.BarChart2,
						children: [
							{
								label: "Reports Overview",
								className: "ihf-reportsoverview",
								path: paths.reportsOverview,
							},
							{
								label: "Email Activity",
								className: "ihf-emailactivity",
								path: paths.reportsEmailActivity,
							},
							{
								label: "Organizer Activity",
								className: "ihf-organizeractivity",
								children: [
									{
										label: "Saved Searches",
										className: "ihf-emailactivity-savedsearches",
										path: paths.reportsSavedSearches,
									},
									{
										label: "Saved Properties",
										className: "ihf-emailactivity-savedprops",
										path: paths.reportSavedListings,
									},
								],
							},
							{
								label: "Leads & Contacts",
								className: "ihf-leadscontacts",
								path: paths.reportsLeads,
							},
							{
								label: "Mobile Statistics",
								className: "ihf-mobilestats",
								path: paths.reportsMobile,
							},
							user.reseller.direct && {
								label: "Support Center",
								className: "ihf-supportCenter",
								path: paths.supportCenter,
							},
						],
					},
					{
						label: "Setup",
						icon: Icons.Settings,
						children: [
							{
								label: "Account",
								className: "ihf-account",
								children: [
									(!env.agentAdminDev || (user.admin && !user.agentId )) && {
										label: "Overview",
										className: "ihf-setupoverview",
										path: paths.settingsOverview,
									},
									user.admin && user.permissions.standalone && !user.kestrelVersion.all && {
										label: "IDX Page Links",
										className: "ihf-siteLinks",
										path: paths.settingsPageLinks,
									},
									{
										label: `${env.agentAdminDev ? "Account" : "My Account"}`,
										className: "ihf-myaccount",
										path: paths.settingsAccount,
									},
									(env.agentAdminDev && (user.admin && user.agentId)) && {
										label: "My Profile",
										className: "ihf-myaccount",
										path: paths.settingsProfile,
									},
									/**
									 * This check should be a single check for legacy client login
									 */
									(!env.agentAdminDev || (user.admin && !user.agentId)) && {
										label: "Change Password",
										className: "ihf-changepassword",
										path: paths.settingsPassword,
									},
									user.permissions.billing && {
										label: "Billing Information",
										className: "ihf-billinginfo",
										path: paths.settingsBilling,
									},
									{
										label: "MLS Setup",
										className: "ihf-mlssetup",
										path: paths.settingsMls,
									},
									user.permissions.version >= 9 && {
										label: "Integrations",
										className: "ihf-integrations",
										path: paths.settingsIntegrations,
									},
								],
							},
							user.permissions.kestrelAll && user.kestrelVersion.all && {
								label: "Website",
								className: "ihf-website",
								children: [
									{
										label: "Version & Platform",
										className: "version-platform",
										path: paths.settingsVersion,
									},
									!user.websitePlatform.wordpress && user.kestrelVersion.all && {
										label: "SEO Pages",
										className: "seo-pages",
										path: paths.settingsPages,
									},
									!user.websitePlatform.wordpress && {
										label: "Widgets",
										className: "widgets",
										path: paths.settingsWidgets,
									},
									!user.websitePlatform.wordpress && {
										label: "CSS",
										className: "css",
										path: paths.settingsCss,
									},
								]
							},
							!(user.permissions.kestrelAll && user.kestrelVersion.all) && {
								label: "Version",
								className: "ihf-version",
								path: paths.settingsVersion,
							},
							!(user.permissions.kestrelAll && user.kestrelVersion.all) && user.permissions.plugin && {
								label: "WordPress Plugin",
								className: "ihf-oesettings",
								path: paths.settingsPlugin,
							},
							(user.permissions.standalone && user.kestrelVersion !== KestrelVersion.ALL) && {
								label: "Design & Branding",
								className: "ihf-designbranding",
								children: [
									{
										label: "Branding Setup",
										className: "ihf-brandingsetup",
										path: paths.settingsBranding,
									},
									{
										label: "Templates",
										className: "ihf-templates",
										path: paths.settingsTemplates,
									},
									user.permissions.sidebarWidgets && {
										label: "Widgets",
										className: "ihf-widgets",
										path: paths.settingsWidgets,
									},
									{
										label: "CSS",
										className: "ihf-css",
										path: paths.settingsCss,
									},
									{
										label: "Framing Setup",
										className: "ihf-framing",
										path: paths.settingsFraming,
									},
									{
										label: "Sub-Heads",
										className: "ihf-subheads",
										path: paths.settingsSubHeads,
									},
									user.permissions.version <= 8 && {
										label: "Page-Specific HTML",
										className: "ihf-pagehtml",
										path: paths.settingsPageSpecificBranding,
									},
								],
							},
							{
								label: "Alert & Email Settings",
								className: "ihf-emailsettings",
								children: [
									{
										label: "Basic Setup",
										className: "ihf-emailbasicsetup",
										path: paths.settingsEmail,
									},
									!user.permissions.plugin && {
										label: "Branding",
										className: "ihf-emailbranding",
										path: paths.settingsEmailBranding,
									},
									user.permissions.version <= 8 && {
										label: "Customization",
										className: "ihf-emailcustomization",
										path: paths.settingsEmailCustomization,
									},
									{
										label: "Advanced Routing",
										className: "ihf-emailadvrouting",
										path: paths.settingsEmailRouting,
									},
									user.permissions.version <= 8 && {
										label: "Geographic Routing",
										className: "ihf-georouting",
										path: paths.settingsEmailGeographicRouting,
									},
								],
							},
							(user.permissions.listingSearch || user.permissions.listingMapSearch) && {
								label: "Search Settings",
								className: "ihf-searchsettings",
								children: [
									{
										label: "Property Types",
										className: "ihf-propertytypesprices",
										path: paths.settingsPropertyTypes,
									},
									{
										label: "Location Customization",
										className: "ihf-searchareas",
										path: paths.settingsLocationCustomization,
									},
									{
										label: "Search Cities",
										className: "ihf-searchcities",
										path: paths.settingsSearchCities,
									},
									user.permissions.neighborhoods && {
										label: "Search Neighborhoods",
										className: "ihf-searchneighborhoods",
										path: paths.settingsSearchNeighborhoods,
									},
									{
										label: "Open House Settings",
										className: "ihf-openhomesettings",
										path: paths.settingsOpenHouse,
									},
									(user.permissions.listingMapSearch || user.permissions.listingPolygonSearch) && {
										label: "Map Search",
										className: "ihf-mapsearch",
										path: paths.settingsMapSearch,
									},
								],
							},
							(user.permissions.listingResults || user.permissions.listingDetail) && {
								label: "Listing Settings",
								className: "ihf-listingsettings",
								children: [
									user.permissions.listingResults && {
										label: "Results & Featured",
										className: "ihf-resultsfeatured",
										path: paths.settingsListingResults,
									},
									user.permissions.listingDetail && {
										label: "Property Details",
										className: "ihf-propertydetails",
										path: paths.settingsListingDetails,
									},
									user.permissions.listingDetail && {
										label: "Mortgage Calc Settings",
										path: paths.settingsMortgageCalculator,
									}
								],
							},
							user.permissions.leads && {
								label: "Lead Capture",
								className: "ihf-leadcapture",
								children: [
									user.permissions.leadUserRegistration && !user.permissions.leadForceUserRegistration && {
										label: "User Registration",
										className: "ihf-userregistration",
										path: paths.settingsUserRegistration,
									},
									{
										label: "Contact Forms",
										className: "ihf-contactforms",
										path: paths.settingsContactForms,
									},
									{
										label: "Property Organizer",
										className: "ihf-propertyorganizer",
										path: paths.settingsPropertyOrganizer,
									},
								],
							},
							(user.permissions.mobileSite) && {
								label:"Mobile Setup",
								className: "ihf-mobile",
								path: paths.settingsMobile,
							},
							user.permissions.broker && {
								label: "Agents & Offices",
								className: "ihf-agentoffices",
								children: [
									{
										label: "Basic Settings",
										className: "ihf-brokersettings",
										path: paths.settingsBroker,
									},
									{
										label: "Agents",
										className: "ihf-agents",
										path: paths.settingsAgents,
									},
									{
										label: "Offices",
										className: "ihf-offices",
										path: paths.settingsOffices,
									},
									{
										path: paths.settingsAgent,
									},
									{
										path: paths.settingsOffice,
									},
								],
							},
							user.reseller.direct && {
								label: "Support Center",
								className: "ihf-supportCenter",
								path: paths.supportCenter,
							},
						],
					},
					(user.permissions.campaigns && user.permissions.blastCampaigns) && {
						label: "Communications",
						icon: Icons.Send,
						children: [
							user.permissions.blastCampaigns && {
								label: "Send Email",
								children: [
									{
										path: paths.campaignDraftEmail,
									},
									{
										path: paths.campaignSentEmail,
									},
									{
										label: "New Email",
										path: paths.campaignNewEmail,
									},
									{
										label: "Email Drafts",
										path: paths.campaignDraftEmails,
									},
									{
										label: "Sent Emails",
										path: paths.campaignSentEmails,
									},
								],
							},
							{
								label: "Marketing Automation",
								children: [
									{
										path: paths.campaign,
									},
									{
										label: "Campaigns",
										path: paths.campaigns,
									},
									{
										label: "Automations",
										path: paths.campaignsRouting,
									},
									(user.permissions.automatedGreetings) && {
										label: "Automated Greetings",
										path: paths.campaignAutomatedGreetings,
									},
									{
										label: "Settings",
										path: paths.campaignsSettings,
									},
									user.reseller.direct && {
										label: "Help",
										path: "https://kb.ihomefinder.com/s/topic/0TO1Q000000kXBUWA2/marketing-automation",
										target: "_blank",
									},
								],
							},
							(user.permissions.dripMms && !user.pending) && {
								label: "Texting",
								className: "text-messaging",
								path: paths.campaignsTexting,
							},
							user.reseller.direct && {
								label: "Support Center",
								className: "ihf-supportCenter",
								path: paths.supportCenter,
							},
						],
					},
					(user.permissions.campaigns && !user.permissions.blastCampaigns) && {
						label: "Communications",
						icon: Icons.Send,
						children: [
							{
								path: paths.campaign,
							},
							{
								label: "Campaigns",
								path: paths.campaigns,
							},
							{
								label: "Automations",
								path: paths.campaignsRouting,
							},
							{
								label: "Settings",
								path: paths.campaignsSettings,
							},
							user.reseller.direct && {
								label: "Help",
								path: "https://kb.ihomefinder.com/s/topic/0TO1Q000000kXBUWA2/marketing-automation",
								target: "_blank",
							},
						],
					},
				] as MenuItem[];
			} else if(user.type.agent) {
				items = [
					{
						label: "",
						children: [
							{
								label: "Welcome",
								path: paths.leadActivity,
							},
							user.permissions.modifySubscriptions && {
								label: "Manage Leads",
								path: paths.leads,
							},
							user.permissions.modifyListings && {
								label: "Manage Listings",
								path: paths.listingsActive,
							},
							user.permissions.modifyBio && {
								label: "Modify Bio",
								path: paths.settingsAccount,
							},
							user.permissions.modifyCredentials && {
								label: "Change Password",
								path: paths.settingsPassword,
							},
							user.permissions.modifySubscriptions && {
								label: "Email Message",
								path: paths.settingsEmail,
							},
							user.permissions.modifySubscriptions && {
								label: "Manage Subscribers",
								path: paths.markets,
							},
						],
					},
				] as MenuItem[];
			}
		} else {
			if(user.admin) {
				items = [
					{
						label: "Leads",
						className: "ihf-leads",
						icon: Icons.User,
						children: [
							{
								label: "Dashboard",
								path: paths.oneBusinessDashboard,
							},
							user.permissions.crm && {
								label: "Dashboard",
								className: "ihf-leaddashboard",
								path: paths.leadDashboard,
							},
							user.permissions.crm && {
								label: "Lead List",
								className: "ihf-searchleads",
								path: paths.leads,
							},
							user.permissions.crmTasks && {
								label: "To Do",
								path: paths.tasks,
							},
							{
								label: "Leads",
								children: [
									{
										label: "Lead List",
										path: paths.oneLeads,
									},
									{
										label: "Add Lead",
										path: paths.oneAddNewLead,
									},
									{
										label: "Active Prospects",
										path: paths.oneActiveProspects,
									},
									{
										label: "Deals",
										children: [
											{
												label: "Pending Deals",
												path: paths.onePendingProspects,
											},
											{
												label: "Closed Deals",
												path: paths.oneClosedProspects,
											},
										],
									},
									{
										label: "Contacts",
										path: paths.oneContactManagement,
									},
									{
										label: "List Manager",
										path: paths.oneListManager,
									},
								],
							},
						],
					},
					user.permissions.markets && {
						label: "Markets",
						icon: Icons.MapPin,
						children: [
							{
								path: paths.market,
							},
							{
								label: "Markets",
								className: "ihf-savedsearchpages",
								path: paths.markets,
							},
							!env.marketsDev && {
								label: "Add New Market",
								className: "ihf-savedsearchpages",
								path: paths.marketCreate,
							},
							user.permissions.report && user.permissions.version === 9 && {
								label: "Market Settings",
								className: "ihf-savedsearchpages",
								path: paths.marketSettings,
							},
						],
					},
					{
						label: "Listings",
						icon: Icons.Home,
						children: [
							{
								label: "Active",
								className: "ihf-activelistings",
								path: paths.listingsActive,
							},
							user.permissions.soldPendingListings && {
								label: "Sold/Off-Market",
								className: "ihf-soldpending",
								path: paths.listingsOffMarket,
							},
							showSupplementalListings && {
								label: "Supplemental",
								className: "ihf-supplemental",
								path: paths.listingsSupplemental,
							},
							user.permissions.markets && {
								label: "Markets (Saved Searches)",
								className: "ihf-savedsearchpages",
								path: paths.markets,
							},
						],
					},
					user.permissions.reporting && {
						label: "Reports",
						icon: Icons.BarChart2,
						children: [
							{
								label: "Reports Overview",
								className: "ihf-reportsoverview",
								path: paths.reportsOverview,
							},
							{
								label: "Email Activity",
								className: "ihf-emailactivity",
								path: paths.reportsEmailActivity,
							},
							{
								label: "Organizer Activity",
								className: "ihf-organizeractivity",
								children: [
									{
										label: "Saved Searches",
										className: "ihf-emailactivity-savedsearches",
										path: paths.reportsSavedSearches,
									},
									{
										label: "Saved Properties",
										className: "ihf-emailactivity-savedprops",
										path: paths.reportSavedListings,
									},
								],
							},
							{
								label: "Leads & Contacts",
								className: "ihf-leadscontacts",
								path: paths.reportsLeads,
							},
							{
								label: "Mobile Statistics",
								className: "ihf-mobilestats",
								path: paths.reportsMobile,
							},
						],
					},
					{
						label: "Setup",
						icon: Icons.Settings,
						children: [
							{
								label: "Account",
								className: "ihf-account",
								children: [
									{
										label: "Overview",
										className: "ihf-setupoverview",
										path: paths.settingsOverview,
									},
									user.permissions.standalone && !user.kestrelVersion.all && {
										label: "IDX Page Links",
										className: "ihf-siteLinks",
										path: paths.settingsPageLinks,
									},
									{
										label: "My Account",
										className: "ihf-myaccount",
										path: paths.settingsAccount,
									},
									{
										label: "Change Password",
										className: "ihf-changepassword",
										path: paths.settingsPassword,
									},
									user.permissions.billing && {
										label: "Billing Information",
										className: "ihf-billinginfo",
										path: paths.settingsBilling,
									},
									{
										label: "MLS Setup",
										className: "ihf-mlssetup",
										path: paths.settingsMls,
									},
									user.permissions.version >= 9 && {
										label: "Integrations",
										className: "ihf-integrations",
										path: paths.settingsIntegrations,
									},
								],
							},
							{
								label: "Version",
								className: "ihf-version",
								path: paths.settingsVersion,
							},
							user.permissions.plugin && {
								label: "WordPress Plugin",
								className: "ihf-oesettings",
								path: paths.settingsPlugin,
							},
							user.permissions.standalone && {
								label: "Design & Branding",
								className: "ihf-designbranding",
								children: [
									{
										label: "Branding Setup",
										className: "ihf-brandingsetup",
										path: paths.settingsBranding,
									},
									{
										label: "Templates",
										className: "ihf-templates",
										path: paths.settingsTemplates,
									},
									user.permissions.sidebarWidgets && {
										label: "Widgets",
										className: "ihf-widgets",
										path: paths.settingsWidgets,
									},
									{
										label: "CSS",
										className: "ihf-css",
										path: paths.settingsCss,
									},
									{
										label: "Framing Setup",
										className: "ihf-framing",
										path: paths.settingsFraming,
									},
									{
										label: "Sub-Heads",
										className: "ihf-subheads",
										path: paths.settingsSubHeads,
									},
									user.permissions.version <= 8 && {
										label: "Page-Specific HTML",
										className: "ihf-pagehtml",
										path: paths.settingsPageSpecificBranding,
									},
								],
							},
							{
								label: "Alert & Email Settings",
								className: "ihf-emailsettings",
								children: [
									{
										label: "Basic Setup",
										className: "ihf-emailbasicsetup",
										path: paths.settingsEmail,
									},
									!user.permissions.plugin && {
										label: "Branding",
										className: "ihf-emailbranding",
										path: paths.settingsEmailBranding,
									},
									user.permissions.version <= 8 && {
										label: "Customization",
										className: "ihf-emailcustomization",
										path: paths.settingsEmailCustomization,
									},
									{
										label: "Advanced Routing",
										className: "ihf-emailadvrouting",
										path: paths.settingsEmailRouting,
									},
									user.permissions.version <= 8 && {
										label: "Geographic Routing",
										className: "ihf-georouting",
										path: paths.settingsEmailGeographicRouting,
									},
								],
							},
							(user.permissions.listingSearch || user.permissions.listingMapSearch) && {
								label: "Search Settings",
								className: "ihf-searchsettings",
								children: [
									{
										label: "Property Types",
										className: "ihf-propertytypesprices",
										path: paths.settingsPropertyTypes,
									},
									{
										label: "Location Customization",
										className: "ihf-searchareas",
										path: paths.settingsLocationCustomization,
									},
									{
										label: "Search Cities",
										className: "ihf-searchcities",
										path: paths.settingsSearchCities,
									},
									user.permissions.neighborhoods && {
										label: "Search Neighborhoods",
										className: "ihf-searchneighborhoods",
										path: paths.settingsSearchNeighborhoods,
									},
									{
										label: "Open House Settings",
										className: "ihf-openhomesettings",
										path: paths.settingsOpenHouse,
									},
									(user.permissions.listingMapSearch || user.permissions.listingPolygonSearch) && {
										label: "Map Search",
										className: "ihf-mapsearch",
										path: paths.settingsMapSearch,
									},
								],
							},
							(user.permissions.listingResults || user.permissions.listingDetail) && {
								label: "Listing Settings",
								className: "ihf-listingsettings",
								children: [
									user.permissions.listingResults && {
										label: "Results & Featured",
										className: "ihf-resultsfeatured",
										path: paths.settingsListingResults,
									},
									user.permissions.listingDetail && {
										label: "Property Details",
										className: "ihf-propertydetails",
										path: paths.settingsListingDetails,
									},
									user.permissions.listingDetail && {
										label: "Mortgage Calc Settings",
										path: paths.settingsMortgageCalculator,
									}
								],
							},
							user.permissions.leads && {
								label: "Lead Capture",
								className: "ihf-leadcapture",
								children: [
									user.permissions.leadUserRegistration && !user.permissions.leadForceUserRegistration && {
										label: "User Registration",
										className: "ihf-userregistration",
										path: paths.settingsUserRegistration,
									},
									{
										label: "Contact Forms",
										className: "ihf-contactforms",
										path: paths.settingsContactForms,
									},
									{
										label: "Property Organizer",
										className: "ihf-propertyorganizer",
										path: paths.settingsPropertyOrganizer,
									},
								],
							},
							(user.permissions.mobileSite) && {
								label: "Mobile Setup",
								className: "ihf-mobile",
								path: paths.settingsMobile,
							},
							user.permissions.broker && {
								label: "Agents & Offices",
								className: "ihf-agentoffices",
								children: [
									{
										label: "Basic Settings",
										className: "ihf-brokersettings",
										path: paths.settingsBroker,
									},
									{
										label: "Agents",
										className: "ihf-agents",
										path: paths.settingsAgents,
									},
									{
										label: "Offices",
										className: "ihf-offices",
										path: paths.settingsOffices,
									},
									{
										path: paths.settingsAgent,
									},
									{
										path: paths.settingsOffice,
									},
								],
							},
						],
					},
					user.onePermissions.sendEmail && {
						label: "Communications",
						icon: Icons.Send,
						children: [
							{
								label: "Email",
								children: [
									{
										label: "Reports",
										path: paths.oneEmailUsage,
									},
									{
										label: "Send Email",
										path: paths.oneBlastEmail,
									},
									{
										label: "Queue",
										path: paths.oneEmailQueue,
									},
								],
							},
							{
								label: "Groups",
								path: paths.oneGroups,
							},
							{
								label: "Campaigns",
								path: paths.oneCampaigns,
							},
						],
					},
					{
						label: "CRM Tools",
						icon: Icons.Sliders,
						children: [
							{
								label: "Team Management",
								path: paths.oneTeamList,
							},
							{
								label: "Business Process Settings",
								path: paths.oneBusinessProcessSettings,
							},
							{
								label: "Action Plan Templates",
								path: paths.oneActionPlanTemplateManagement,
							},
							{
								label: "Marketing Expenses",
								path: paths.oneMarketingExpenses,
							},
							{
								label: "Report Manager",
								path: paths.oneReports,
							}
						],
					},
				] as MenuItem[];
			} else if(user.type.agent) {
				items = [
					{
						label: "Leads",
						className: "ihf-leads",
						icon: Icons.User,
						children: [
							{
								label: "Dashboard",
								path: paths.oneBusinessDashboard,
							},
							{
								label: "Leads",
								children: [
									{
										label: "Lead List",
										path: paths.oneLeads,
									},
									{
										label: "Add Lead",
										path: paths.oneAddNewLead,
									},
									{
										label: "Active Prospects",
										path: paths.oneActiveProspects,
									},
									{
										label: "Deals",
										children: [
											{
												label: "Pending Deals",
												path: paths.onePendingProspects,
											},
											{
												label: "Closed Deals",
												path: paths.oneClosedProspects,
											},
										],
									},
									{
										label: "Contacts",
										path: paths.oneContactManagement,
									},
									{
										label: "List Manager",
										path: paths.oneListManager,
									},
								],
							},
						],
					},
					{
						label: "Listings",
						icon: Icons.Home,
						children: [
							{
								label: "Active",
								className: "ihf-activelistings",
								path: paths.listingsActive,
							},
						],
					},
					{
						label: "IDX Setup",
						icon: Icons.Settings,
						children: [
							{
								path: paths.settingsOverview
							},
							user.permissions.modifyListings && {
								label: "Manage Listings",
								path: paths.listingsActive,
							},
							user.permissions.modifyBio && {
								label: "Modify Bio",
								path: paths.settingsAccount,
							},
							user.permissions.modifyCredentials && {
								label: "Change Password",
								path: paths.settingsPassword,
							},
							user.permissions.modifySubscriptions && {
								label: "Email Message",
								path: paths.settingsEmail,
							},
							user.permissions.modifySubscriptions && {
								label: "Manage Subscribers",
								path: paths.oneMarkets,
							},
						],
					},
					user.onePermissions.sendEmail && {
						label: "Communications",
						icon: Icons.Send,
						children: [
							{
								label: "Email",
								children: [
									{
										label: "Reports",
										path: paths.oneEmailUsage,
									},
									{
										label: "Send Email",
										path: paths.oneBlastEmail,
									},
									{
										label: "Queue",
										path: paths.oneEmailQueue,
									},
								],
							},
							{
								label: "Groups",
								path: paths.oneGroups,
							},
							{
								label: "Campaigns",
								path: paths.oneCampaigns,
							},
						],
					},
					{
						label: "CRM Tools",
						icon: Icons.Sliders,
						children: [
							{
								label: "Team Management",
								path: paths.oneTeamList,
							},
							{
								label: "Business Process Settings",
								path: paths.oneBusinessProcessSettings,
							},
							{
								label: "Action Plan Templates",
								path: paths.oneActionPlanTemplateManagement,
							},
							{
								label: "Marketing Expenses",
								path: paths.oneMarketingExpenses,
							},
							{
								label: "Report Manager",
								path: paths.oneReports,
							}
						],
					},
				] as MenuItem[];
			}
		}
		items = items.filter(function f(o: any) {
			if(!(o instanceof Object)) {
				return false;
			}
			if(o.children) {
				o.children = o.children.filter(f);
			}
			return true;
		})
		this.recursivelyGenerateIds(items, "");
		return items;
	}

	private recursivelyGenerateIds(items: MenuItem[], parentId: string) {
		items.forEach((item, index) => {
			item.id = (parentId ? parentId + "." : "") + index;
			if(item.children) {
				this.recursivelyGenerateIds(item.children, item.id);
			}
		})
	}

	private recursivelyFindActiveItem(items: MenuItem[]): MenuItem | undefined {
		const path = this.props.match.path;
		for(let i = 0; i < items.length; i++) {
			const item = items[i];
			if(item.path === path) {
				return item;
			}
			if(item.children) {
				const result = this.recursivelyFindActiveItem(item.children);
				if(result) {
					return result;
				}
			}
		}
	}

}

export const SidebarMenu = Mui.withStyles(styles)(
	Router.withRouter(
		connect(mapStateToProps, mapDispatchToProps)(Component)
	)
);