import React from 'react';
import * as Icons from "react-feather";
import { styles } from './style';
import * as Mui from '@material-ui/core';

interface Props extends OwnProps,
	Mui.WithStyles<typeof styles> {}

interface OwnProps {
	onClose: () => void;
	title: string;	
}

class Component extends React.Component<Props> {

	render() {
		const { classes, title }  = this.props;
		return (
			<Mui.DialogTitle>
				<Mui.Grid container direction="column">
					<Mui.Grid item>
						<Mui.Typography>
							{title}
						</Mui.Typography>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.IconButton
					className={classes.closeButton}
					onClick={() => this.props.onClose()}
				>
					<Icons.X />
				</Mui.IconButton>
			</Mui.DialogTitle>
		);
	}
}

export const DialogTitle = Mui.withStyles(styles)(Component);