import React from "react";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as Mui from "@material-ui/core";
import { styles } from "./styles";
import { updateCampaignMessage } from "redux/slice/campaign-messages";
import { withMediaQuery, WithMediaQuery } from "component/shared/with-media-query";
import { CampaignCampaignMessage } from "model/campaign-campaign-message";
import { TimeUnitType } from "type/time-unit";
import { Select } from "component/shared/select";
import { CampaignMessage } from "model/campaign-message";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateCampaignMessage,
}, dispatch);

interface OwnProps {
	campaignCampaignMessage: CampaignCampaignMessage,
}

type Props = OwnProps &
	Mui.WithStyles<typeof styles> &
	WithMediaQuery &
	ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps>;

interface State {
	timeToSendUnit: TimeUnitType,
	timeToSendNumber: number,
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			timeToSendUnit: TimeUnitType.DAYS,
			timeToSendNumber: 1,
		}
	}

	public componentDidMount() {
		const campaignMessage = this.props.campaignCampaignMessage.campaignMessage;
		if(campaignMessage.timeToSendNumber && campaignMessage.timeToSendUnit) {
			this.setState({
				timeToSendUnit: campaignMessage.timeToSendUnit,
				timeToSendNumber: campaignMessage.timeToSendNumber,
			});
		}
	}

	public componentDidUpdate(prevProps: Props) {
		const campaignMessage = this.props.campaignCampaignMessage.campaignMessage;
		if(
			(prevProps.campaignCampaignMessage.campaignMessage.timeToSendNumber !== campaignMessage.timeToSendNumber) &&
			(prevProps.campaignCampaignMessage.campaignMessage.timeToSendUnit !== campaignMessage.timeToSendUnit)
		) {
			if(campaignMessage.timeToSendNumber && campaignMessage.timeToSendUnit) {
				this.setState({
					timeToSendUnit: campaignMessage.timeToSendUnit,
					timeToSendNumber: campaignMessage.timeToSendNumber,
				});
			}
		}
	}

	private handleTimeToSendUnit(value: "minutes" | "days") {
		const timeUnitType = TimeUnitType.getById(value);
		if(timeUnitType) {
			this.setState({timeToSendUnit: timeUnitType}, () => this.save());
		}
	}

	private handleTimeToSendNumber(value: string) {
		const timeToSendNumber = parseInt(value);
		if(timeToSendNumber) {
			this.setState({timeToSendNumber: timeToSendNumber});
		}
	}

	private save() {
		const campaignMessage: CampaignMessage = {...this.props.campaignCampaignMessage.campaignMessage};
		if (this.state.timeToSendNumber && this.state.timeToSendUnit) {
			campaignMessage.timeToSendNumber = this.state.timeToSendNumber;
			campaignMessage.timeToSendUnit = this.state.timeToSendUnit;
		}
		this.props.updateCampaignMessage({campaignMessage});
	}

	public render() {
		const {timeToSendUnit, timeToSendNumber} = this.state;
		const { campaignCampaignMessage, classes } = this.props;
		return (
			<Mui.Grid container direction="row" alignItems="center" spacing={1} wrap="nowrap">
				<Mui.Grid item className={classes.label}>
					Send After:
				</Mui.Grid>
				<Mui.Grid item > 
					<Mui.Grid container direction="row" alignItems="center" spacing={1}>
						<Mui.Grid item xs={4}>
							<Mui.TextField
								type="number" 
								variant="outlined" 
								margin="dense" 
								size="small"
								value={timeToSendNumber}
								onChange={(element) => this.handleTimeToSendNumber(element.currentTarget.value)}
								onBlur={() => this.save()}
								className={classes.field}

							/>
						</Mui.Grid>
						<Mui.Grid item xs={8} className={classes.minute}>
							<Select
								fullWidth
								variant="outlined"
								margin="dense"
								placeholder="Select"
								value={timeToSendUnit && timeToSendUnit.id}
								options={TimeUnitType.values()}
								valueExtractor={type => type.id}
								labelExtractor={
									type => campaignCampaignMessage.campaignMessage.timeToSendNumber &&
									campaignCampaignMessage.campaignMessage.timeToSendNumber > 1 ? type.pluralLabel : type.label
								}
								optionRenderer={type => <Mui.ListItemText primary={type.label} />}
								onChange={value => this.handleTimeToSendUnit(value)}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</Mui.Grid>
		);
	};
}

export const SendAfter = withMediaQuery("(max-width:375px)")(Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
));