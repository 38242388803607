import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Agent } from "model/agent";
import { updateAgent, createAgent, resetError} from "redux/slice/agents";
import { deleteAgentBoard, createAgentBoard, updateAgentBoard } from "redux/slice/agent-boards";
import { getAgentBoardsForAgent, getBoardsForUser } from "redux/selector";
import { getOffices } from "redux/selector";
import { RootState, getPayload } from "redux/store";
import { AgentStaffType } from "type/agent-staff";
import { styles } from "./style";
import { getLanguages } from "redux/selector";
import { BasicInfoForm } from "./basic-info";
import { AgentPageSettings } from "./agent-page-settings";
import { ControlPanelSettings } from "./control-panel-settings";
import { LeadCaptureSettings } from "./lead-capture-settings";
import { BasicInfoFields } from "./form-fields/basic-Info-fields";
import { ControlPanelSettingsFields } from "./form-fields/control-panel-settings-fields";
import { AgentPageSettingsFields } from "./form-fields/agent-page-settings-fields";
import { LeadCaptureSettingsFields } from "./form-fields/lead-capture-settings-fields";
import * as env from "shared/env";
import { SaveButton } from "component/shared/save-button";

interface Props
	extends OwnProps,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		Mui.WithStyles<typeof styles> {}

interface OwnProps {
	agent: Agent;
}

interface State {
	basicInfoFields: BasicInfoFields;
	agentPageSettingsFields: AgentPageSettingsFields;
	leadCaptureSettingsFields: LeadCaptureSettingsFields;
	controlPanelSettingsFields:  ControlPanelSettingsFields
	submitted: boolean;
	formError?: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		agentBoards: getAgentBoardsForAgent(state, ownProps.agent.id),
		loading: state.agents.loading || state.agentBoards.loading || state.boards.loading,
		boards: getBoardsForUser(state),
		offices: getOffices(state),
		languages: getLanguages(state),
		agentError: state.agents.error,
		usernameConflict: state.agents.error === "conflict",
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			createAgent,
			updateAgent,
			createAgentBoard,
			updateAgentBoard,
			deleteAgentBoard,
			resetError,
		},
		dispatch
	);

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		const { agent } = props;
		
		this.state = {
			basicInfoFields: {
				firstName: agent.firstName || "",
				lastName: agent.lastName || "",
				designation: agent.designation || "",
				email: agent.email || "",
				username: agent.username || "",
				password: agent.password || "",
				homePhone: agent.homePhone || "",
				officePhone: agent.officePhone || "",
				homeFax: agent.homeFax || "",
				officeFax: agent.officeFax || "",
				cellPhone: agent.cellPhone || "",
				staffType: agent.staffType || AgentStaffType.AGENT,
				code: agent.code || "",
				officeAddress: agent.officeAddress || "",
				officeCity: agent.officeCity || "",
				officeState: agent.officeState || "",
				officeZip: agent.officeZip || "",
				photoSource: agent.photoUrl ? "photoUrl" : "uploadPhoto",
				photoUrl: agent.photoUrl || "",
				agentBoards: props.agentBoards,
				officeId: (agent.office && agent.office.id) || 0,
				languageIds: agent.languageIds || [],
				deletedAgentBoardIds: new Set<number>(),
			},
			agentPageSettingsFields: {
				head: agent.head || "",
				body: agent.body || "",
				allowModifyBio: agent.allowModifyBio,
				hasOwnSite: agent.hasOwnSite,
				redirectUrl: agent.redirectUrl || "",	
				enableAgentLink: agent.enableAgentLink,
			},
			leadCaptureSettingsFields: {
				showOnSignupDropDown: agent.showOnSignupDropDown,
				emailHeader: agent.emailHeader || "",
				sendEmailUpdatesToAgent: agent.sendEmailUpdatesToAgent,	
			},
			controlPanelSettingsFields: {
				allowModifyUserPass: agent.allowModifyUserPass,
				allowModifyListings: agent.allowModifyListings,
				allowModifySubscribers: agent.allowModifySubscribers,
				username: agent.username || "",
				password: agent.password || "",
				admin: agent.admin,
			},
			submitted: false,
		};
	}

	public componentDidMount() {}

	public componentDidUpdate(prevProps: Props) {}

	private submit = async () => {
		const { agent, offices, } = this.props;
		const {
			basicInfoFields,
			agentPageSettingsFields,
			leadCaptureSettingsFields,
			controlPanelSettingsFields,
			formError,
		} = this.state;
		const office = offices.find((office) => office.id === basicInfoFields.officeId);
		if (!formError && basicInfoFields.email) {
			const agentResource = getPayload(await this.props.updateAgent( { agent: 
					{
						...agent,
						firstName: basicInfoFields.firstName,
						lastName: basicInfoFields.lastName,
						designation: basicInfoFields.designation,
						email: basicInfoFields.email,
						username: env.agentAdminDev ? controlPanelSettingsFields.username : basicInfoFields.username,
						password: env.agentAdminDev ? controlPanelSettingsFields.password : basicInfoFields.password,
						homePhone: basicInfoFields.homePhone,
						officePhone: basicInfoFields.officePhone,
						homeFax: basicInfoFields.homeFax,
						officeFax: basicInfoFields.officeFax,
						cellPhone: basicInfoFields.cellPhone,
						staffType: basicInfoFields.staffType,
						code: basicInfoFields.code,
						officeAddress: basicInfoFields.officeAddress,
						officeCity: basicInfoFields.officeCity,
						officeState: basicInfoFields.officeState,
						officeZip: basicInfoFields.officeZip,
						office: office ? office : undefined,
						photoUrl: basicInfoFields.photoUrl,
						head: agentPageSettingsFields.head,
						body: agentPageSettingsFields.body,
						allowModifyBio: agentPageSettingsFields.allowModifyBio,
						hasOwnSite: agentPageSettingsFields.hasOwnSite,
						redirectUrl: agentPageSettingsFields.redirectUrl,
						enableAgentLink: agentPageSettingsFields.enableAgentLink,
						showOnSignupDropDown: leadCaptureSettingsFields.showOnSignupDropDown,
						emailHeader: leadCaptureSettingsFields.emailHeader,
						sendEmailUpdatesToAgent: leadCaptureSettingsFields.sendEmailUpdatesToAgent,
						allowModifyUserPass: controlPanelSettingsFields.allowModifyUserPass,
						allowModifyListings: controlPanelSettingsFields.allowModifyListings,
						allowModifySubscribers: controlPanelSettingsFields.allowModifySubscribers,
						admin: controlPanelSettingsFields.admin,
					}
				}));
			 
			if(agentResource.id) {
				const agentId = agentResource.id;
				// delete
				{
					const agentBoards = basicInfoFields.agentBoards.filter(agentBoard => agentBoard.id && !agentBoard.agentId);
					for(const agentBoard of agentBoards) {
						await this.props.deleteAgentBoard({agentBoard});					
					}
				}
				// update
				{
					const agentBoards = basicInfoFields.agentBoards.filter(agentBoard => agentBoard.id && agentBoard.agentCode);
					for(const agentBoard of agentBoards) {
						await this.props.updateAgentBoard({agentBoard, agentId});					
					}
				}
				// create
				{
					const agentBoards = basicInfoFields.agentBoards.filter(agentBoard => !agentBoard.id);
					for(const agentBoard of agentBoards) {
						await this.props.createAgentBoard({agentBoard, agentId});					
					}
				}
			}
		} 
	};
	
	render() {
		const { agent, loading, usernameConflict } = this.props;
		const {
			basicInfoFields,
			agentPageSettingsFields,
			leadCaptureSettingsFields,
			controlPanelSettingsFields,
			submitted,
			formError,
		} = this.state;
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Mui.Accordion defaultExpanded>
						<Mui.AccordionSummary expandIcon={<Icons.ChevronDown />}>
							<Mui.Typography>Basic Info</Mui.Typography>
						</Mui.AccordionSummary>
						<Mui.AccordionDetails>
							<BasicInfoForm 
								fields={basicInfoFields}
								agent={agent}
								submitted={submitted}
								onChange={(fields, errors) => {
									this.setState({basicInfoFields: fields, formError: errors});

								}}
							/>
						</Mui.AccordionDetails>
					</Mui.Accordion>
					<Mui.Accordion>
						<Mui.AccordionSummary expandIcon={<Icons.ChevronDown />}>
							<Mui.Typography>Bio & Page Header</Mui.Typography>
						</Mui.AccordionSummary>
						<Mui.AccordionDetails>
							<AgentPageSettings 
								fields={agentPageSettingsFields}
								onChange={(fields) => {
									this.setState({agentPageSettingsFields: fields});
								}}
							/>
						</Mui.AccordionDetails>
					</Mui.Accordion>
					<Mui.Accordion>
						<Mui.AccordionSummary expandIcon={<Icons.ChevronDown />}>
							<Mui.Typography>Lead Capture & Email Alert Settings</Mui.Typography>
						</Mui.AccordionSummary>
						<Mui.AccordionDetails>
							<LeadCaptureSettings 
								fields={leadCaptureSettingsFields}
								onChange={(fields) => {
									this.setState({leadCaptureSettingsFields: fields});
								}}
							/>
						</Mui.AccordionDetails>
					</Mui.Accordion>
					<Mui.Accordion>
						<Mui.AccordionSummary expandIcon={<Icons.ChevronDown />}>
							<Mui.Typography>Agent Control Panel Settings</Mui.Typography>
						</Mui.AccordionSummary>
						<Mui.AccordionDetails>
							<ControlPanelSettings 
								fields={controlPanelSettingsFields}
								agent={agent}
								submitted={submitted}
								onChange={(fields) => {
									this.setState({controlPanelSettingsFields: fields});
								}}
							/>
						</Mui.AccordionDetails>
					</Mui.Accordion>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Grid container justifyContent="flex-end" spacing={2}>
						<Mui.Grid item>
							<SaveButton 
								loading={submitted && !!loading}
								error={submitted && formError ? "Please fix the highlighted errors" : undefined} 
								label={{
									primary: "Save",
									inProgress: "Saving...",
									completed: usernameConflict ? "Error" : "Saved",
								}}
								snackBar={true}
								onClick={() => {
									this.setState({submitted: true})
									this.submit();
								}}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const Content = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
