import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import * as Mui from "@material-ui/core";
import * as Router from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "redux/store";
import { getUser } from "redux/selector";
import { Agent } from "model/agent";
import { Info } from "./info";
import { styles } from "./styles";
import { AgentSettingsTab } from "./tab-agent-settings";
import { BioTab } from "./tab-bio";
import { LeadCaptureTab } from "./tab-email-header";

interface Props extends OwnProps, 
	Router.RouteComponentProps<RouteParams>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
}

interface OwnProps {
	agent: Agent;
}

interface State {
	tab: number;
}

interface RouteParams {
	id: string;
}

const mapStateToProps = (state: RootState, ownProps: Router.RouteComponentProps<RouteParams>) => {
	const user = getUser(state);
	return {
		user,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

class Component extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			tab: 0,
		}
	}

	public render() {
		const { user, agent, classes } = this.props;
		const { tab } = this.state;

		if (!agent) {
			return <>Error</>;
		}
		return (
			<Mui.Grid container spacing={2}>
				<Mui.Grid item lg={4} sm={6} xs={12}>
					<Mui.Card elevation={2}>
						<Mui.CardHeader 
							title="Profile" 
							classes={{
								root: classes.root,
								title: classes.title,
							}} 
						/>
						<Mui.CardContent style={{padding: 25, paddingTop: 10}}>
							<Info agent={agent} user={user} />
						</Mui.CardContent>
					</Mui.Card>
				</Mui.Grid>
				<Mui.Grid item lg={8} sm={6}>
					<Mui.Grid container direction="column" spacing={2} style={{marginLeft: 5}}>
						<Mui.Grid item>
							<Mui.Tabs
								value={tab}
								onChange={(event, tabIndex) => this.setState({tab: tabIndex})}
							>
								<Mui.Tab label="Agent Settings" />
								<Mui.Tab label="Public Bio" />
								<Mui.Tab label="Email Header" />
							</Mui.Tabs>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid item lg={8} sm={6}>
								{tab === 0 && (
									<AgentSettingsTab agent={agent} />
								)}
								{tab === 1 && (
									<BioTab agent={agent} />
								)}
								{tab === 2 && (
									<LeadCaptureTab agent={agent} />
								)}
							</Mui.Grid>
							
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</Mui.Grid>
		);
	}

}

export const Profile = Mui.withStyles(styles)(Router.withRouter(connect(mapStateToProps, mapDispatchToProps)(Component)));