import { arrayToById, objectToById, arrayToByForeignId, objectToByForeignId, mergeByForeignId } from "redux/normalize";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { LeadAnniversary, NewLeadAnniversary } from "model/lead-anniversary";
import { LeadAnniversaryResource } from "api/resource/lead-anniversary";

export interface LeadAnniversariesState {
    loading?: boolean;
    error?: string | null;
    byId: {
        [key: number]: LeadAnniversaryResource;
    };
    byLeadId: {
        [key: number]: number[];
    };
};

const initialState: LeadAnniversariesState = {
    byId: {},
    byLeadId: {},
};

const fetchLeadAnniversaries = createAsyncThunk("leadAnniversaries/fetchLeadAnniversaries", async (payload, thunkAPI) => {
    thunkAPI.dispatch(leadAnniversariesLoading());
    const result = api.getLeadAnniversaries();
    result
        .then((leadAnniversaries) => thunkAPI.dispatch(leadAnniversariesLoaded(leadAnniversaries)))
        .catch(error => thunkAPI.dispatch(leadAnniversariesFailed(error)));
    return result;
});

const createLeadAnniversary = createAsyncThunk("leadAnniversaries/createLeadAnniversary", async (payload: { leadAnniversary: NewLeadAnniversary }, thunkAPI) => {
	thunkAPI.dispatch(leadAnniversariesLoading());
	const result = api.createLeadAnniversary(payload.leadAnniversary);
	result
		.then((leadAnniversary) => thunkAPI.dispatch(leadAnniversaryLoaded(leadAnniversary)))
		.catch(error => thunkAPI.dispatch(leadAnniversariesFailed(error)));
	return result;
});

const updateLeadAnniversary = createAsyncThunk("leadAnniversaries/updateLeadAnniversary", async (payload: { leadAnniversary: LeadAnniversary }, thunkAPI) => {
	thunkAPI.dispatch(leadAnniversariesLoading());
	const result = api.updateLeadAnniversary(payload.leadAnniversary);
	result
		.then((leadAnniversary) => thunkAPI.dispatch(leadAnniversaryLoaded(leadAnniversary)))
		.catch(error => thunkAPI.dispatch(leadAnniversariesFailed(error)));
	return result;
});

export const leadAnniversariesSlice = createSlice({
    name: "leadAnniversaries",
    initialState,
    reducers: {
        leadAnniversariesLoading: (state) => {
            state.loading = true;
        },
        leadAnniversariesLoaded: (state, action: PayloadAction<LeadAnniversaryResource[]>) => {
            state.loading = false;
            state.error = null;
            state.byId = arrayToById(action.payload, "id");
            state.byLeadId = arrayToByForeignId(action.payload, "id", "leadId");
        },
        leadAnniversaryLoaded: (state, action: PayloadAction<LeadAnniversaryResource>) => {
            const byId = objectToById(action.payload, "id");
			const byLeadId = mergeByForeignId(state.byLeadId, objectToByForeignId(action.payload, "id", "leadId"));
            state.loading = false;
            state.error = null;
            state.byId = {
                ...state.byId,
                ...byId,
            };
            state.byLeadId = byLeadId;
        },
        leadAnniversariesFailed: (state, action: PayloadAction<Error>) => {
            state.loading = false;
            state.error = action.payload.message;
        },
        default: (state) => {
            return state;
        }
    },
    extraReducers(builder) {
        builder.addCase(loginReset, () => {
            return initialState;
        });
    }
});

export const { leadAnniversariesLoading, leadAnniversariesLoaded, leadAnniversaryLoaded, leadAnniversariesFailed } = leadAnniversariesSlice.actions;
export { fetchLeadAnniversaries, createLeadAnniversary, updateLeadAnniversary };
