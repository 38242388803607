import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { DashboardLayout } from "component/layout/dashboard";
import * as Mui from "@material-ui/core";
import { LeadTable } from "./lead-table";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { Lead } from "model/lead";
import { getFilteredLeads, getLeadById } from "redux/selector";
import { updateLeadsPagePage, updateLeadsPageSort } from "redux/slice/leads-page";
import { getLeadsPageFiltersCount, getLeadsPageFilters, getLeadsPageSort } from "redux/selector";
import { styles } from "./style";
import Fuse from "fuse.js";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import { ColdFusionIframe } from "component/shared/iframe/coldfusion";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { FilterDialog } from "./filter-dialog";
import queryString from "query-string";
import { LeadFilterType } from "type/lead-filter";
import { setLeadsPageFilters } from "redux/slice/leads-page"
import { LeadsPageFilters } from "model/leads-page-filters";
import { urls, LeadQuery } from "routes/urls";
import { Sort } from "component/shared/table";

const mapStateToProps = (state: RootState) => {
	
	const leads = getFilteredLeads(state);
	const filterCount = getLeadsPageFiltersCount(state);
	const filters = getLeadsPageFilters(state);
	const lastLead = getLeadById(state, state.leads.lastSavedId);
	const options: Fuse.IFuseOptions<Lead> = {
		shouldSort: true,
		threshold: 0.5,
		location: 0,
		distance: 100,
		minMatchCharLength: 1,
		keys: [
			"id",
			"name",
			"email",
			"email2",
			"phone",
			"ranking",
			"source",
		],
	};
	return {
		loading: state.leads.loading,
		leads,
		filterCount,
		filters,
		lastLead,
		fuse: new Fuse(leads, options),
		sort: getLeadsPageSort(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	setLeadsPageFilters,
	updateLeadsPagePage,
	updateLeadsPageSort,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	PageProps,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles>
{
}

interface State {
	term: string;
	leadFilterDialogIsOpen: boolean;
	numResultsShowing: number;
	totalResults: number;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			term: "",
			leadFilterDialogIsOpen: false,
			numResultsShowing: 0,
			totalResults: 0,
		};
	}

	public componentDidMount() {
		const sort = this.getSortFromParams(this.props.location.search);
		const filters = this.getFiltersFromParams(this.props.location.search);
		if (Object.keys(filters).length) {
			this.props.setLeadsPageFilters(filters);
		}
		if(sort) {
			this.props.updateLeadsPageSort(sort);
		}
	}

	public componentDidUpdate (prevProps: Props) {
		if(prevProps.filters !== this.props.filters) {
			this.updateUrl();
		}
		if(prevProps.sort !== this.props.sort) {
			this.updateUrl();
		}
		if (this.props.lastLead && !this.props.lastLead.status.deleted) {
			if (
				!prevProps.lastLead ||
				this.props.lastLead.id !== prevProps.lastLead.id
			) {
				const url = urls.lead(this.props.lastLead);
				this.props.history.push(url);
			}
		}
	}

	public getFiltersFromParams = (search: string) => {
		const query = queryString.parse(search);
		const filters: LeadsPageFilters = {};
		Object.keys(query).forEach((name) => {
			if(LeadFilterType.getById(name)) {
				let value: any = query[name];
				if(value === "true") {
					value = true;
				} else if(value === "false") {
					value = false;
				} else if (typeof value === "object") {
					if(name === "pipeline") {
						value = value.filter((item: any) => !isNaN(item))
							.map((numberString: string) => parseInt(numberString));
					} else if(name === "ranking") {
						value = value.filter((item: any) => !isNaN(item))
							.map((numberString: string) => parseInt(numberString));
					}
				} else if(!isNaN(value)) {
					value = [parseInt(value)];
				} else if (typeof value === "string" ) {
					value = [value];	
				} 
				// @ts-ignore
				filters[name] = value;
			}
		});
		return filters;
	}

	public getSortFromParams = (search: string): Sort | undefined => {
		const query = queryString.parse(search);
		const value = query["sort"];
		if(typeof value === "string") {
			const id = value.split(":")[0];
			const direction = value.split(":")[1];
			return {
				id,
				desc: direction === "desc",
			};
		}
	}

	private updateUrl = () => {
		const query: LeadQuery = {
			leadsPageFilters: this.props.filters,
			sort: this.props.sort,
		};
		this.props.history.push(urls.leads(query));
	}

	private onTermChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const { updateLeadsPagePage, updateLeadsPageSort, sort } = this.props;
		const newTerm = event.target.value;
		
		this.setState({ term: newTerm });
		updateLeadsPagePage(0);
		
		if (sort) {
		  const updatedSort = {
			...sort,
			searchTerm: newTerm,
		  };
		  updateLeadsPageSort(updatedSort);
		}
	  }

	private showLeadFilterDialog = () => this.setState({ leadFilterDialogIsOpen: true});
	private hideLeadFilterDialog = () => this.setState({leadFilterDialogIsOpen: false});

	public render() {
		const title = "Leads";
		const { user, classes, fuse, loading, filterCount } = this.props;
		let { leads } = this.props;
		const { term, leadFilterDialogIsOpen } = this.state;
		const filteredLeadCount: number = leads.length;
		if(user && !user.admin && user.type.agent) {
			return (
				<DashboardLayout
					permitted={(user.permissions.modifySubscriptions)}
					title={title}
					header={
						<Mui.Typography variant="h1">
							<FeatherIcon>
								<Icons.User />
							</FeatherIcon>
							{title}
						</Mui.Typography>
					}
					iframe={<ColdFusionIframe url="/subscriber_list.cfm" />}
				/>
			);
		}
		if(term) {
			leads = fuse.search(term).map(result => result.item);
		}
		return (
			<DashboardLayout
				permitted={user && user.permissions.leads}
				title={title}
				header={
					<Mui.Grid container justifyContent="space-between" alignItems="center" >
						<Mui.Grid item xs={3}>
							<Mui.Typography variant="h1">
								<FeatherIcon>
									<Icons.User />
								</FeatherIcon>
								{title}
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Hidden xsDown>
							<Mui.Grid item xs={6}>
								<Mui.InputBase
									placeholder="Search for lead"
									fullWidth
									onChange={this.onTermChange}
									className={classes.searchBar}
								/>
							</Mui.Grid>
							<Mui.Grid item xs={3} className={classes.filter}>
								{user.permissions.crm && 
									<Mui.Button
										variant="contained"
										color="secondary"
										onClick={() => this.showLeadFilterDialog()}
									>
										Filters {filterCount ? `(${filterCount})` : ""}
									</Mui.Button>
								}
							</Mui.Grid>
						</Mui.Hidden>
					</Mui.Grid>
				}
			>
				<FilterDialog
					open={leadFilterDialogIsOpen}
					onClose={this.hideLeadFilterDialog}
					count = {filteredLeadCount}
					buttonText="View"
				/>
				<Mui.Grid container direction="column" spacing={2}>
				{loading ? (
					<Mui.Grid item>
						<LoadingIndicator />
					</Mui.Grid>
				) : (
					<Mui.Grid container direction="column" spacing={2}>
						<Mui.Hidden smUp>
							<Mui.Grid item>
								<Mui.Grid container justifyContent="space-between" alignItems="center">
									<Mui.Grid item xs={5}>
										<Mui.InputBase
											placeholder="Search for lead"
											fullWidth
											onChange={this.onTermChange}
											className={classes.searchBar}
											style={{ border: "1px solid gray" }}
										/>
									</Mui.Grid>
									{user.permissions.crm && 
										<Mui.Grid item>
											<Mui.Button
												variant="contained"
												color="secondary"
												onClick={() => this.showLeadFilterDialog()}
												className={classes.filterMobileButton}
											>
												<FeatherIcon className={classes.filterIcon} >
													<Icons.Filter />
												</FeatherIcon> 
												{filterCount > 0 ? "(" + filterCount + ")" : ""}
											</Mui.Button>
										</Mui.Grid>
									}
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Hidden>
						<Mui.Grid item>
							<LeadTable user={user} leads={leads} />
						</Mui.Grid>
					</Mui.Grid>
				)}
				</Mui.Grid>
			</DashboardLayout>
		);
	}
}

export const LeadsPage = Mui.withStyles(styles)(
	Router.withRouter(
		connect(mapStateToProps, mapDispatchToProps)(Component)
	),
);
