import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { fetchReseller } from "redux/slice/resellers";
import { getResellerById } from "redux/selector"
import { getClientById } from "redux/selector"
import { fetchClient } from "redux/slice/clients";
import queryString from "query-string";
import * as Router from "react-router-dom";

const mapStateToProps = (state: RootState, ownProps: Router.RouteComponentProps) => {
	let clientId = getClientId(ownProps)
	let resellerId;
	if(!clientId) {
		resellerId = getResellerId(ownProps);
	}
	return {
		reseller: getResellerById(state, resellerId),
		client: getClientById(state, clientId),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	fetchClient,
	fetchReseller,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles>
{
	title: string,
	header?: React.ReactNode,
}

const getResellerId = (props: Router.RouteComponentProps) => {
	const query = queryString.parse(props.location.search);
	let result: number | undefined;
	if(typeof query.resellerId === "string") {
		result = parseInt(query.resellerId);
	}
	return result;
}

const getClientId = (props: Router.RouteComponentProps) => {
	const query = queryString.parse(props.location.search);
	let result: number | undefined;
	if(typeof query.clientId === "string") {
		result = parseInt(query.clientId);
	}
	return result;
}

class Component extends React.Component<Props> {

	public componentDidMount() {
		let clientId = getClientId(this.props)
		if(clientId === undefined) {
			let resellerId = getResellerId(this.props);
			if(resellerId) {
				this.props.fetchReseller({resellerId});
			}
		} else {
			if(clientId) {
				this.props.fetchClient({clientId});
			}
		}
	}

	public get logoUrl() {
		const { client, reseller } = this.props;
		let result: string | undefined;
		if(client) {
			result = client.reseller.logo;
		} else if (reseller) {
			result = reseller.logo;
		} else {
			result = "https://pix.idxre.com/pix/agentOffice/577_clientcplogo_logo_577.png"
		}
		return result;
	}

	public render() {
		const { classes, children, title, header } = this.props;
		return (
			<>
				<Helmet
					title={title}
				/>
				<div className={classes.layout}>
					<div className={classes.container}>
						{this.logoUrl &&
							<img className={classes.logo} src={this.logoUrl} alt="" />
						}
						{header && 
							<Mui.Typography className={classes.header} variant="h5">
								{header}
							</Mui.Typography>
						}
						{children}
					</div>
				</div>
			</>
		);
	}

}

export const AuthLayout = Mui.withStyles(styles)(
	Router.withRouter(
		connect(mapStateToProps, mapDispatchToProps)(Component)
	)
);