import { arrayToById, arrayToByForeignId, mergeByForeignId, objectToByForeignId, objectToById } from "redux/normalize";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { NewOfficeBoard, OfficeBoard } from "model/office-board";
import { loginReset } from "redux/slice/authentication";
import { OfficeBoardResource } from "api/resource/office-board";

export interface OfficeBoardsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: OfficeBoardResource;
	};
	byOfficeId: {
		[key: number]: number[];
	}
};

const initialState: OfficeBoardsState = {
	byId: {},
	byOfficeId: {},
};

const fetchOfficeBoards = createAsyncThunk("officeBoards/fetchOfficeBoards", async (payload, thunkAPI) => {
	thunkAPI.dispatch(officeBoardsLoading());
	const result = api.getOfficeBoards();
	result
		.then((officeBoards) => thunkAPI.dispatch(officeBoardsLoaded(officeBoards)))
		.catch(error => thunkAPI.dispatch(officeBoardsFailed(error)));
	return result;
});

const createOfficeBoard = createAsyncThunk("officeBoards/createOfficeBoard", async (payload: { officeBoard: NewOfficeBoard }, thunkAPI) => {
	thunkAPI.dispatch(officeBoardsLoading());
	const result = api.createOfficeBoard(payload.officeBoard);
	result
		.then((officeBoard) => thunkAPI.dispatch(officeBoardLoaded(officeBoard)))
		.catch((error) => thunkAPI.dispatch(officeBoardsFailed(error)));
	return result;
});

const updateOfficeBoard = createAsyncThunk("officeBoards/updateOfficeBoard", async (payload: { officeBoard: OfficeBoard }, thunkAPI) => {
	thunkAPI.dispatch(officeBoardsLoading());
	const result = api.updateOfficeBoard(payload.officeBoard);
	result
		.then((officeBoard) => thunkAPI.dispatch(officeBoardLoaded(officeBoard)))
		.catch((error) => thunkAPI.dispatch(officeBoardsFailed(error)));
	return result;
});

const deleteOfficeBoard = createAsyncThunk("officeBoards/deleteOfficeBoard", async (payload: { officeBoard: OfficeBoard }, thunkAPI) => {
	thunkAPI.dispatch(officeBoardDeleted(payload.officeBoard));
	const result = api.deleteOfficeBoard(payload.officeBoard);
	result
		.then((officeBoard) => thunkAPI.dispatch(officeBoardDeleted(officeBoard)))
		.catch((error) => thunkAPI.dispatch(officeBoardsFailed(error)));
	return result;
});

export const officeBoardsSlice = createSlice({
	name: "officeBoards",
	initialState,
	reducers: {
		officeBoardsLoading: (state) => {
			state.loading = true;
		},
		officeBoardsLoaded: (state, action: PayloadAction<OfficeBoardResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
			state.byOfficeId = arrayToByForeignId(action.payload, "id", "officeId");
		},
		officeBoardLoaded: (state, action: PayloadAction<OfficeBoardResource>) => {
			const byId = objectToById(action.payload, "id");
			const byOfficeId = mergeByForeignId(
				state.byOfficeId,
				objectToByForeignId(action.payload, "id", "officeId"),
			);
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...byId,
			};
			state.byOfficeId = byOfficeId;
		},
		officeBoardDeleted: (state, action: PayloadAction<any>) => {
			const { id } = action.payload;
			const result = {
				...state,
				loading: false,
				error: null,
				byId: {
					...state.byId,
				}
			};
			delete result.byId[id];
			return result;
		},
		officeBoardsFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
            return state;
        }
    },
    extraReducers(builder) {
        builder.addCase(loginReset, () => {
            return initialState;
        });
    }
});

export const { officeBoardDeleted, officeBoardLoaded, officeBoardsFailed, officeBoardsLoaded, officeBoardsLoading } = officeBoardsSlice.actions;
export { fetchOfficeBoards, deleteOfficeBoard, createOfficeBoard, updateOfficeBoard };