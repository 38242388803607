import * as credentials from "shared/credentials";
import * as urls from "./urls";

export const getUser = () => {
	return request({
		method: "GET",
		url: urls.oneGetUser()
	});
}

interface RequestOptions {
	url: string;
	method: "GET" | "POST" | "PUT" | "DELETE";
	body?: any;
}

const request = (options: RequestOptions) => {
	const { url, method, body } = options;
	return new Promise((resolve, reject) => {
		const creds = credentials.get();
		let auth = ":";
		if(creds) {
			auth = creds.username + ":" + creds.password;
		}
		fetch(url, {
			method: method,
			body: JSON.stringify(body),
			headers: {
				"Authorization": "Basic " + btoa(auth),
				"Content-Type": "application/json",
			},
		}).then(
			async (response) => {
				try {
					const json = await response.json();
					if(response.ok) {
						resolve(json);
					} else {
						const error = new Error(json.message);
						reject(error);
					}
				} catch(error) {
					reject(error);
				}
			},
			(error) => {
				reject(error);
			}
		);
	});
}