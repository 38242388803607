import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { SavedListing } from "model/saved-listing";
import { styles } from "./style";
import { LocationIcon } from "./location-icon";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { updateSavedListing } from "redux/slice/saved-listings";
import { connect } from "react-redux";
import { Dialog } from "component/shared/dialog";

const mapStateToProps = (state: RootState) => {
	return {}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateSavedListing,
}, dispatch);

interface Props extends
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
	index: number;
	savedListing: SavedListing;
}

interface State {
	showConfirmDialog: boolean;
}

class Component extends React.Component<Props, State>  {

	public constructor(props: Props) {
		super(props);
		this.state = {
			showConfirmDialog: false,
		};
	}

	setConfirmDialog = (showConfirmDialog: boolean) => {
		this.setState({ showConfirmDialog })
	}

	saveListing = () => {
		const deleted = true;
		let updatedSavedListing = {
			...this.props.savedListing,
			deleted,
		}
		this.props.updateSavedListing({savedListing: updatedSavedListing});
	}

	renderConfirmationDialog = () => {
		const { classes } = this.props;
		return (
			<Dialog
				open={this.state.showConfirmDialog}
				onClose={() => this.setConfirmDialog(false)}
			>
				<Mui.DialogTitle>Delete Saved Listing</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<p>Are you sure you want to delete this listing?</p>
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
					<Mui.Button
						variant="contained"
						color="secondary"
						onClick={() => {
							this.saveListing();
							this.setConfirmDialog(false);
						}}
					>
						Yes
					</Mui.Button>
					<Mui.Button
						variant="outlined"
						onClick={() => this.setConfirmDialog(false)}
					>
						No
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
		);}

	render() {
		const { classes, index, savedListing } = this.props;
		const { listing } = savedListing;
		if(!listing) {
			return null;
		}
		return (
			<>
				{this.renderConfirmationDialog()}
				<Mui.Card key={index}>
					<Mui.CardContent>
						<Mui.Grid container>
							<Mui.Grid item xs={11}> 
								<Mui.Grid container spacing={1}>
									<Mui.Grid item xs={12} style={{width: 0}}>
										<Mui.Link href={listing.detailUrl} target="_blank">
											<Mui.Typography noWrap>{listing.addressOneLine}</Mui.Typography>
										</Mui.Link>
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Typography>
											{listing.priceLabel}
										</Mui.Typography>
									</Mui.Grid>
									{!!listing.bedrooms &&
										<Mui.Grid item>
											<Mui.Typography>Beds: {listing.bedrooms}</Mui.Typography>
										</Mui.Grid>
									}
									{!!listing.bathrooms &&
										<Mui.Grid item>
											<Mui.Typography>Baths: {listing.bathrooms}</Mui.Typography>
										</Mui.Grid>
									}
								</Mui.Grid>
							</Mui.Grid>
							<Mui.Grid item xs={1}>
								<LocationIcon index={index} />
							</Mui.Grid>
						</Mui.Grid>
					</Mui.CardContent>
					<Mui.CardActionArea>
						<Mui.Link href={listing.detailUrl} target="_blank">
							<Mui.CardMedia
								className={classes.image}
								image={listing.photoUrl}
							/>
						</Mui.Link>
					</Mui.CardActionArea>
					<Mui.CardContent>
						<Mui.Grid container direction="column" spacing={1}>
							<Mui.Grid item>
								<Mui.Typography>
									Listing # 
									{listing.listingNumber}
								</Mui.Typography>
							</Mui.Grid>
							{!!savedListing.rating &&
								<Mui.Grid item>
									<Mui.Typography>
										<strong>Lead's Rating:</strong>
									</Mui.Typography>
									<Mui.Grid container>
										{[...Array(5)].map((value, index) => (
											<Mui.Grid key={index} item>
												<FeatherIcon>
													<Icons.Star
														className={(savedListing.rating || 0) < index + 1 ? classes.unstarred : classes.starred}
													/>
												</FeatherIcon>
											</Mui.Grid>
										))}
									</Mui.Grid>
								</Mui.Grid>
							}
							{savedListing.comments && (
								<Mui.Grid item>
									<Mui.Typography>
										<strong>Lead's Comment:</strong>
									</Mui.Typography>
									<Mui.Typography>
										{savedListing.comments}
									</Mui.Typography>
								</Mui.Grid>
							)}
						</Mui.Grid>
					</Mui.CardContent>
					<Mui.CardActions>
						<Mui.IconButton
							onClick={() => this.setConfirmDialog(true)} 
							className={classes.delete}
						>
							<FeatherIcon>
								<Icons.Trash />
							</FeatherIcon>
						</Mui.IconButton>
					</Mui.CardActions>
				</Mui.Card>
			</>
		);
	}
};

export const ListingCard = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
