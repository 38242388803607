import React from "react";
import * as Mui from "@material-ui/core";
import { RichTextEditor } from "component/shared/rich-text-editor";
import { LeadCaptureSettingsFields } from "../form-fields/lead-capture-settings-fields";
import { Row } from "../row";

interface Props	extends OwnProps {}

interface OwnProps {
	fields: LeadCaptureSettingsFields;
	onChange: (fields: LeadCaptureSettingsFields) => void;
}

interface State {
	fields: LeadCaptureSettingsFields;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			fields: props.fields,
		};
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		if(prevState.fields !== this.state.fields) {
			this.props.onChange(this.state.fields);
		}
	}
	
	render() {
		const { fields } = this.state;
		return (
			<Mui.Grid container alignItems="center"	justifyContent="center"	spacing={1}>
				<Mui.Grid item xs={12}>
					<Row>
						<Mui.Grid 
							container
							alignItems="center"
							justifyContent="space-between"
						>
							<Mui.Grid item>
								<Mui.Typography>
								Show on Sign-up Drop Down Selector
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Switch
									checked={fields.showOnSignupDropDown}
									onChange={(event) =>
										this.setState({ fields: {...fields, showOnSignupDropDown: event.target.checked} })
									}
								/>
							</Mui.Grid>
						</Mui.Grid>
					</Row>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Row>
						<Mui.Grid 
							container
							alignItems="center"
							justifyContent="space-between"
						>
							<Mui.Grid item>
								<Mui.Typography>
									Agent-Specific Email Update Header
								</Mui.Typography>
								<RichTextEditor
									onChange={(text) =>
										this.setState({ fields: {...fields, emailHeader: text} })
									}
									toolbarId="emailHeader"
									value={fields.emailHeader}
								/>
								<Mui.Typography
									style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "0.75rem" }}
									variant="caption"
								>
									Enter text or HTML that appears at the top of the email updates
									sent to this agent's leads. If left blank, a simple contact message
									will appear.
								</Mui.Typography>
							</Mui.Grid>
						</Mui.Grid>
					</Row>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Row>
						<Mui.Grid 
							container
							alignItems="center"
							justifyContent="space-between"
						>
							<Mui.Grid item>
								<Mui.Typography>
									Send Copy of Email Updates to Agent
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Switch
									checked={fields.sendEmailUpdatesToAgent}
									onChange={(event) =>
										this.setState({ fields: {...fields, sendEmailUpdatesToAgent: event.target.checked} })
									}
								/>
							</Mui.Grid>
						</Mui.Grid>
					</Row>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const LeadCaptureSettings = (Component);
