import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { styles } from "./style";

const mapStateToProps = (state: RootState) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends 	
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	PageProps,
	Mui.WithStyles<typeof styles> {
}

class Component extends React.Component<Props> { 
	public render(){
		const { classes, user } = this.props;
		const trainingWebinars = "https://www.ihomefinder.com/resources/support/training-webinars/";
		const learningContent = "https://www.ihomefinder.com/resources/support/#content";
		const contactSupport = "https://www.ihomefinder.com/resources/support/#contact";
		return (
			<DashboardLayout
				fullScreen={false}
				permitted={user.admin}
				title="Support Center"
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.User />
						</FeatherIcon>
						{"Support Center"}
					</Mui.Typography>
				}
			>
				<Mui.Grid 
					container 
					direction="column" 
					alignItems="center" 
					spacing={8}
				>
					<Mui.Grid item>
						<Mui.Grid 
							container 
							direction="column" 
							spacing={1} 
							alignItems="center"
						>
							<Mui.Grid item>
								<Mui.Typography align="center" className={classes.pageTitle}>Support Center</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Typography variant="h3" align="center">Everything you need to get the most value from your account.</Mui.Typography>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Grid container direction="row" spacing={8}>
							<Mui.Grid item xs>
								<Mui.Grid 
									container 
									direction="column" 
									spacing={3} 
									alignItems="center"
								>
									<Mui.Grid item className={classes.iconBorder}>
										<FeatherIcon className={classes.icon}>
											<Icons.Monitor/>
										</FeatherIcon>
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Grid 
											container 
											direction="column" 
											alignItems="center"
											spacing={2}
										>
											<Mui.Grid item>
												<Mui.Typography variant="h3" align="center" className={classes.topicTitle}>
													TRAINING WEBINARS
												</Mui.Typography>
											</Mui.Grid>
											<Mui.Grid item>
												<Mui.Typography variant="h4" align="center">
													Live training webinars and recordings
												</Mui.Typography>
											</Mui.Grid>
										</Mui.Grid>
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Button 
											variant="outlined" 
											className={classes.topicButton} 
											href={trainingWebinars} 
											target="_blank"
										>
											<Mui.Typography className={classes.topicLink}>
												GO 
											</Mui.Typography>
										</Mui.Button>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
							<Mui.Grid item xs>
								<Mui.Grid 
									container 
									direction="column" 
									spacing={3} 
									alignItems="center"
								>
									<Mui.Grid item  className={classes.iconBorder}>
										<FeatherIcon className={classes.icon}>
											<Icons.BookOpen/>
										</FeatherIcon>
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Grid 
											container 
											direction="column" 
											alignItems="center" 
											spacing={2}
										>
											<Mui.Grid item>
												<Mui.Typography variant="h3" align="center" className={classes.topicTitle}>
													LEARNING CONTENT
												</Mui.Typography>
											</Mui.Grid>
											<Mui.Grid item>
												<Mui.Typography variant="h4" align="center">
													Quick start Guides, Knowledge Base, videos, and more
												</Mui.Typography>
											</Mui.Grid>
										</Mui.Grid>
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Button 
											variant="outlined" 
											className={classes.topicButton} 
											href={learningContent} 
											target="_blank"
										>
											<Mui.Typography className={classes.topicLink}>
												GO 
											</Mui.Typography>
										</Mui.Button>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
							<Mui.Grid item xs>
								<Mui.Grid 
									container 
									direction="column" 
									spacing={3} 
									alignItems="center"
								>
									<Mui.Grid item className={classes.iconBorder}>
										<FeatherIcon className={classes.icon}>
											<Icons.MessageSquare/>
										</FeatherIcon>
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Grid 
											container 
											direction="column" 
											alignItems="center" 
											spacing={2}
										>
											<Mui.Grid item>
												<Mui.Typography variant="h3" align="center" className={classes.topicTitle}>
													CONTACT SUPPORT
												</Mui.Typography>
											</Mui.Grid>
											<Mui.Grid item>
												<Mui.Typography variant="h4" align="center">
													Need help? We're here to answer your questions.
												</Mui.Typography>
											</Mui.Grid>
										</Mui.Grid>
									</Mui.Grid>
									<Mui.Grid item className={classes.topicLink}>
										<Mui.Button 
											variant="outlined" 
											className={classes.topicButton} 
											href={contactSupport} 
											target="_blank"
										>
											<Mui.Typography className={classes.topicLink}>
												GO 
											</Mui.Typography>
										</Mui.Button>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</DashboardLayout> 
		)
	}
}

export const SupportCenterPage = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));