import { LeadImportResource } from "api/resource/lead-import";
import { leads } from "./leads";
import { marketReports } from "./market-reports";
import { markets } from "./markets";

if(false) {
	leads.length = 0;
	markets.length = 0;
	marketReports.length = 0;
	const multiplier = 50;
	{
		const count = 100 * multiplier;
		for(let i = 0; i < count; i++) {
			leads.push({
				id: i,
				email: i + "@mailinator.com",
				source: "Zillow",
				createdOn: "2018-11-13T14:46:41.723-08:00",
				status: "active",
			});
		}
	}
	{
		const count = 10 * multiplier;
		for(let i = 0; i < count; i++) {
			markets.push({
				id: i,
				name: `Market ${i}`,
				criteria: [],
				listingReportSupported: true,
				openHomesReportSupported: true,
				marketReportSupported: true,
				showOnIndex: false,
				inactive: false,
			});
			marketReports.push({
				id: i + (count * 0),
				typeId: 1,
				marketId: i,
				inactive: false,
				pageUrl: "http://yahoo.com"
			});
			marketReports.push({
				id: i + (count * 1),
				typeId: 2,
				marketId: i,
				inactive: false,
				pageUrl: "http://amazon.com"
			});
			marketReports.push({
				id: i + (count * 2),
				typeId: 3,
				marketId: i,
				inactive: false,
				pageUrl: "http://thriftbooks.com"
			});
		}
	}
}

export const leadImports: LeadImportResource[] = [];