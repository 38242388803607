import React from "react"
import * as Mui from "@material-ui/core";

interface Props<T> {
	label: string,
	value?: T,
	sidebarNote?: React.ReactNode,
	onChange: (value: T) => void,
}
class Component<T> extends React.Component<Props<T>> {
	public render() {
		const { label, sidebarNote, value, onChange, children } = this.props;
		return (
			<Mui.Grid container direction="row" spacing={2}>
				<Mui.Grid item xs>
					<Mui.FormControl fullWidth variant="outlined">
						<Mui.InputLabel>
							{label}
						</Mui.InputLabel>
						<Mui.Select 
							fullWidth
							variant="outlined"
							value={value}
							input={
								<Mui.OutlinedInput 
									notched={true} 
									label={label} />
							}
							onChange={event => onChange(event.target.value as T)}>
								{children}
						</Mui.Select>
					</Mui.FormControl>
				</Mui.Grid>
				{sidebarNote !== false && (
					<Mui.Grid item xs>
						{sidebarNote && (
							<Mui.Typography>
								<strong>Note:</strong>
								{" "}
								{sidebarNote}
							</Mui.Typography>
						)}
					</Mui.Grid>
				)}
			</Mui.Grid>
		);
	}
}

export const FullWidthDropdown = Component;
