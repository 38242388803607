import { arrayToById, objectToById, arrayToByForeignId, objectToByForeignId, mergeByForeignId } from "redux/normalize";
import * as api from "api/api";
import { CampaignSubscription, NewCampaignSubscription } from "model/campaign-subscription";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loginReset } from "redux/slice/authentication";
import { CampaignSubscriptionResource } from "api/resource/campaign-subscription";

export interface CampaignSubscriptionsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: CampaignSubscriptionResource;
	},
	byLeadId: {
		[key: number]: number[];
	};
	byCampaignId: {
		[key: number]: number[];
	};
};

const initialState: CampaignSubscriptionsState = {
	byId: {},
	byLeadId: {},
	byCampaignId: {},
};

const fetchCampaignSubscriptions = createAsyncThunk("campaignSubscriptions/fetchCampaignSubscriptions", async (payload, thunkAPI) => {
	thunkAPI.dispatch(campaignSubscriptionsLoading());
	const result = api.getCampaignSubscriptions();
	result
		.then((campaignSubscriptions) => thunkAPI.dispatch(campaignSubscriptionsLoaded(campaignSubscriptions)))
		.catch(error => thunkAPI.dispatch(campaignSubscriptionsFailed(error)));
	return result;
});

const createCampaignSubscription = createAsyncThunk("campaignSubscriptions/createCampaignSubscription", async (payload: {subscription: NewCampaignSubscription}, thunkAPI) => {
	thunkAPI.dispatch(campaignSubscriptionsLoading());
	const result = api.createCampaignSubscription(payload.subscription);
	result
		.then((campaignSubscription) => thunkAPI.dispatch(campaignSubscriptionLoaded(campaignSubscription)))
		.catch(error => thunkAPI.dispatch(campaignSubscriptionsFailed(error)));
	return result;
});

const updateCampaignSubscription = createAsyncThunk("campaignSubscriptions/updateCampaignSubscription", async (payload: {subscription: CampaignSubscription}, thunkAPI) => {
	thunkAPI.dispatch(campaignSubscriptionsLoading());
	const result = api.updateCampaignSubscription(payload.subscription);
	result
		.then((campaignSubscription) => thunkAPI.dispatch(campaignSubscriptionLoaded(campaignSubscription)))
		.catch(error => thunkAPI.dispatch(campaignSubscriptionsFailed(error)));
	return result;
});

export const campaignSubscriptionsSlice = createSlice({
	name: "campaignSubscriptions",
	initialState,
	reducers: {
		campaignSubscriptionsLoading: (state) => {
			state.loading = true;
		},
		campaignSubscriptionsLoaded: (state, action: PayloadAction<CampaignSubscriptionResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
			state.byLeadId =  arrayToByForeignId(action.payload, "id", "leadId");
			state.byCampaignId = arrayToByForeignId(action.payload, "id", "campaignId");
		},
		campaignSubscriptionLoaded: (state, action: PayloadAction<CampaignSubscriptionResource>) => {
			const byId = objectToById(action.payload, "id");
			const byLeadId = mergeByForeignId(state.byLeadId, objectToByForeignId(action.payload, "id", "leadId"));
			const byCampaignId = mergeByForeignId(state.byCampaignId, objectToByForeignId(action.payload, "id", "campaignId"));
			state.loading = false;
			state.error = null;
			state.byId =  {
				...state.byId,
				...byId,
			};
			state.byLeadId = byLeadId;
			state.byCampaignId = byCampaignId;
		},
		campaignSubscriptionsFailed: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});

export const {campaignSubscriptionLoaded, campaignSubscriptionsFailed, campaignSubscriptionsLoaded, campaignSubscriptionsLoading} = campaignSubscriptionsSlice.actions;
export {fetchCampaignSubscriptions, createCampaignSubscription, updateCampaignSubscription};