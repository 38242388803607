export const login = "/login";
export const lead = "/lead/:id(\\d+)";
export const leads = "/leads";
export const leadDashboard = "/lead-dashboard";
export const tasks = "/tasks";
export const leadActivity = "/lead-activity";
export const leadRouting = "/lead-routing";
export const leadExport = "/leads/export";
export const LeadImport = "/leads/import"
export const leadLowActivity = "/leads-low-activity";
export const markets = "/markets";
export const market = "/market/:id(\\d+)";
export const marketTab = "/market/:id(\\d+)/:tab(\\w+)";
export const marketCreate = "/markets/create";
export const marketSettings = "/markets/settings";
export const campaigns = "/campaigns";
export const campaign = "/campaign/:id(\\d+)";
export const campaignCampaignMessage = "/campaign/message/:id(\\d+)";
export const campaignCampaignEmailMessageNew = "/campaign/:id(\\d+)/message/new/";
export const campaignCampaignTextMessage = "/campaign/text-message/:id(\\d+)";
export const campaignCampaignTextMessageNew = "/campaign/:id(\\d+)/text-message/new/";
export const campaignNewEmail = "/campaign/email/new";
export const campaignDraftEmails = "/campaign/email/drafts";
export const campaignDraftEmail = "/campaign/email/draft/:id";
export const campaignSentEmails = "/campaign/email/sent";
export const campaignSentEmail = "/campaign/email/sent/:id";
export const campaignAutomatedGreetings = "/campaign/automated-greetings";
export const campaignsRouting = "/campaigns/routes";
export const campaignsSettings = "/campaigns/settings";
export const campaignsTexting = "/campaigns/texting";
export const listingsActive = "/listings/active";
export const listingsOffMarket = "/listings/off-market";
export const listingsSupplemental = "/listings/supplemental";
export const redirect = "/redirect";
export const reportsOverview = "/reports/overview";
export const reportsEmailActivity = "/reports/email-activity";
export const reportsSavedSearches = "/reports/saved-searches";
export const reportSavedListings = "/reports/saved-listings";
export const reportsLeads = "/reports/leads";
export const reportsMobile = "/reports/mobile";
export const settingsOverview = "/settings/overview";
export const settingsPageLinks = "/settings/page-links";
export const settingsAccount = "/settings/account";
export const settingsPassword = "/settings/password";
export const settingsBilling = "/settings/billing";
export const settingsMls = "/settings/mls-setup";
export const settingsIntegrations = "/settings/integrations";
export const settingsPlugin = "/settings/optima-express";
export const settingsBranding = "/settings/branding";
export const settingsTemplates = "/settings/templates";
export const settingsWidgets = "/settings/widgets";
export const settingsCss = "/settings/css";
export const settingsFraming = "/settings/framing";
export const settingsSubHeads = "/settings/sub-heads";
export const settingsPageSpecificBranding = "/settings/page-specific-html";
export const settingsEmail = "/settings/email/basic";
export const settingsEmailBranding = "/settings/email/branding";
export const settingsEmailCustomization = "/settings/email/customization";
export const settingsEmailRouting = "/settings/email/routing";
export const settingsEmailGeographicRouting = "/settings/email/routing/geographic";
export const settingsPropertyTypes = "/settings/property-types";
export const settingsLocationCustomization = "/settings/location-customization";
export const settingsSearchCities = "/settings/search-cities";
export const settingsSearchNeighborhoods = "/settings/search-neighborhoods";
export const settingsOpenHouse = "/settings/open-house";
export const settingsMapSearch = "/settings/map-search";
export const settingsListingResults = "/settings/listing-results";
export const settingsListingDetails = "/settings/listing-details";
export const settingsUserRegistration = "/settings/user-registration";
export const settingsContactForms = "/settings/contact-forms";
export const settingsPropertyOrganizer = "/settings/property-organizer";
export const settingsFacebook = "/settings/facebook";
export const settingsMobile = "/settings/mobile";
export const settingsBroker = "/settings/broker";
export const settingsAgents = "/settings/broker/agents";
export const settingsAgent = "/settings/broker/agent/:id(\\d+)";
export const settingsProfile = "/settings/profile";
export const settingsOffices = "/settings/broker/offices";
export const settingsOffice = "/settings/broker/office/:id(\\d+)";
export const settingsLeadImport = "/settings/lead-import";
export const settingsMortgageCalculator = "/settings/mortgage-calculator";
export const settingsVersion = "/settings/version";
export const settingsPages = "/settings/pages";
export const supportCenter = "/support-center";
export const profile = "/settings/profiles/:id(\\d+)";
export const oneBusinessDashboard = "/one/dashboard";
export const oneLeads = "/one/leads";
export const oneAddNewLead = "/one/createlead";
export const oneActiveProspects = "/one/prospects";
export const onePendingProspects = "/one/prospectsPending";
export const oneClosedProspects = "/one/prospectsClosed";
export const oneContactManagement = "/one/contactmanagement";
export const oneLeadRouting = "/one/leadrouting";
export const oneListManager = "/one/listmanager";
export const oneBlastEmail = "/one/blastemail";
export const oneEmailUsage = "/one/emailusage";
export const oneEmailQueue = "/one/emailqueue";
export const oneGroups = "/one/groups";
export const oneCampaigns = "/one/campaigns";
export const oneTeamList = "/one/teamlist";
export const oneBusinessProcessSettings = "/one/businessprocesssettings";
export const oneActionPlanTemplateManagement = "/one/actionplantemplate";
export const oneMarketingExpenses = "/one/marketingexpenses";
export const oneReports = "/one/reports";
export const oneCalendar = "/one/calendar";
export const oneMarkets = "/one/markets";