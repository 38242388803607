import React from "react";
import { connect } from "react-redux";
import { RootState } from "redux/store";
import * as Mui from "@material-ui/core";
import { TasksComponent } from "component/shared/tasks-component";
import { getActiveLeadTasksDueThisWeek, getLeadsByUnassignedAgent } from "redux/selector";
import { Task } from "model/task";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { getLeadsByAgentId } from "redux/selector";

const mapStateToProps = (state: RootState) => {
	const tasksDueThisWeek = getActiveLeadTasksDueThisWeek(state);
	const agentId = state.agents.value;
	const leadsByAgent = getLeadsByAgentId(state, agentId);
	const leadsByUnassigned = getLeadsByUnassignedAgent(state);
	const unassigned = localStorage.getItem("agentId") === "unassigned"
	return {
		tasks: !agentId ? (!unassigned ? tasksDueThisWeek.slice(0, 5) : tasksDueThisWeek.filter(task => leadsByUnassigned.some(agent => agent.id === task.leadId)).slice(0, 5)) : tasksDueThisWeek.filter(task => leadsByAgent.some(agent => agent.id === task.leadId)).slice(0, 5), 
		loading: state.tasks.loading,
	};
};

interface Props extends ReturnType<typeof mapStateToProps> {
	tasks: Task[];
}

class Component extends React.Component<Props> {
	render() {
		const { tasks, loading } = this.props;
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item xs>
					<Mui.Typography variant="h3">Tasks this Week</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item xs>
					<Mui.Card>
						<Mui.CardContent>
							{!!loading && (
								<Mui.Grid container justifyContent="center">
									<Mui.Grid item>
										<LoadingIndicator />
									</Mui.Grid>
								</Mui.Grid>
							)}
							{!loading && !tasks.length && (
								<Mui.Typography align="center">You have no tasks due this week.</Mui.Typography>
							)}
							{!loading && !!tasks.length && <TasksComponent tasks={tasks} />}
						</Mui.CardContent>
					</Mui.Card>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const Tasks = connect(mapStateToProps)(Component);
