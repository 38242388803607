import React from "react";
import { connect } from "react-redux";
import { Left } from "../left";
import { RootState } from "redux/store";
import { getCallNotes } from "redux/selector";
import moment from "moment";

interface Props extends ReturnType<typeof mapStateToProps> {}

const mapStateToProps = (state: RootState) => {
	return {
		callNotes: getCallNotes(state)
	};
};

const Component = (props: Props) => {
	const numCallsLoggedPast7Days = props.callNotes.filter(
		({ createdOn }) =>
			moment().subtract(7, "d") < createdOn && createdOn <= moment()
	).length;
	const text = numCallsLoggedPast7Days === 1 ? "call logged" : "calls logged";
	return <Left color="#ab47bc" value={numCallsLoggedPast7Days} text={text} />;
};

export const CallsLogged = connect(mapStateToProps)(Component);
