import { normalize, schema } from "normalizr";
import { arrayToByForeignId, objectToByForeignId, mergeByForeignId } from "redux/normalize";
import { MarketListingResource } from "api/resource/market-listing";
import { MarketListing } from "model/market-listing";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";

export interface MarketListingsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: MarketListingState;
	};
	byMarketId: {
		[key: number]: number[];
	};
};

export interface MarketListingState {
	id: number;
	marketId: number;
	value: string;
	sort: number;
	listing?: string;
};

const initialState: MarketListingsState = {
	byId: {},
	byMarketId: {},
};

const fetchMarketListings = createAsyncThunk("marketListings/fetchMarketListings", async (payload, thunkAPI) => {
	thunkAPI.dispatch(marketListingsLoading());
	const result = api.getMarketListings()
	result
		.then((marketListings) => thunkAPI.dispatch(marketListingsLoaded(marketListings)))
		.catch(error => thunkAPI.dispatch(marketListingsFailed(error)));
	return result;
});

const updateMarketListing = createAsyncThunk("marketListings/updateMarketListing", async (payload: { marketListing: MarketListing }, thunkAPI) => {
	thunkAPI.dispatch(marketListingsLoading());
	const result = api.updateMarketListing(payload.marketListing);
	result
		.then((marketListingResource) => thunkAPI.dispatch(marketListingLoaded(marketListingResource)))
		.catch(error => thunkAPI.dispatch(marketListingsFailed(error)));
	return result;
});

const updateMarketListings = createAsyncThunk("marketListings/updateMarketListings", async (payload: { marketListings: MarketListing[] }, thunkAPI) => {
	const result = api.updateMarketListings(payload.marketListings);
	result
		.then((marketListings) => thunkAPI.dispatch(marketListingsLoaded(marketListings)))
		.catch(error => thunkAPI.dispatch(marketListingsFailed(error)));
	return result;
});

const deleteMarketListing = createAsyncThunk("marketListings/deleteMarketListing", async (payload: { marketListing: MarketListing }, thunkAPI) => {
	thunkAPI.dispatch(marketListingsLoading());
	const result = api.deleteMarketListing(payload.marketListing)
	result
		.then((marketListingResource) => thunkAPI.dispatch(marketListingDeleted(marketListingResource)))
		.catch(error => thunkAPI.dispatch(marketListingsFailed(error)));
	return result;
});

export const marketListingsSlice = createSlice({
	name: "marketListings",
	initialState,
	reducers: {
		marketListingsLoading: (state) => {
			state.loading = true;
		},
		marketListingsLoaded: (state, action: PayloadAction<MarketListingResource[]>) => {
			const listing = new schema.Entity("listings");
			const marketListing = new schema.Entity("marketListings", {
				listing,
			});
			const marketListings = new schema.Array(marketListing);
			const normalized = normalize(action.payload, marketListings);
			const byId = normalized.entities.marketListings || {};
			const byMarketId = arrayToByForeignId(action.payload, "id", "marketId");
			state.loading = false;
			state.error = null;
			state.byId = {
				...byId
			};
			state.byMarketId = byMarketId;
		},
		marketListingLoaded: (state, action: PayloadAction<MarketListingResource>) => {
			const listing = new schema.Entity("listings");
			const marketListing = new schema.Entity("marketListings", {
				listing
			})
			const normalized = normalize(action.payload, marketListing);
			const byId = normalized.entities.marketListings || {};
			const byMarketId = mergeByForeignId(state.byMarketId, objectToByForeignId(action.payload, "id", "marketId"));
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...byId
			};
			state.byMarketId = byMarketId;
		},
		marketListingDeleted: (state, action: PayloadAction<any>) => {
			const id = action.payload.id;
			const result = {
				...state,
				loading: false,
				error: null,
				byId: {
					...state.byId,
				}
			}
			delete result.byId[id];
			return result;
		},
		marketListingsFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
            return state;
        }
    },
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});

export const { marketListingLoaded, marketListingDeleted, marketListingsFailed, marketListingsLoaded, marketListingsLoading,  } = marketListingsSlice.actions;
export { fetchMarketListings, updateMarketListing, deleteMarketListing, updateMarketListings };
