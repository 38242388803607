import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import { RootState } from "redux/store";
import MuiAlert from "@material-ui/lab/Alert";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { FullPageCard } from "component/shared/full-page-card";
import { YesNoRadioField } from "component/shared/yes-no-radio-field";
import { User } from "model/user";
import { saveUser } from "redux/slice/authentication";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		authLoading: state.authentication.loading,
		authError: state.authentication.error,
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveUser,
}, dispatch);

interface Props extends
	PageProps,
	Router.RouteComponentProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
	user: User;
}

interface OwnProps {
	user: User;
}
interface State {
	saving: boolean;
	emailSignupPhoneRequired: boolean;
	showCaptchaOnForms: boolean;
	emailAlertPopup: boolean;
	formHeaderMoreInfo: string;
	formHeaderScheduleShowing: string;
	formHeaderSaveListing: string;
}

class Component extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {...props.user, 
			saving: false, 
		}
	}

	public save() {
		const { saveUser } = this.props;
		const user = {...this.state};
		saveUser({user});
		this.setState({saving: true});
	}

	public render() {
		const {
			user,
			authLoading, 
			authError, 
		} = this.props;
		const {
			saving,
			emailSignupPhoneRequired,
			showCaptchaOnForms,
			emailAlertPopup,
			formHeaderMoreInfo,
			formHeaderScheduleShowing,
			formHeaderSaveListing
		} = this.state;
		const title = "Contact Form Settings";
		return (
			<DashboardLayout
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<FullPageCard>
					<Mui.Grid container direction="column" spacing={3}>
						<Mui.Grid item>
							<YesNoRadioField
								label="Require Phone Number on all Lead Capture forms"
								value={emailSignupPhoneRequired}
								onChange={value => this.setState({emailSignupPhoneRequired: value})}
								onOff={true} />
						</Mui.Grid>
						<Mui.Grid item>
							<YesNoRadioField 
								label="Show CAPTCHA on all Lead Capture forms"
								sidebarNote={
									<>
										A CAPTCHA is a program that can tell whether its user is a human
										or a computer. You've probably seen them&#8212; colorful images with
										distorted text at the bottom of Web registration forms. CAPTCHAs
										are used by many websites to prevent abuse from "bots," or automated
										programs usually written to generate spam. No computer program can read
										distorted text as well as humans can, so bots cannot navigate sites
										protected by CAPTCHAs.
									</>
								}
								value={showCaptchaOnForms}
								onChange={value => this.setState({showCaptchaOnForms: value})}
								onOff={true}/>
						</Mui.Grid>
						{user.permissions.leadEmailUpdates && user.emailUpdates && 
							<Mui.Grid item>
								<YesNoRadioField 
									label="Email Alert Pop-up"
									value={emailAlertPopup}
									onChange={value => this.setState({emailAlertPopup: value})}
									onOff={true}/>
							</Mui.Grid>
						}
						<Mui.Grid item>
							<Mui.Grid container direction="row">
								<Mui.Grid item xs={6}>
									<Mui.TextField
										fullWidth={true}
										value={formHeaderMoreInfo}
										onChange={event => this.setState({formHeaderMoreInfo: event.target.value})}
										multiline
										variant="outlined"
										InputLabelProps={{ shrink: true }} 
										minRows={4}
										label={"Custom HTML message on \"More Info Request\" forms"} />
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container direction="row">
								<Mui.Grid item xs={6}>
									<Mui.TextField
										fullWidth={true}
										value={formHeaderScheduleShowing}
										onChange={event => this.setState({formHeaderScheduleShowing: event.target.value})}
										multiline
										variant="outlined"
										InputLabelProps={{ shrink: true }} 
										minRows={4}
										label={"Custom HTML message on \"Schedule a Showing\" forms"} />
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container direction="row">
								<Mui.Grid item xs={6}>
									<Mui.TextField
										fullWidth={true}
										value={formHeaderSaveListing}
										onChange={event => this.setState({formHeaderSaveListing: event.target.value})}
										multiline
										variant="outlined"
										InputLabelProps={{ shrink: true }} 
										minRows={4}
										label={"Custom HTML message on \"Save to Favorites\" forms"} />
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Grid container spacing={1} style={{ marginTop: 24 }}>
							<Mui.Grid item>
								<Mui.Button
									color="secondary"
									variant="contained"
									disabled={saving}
									onClick={() => this.save()}
									style={{ marginRight: 32 }}
									>Save Changes</Mui.Button>
								<Mui.Button
									variant="contained"
									onClick={event => this.setState({
										saving: this.state.saving, 
										...user
									})}
									>
										Cancel
								</Mui.Button>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Snackbar
							open={saving && !authLoading && !authError}
							message="Settings saved"
							autoHideDuration={6000}
							onClose={event => this.setState({ saving: false })}
							action={
								<Mui.IconButton 
									size="small" 
									aria-label="close" 
									color="inherit" 
									onClick={event => this.setState({ saving: false })}>
									<Icons.X fontSize="small" />
								</Mui.IconButton>
							}>
								<MuiAlert severity="info">
									<Mui.Typography>Settings saved</Mui.Typography>
								</MuiAlert>
						</Mui.Snackbar>
				</FullPageCard>
			</DashboardLayout>
		);
	}

}

export const SettingsContactFormsPage = connect(mapStateToProps, mapDispatchToProps)(Component);
