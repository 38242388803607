import React from "react";
import * as Mui from "@material-ui/core";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { deleteIntegration } from "redux/slice/integrations";
import { Integration } from "model/integration";
import { styles } from "./style";

const mapStateToProps = (state: RootState) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	deleteIntegration,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{
	integration: Integration;
	deletable: boolean;
	content: React.ReactNode;
	actions?: React.ReactNode;
}

class Component extends React.Component<Props> {

	public render() {
		const { classes, integration, content, actions, deletable, deleteIntegration } = this.props;
		return (
			<Mui.Card className={classes.card}>
				<Mui.CardHeader
					avatar={integration.integrationType.iconUrl ? (
						<img
							className={classes.icon}
							src={integration.integrationType.iconUrl}
							alt={`Icon for ${integration.label}`}
						/>
					) : (
						<FeatherIcon>
							<Icons.Tool />
						</FeatherIcon>
					)}
					title={integration.label}
					titleTypographyProps={{
						variant: "h4",
					}}
					action={deletable && (
						<Mui.IconButton
							size="small"
							onClick={() => {
								if(window.confirm("Delete this integration?")) {
									deleteIntegration({integration});
								}
							}}
						>
							<FeatherIcon>
								<Icons.Trash />
							</FeatherIcon>
						</Mui.IconButton>
					)}
				/>
				<Mui.CardContent>
					{content}
				</Mui.CardContent>
				<Mui.CardActions className={classes.cardActions}>
					{actions}
				</Mui.CardActions>
			</Mui.Card>
		);
	}

}

export const Card = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);