import React from "react";
import * as Mui from "@material-ui/core";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { styles } from "./style";
import { getRoundRobinRoutes, getUser } from "redux/selector";
import { Avatar } from "component/shared/avatar";

interface Props
	extends Mui.WithStyles<typeof styles>,
		ReturnType<typeof mapStateToProps> {}

const mapStateToProps = (state: RootState) => {
	const user = getUser(state);
	const roundRobinRoutes = getRoundRobinRoutes(state);
	const position = user.roundRobinPosition;
	return {
		roundRobinRoutes,
		position
	};
};

class Component extends React.Component<Props> {
	public render() {
		const { roundRobinRoutes, position } = this.props;
		const agent = roundRobinRoutes.find(route => route.id === position)?.agent;

		if (agent) {
			return (
				<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Mui.Typography variant="h3">Round Robin</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Card>
						<Mui.CardContent>
								<Mui.Grid container alignItems="center" spacing={1}>
									<Mui.Grid item>
										<Mui.Typography>
											Next round robin lead assigned to
										</Mui.Typography>
									</Mui.Grid>
									<Mui.Link href={`settings/broker/agent/${agent.id}`}>
										<Mui.Grid item>
											<Avatar src={agent.photoUrl} phrase={agent.label}/>
										</Mui.Grid>
									</Mui.Link>
									<Mui.Grid item>
										<Mui.Link color="secondary" href={`settings/broker/agent/${agent.id}`}>
											{agent.name}
										</Mui.Link>
									</Mui.Grid>
								</Mui.Grid>
						</Mui.CardContent>
					</Mui.Card>
				</Mui.Grid>
			</Mui.Grid>
		);
	} else {
		return null;
	}
	}
}

export const RoundRobin = Mui.withStyles(styles)(
	connect(mapStateToProps)(Component)
);
