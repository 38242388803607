import React from "react";
import * as Mui from "@material-ui/core";
import { Avatar } from "component/shared/avatar";
import classNames from "classnames";
import { User } from "model/user";
import { styles } from "./style";

interface Props extends
	Mui.WithStyles<typeof styles>,
	React.HTMLAttributes<HTMLDivElement>
{
	user: User;
	diameter?: number;
}

const Component = (props: Props) => {
	const { classes, className, user } = props;
	return (
		<Avatar
			{...props}
			classes={{
				avatar: classNames(classes.avatar, className),
			}}
			phrase={user.name}
		/>
	);
};

export const UserAvatar = Mui.withStyles(styles)(Component);