import React from "react";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";import * as Mui from "@material-ui/core";
import { styles } from "../../style";
import { Dialog } from "component/shared/dialog";
import { Select } from "component/shared/select";
import { getDripCampaignSubscriptionsForLeads, getActiveDripCampaigns, getLeads } from "redux/selector";
import { createCampaignSubscription } from "redux/slice/campaign-subscriptions";
import * as yup from "yup";
import { validate } from "shared/yup";
import { CampaignSubscriptionStatus } from "type/campaign-subscription-status";

const mapStateToProps = (state: RootState, ownProps: any) => {
	return {
		campaigns: getActiveDripCampaigns(state),
		subscriptions: getDripCampaignSubscriptionsForLeads(state, ownProps.leadIds),
		leads: getLeads(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createCampaignSubscription,
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
	open: boolean, 
	onClose: Function,
	leadIds: number[],
}

interface State {
	campaignId?: number;
	submitted: boolean;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			submitted: false,
		};
	}

	private validationSchema = {
		campaignId: yup
			.string()
			.required("Please select a campaign."),
	};

	private addCampaign() {
		const { leadIds, campaigns, leads, subscriptions, createCampaignSubscription } = this.props;
		const { campaignId } = this.state;

		const errors = validate(this.validationSchema, this.state);

		if(!errors && campaignId) {
			leadIds.forEach((id) => {
				const existingSubscription = subscriptions.find((subscription) => {
					return subscription.campaign.id === campaignId && subscription.lead.id === id
				});
				const campaign = campaigns.find(item => item.id === campaignId);
				const lead = leads.find((item) => item.id === id);
				if (!existingSubscription && campaign && lead) {
					createCampaignSubscription({ subscription: {
						lead,
						campaign,
						status: CampaignSubscriptionStatus.ACTIVE,
					}});
				}
			});
			this.onClose();
		} else {
			this.setState({ submitted: true });
		}
	}

	private onClose() {
		this.props.onClose();
		this.setState({ campaignId: undefined, submitted: false });
	}

    render() {
		const { open, onClose, classes, campaigns } = this.props;
		const { campaignId, submitted } = this.state;
		const errors = validate(this.validationSchema, this.state);
		
        return (
			<Dialog
				open={open}
				onClose={() => onClose()}
				fullWidth={true}
				maxWidth="xs" 
			>
				<Mui.DialogTitle>Add To Campaign</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<Mui.Grid container alignItems="center" spacing={1}>
						<Mui.Grid item xs={12}>
							<Mui.DialogContentText className={classes.dialogText}>Subscribe all selected contacts to a Marketing Automation Campaign.</Mui.DialogContentText>
						</Mui.Grid>
						<Mui.Grid container item xs={12} justifyContent="center">
							<Mui.FormControl className={classes.formControl}>
								<Select
									placeholder="Select a Campaign"
									value={campaignId}
									options={campaigns}
									valueExtractor={(campaign) => campaign.id}
									labelExtractor={(campaign) => campaign && campaign.name}
									optionRenderer={(campaign) => (
										<Mui.ListItemText primary={campaign && campaign.name} />
									)}
									onChange={(value) => {
										this.setState({campaignId: value as number})
									}}
									helperText={submitted && errors && errors.campaignId.length ? errors.campaignId : undefined}
								/>
							</Mui.FormControl>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
					<Mui.Button
						onClick={() => {
							this.addCampaign();
						}}
						variant="contained"
						color="secondary"
						disabled={submitted && !campaignId}
					>
						Save
					</Mui.Button>
					<Mui.Button 
						onClick={() => {
							this.onClose();
						}}
						variant="outlined"
					>
						Cancel
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
		)
	}
}

export const BulkCampaignDialog = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);