import React from "react";
import * as Mui from "@material-ui/core";
import { withMediaQuery, WithMediaQuery } from "component/shared/with-media-query";
import { DialogProps } from "@material-ui/core/Dialog";

interface Props extends DialogProps, WithMediaQuery {
	disableBackdropClick?: boolean;
	onClose: () => void;
}
interface State {}

class Component extends React.Component<Props, State> {
	render() {
		var { disableBackdropClick, mediaQuery, onClose, open, ...otherProps } = this.props;
		const isMobile = mediaQuery;
		return (
			<Mui.Dialog
				{...otherProps}
				fullScreen={isMobile}
				onClose={(event, reason) => {
					if (disableBackdropClick && reason === "backdropClick") {
						return;
					} else if (onClose) {
						onClose();
					}
				}}
				open={open}
			>
				{otherProps.children}
			</Mui.Dialog>
		);
	}
}

export const Dialog = withMediaQuery("(max-width:600px)")(Component);
