import * as api from "api/one-api";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { loginReset } from "redux/slice/authentication";
import { OneUserResource } from "api/resource/one-user-now";

export interface OneUsersState {
	loading?: boolean;
	error?: string | null;
	user?: OneUserResource
};
// go through one user with joe result from api is unknown &  getUser refers to 2 seperate things

const initialState: OneUsersState = {};

const fetchOneUser = createAsyncThunk("oneUsers/fetchOneUser", async (payload, thunkAPI) => {
	thunkAPI.dispatch(oneUserLoading());
	const result = api.getUser();
	result
		.then((oneUser) => thunkAPI.dispatch(oneUserLoaded(oneUser)))
		.catch(error => thunkAPI.dispatch(oneUserFailed(error)));
	return result;
});

export const oneUsersSlice = createSlice({
	name: "oneUsers",
	initialState,
	reducers: {
		oneUserLoading: (state) => {
			state.loading = true;
		},
		oneUserLoaded: (state, action: PayloadAction<OneUserResource>) => {
			state.loading = false;
			state.error = null;
			state.user = action.payload;
		},
		oneUserFailed: (state, action: PayloadAction<Error>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
		default: (state) => {
            return state;
        }
    },
    extraReducers(builder) {
        builder.addCase(loginReset, () => {
            return initialState;
        });
    }
});

export const { oneUserLoading, oneUserLoaded, oneUserFailed } = oneUsersSlice.actions;
export { fetchOneUser };