import React from "react";
import * as Mui from "@material-ui/core";
import { useCSVReader } from 'react-papaparse';

interface Headers {
	email: string;
	name?: string;
	address?: string;
	state?: string;
	phone?: string;
	city?: string;
	zip?: string;
	birthday?: string; 
	type?: string; 
	agentid?: string; 
	anniversary?: string; 
	pipeline?: string; 
	tags?: string; 
	note?: string; 
	texting?: string;
	marketids?: string;
	campaignids?: string;
	source?: string;
}

interface Props {
	onUpload: (results: Headers[], invalidHeaders: string[]) => void;
	buttonLabel?: string;
}

const Component: React.FC<Props> = (props) => {
	const { onUpload, buttonLabel } = props;
	const { CSVReader } = useCSVReader();
	const processHeader = (header: string) => {
		return header.replace(/\s/g,'').toLowerCase();
	}
	const validate = (headerRow: any) => {
		let exclude: string[] = [];
		type Columns = (keyof Headers);
		const headers: Columns[] = [
			"email",
			"name",
			"address",
			"state",
			"phone",
			"city",
			"zip",
			"birthday", 
			"type", 
			"agentid",  
			"anniversary", 
			"pipeline", 
			"tags", 
			"note", 
			"texting",
			"marketids",
			"campaignids",
			"source",
		];
		for(let [key] of Object.entries(headerRow)) {
			if(!headers.includes(key as Columns)) {
				exclude.push(key);
			}
		}
		return exclude;
	}
	return (
		<CSVReader
			config={
				{
					header: true,
					skipEmptyLines: 'greedy',
					transformHeader: (header: string) => processHeader(header),
				}
			}
			onUploadAccepted={(results: any) => {
				const invalidHeaders = validate(results.data[0])
				onUpload(results.data, invalidHeaders);
			}}
			
		>
			{({
				getRootProps,
				acceptedFile,
				ProgressBar,
				getRemoveFileProps,
			}: any) => (
			<Mui.Grid container direction="column" spacing={1}>
				<Mui.Grid container spacing={1}>
					<Mui.Grid item>
						<Mui.Button variant="contained" color="secondary" {...getRootProps()}>
								{buttonLabel ? buttonLabel : "Browse File"}
						</Mui.Button>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					<ProgressBar />
				</Mui.Grid>
			</Mui.Grid>
			)}
		</CSVReader>
	);
};

export const CsvReader = Component;

