import React from 'react'
import * as Mui from "@material-ui/core";
import { bindActionCreators, Dispatch } from "redux";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { User } from 'model/user';
import { Market } from 'model/market';
import { connect } from "react-redux";
import { RootState } from 'redux/store';
import { ListingReportContent } from './listing-report';
import { OpenHomeReportContent } from './open-home-report';
import { MarketReportContent } from './market-report';
import { GeneralContent } from './general';

interface Props extends 
	ReturnType<typeof mapDispatchToProps>,
	ReturnType<typeof mapStateToProps> {
		user: User;
		market: Market;
	}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

const mapStateToProps = (state: RootState) => {
	return {
		loading: state.marketReports.loading 
	};
}

class Component extends React.Component<Props> {

	render() {
		const { user, market } = this.props;
		const hasListingReport = user.permissions.listingReport && market.listingReportSupported;
		const hasOpenHomeReport = user.permissions.openHomeReport && market.openHomesReportSupported;
		const hasMarketReport = user.permissions.marketReport && market.marketReportSupported;
		return (
			<Mui.Grid container spacing={1} direction="column">
				{hasListingReport && (
					<Mui.Grid item>
						<Mui.Accordion variant='outlined'>
							<Mui.AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: '#f5f5f5' }}>
								<Mui.Typography>Listing Report</Mui.Typography>
							</Mui.AccordionSummary>
							<Mui.AccordionDetails>
								<ListingReportContent market={market} user={user} />
							</Mui.AccordionDetails>
						</Mui.Accordion>
					</Mui.Grid>
				)}
				{hasOpenHomeReport && (
					<Mui.Grid item>
						<Mui.Accordion variant='outlined'>
							<Mui.AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: '#f5f5f5' }}>
								<Mui.Typography>Open Homes</Mui.Typography>
							</Mui.AccordionSummary>
							<Mui.AccordionDetails>
								<OpenHomeReportContent market={market} />
							</Mui.AccordionDetails>
						</Mui.Accordion>
					</Mui.Grid>
				)}
				{hasMarketReport && (
					<Mui.Grid item>
						<Mui.Accordion variant='outlined'>
							<Mui.AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: '#f5f5f5' }}>
								<Mui.Typography >Market Report</Mui.Typography>
							</Mui.AccordionSummary>
							<Mui.AccordionDetails>
								<MarketReportContent market={market} />
							</Mui.AccordionDetails>
						</Mui.Accordion>
					</Mui.Grid>
				)}
				<Mui.Grid item>
					<Mui.Accordion variant='outlined'>
						<Mui.AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: '#f5f5f5' }}>
							<Mui.Typography>General</Mui.Typography>
						</Mui.AccordionSummary>
						<Mui.AccordionDetails>
							<GeneralContent market={market} />
						</Mui.AccordionDetails>
					</Mui.Accordion>
				</Mui.Grid>
			</Mui.Grid>
		)
	}
}

export const MarketEditSettings = connect(mapStateToProps, mapDispatchToProps)(Component);