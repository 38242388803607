import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Table } from "component/shared/table";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { styles } from "./style";
import { CampaignSubscription } from "model/campaign-subscription";
import { CampaignEmail } from "model/campaign-email";
import { RootState } from "redux/store";
import { updateCampaignSubscription } from "redux/slice/campaign-subscriptions";
import { CampaignSubscriptionStatus } from "type/campaign-subscription-status";
import { Dialog } from "component/shared/dialog";
import { dateFormatter, shortDateFormatter } from "shared/date-formatter";

const mapStateToProps = (state: RootState) => {
	return {}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateCampaignSubscription,
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{
	subscription: CampaignSubscription;
	index: number;
}

interface State {
	showConfirmDialog: boolean;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			showConfirmDialog: false,
		}
	}

	public toggleStatus() {
		const status = this.props.subscription.status === CampaignSubscriptionStatus.ACTIVE 
			? 
			CampaignSubscriptionStatus.INACTIVE 
			: 
			CampaignSubscriptionStatus.ACTIVE
		;
		let updatedSubscription: CampaignSubscription = {
			...this.props.subscription,
			status,
		}
		this.props.updateCampaignSubscription({subscription: updatedSubscription});
	}

	renderConfirmationDialog = () => {
		
		const { classes } = this.props;
	
		return (
			<Dialog
				open={this.state.showConfirmDialog}
				onClose={() => this.setConfirmDialog(false)}
			>
				<Mui.DialogTitle>Remove Contact</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					{this.props.subscription.status.active ? (
						<p>Are you sure you want to remove this contact from this campaign?</p>
					) : (
						<p>
							Are you sure you want to include this contact in receiving the Marketing
							Automation emails?
						</p>
					)}
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
					<Mui.Button
						variant="contained"
						color="secondary"
						onClick={() => {
							this.setConfirmDialog(false);
							this.toggleStatus();
						}}
					>
						Yes
					</Mui.Button>
					<Mui.Button
						variant="outlined"
						onClick={() => this.setConfirmDialog(false)}
					>
						No
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
		);
	}

	setConfirmDialog = (showConfirmDialog: boolean) => {
		this.setState({ showConfirmDialog })
	}

	getDeliverdOnText = (email: CampaignEmail): string => {
		let text: string = "--";
		if (email.deliveredOn) {
			text = dateFormatter(email.deliveredOn);
		}
		if (!!email.deliveredOn && email.failedOn) {
			text = "Could not deliver (" + shortDateFormatter(email.failedOn) + ")";
		}
		return text;
	}

	public render() {
		const { subscription, index, classes } = this.props;
		return (
			<>
				{this.renderConfirmationDialog()}
				<Mui.ExpansionPanel key={index}>
					<Mui.ExpansionPanelSummary
						expandIcon={
							<FeatherIcon>
								<Icons.ChevronDown />
							</FeatherIcon>
						}
					>
						<Mui.Grid container justifyContent="space-between">
							<Mui.Grid item>
								<Mui.Typography variant="h4">
									{subscription.campaign.name}
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Chip
									label={subscription.status.label}
									size="small"
									variant="outlined"
									color={subscription.status === CampaignSubscriptionStatus.ACTIVE ? "secondary" : "default"}
								/>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.ExpansionPanelSummary>
					<Mui.ExpansionPanelDetails>
						<Mui.Grid container direction="column" spacing={2}>
							<Mui.Grid item className={classes.disclaimer}>
								<Mui.Typography color="textSecondary">*Data provided only within the last 30 days is shown</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item>
								<Table
									className="-highlight"
									items={this.props.subscription.emails}
									columns={[
										{
											id: "message",
											title: "Message",
											value: (email) => {
												return <Mui.Typography>{email.campaignMessage ? email.campaignMessage.name : "Message name not available" }</Mui.Typography>;
											},
										},
										{
											id: "sentOn",
											title: "Sent",
											width: 200,
											value: (email) => {
												return (
													<Mui.Typography>
														{this.getDeliverdOnText(email)}
													</Mui.Typography>
												);
											},
										},
										{
											id: "openedOn",
											title: "Last opened*",
											width: 165,
											value: (email) => {
												return (
													<Mui.Typography>
														{email.openedOn ?  dateFormatter(email.openedOn) : "--"}
													</Mui.Typography>
												);
											},
										},
										{
											id: "clicks",
											title: "Click-throughs*",
											width: 120,
											value: (email) => {
												const { clicks } = email;
												return <Mui.Typography>{clicks || "--"}</Mui.Typography>;
											},
											style: {
												alignItems: "flex-start",
												justifyContent: "center",
											},
										},
									]}
									style={{
										width: "100%",
									}}
									minRows={0}
									showPagination={this.props.subscription.emails.length > 5 ? true : false }
									defaultPageSize={5}
									NoDataComponent={() => (
										<Mui.Typography>No emails sent yet.</Mui.Typography>
									)}
								/>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.ExpansionPanelDetails>
					<Mui.ExpansionPanelActions>
						<Mui.Grid container spacing={2} justifyContent="space-between">
							<Mui.Grid item>
								{subscription.status.active && subscription.remaining && subscription.remaining > 0 &&
									<Mui.Typography>
										{subscription.remaining} messages remaining
									</Mui.Typography>
								}
							</Mui.Grid>	
							{!subscription.status.complete &&
								<Mui.Grid item>
									<Mui.Button
										variant="contained"
										size="small"
										color="secondary"
										onClick={() => this.setConfirmDialog(true)}
									>
										{subscription.status.active ? "Deactivate" : "Activate"}
									</Mui.Button>
								</Mui.Grid>
							}
						</Mui.Grid>
					</Mui.ExpansionPanelActions>
				</Mui.ExpansionPanel>
			</>	
		);
	}
};

export const ExpansionTable = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
