import React from "react";
import { connect } from "react-redux";
import { Right } from "../right";
import { RootState } from "redux/store";
import { getActiveLeads } from "redux/selector";
import { getLatestLeadPipelinesForLeads } from "redux/selector";
import moment from "moment";
import * as Icons from "react-feather";
import { urls } from "routes/urls";
import { LeadColumnType } from "type/lead-column";

interface Props extends ReturnType<typeof mapStateToProps> {}

const mapStateToProps = (state: RootState) => {
	const leads = getActiveLeads(state);
	return {
		leadPipelines: getLatestLeadPipelinesForLeads(
			state,
			leads.map(lead => lead.id),
		),
	};
};

const Component = (props: Props) => {
	const { leadPipelines } = props;

	const numLeadsUpdatedToday = leadPipelines.filter(
		pipeline =>
			pipeline &&
			pipeline.createdOn.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD"),
	).length;
	const numBlankPipelineStatuses = leadPipelines.filter(pipeline => !pipeline)
		.length;
	const numLeadsLeftToUpdate = Math.max(
		Math.min(5, numBlankPipelineStatuses) - numLeadsUpdatedToday,
		0,
	);
	const text =
		numBlankPipelineStatuses === 0 && numLeadsLeftToUpdate === 1
			? "lead"
			: numBlankPipelineStatuses === 0 && numLeadsLeftToUpdate > 1
			? "leads"
			: numLeadsLeftToUpdate === 1
			? "lead's status"
			: "leads' statuses";
	return (
		<Right
			icon={<Icons.Edit />}
			prefix="Update"
			value={numLeadsLeftToUpdate}
			suffix={text}
			url={urls.leads({
				sort: {
					id: LeadColumnType.PIPELINE.id,
					desc: true,
				},
			})}
		/>
	);
};

export const LeadsToUpdate = connect(mapStateToProps)(Component);
