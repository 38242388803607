import React from "react";
import * as Mui from "@material-ui/core";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { styles } from "./style";
import { connect } from "react-redux";
import { Dialog } from "component/shared/dialog";
import { getAgents } from "redux/selector";
import { getPostalCodes } from "redux/selector";
import { Select } from "component/shared/select";
import { GeographicRoute, NewGeographicRoute } from "model/geographic-route";
import { Agent } from "model/agent";
import { saveGeographicRoute } from "redux/slice/geographic-routes";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';



const mapStateToProps = (state: RootState) => {
	return {
		loading: state.leads.loading,
		agents: getAgents(state),
		postalCodes: getPostalCodes(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveGeographicRoute
}, dispatch);

interface OwnProps {
	open: boolean;
	onClose: Function;
	geographicRoute : GeographicRoute | null; 
}

interface Props extends
	OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{}

interface State {
	submitted: boolean;
	postalCode:string[];
	addPostalCodeIsOpen: boolean;
	selectedAgent : number;
}

const initialState: State = {
	submitted: false,
	postalCode: [],
	addPostalCodeIsOpen: false,
	selectedAgent:0
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}
	



	private submit = () => {

		const {
			selectedAgent,
			postalCode
		} = this.state;
		const agentSelectObject: Agent = {
			id: selectedAgent,
			label: '',
			name: '',
			hasOwnSite: false,
			showOnSignupDropDown: false,
			languageIds: []

		}
		postalCode.forEach((value, index)=>{
			const geographicRoute: NewGeographicRoute = {
				agent: agentSelectObject,
				postalCode: value,	
			}
			this.setState({ submitted: true });
			this.props.saveGeographicRoute({ geographicRoute })
			this.props.onClose();		
		})
	}


	public render() {
		const {
			selectedAgent
		} = this.state;
		const { open, onClose, agents, postalCodes } = this.props;

		return (
			<Dialog open={open} onClose={() => onClose()} scroll="paper">
				<Mui.DialogTitle>
					Add Geographic Lead Routing Rule
				</Mui.DialogTitle>
				<Mui.DialogContent>
					<Mui.Grid container spacing={5} direction="column">
						<Mui.Grid item xs={12}>
						<Select
							fullWidth
							variant="outlined"
							margin="dense"
							placeholder="Select an agent"
							value={selectedAgent}
							options={Array.from(agents)}
							valueExtractor={agent => agent.id}
							labelExtractor={agent => agent.name}
							optionRenderer={agent => <Mui.ListItemText primary={agent.name} />}
							onChange={value => this.setState({ 
								selectedAgent: value
							})}
						/>

						</Mui.Grid>
						<Mui.Grid item xs={12}>
						<Autocomplete
							multiple
							id="tags-filled"
							options={postalCodes.map((option) => option?.postalCode)}
							freeSolo
							placeholder="Hit enter after your postal code to add multiple postal codes for an agent"
							renderTags={(value, getTagProps) =>
							value.map((option, index) => (
								<Chip
								variant="outlined"
								label={option}
								{...getTagProps({ index })}
								/>
							))
							}
							renderInput={(params) => (
							<TextField
								{...params}
								label="Postal Codes"
								placeholder="Postal Codes"
							/>
							)}
							onChange={(event, value:any) => this.setState({ 
								postalCode: value
							})}
						/>
						</Mui.Grid>
						<Mui.Grid item>
						<Mui.Button
								variant="contained"
								color="secondary"
								onClick={() => this.submit()}
								style={{ marginRight: 32 }}
								>
									Configure
							</Mui.Button>
							<Mui.Button
								variant="outlined"
								color="secondary"
								onClick={() => onClose()}
							>
								Cancel
							</Mui.Button>
							
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
			</Dialog>
			
		)
	}
}
export const SelectAgentDialog = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);
