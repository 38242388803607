import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { Stars } from "component/shared/ranking/stars";
import { setLeadsPageFilters } from "redux/slice/leads-page"
import { getLeadsPageFilters } from "redux/selector";
import { LeadStatus } from "type/lead-status";
import { LeadPipelineType } from "type/lead-pipeline";
import { LeadRanking } from "type/lead-ranking";

const mapStateToProps = (state: RootState) => {
	const filters = getLeadsPageFilters(state);
	return {
		filters,
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	setLeadsPageFilters,
}, dispatch);

interface State { 
}

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles>
{
	emailFilter?: boolean
}

class Component extends React.Component<Props, State> {

	public render() {
		const { classes, filters, setLeadsPageFilters, emailFilter } = this.props;
		return (
			<>
				{filters.type && filters.type.map((value, index, values) => (
					<Mui.Chip
						key={index}
						label={value}
						onDelete={() => {
							setLeadsPageFilters({
								...filters,
								type: values.filter(item => item !== value),
							});
						}}
						deleteIcon={<Icons.XCircle color="#616161" />}
						className={classes.chip}
					/>
				))}
				{filters.status && filters.status.filter((value) => {
					if(emailFilter) {
						return value === LeadStatus.ACTIVE.id
					} 
					return true;
				}).map((value, index, values) => {
					const statusType = LeadStatus.getById(value);
					if(!statusType) {
						return null;
					}
					return (
						<Mui.Chip
							key={index}
							label={statusType.label}
							onDelete={() => {
								setLeadsPageFilters({
									...filters,
									status: values.filter(item => item !== value),
								});
							}}
							deleteIcon={<Icons.XCircle color="#616161" />}
							className={classes.chip}
						/>
					);
				})}
				{filters.bookmarked && 
					<Mui.Chip
						label="Bookmarked"
						onDelete={() => {
							setLeadsPageFilters({
								...filters,
								bookmarked: false,
							});
						}}
						deleteIcon={<Icons.XCircle color="#616161" />}
						className={classes.chip}
					/>
				}
				{filters.subscribed && 
					<Mui.Chip
						label="Receives Email Updates"
						onDelete={() => {
							setLeadsPageFilters({
								...filters,
								subscribed: false,
							});
						}}
						deleteIcon={<Icons.XCircle color="#616161" />}
						className={classes.chip}
					/>
				}
				{filters.ranking && filters.ranking.map((value, index, values) => {
					const leadRanking = LeadRanking.getById(value);
					if(!leadRanking) {
						return null;
					}
					return (
						<Mui.Chip
							key={index}
							label={<Stars leadRanking={leadRanking} />}
							onDelete={() => {
								setLeadsPageFilters({
									...filters,
									ranking: values.filter((item) => item !== value),
								});
							}}
							deleteIcon={<Icons.XCircle color="#616161" />}
							className={classes.chip}
						/>
					);
						})}
				{filters.source && filters.source.map((value, index, values) => (
					<Mui.Chip
						key={index}
						label={value}
						onDelete={() => {
							setLeadsPageFilters({
								...filters,
								source: values.filter(item => item !== value),
							});
						}}
						deleteIcon={<Icons.XCircle color="#616161" />}
						className={classes.chip}
					/>
				))}
				{filters.pipeline && filters.pipeline.map((value, index, values) => {
					const leadPipelineType = LeadPipelineType.getById(value);
					if(!leadPipelineType) {
						return null;
					}
					return (
						<Mui.Chip
							key={index}
							label={leadPipelineType.label}
							onDelete={() => {
								setLeadsPageFilters({
									...filters,
									pipeline: values.filter(item => item !== value),
								});
							}}
							deleteIcon={<Icons.XCircle color="#616161" />}
							className={classes.chip}
						/>
					);
				})}
				{filters.tag && filters.tag.map((value, index, values) => (
					<Mui.Chip
						key={index}
						label={`TAG: ${value}`}
						onDelete={() => {
							setLeadsPageFilters({
								...filters,
								tag: values.filter(item => item !== value),
							});
						}}
						deleteIcon={<Icons.XCircle color="#616161" />}
						className={classes.chip}
					/>
				))}
			</>
		);
	}

}



export const FiltersChips = Mui.withStyles(styles)(
	Router.withRouter(
		connect(mapStateToProps, mapDispatchToProps)(Component)
	),
);