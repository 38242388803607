import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState, getPayload } from "redux/store";
import { styles } from "./style";
import * as yup from "yup";
import { Agent } from "model/agent";
import { validate } from "shared/yup";
import { updateAgent, resetError} from "redux/slice/agents";
import { SaveButton } from "component/shared/save-button";
import * as Icons from "react-feather";
import { getAgents, getUser } from "redux/selector";
import MuiAlert from '@material-ui/lab/Alert';
import * as MuiIcons from '@material-ui/icons';

interface Props extends OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {}

interface OwnProps {
	agent: Agent;
	onClose: () => void;
}

interface State {
	username: string;
	password: string;
	enablePassword: boolean;
	admin: boolean;
	modifyUserPass: boolean;
	submitted: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const user = getUser(state);
	const agents = getAgents(state);
	return {
		usernameConflict: state.agents.error === "conflict",
		loading: state.agents.loading,
		user,
		agents,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{
			resetError,
			updateAgent,
		},
		dispatch
	);
};

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			username: this.props.agent.username || "",
			password: "",
			enablePassword: false,
			admin: this.props.agent.admin || false,
			modifyUserPass: this.props.agent.allowModifyUserPass || false,
			submitted: false,
		};
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		const { usernameConflict, resetError } = this.props;
		if(usernameConflict) {
			resetError();
		}
	}

	private async save() {
		const { agent, onClose } = this.props;
		const { username, password, admin, modifyUserPass } = this.state;
		const agentResource = getPayload(await this.props.updateAgent( { agent: 
			{
				...agent,
				username,
				password,
				admin,
				allowModifyUserPass: modifyUserPass,
			}
		}));
		if(agentResource) {
			onClose();
		}
	}

	private validationSchema = {
		username: yup
			.string()
			.test(
				"username-length",
				"Username must be 4-75 characters",
				(username) => {
					const correctLength = username && (username.length >= 4 && username.length <= 75);
					return !!!username || !!correctLength;
				}
			)
			.test(
				"username-required",
				"A Username is required",
				(username) => {
					let result = true;
					if(this.props.agent && this.props.agent.username ) {
						if(!!!username) {
							result = false;
						}
					}
					return result;
				}
			)
			.test(
				"username-conflict",
				"Username already exists",
				(username) => {
					let result = true;
					if(this.props.usernameConflict) {
						result = false;
					}
					return result;
				}
			),
		password: yup
			.string()
			.test(
				"password-length",
				"Password must be 4-75 characters",
				(password) => {
					const correctLength = password && (password.length >= 4 && password.length <= 75);
					return !!!password || !!correctLength;
				}
			)
			.test(
				"password-username-required",
				"A Username is required when setting a password",
				(password) => {
					return !!!password || !!this.state.username;
				}
			),
	};

	private minimumAdmin() {
		return this.props.agents.filter((agent) => agent.admin).length === 1;
	}

	render() {
		const { usernameConflict, classes, loading, user, agent } = this.props;
		const { password, username, submitted, modifyUserPass, admin, enablePassword } = this.state;
		const errors = validate(this.validationSchema, {...this.state});
		console.log(1, this.minimumAdmin());
		return (
			<Mui.Grid container direction="column">
				<Mui.Grid item xs={12}>
						<Mui.TextField
							error={(!!usernameConflict) || !!(errors && errors.username)}
							fullWidth
							helperText={
								!!usernameConflict ? "Please choose a different username and try again" :
								errors && errors.username
							}
							inputProps={{
								autoComplete: "off",
							}}
							label="Username"
							margin="dense"
							onChange={(event) => {
								if(usernameConflict) {
									this.props.resetError();
								}	
								this.setState({username: event.target.value})
							}}
							value={username}
							variant="outlined"
							disabled={!modifyUserPass}
						/>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Mui.Grid container alignItems="center">
						<Mui.Grid item style={{ flexGrow: 1 }}>
							<Mui.TextField
								error={!!errors && !!errors.password}
								FormHelperTextProps={{
									className:
										!!errors && !!errors.password
											? classes.errorMessage
											: undefined,
								}}
								fullWidth
								helperText={errors && errors.password}
								inputProps={{
									autoComplete: "new-password",
								}}
								label={enablePassword ? "Update Password" : "********"}
								margin="dense"
								onChange={(event) =>
									this.setState({password: event.target.value})
								}
								type="password"
								value={password}
								variant="outlined"
								style={{backgroundColor: enablePassword ? undefined : "#eeeeee"}}
								disabled={!enablePassword || !modifyUserPass}
							/>
						</Mui.Grid>
						{modifyUserPass && (
							<Mui.Grid item>
								<Mui.Button 
									onClick={() => this.setState({enablePassword: !enablePassword, password: ""})} 
									disableRipple
									className={classes.edit}
								>
									{enablePassword ? (
										<Icons.X size={20} />
									) : (
										<Icons.Edit2 size={20} />
									)}
								</Mui.Button>
							</Mui.Grid>
						)}
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					{user.agentId !== agent.id && (
						<Mui.FormControlLabel
							control={
								<Mui.Switch 
									checked={admin} 
									onChange={() => this.setState({admin: !admin})} 
									name="admin" 
									disabled={agent.admin && this.minimumAdmin()}
								/>
							}
							label="Administrator"
						/>
					)}
					{agent.admin && this.minimumAdmin() && (
						<MuiAlert severity="info" variant="outlined" classes={{message: classes.infoMessage, icon: classes.infoMessageIcon, root: classes.infoRoot}}>
							<Mui.Typography>
								There must be at least one administrator.
							</Mui.Typography>
						</MuiAlert>
					)}
				</Mui.Grid>
				<Mui.Grid item>
					{user.agentId !== agent.id && (
						<Mui.FormControlLabel
							control={
								<Mui.Switch 
									checked={!modifyUserPass} 
									onChange={() => this.setState({modifyUserPass: !modifyUserPass})} 
									name="admin" 
									disabled={agent.admin && this.minimumAdmin()}
									checkedIcon={<MuiIcons.Lock />}
								/>
							}
							label="Lock Username & Password"
						/>
					)}
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Grid container spacing={2} style={{marginTop: 15}}>
						<Mui.Grid item>
							<SaveButton 
								loading={submitted && !!loading}
								error={submitted && errors ? "Please fix the highlighted errors" : undefined} 
								label={{
									primary: "Save",
									inProgress: "Saving...",
									completed: "Saved",
								}}
								snackBar={true}
								onClick={() => {
									this.setState({submitted: true});
									this.save();
								}}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Button 
								variant="contained"
								onClick={() => this.props.onClose() }
							>
								Cancel
							</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const Login = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));