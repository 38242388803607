const key = "credentials";

interface Credentials {
	username: string;
	password: string;
	clientId?: number;
}

export const get = () => {
	retrieveFromUrl();
	const value = localStorage.getItem(key);
	if(value) {
		return JSON.parse(value) as Credentials;
	}
}

/**
 * Used for autologin
 */
const retrieveFromUrl = () => {
	const url = new URL(document.location.href);
	const username = url.searchParams.get("username");
	const password = url.searchParams.get("password");
	if(username && password) {
		url.searchParams.delete("username");
		url.searchParams.delete("password");
		url.searchParams.delete("clientId");
		window.history.replaceState(null, "", url.href);
		set(username, password);
	}
}

export const set = (username: string, password: string) => {
	const credentials: Credentials = {
		username,
		password,
	};
	const value = JSON.stringify(credentials);
	localStorage.setItem(key, value);
}

export const remove = () => {
	localStorage.removeItem(key);
}