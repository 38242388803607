import React from "react";
import * as Mui from "@material-ui/core";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { getPayload, RootState } from "redux/store";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { saveUser } from "redux/slice/authentication";
import { getUserUnsafe } from "redux/selector";
import { styles } from "./style";
import { UserPartial } from "model/user";

const mapStateToProps = (state: RootState) => {
	const user = getUserUnsafe(state);
	return {
		user,
		loading: state.authentication.loading,
		error: state.authentication.error,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveUser,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{
	onSave: (areaCode: string) => void;
	label?: string;
	disabled?: boolean;
}

interface State {
	areaCode: string;
	completed: boolean;
}

const initialState: State = {
	areaCode: "",
	completed: false,
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}

	public componentDidMount() {
		const areaCode = this.props.user?.textMessageAreaCode;
		if(areaCode) {
			this.setState({areaCode: areaCode});
		}
	}

	private onAreaCodeChange = (value: string) => {
		if(value.length <= 3) {
			this.setState({areaCode: value}, 
				() => {
					if(this.state.areaCode.length === 3) {
						this.saveAreaCode();
					} else {
						this.setState({completed: false});
					}
				}
			);
		}
	}

	private saveAreaCode = async () => {
		const userTextMessageAreaCode = this.props.user ? this.props.user.textMessageAreaCode : undefined;
		const userPartial: UserPartial = {
			...this.props.user,
			textMessageAreaCode: this.state.areaCode,
		}
		if(this.state.areaCode !== userTextMessageAreaCode) {
			await getPayload(this.props.saveUser({user: userPartial}));
		}
		this.setState({completed: true});
		this.props.onSave(this.state.areaCode);
		
	}
	
	public render() {
		const { loading, error, disabled, classes } = this.props;
		return (
			<Mui.Grid container>
				<Mui.Grid item>
					<Mui.Grid container direction="row" alignItems="center" spacing={1}>
						{this.props.label &&
							<Mui.Grid item>
								{this.props.label}
							</Mui.Grid>
						}
						<Mui.Grid item style={{paddingRight: 0}}>
							<Mui.TextField 
								value={this.state.areaCode}
								variant="outlined"
								margin="dense"
								type="number"
								placeholder="Area Code"
								onChange={(event) => {
									this.onAreaCodeChange(event.target.value);
									
								}}
								className={classes.field}
								disabled={disabled}
								style={{backgroundColor: disabled ? "#eee" : undefined}}
							/>
						</Mui.Grid>
						<Mui.Grid item className={classes.loader}>
							{(this.state.areaCode !== this.props.user?.textMessageAreaCode) && loading && 
								<LoadingIndicator size={20}/>
							}
							{this.state.completed && !loading && !error &&
								<FeatherIcon>
									<Icons.CheckCircle color="green"/>
								</FeatherIcon>
							}
							{this.state.completed && !loading && error && error === "Area code failed" &&
								<FeatherIcon>
									<Icons.AlertCircle color="red"/>
								</FeatherIcon>
							}
						</Mui.Grid>
					</Mui.Grid>
					{this.state.completed && !loading && error && error === "Area code failed" && 
						<Mui.Grid item>
							<Mui.Typography className={classes.error} >
								Please try again or use a different area code.
							</Mui.Typography>
						</Mui.Grid>
					}
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const AreaCode = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);


