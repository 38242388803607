import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState, getPayload } from "redux/store";
import * as Router from "react-router-dom";
import { urls } from "routes/urls";
import { createCampaign } from "redux/slice/campaigns";
import { createCampaignMessage } from "redux/slice/campaign-messages";
import { createCampaignCampaignMessage } from "redux/slice/campaign-campaign-messages";
import { EmailMarketingType } from "type/email-marketing-type";
import { Campaign } from "model/campaign";

const mapStateToProps = (state: RootState) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createCampaign,
	createCampaignMessage,
	createCampaignCampaignMessage,
}, dispatch);

interface Props extends
	Router.RouteComponentProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
	campaign: Campaign;
}
class Component extends React.Component<Props> {

	private async copy() {
		const { history, campaign, createCampaign, createCampaignMessage, createCampaignCampaignMessage } = this.props;
		const { campaignCampaignMessages } = campaign;
		const [ campaignCampaignMessage ] = campaignCampaignMessages;
		if(!campaignCampaignMessage) {
			throw new Error();
		}
		const { campaignMessage } = campaignCampaignMessage;
		if(!campaignMessage) {
			throw new Error();
		}
		const newCampaign = getPayload(await createCampaign({ campaign: {
			name: campaign.name,
			emailMarketingType: EmailMarketingType.BLAST,
		}}));
		const newCampaignMessage = getPayload(await createCampaignMessage({ campaignMessage: {
			name: campaignMessage.name,
			subject: campaignMessage.subject,
			htmlContent: campaignMessage.htmlContent,
			jsonContent: campaignMessage.jsonContent,
			ownedByReseller: false,
			isInactiveYn: false,
		}}));
		if(newCampaign.id && newCampaignMessage.id) {
			getPayload(await createCampaignCampaignMessage({ campaignCampaignMessage: {
				campaignId: newCampaign.id,
				campaignMessageId: newCampaignMessage.id,
				messageOrder: 0,
			}}));
			history.push(urls.campaignDraftEmail(newCampaign.id));
		}
	}
	
	public render() {
		return (
			<Mui.Button
				variant="contained"
				color="secondary"
				onClick={() => this.copy()}
			>
				Copy
			</Mui.Button>
		);
	}
}

export const CampaignCopyButton = Router.withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);