import React from "react";
import { connect } from "react-redux";
import { Left } from "../left";
import { RootState } from "redux/store";
import { getActiveLeads } from "redux/selector";
import { getActiveDripCampaignSubscriptionsForLead } from "redux/selector";
import { getActiveMarketReportSubscriptionsForLead } from "redux/selector";
import { getActiveSavedSearchesForLead } from "redux/selector";
import moment from "moment";

interface Props extends ReturnType<typeof mapStateToProps> {}

const mapStateToProps = (state: RootState) => {
	const subscribedLeads = getActiveLeads(state).filter(lead => lead.subscribed);
	const leadsSubscriptions = subscribedLeads.map(lead => [
		...getActiveDripCampaignSubscriptionsForLead(state, lead.id),
		...getActiveMarketReportSubscriptionsForLead(state, lead.id),
		...getActiveSavedSearchesForLead(state, lead.id),
	]);
	return {
		leadsSubscriptions,
	};
};

const Component = (props: Props) => {
	const { leadsSubscriptions } = props;
	const numSubscribersAddedPast7Days = leadsSubscriptions.filter(
		leadSubscriptions =>
			!leadSubscriptions.some(
				({ createdOn }) => createdOn < moment().subtract(7, "d"),
			),
	).length;
	const text =
		numSubscribersAddedPast7Days === 1 ? "subscriber added" : "subscribers added";

	return (
		<Left color="#fa8a33" value={numSubscribersAddedPast7Days} text={text} />
	);
};

export const SubscribersAdded = connect(mapStateToProps)(Component);