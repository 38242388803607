import React from "react"
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { Market } from "model/market";
import { bindActionCreators, Dispatch } from "redux";
import { updateMarket, createMarket } from "redux/slice/markets";
import { Criterion } from "model/criterion";
import { RootState } from "redux/store";
import { Manual } from "./manual";
import { MarketListing } from "model/market-listing";
import { MarketListingTableRow } from "./market-listings-table";
import { fetchMarketListings } from "redux/slice/market-listings";
import { styles } from "./styles";
import { ListingSearch } from "component/shared/listing-search";
import { getUser } from "redux/selector";

interface Props extends
	ReturnType<typeof mapDispatchToProps>,
	ReturnType<typeof mapStateToProps>,
	Mui.WithStyles<typeof styles>
{
	market: Market,
	marketListings: MarketListing[],
}

interface State {
	name: string;
	type: "search" | "manual";
	criteria: Criterion[];
}

const mapStateToProps = (state: RootState) => {
	return {
		user: getUser(state),
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateMarket,
	createMarket,
	fetchMarketListings,
}, dispatch);

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props)
		this.state = {
			name: this.props.market.name,
			type: this.idsCriterion() ? "manual" : "search",
			criteria: this.props.market.criteria,
		}
	}

	private handleNameChange(value: string) {
		this.setState({ name: value })
	}

	private idsCriterion() {
		const idsCriterion = this.props.market.criteria && this.props.market.criteria.find((criterion) => criterion.name === "ids");
		return idsCriterion;
	}

	private async save() {
		const { updateMarket, market, fetchMarketListings } = this.props;
		const { name, criteria } = this.state;
		if(criteria) {
			await updateMarket({ market: {
				...market,
				name,
				criteria,
			}});
			fetchMarketListings();
		}
		this.setState({criteria: []});
	}

	private toggleSearchType() {
		const type = this.state.type === "search" ? "manual" : "search"
		this.setState({type, criteria: []});
	}

	public render() {
		const { name, type } = this.state;
		const { marketListings, classes, user } = this.props;
		const { market } = this.props;
		return (
			<Mui.FormControl fullWidth size="small">
				<Mui.Grid container spacing={2} direction="column">
					<Mui.Grid item>
						<Mui.FormControl fullWidth variant="outlined" size="small">
							<Mui.TextField
								value={name}
								label="Name"
								variant="outlined"
								type="text"
								required
								size="small"
								onChange={e => this.handleNameChange(e.target.value)}
								helperText={!name && "Market must have a name"}
								FormHelperTextProps={{className: classes.helperText}}
							/>
						</Mui.FormControl>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.FormControl fullWidth variant="outlined" size="small">
							<Mui.InputLabel>
								Type
							</Mui.InputLabel>
							<Mui.Select
								required
								label="Type"
								value={type}
								onChange={() => this.toggleSearchType()}
							>
								<Mui.MenuItem value="search">
									Eureka
								</Mui.MenuItem>
								<Mui.MenuItem value="manual">
									Manual (add listing by MLS #)
								</Mui.MenuItem>
							</Mui.Select>
						</Mui.FormControl>
					</Mui.Grid>
					{type === "manual" && (
						<Mui.Grid item>
							<Manual 
								criteria={market.criteria} 
								onUpdate={(criterion) => {
								this.setState({ criteria: [criterion] });
							}}/>						
						</Mui.Grid>
					)}

					<Mui.Grid item>
						<Mui.Button
							variant="contained"
							color="secondary"
							fullWidth
							onClick={() => this.save()}
							disabled={!name}
						>
							save
						</Mui.Button>
					</Mui.Grid>
					{type === "search" && (
						<ListingSearch
							user={user}
							criteria={market.criteria} 
							onCriteriaChange={(criteria) => this.setState({ criteria })} 
						/> 
					)}
					{this.idsCriterion() && type === "manual" && (
							<MarketListingTableRow
								marketListings={marketListings}
								market={market}
							/>
					)}
				</Mui.Grid>
			</Mui.FormControl>
		)
	}
}

export const MarketEditCriteria = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));