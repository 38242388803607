import React from "react";
import { useMediaQuery } from "@material-ui/core/";

export const withMediaQuery = (...args: Parameters<typeof useMediaQuery>) => <
	P extends object
>(
	Component: React.ComponentType<P>,
) => (props: any) => {
	const mediaQuery = useMediaQuery(...args);
	return <Component mediaQuery={mediaQuery} {...props} />;
};

export interface WithMediaQuery {
	mediaQuery: boolean;
}
