import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { RootState } from "redux/store";
import { deleteGeographicRoute } from "redux/slice/geographic-routes";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Dialog } from "component/shared/dialog";
import { GeographicRoute } from "model/geographic-route";


const mapStateToProps = (state: RootState) => {
	return {
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	deleteGeographicRoute
}, dispatch);



interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> 
{
	onClose: Function;
	geographicRoute: any;
}

interface State {
}

class Component extends React.Component<Props, State> {

	private submit() {
		const { onClose, geographicRoute, deleteGeographicRoute } = this.props;
		if ('routes' in geographicRoute){
			geographicRoute.routes.forEach((geographicRoute:GeographicRoute)=>{
				deleteGeographicRoute({geographicRoute});
			})
		}else{
			deleteGeographicRoute({geographicRoute});
		}
		
		onClose();
	}

	public render() {
		const { onClose, geographicRoute, classes } = this.props;
		const title = 'routes' in geographicRoute ? ' Geographic Rule' : 'Postal Code'
		return (
			<Dialog
				open={true}
				onClose={() => onClose()}
			>
				<Mui.DialogTitle>{'Delete '+title}</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<Mui.DialogContentText>
						{'Are you sure you want to delete this '+ title +'?'}
					</Mui.DialogContentText>
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
					<Mui.Button
						onClick={() => this.submit()}
						variant="contained"
						color="secondary"
					>
						Yes
					</Mui.Button>
					<Mui.Button onClick={() => onClose()} variant="outlined">
						No
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
		);
	}
}

export const GeogrpahicDeleteDialog = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
