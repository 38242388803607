class Enum {

	public static AGED_COMMUNITY_YN = new Enum("agedCommunityYn");
	public static AREA_AND_SEARCH = new Enum("areaAndSearch");
	public static BASEMENT_YN = new Enum("basementYn");
	public static BATH_COUNT = new Enum("bathCount");
	public static MAX_BATHROOMS = new Enum("maxBathrooms");
	public static BEDROOMS = new Enum("bedrooms");
	public static MAX_BEDROOMS = new Enum("maxBedrooms");
	public static BOARD_ID = new Enum("boardId");
	public static BOUNDARY_WKT = new Enum("boundaryWKT");
	public static CITY_ID = new Enum("cityId");
	public static DATE_RANGE = new Enum("dateRange");
	public static DINING_ROOM_YN = new Enum("diningRoomYn");
	public static FAMILY_ROOM_YN = new Enum("familyRoomYn");
	public static FEATURED_ONLY_YN = new Enum("featuredOnlyYn");
	public static FIREPLACE_YN = new Enum("fireplaceYn");
	public static FORECLOSURE_YN = new Enum("foreclosureYn");
	public static GARAGE_SPACES = new Enum("garageSpaces");
	public static GAR_YN = new Enum("garYn");
	public static GEOGRAPHIC_LIKE = new Enum("geographicLike");
	public static GOLF_COURSE_YN = new Enum("golfCourseYn");
	public static HORSE_PROPERTY_YN = new Enum("horsePropertyYn");
	public static IDS = new Enum("ids");
	public static LIMIT = new Enum("limit");
	public static LOT_ACRES = new Enum("lotAcres");
	public static MAX_LOT_ACRES = new Enum("maxLotAcres");
	public static LOT_SQ_FT = new Enum("lotSqFt");
	public static MAP_CODE = new Enum("mapCode");
	public static MAX_LIST_PRICE = new Enum("maxListPrice");
	public static MIN_LIST_PRICE = new Enum("minListPrice");
	public static MISC1_YN = new Enum("misc1Yn");
	public static MISC2_YN = new Enum("misc2Yn");
	public static MISC3_YN = new Enum("misc3Yn");
	public static MISC4_YN = new Enum("misc4Yn");
	public static MISC5_YN = new Enum("misc5Yn");
	public static MISC6_YN = new Enum("misc6Yn");
	public static MISC7_YN = new Enum("misc7Yn");
	public static MISC8_YN = new Enum("misc8Yn");
	public static MISC9_YN = new Enum("misc9Yn");
	public static MISC_INT1 = new Enum("miscInt1");
	public static MISC_INT2 = new Enum("miscInt2");
	public static MISC_INT3 = new Enum("miscInt3");
	public static MISC_INT4 = new Enum("miscInt4");
	public static MISC_INT5 = new Enum("miscInt5");
	public static MISC_INT6 = new Enum("miscInt6");
	public static MISC_INT7 = new Enum("miscInt7");
	public static MISC_INT8 = new Enum("miscInt8");
	public static MISC_INT9 = new Enum("miscInt9");
	public static MISC_INT10 = new Enum("miscInt10");
	public static MISC_INT11 = new Enum("miscInt11");
	public static MISC_INT12 = new Enum("miscInt12");
	public static MISC_INT13 = new Enum("miscInt13");
	public static MISC_STRING1 = new Enum("miscString1");
	public static MISC_STRING2 = new Enum("miscString2");
	public static MISC_STRING3 = new Enum("miscString3");
	public static MISC_STRING4 = new Enum("miscString4");
	public static MISC_STRING5 = new Enum("miscString5");
	public static MISC_STRING6 = new Enum("miscString6");
	public static MISC_STRING7 = new Enum("miscString7");
	public static MISC_STRING8 = new Enum("miscString8");
	public static MLSAREA = new Enum("mlsarea");
	public static NEIGHBORHOOD = new Enum("neighborhood");
	public static NEIGHBORHOOD_ID = new Enum("neighborhoodId");
	public static NEW_CONSTRUCTION_YN = new Enum("newConstructionYn");
	public static NUM_FIREPLACES = new Enum("numFireplaces");
	public static OFFSET = new Enum("offset");
	public static ONE_STORY_YN = new Enum("oneStoryYn");
	public static OPEN_HOMES_ONLY_YN = new Enum("openHomesOnlyYn");
	public static POOL_YN = new Enum("poolYn");
	public static PROPERTY_LOCATION = new Enum("propertyLocation");
	public static PROPERTY_SUB_TYPE = new Enum("propertySubType");
	public static PROPERTY_SUB_TYPE2 = new Enum("propertySubType2");
	public static PROPERTY_TYPE = new Enum("propertyType");
	public static REGION = new Enum("region");
	public static REOYN = new Enum("reoyn");
	public static SCHOOL_DISTRICT = new Enum("schoolDistrict");
	public static ELEMENTARY_SCHOOL = new Enum("elementarySchool");
	public static MIDDLE_SCHOOL = new Enum("elementarySchool");
	public static HIGH_SCHOOL = new Enum("highSch");
	public static SHORT_SALE_YN = new Enum("shortSaleYn");
	public static SORT = new Enum("sort");
	public static SQUARE_FEET = new Enum("squareFeet");
	public static MAX_SQUARE_FEET = new Enum("maxSquareFeet");
	public static STATUS = new Enum("status");
	public static STYLE = new Enum("style");
	public static STYLE_LIKE = new Enum("styleLike");
	public static DISTRESSED = new Enum("distressed");
	public static LOT_FREATURES = new Enum("lotFeatures");
	public static SUBDIVISION = new Enum("subdivision");
	public static VIEW_YN = new Enum("viewYn");
	public static VIRTUAL_TOUR_YN = new Enum("virtualTourYn");
	public static WATER_BODY = new Enum("waterBody");
	public static WATERFRONT_DESC = new Enum("waterfrontDesc");
	public static WATER_FRONT_YN = new Enum("waterFrontYn");
	public static WATERFRONT_DESC_LIKE = new Enum("waterfrontDescLike");
	public static YEAR_BUILT = new Enum("yearBuilt");
	public static MAX_YEAR_BUILT = new Enum("maxYearBuilt");
	public static ZIP = new Enum("zip");
	public static COUNTY = new Enum("county");
	public static GATED_COMMUNITY_YN = new Enum("gatedCommunityYn");
	public static PRIMARY_BEDROOM_ON_MAIN_YN = new Enum("primaryBedroomOnMainYn");
	public static PETS_ALLOWED_YN = new Enum("petsAllowedYn");
	
	public static readonly AREA_TYPES = [
		Enum.CITY_ID,
		Enum.ZIP,
		Enum.NEIGHBORHOOD_ID,
		Enum.MLSAREA,
		Enum.NEIGHBORHOOD,
		Enum.MAP_CODE,
		Enum.PROPERTY_LOCATION,
		Enum.REGION,
		Enum.SCHOOL_DISTRICT,
		Enum.SUBDIVISION,
		Enum.COUNTY,
		Enum.ELEMENTARY_SCHOOL,
		Enum.MIDDLE_SCHOOL,
		Enum.HIGH_SCHOOL,
	];

	private constructor(
		public readonly id: string,
	) {
	}

	public static values(): Enum[] {
		return Object.values(Enum);
	}

	public static getById(id: string) {
		return this.values().find((value) => value.id === id);
	}

	public static includesArea(ids: string[]) {
		return ids.some(id => this.AREA_TYPES.map(type => type.id).includes(id))
	}
	
}

export { Enum as ListingSearchFieldType };
