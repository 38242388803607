import React from "react";
import { connect } from "react-redux";
import { Right } from "../right";
import { RootState } from "redux/store";
import { getActiveLeads } from "redux/selector";
import { getCallNotes } from "redux/selector";
import moment from "moment";
import * as Icons from "react-feather";
import { urls } from "routes/urls";
import { LeadColumnType } from "type/lead-column";

interface Props extends ReturnType<typeof mapStateToProps> {}

const mapStateToProps = (state: RootState) => {
	return {
		leads: getActiveLeads(state),
		callNotes: getCallNotes(state)
	};
};

const Component = (props: Props) => {
	const { leads, callNotes } = props;
	const calledLeadIds = new Set(callNotes.map(note => note.leadId));

	// number of active leads minus number of unique lead ids that have been called
	const numUncontactedLeads = leads.length - calledLeadIds.size;

	let numUncontactedLeadsContactedToday = 0;
	const leadWasFirstContactedToday: {[key: string]: boolean} = {};
	callNotes.forEach(({createdOn, leadId}) => {
		// If call was logged today
		if(createdOn.format("YYYYMMDD") === moment().format("YYYYMMDD")) {
			if(leadWasFirstContactedToday[leadId] === undefined) {
				leadWasFirstContactedToday[leadId] = true;
			}
		} else { // If lead has been contacted before today
			leadWasFirstContactedToday[leadId] = false;
		}
	})
	leads.forEach(lead => {
		if(leadWasFirstContactedToday[lead.id]) {
			numUncontactedLeadsContactedToday++;
		}
	})

	const numCallsToMake = Math.max(
		Math.min(5, numUncontactedLeads) - numUncontactedLeadsContactedToday,
		0
	);
	return (
		<Right
			icon={<Icons.Phone />}
			prefix="Call"
			value={numCallsToMake}
			suffix={
				(numUncontactedLeads === 0 ? "" : "uncontacted ") +
				(numCallsToMake === 1 ? "lead" : "leads")
			}
			url={urls.leads({
				sort: {
					id: LeadColumnType.PIPELINE.id,
					desc: true,
				},
			})}
		/>
	);
};

export const CallsToMake = connect(mapStateToProps)(Component);
