import { arrayToById, objectToById, arrayToByForeignId, objectToByForeignId, mergeByForeignId } from "redux/normalize";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { NewLeadPipeline } from "model/lead-pipeline";
import { loginReset } from "redux/slice/authentication";
import { LeadPipelineResource } from "api/resource/lead-pipeline";

export interface LeadPipelinesState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: LeadPipelineResource;
	};
	byLeadId: {
		[key: number]: number[];
	};
};

const initialState: LeadPipelinesState = {
	byId: {},
	byLeadId: {},
};

const fetchLeadPipelines = createAsyncThunk("leadPipelines/fetchLeadPipelines", async (payload, thunkAPI) => {
	thunkAPI.dispatch(leadPipelinesLoading());
	const result = api.getLeadPipelines();
	result
		.then((leadPipelines) => thunkAPI.dispatch(leadPipelinesLoaded(leadPipelines)))
		.catch(error => thunkAPI.dispatch(leadPipelinesFailed(error)));
	return result;
});

const createLeadPipeline = createAsyncThunk("leadPipelines/saveLeadPipelines", async (payload: { leadPipeline: NewLeadPipeline}, thunkAPI) => {
	thunkAPI.dispatch(leadPipelinesLoading());
	const result = api.createLeadPipeline(payload.leadPipeline);
	result
		.then((leadPipeline) => thunkAPI.dispatch(leadPipelineLoaded(leadPipeline)))
		.catch(error => thunkAPI.dispatch(leadPipelinesFailed(error)));
	return result;
});

export const leadPipelinesSlice = createSlice({
	name: "leadPipelines",
	initialState,
	reducers: {
		leadPipelinesLoading: (state) => {
			state.loading = true;
		},
		leadPipelinesLoaded: (state, action: PayloadAction<LeadPipelineResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
			state.byLeadId = arrayToByForeignId(action.payload, "id", "leadId");
		},
		leadPipelineLoaded: (state, action: PayloadAction<LeadPipelineResource>) => {
			const byId = objectToById(action.payload, "id");
			const byLeadId = mergeByForeignId(state.byLeadId, objectToByForeignId(action.payload, "id", "leadId"));
			state.loading = false;
			state.error = null;
			state.byId = { 
				...state.byId, 
				...byId 
			};
			state.byLeadId = byLeadId;
		},
		leadPipelinesFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
            return state;
        }
    },
    extraReducers(builder) {
        builder.addCase(loginReset, () => {
            return initialState;
        });
    }
});

export const { leadPipelinesLoading, leadPipelinesLoaded, leadPipelineLoaded, leadPipelinesFailed } = leadPipelinesSlice.actions;
export { fetchLeadPipelines, createLeadPipeline };
