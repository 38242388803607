import React from "react";
import * as Mui from "@material-ui/core";
import { ActivityCard } from "./activity-card";
import { LeadActivityType } from "type/lead-activity-type";
import { styles } from "./style";
import { RootState } from "redux/store";
import { getLeadActivitiesForLeadChronological } from "redux/selector";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FeatherIcon } from "component/shared/feather-icon";
import { Select } from "component/shared/select";
import { Pagination } from "./pagination";

const mapStateToProps = (state: RootState, ownProps: any) => {
	return {
		leadActivities: getLeadActivitiesForLeadChronological(state, ownProps.lead.id),
		loading: state.leadActivities.loading
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
}

interface State {
	selectedLeadActivityTypes: Set<LeadActivityType>;
}

class Component extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			selectedLeadActivityTypes: new Set(LeadActivityType.values()),
		};
	}

	handleChange(typeIds: number[]) {
		const selectedLeadActivityTypes = new Set<LeadActivityType>();
		typeIds.forEach((id) => {
			const leadActivityType = LeadActivityType.getById(id);
			if(leadActivityType) {
				selectedLeadActivityTypes.add(leadActivityType);
			}
		});
		this.setState({ 
			selectedLeadActivityTypes,
		});
	}

	public render() {
		const { loading } = this.props;
		let { leadActivities } = this.props;
		const { selectedLeadActivityTypes } = this.state;
		const filteredLeadActivities = leadActivities.filter((leadActivity) => {
			return selectedLeadActivityTypes.has(leadActivity.type);
		});
		const leadActivityTypes = new Set(leadActivities.map(leadActivity => leadActivity.type));
		const selectedLeadActivityTypeIds = Array.from(selectedLeadActivityTypes).map(type => type.id);
		if(!leadActivities.length && !loading) {
			return (
				<Mui.Typography align="center" style={{padding: "30px 50px"}}>This lead does not have any activity yet.</Mui.Typography>
			);
		}
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Mui.FormControl>
						<Select
							multiple
							placeholder="Activity Filters"
							value={selectedLeadActivityTypeIds}
							options={Array.from(leadActivityTypes)}
							valueExtractor={(type) => type.id}
							labelExtractor={(type) => type.label}
							optionRenderer={(type) => (
								<>
									<Mui.ListItemIcon>
										<FeatherIcon>
											<type.icon />
										</FeatherIcon>
									</Mui.ListItemIcon>
									<Mui.ListItemText primary={type.label} />
								</>
							)}
							onChange={(values) => this.handleChange(values)}
						/>
					</Mui.FormControl>
				</Mui.Grid>
				<Pagination>
					{filteredLeadActivities.map((activity, index) => (
						<Mui.Grid key={index} item>
							<ActivityCard activity={activity} />
						</Mui.Grid>
					))}
				</Pagination>
				{filteredLeadActivities.length === 0 &&
					<Mui.Grid item>
						<Mui.Typography align="center">Select a filter to see results.</Mui.Typography>
					</Mui.Grid>
				}
			</Mui.Grid>
		);
	}
}

export const ActivityTab = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
