class Type {
	public static LEAD_PROFILE =  new Type("userSpecific", "Lead Profile");
	public static MARKET =  new Type("marketSpecific", "Market");

	private constructor(
		public readonly id: string,
		public readonly label: string,
	) {
	}

	public get LeadProfile() {
		return this === Type.LEAD_PROFILE;
	}

	public get Market() {
		return this === Type.MARKET;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: string) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as DynamicContentType };