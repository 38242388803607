import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { MapSearchMapType } from "type/map-search-map-type";
import { styles } from "./style";
import * as mapboxgl from "mapbox-gl";
import * as env from "shared/env";
import { fetchGeocode } from "redux/slice/geocode";
import { debounce } from "debounce";

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{
	zoom: number;
	address: string;
	mapType: MapSearchMapType;
}

interface State {
}

const mapStateToProps = (state: RootState) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

class Component extends React.Component<Props, State> {

	private loaded = false;
	private map?: mapboxgl.Map;

	private async load(container: HTMLElement) {
		const {
			zoom,
			address,
			mapType,
		} = this.props;
		const center = await fetchGeocode(address);
		const { style } = mapType;
		const map = new mapboxgl.Map({
			accessToken: env.mapboxAccessToken,
			container,
			zoom,
			center,
			style,
			// attributionControl: false,
			interactive: false,
		});
		this.map = map;
	}

	public async componentDidUpdate(prevProps: Props) {
		if(this.map) {
			if(prevProps.zoom !== this.props.zoom) {
				this.map.zoomTo(this.props.zoom);
			}
			if(prevProps.address !== this.props.address) {
				this.updateCenter(this.props.address);
			}
			if(prevProps.mapType !== this.props.mapType) {
				this.map.setStyle(this.props.mapType.style);
			}
		}
	}

	
	private updateCenter = debounce(async (address: string) => {
		if(this.map) {
			const center = await fetchGeocode(address);
			this.map.panTo(center);
		}
	}, 1000);

	public render() {
		const { classes } = this.props;
		return (
			<Mui.Paper
				className={classes.container}
				ref={(element: HTMLElement | null) => {
					if(element && !this.loaded) {
						this.loaded = true;
						this.load(element);
					}
				}}
			/>
		);
	}
}

export const Map = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);