import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./styles";

interface Props extends
	Mui.WithStyles<typeof styles> {
}

interface State {
	loading: boolean;
}

class Component extends React.Component<Props, State> {
	public render() {
		const {classes} = this.props;
		return (
			<Mui.Grid
				container
				spacing={4}
				direction="column"
				justifyContent="center"

			>
				<Mui.Grid item>
					<Mui.Typography variant="h1">
						With a paid account, you’ll have access to Marketing Automation.
					</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Grid container spacing={3} direction="row" justifyContent="center">
						<Mui.Grid item>
							<img alt="" src="https://www.ihomefinder.com/wp-content/uploads/2019/07/marketing-automation-emails.gif" style={{width: 215, height: 400, objectFit: "cover"}} />
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container direction="column" justifyContent="center" className={classes.featurePoints}>
								<Mui.Grid item>
									<Mui.Typography variant="h1">
										Better, smarter follow-up
									</Mui.Typography>
									<Mui.Typography variant="subtitle2">
										Jump-start lead engagement with personalized, dynamic follow-up
										you won’t get anywhere else.
									</Mui.Typography>
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Grid container direction="row" spacing={2} justifyContent="space-evenly" alignItems="stretch">
										<Mui.Grid item>
											<Mui.Card className={classes.featureCard}>
												<Mui.CardContent>
													<Mui.Typography className={classes.subtitle}>
														Smart
													</Mui.Typography>
													<Mui.Typography >
														Send automated listing recommendations and market data based on your leads' search and viewing behavior.
													</Mui.Typography>
												</Mui.CardContent>
											</Mui.Card>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Card className={classes.featureCard}>
												<Mui.CardContent>
													<Mui.Typography className={classes.subtitle}>
														Fresh
													</Mui.Typography>
													<Mui.Typography variant="body2">
														Keep the conversation going by sending always-current content instead of stale, generic campaigns.
													</Mui.Typography>
												</Mui.CardContent>
											</Mui.Card>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Card className={classes.featureCard}>
												<Mui.CardContent>
													<Mui.Typography className={classes.subtitle}>
														Customizable
													</Mui.Typography>
													<Mui.Typography variant="body2">
														Tailor messages by mixing your own custom content with Smart Content.
													</Mui.Typography>
												</Mui.CardContent>
											</Mui.Card>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Divider variant="middle" />
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Grid container spacing={3} direction="row" justifyContent="center">
						<Mui.Grid item>
							<img alt="" src="https://www.ihomefinder.com/wp-content/uploads/2018/11/screen1497b.gif" className={classes.screenShot} />
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container direction="column" spacing={2} justifyContent="center">
								<Mui.Grid item>
									<Mui.Typography variant="h1">
										Straightforward and simple
									</Mui.Typography>
									<Mui.Typography variant="subtitle2">
										Spend more time doing what you do best.
									</Mui.Typography>
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Grid container direction="row" spacing={2} justifyContent="space-evenly" alignItems="stretch">
										<Mui.Grid item>
											<Mui.Card className={classes.featureCard}>
												<Mui.CardContent>
													<Mui.Typography className={classes.subtitle}>
														Ready To Go
													</Mui.Typography>
													<Mui.Typography >
														Start following up right away! Your account comes pre-loaded with a "buyer", "seller" and "general follow-up" campaign.
													</Mui.Typography>
												</Mui.CardContent>
											</Mui.Card>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Card className={classes.featureCard}>
												<Mui.CardContent>
													<Mui.Typography className={classes.subtitle}>
														Flexible and Friendly
													</Mui.Typography>
													<Mui.Typography variant="body2">
														Build your own great-looking emails with a simple editor that makes it easy to add images, video, and Smart Content.
													</Mui.Typography>
												</Mui.CardContent>
											</Mui.Card>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Card className={classes.featureCard}>
												<Mui.CardContent>
													<Mui.Typography className={classes.subtitle}>
														Easy Scheduling
													</Mui.Typography>
													<Mui.Typography variant="body2">
														Set the days or minutes between messages with a few clicks, and re-order messages with a simple drag and drop.
													</Mui.Typography>
												</Mui.CardContent>
											</Mui.Card>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
						
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Divider variant="middle" />
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Grid container spacing={3} direction="row" justifyContent="center">
						<Mui.Grid item>
							<Mui.Paper elevation={0} style={{borderWidth: 1, borderStyle: "solid", borderColor: "#ddd", padding: 5}}>
								<img  alt="" src="https://www.ihomefinder.com/wp-content/uploads/2018/11/ar_ruleb.png"  className={classes.screenShot} />
							</Mui.Paper>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container direction="column" spacing={2} justifyContent="center">
								<Mui.Grid item>
									<Mui.Typography variant="h1">
										“Oops” is not an option
									</Mui.Typography>
									<Mui.Typography variant="subtitle2">
										Consistent follow-up for leads from all sources means nobody slips through the cracks.
									</Mui.Typography>
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Grid container direction="row" spacing={2} justifyContent="space-evenly" alignItems="stretch">
										<Mui.Grid item>
											<Mui.Card className={classes.featureCard}>
												<Mui.CardContent>
													<Mui.Typography className={classes.subtitle}>
														Lead Aggregation
													</Mui.Typography>
													<Mui.Typography >
														New leads from Zillow, Trulia, Realtor.com, and other lead generation services are automatically imported into your iHomefinder account.
													</Mui.Typography>
												</Mui.CardContent>
											</Mui.Card>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Card className={classes.featureCard}>
												<Mui.CardContent>
													<Mui.Typography className={classes.subtitle}>
														Automated Campaign Assignment
													</Mui.Typography>
													<Mui.Typography variant="body2">
														Easily create rules that assign leads to specific campaigns depending on their source.
													</Mui.Typography>
												</Mui.CardContent>
											</Mui.Card>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Card className={classes.featureCard}>
												<Mui.CardContent>
													<Mui.Typography className={classes.subtitle}>
														Gain Insight
													</Mui.Typography>
													<Mui.Typography variant="body2">
														Check the performance of your campaigns and the activity of your subscribers with integrated stats including opens, clicks, and more.
													</Mui.Typography>
												</Mui.CardContent>
											</Mui.Card>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</Mui.Grid>		
		);
	}
}

export const UnauthorizedMa = Mui.withStyles(styles)(Component);
