import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import * as kestrelEmbedCode from "shared/kestrel-embed-code";
import * as yup from "yup";
import { validate } from "shared/yup";
import { getMarkets } from "redux/selector";
import { WidgetConfigLayout } from "../widget-config-layout";
import { getUser } from "redux/selector";
import { MarketReportType } from "type/market-report-type";

const mapStateToProps = (state: RootState) => {
	return {
		user: getUser(state),
		markets: getMarkets(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
}

interface State {
	id: number;
	marketReportTypeId: number;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props) 
		this.state = {
			id: 0,
			marketReportTypeId: 0,
		}
	}

	private validationSchema = {
		id: yup
			.number().moreThan(0)
			.required("Market is required."),
		marketReportTypeId: yup
			.number().test("Market Report is required", (marketReportTypeId) => {
				const market = this.selectedMarket();
				return market && market.marketReportTypes.length ? !!marketReportTypeId : true;
			}),

	};

	private selectedMarket() {
		return this.props.markets.find((market) => market.id === this.state.id);
	}

	public render() {
		const { user, markets } = this.props;
		const { id, marketReportTypeId } = this.state;
		const errors = validate(this.validationSchema, this.state);
		const embedCode = kestrelEmbedCode.marketReportWidget(
			user.websitePlatform,
			id,
			marketReportTypeId || MarketReportType.LISTING.id,
		);
		const market = this.selectedMarket();
		return (
			<WidgetConfigLayout user={user} embedCode={!errors ? embedCode : undefined}>
				<Mui.Grid container spacing={2} direction="row">
					<Mui.Grid item xs={12}>
						<Mui.Typography variant="h4">
							Widget Options
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								select
								required
								fullWidth
								variant="outlined"
								value={id}
								onChange={(event) => this.setState({id: parseInt(event.target.value)})}
								label="Market"
							>
								{markets.map((market) => (
									<Mui.MenuItem key={market.id} value={market.id}>{market.name}</Mui.MenuItem>)
								)}	
							</Mui.TextField>
						</Mui.FormControl>
					</Mui.Grid>
					{market && market.marketReportTypes.length > 0 && (
						<Mui.Grid item xs={12}>
							<Mui.FormControl variant="outlined" fullWidth>
								<Mui.TextField
									select
									fullWidth
									required
									variant="outlined"
									value={marketReportTypeId}
									onChange={(event) => this.setState({marketReportTypeId: parseInt(event.target.value)})}
									label="Type"
								>
									{market.marketReportTypes && market.marketReportTypes.map((type) => (
										<Mui.MenuItem key={type.id} value={type.id}>{type.label}</Mui.MenuItem>
									))}
								</Mui.TextField>
							</Mui.FormControl>
						</Mui.Grid>
					)}
				</Mui.Grid>
			</WidgetConfigLayout>			
		);
	}
}

export const MarketReportConfig = (
	connect(mapStateToProps, mapDispatchToProps)(Component)
);