import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { CsvReader } from './csv-reader';
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { NewLeadImport } from "model/lead-import";
import { saveLeadImport, reset } from "redux/slice/lead-import";
import { fetchLeads } from "redux/slice/leads";
import { fetchLeadTags } from "redux/slice/lead-tags"
import { getPayload, RootState } from "redux/store";
import { getLeadImports } from "redux/selector";
import LinearProgress from '@material-ui/core/LinearProgress';
import { TableDisplay } from './table-display';
import MuiAlert from "@material-ui/lab/Alert";
import { styles } from "./style";
import { ImportError } from "type/import-error";
import moment from "moment";
import {CSVLink as CsvLink} from "react-csv";

interface OwnProps {}

interface Props 
	extends OwnProps,
		PageProps, 
		Router.RouteComponentProps,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		Mui.WithStyles<typeof styles> {
}


interface State {
	importPreview: NewLeadImport[];
	valid?: boolean;
	warning?: boolean;
	invalidHeaders: string[];
} 

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const importResult = getLeadImports(state);
	return {
		importResult,
		loading: state.leadImports.loading,
		error: state.leadImports.error,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{
			saveLeadImport,
			reset,
			fetchLeads,
			fetchLeadTags,
		},
		dispatch);
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			importPreview: [],
			invalidHeaders: [],
		};
	}

	private leaveMessage = "Are you sure you want to leave the importer? Make sure your import has completed.";

	private async save()  {
		const chunkSize = 50;
		this.props.reset()
		for (let i = 0;  i < this.state.importPreview.length; i += chunkSize) {
			let chunk = [...this.state.importPreview.slice(i, i + chunkSize)];
			await getPayload(this.props.saveLeadImport({leadImports: chunk}));
		}
		this.props.fetchLeads();
		this.props.fetchLeadTags();
	}	

	private progress() {
		return (this.props.importResult.length - 0) * 100 / (this.state.importPreview.length - 0)
	}

	private inProgress() {
		return this.progress() > 0 && this.progress() < 100;
	}

	private clear() {
		this.setState({importPreview: []});
		this.props.reset();
	}

	private formattedDateString(dateString: string) {
		const dateRegex = /^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{2}|[0-9]{4})$/;
		if(dateRegex.test(dateString)) {
			let formattedDateString: string;
			const centuryLimit = parseInt(moment().format("YY")) + 7;
			const dateParts = dateString.split("/");
			const yearPadding = dateParts[2].length === 2 && parseInt(dateParts[2]) > centuryLimit ? 19 : 20;
			let month = dateParts[0].length === 1 ? `0${dateParts[0]}` : dateParts[0];
			let day = dateParts[1].length === 1 ? `0${dateParts[1]}` : dateParts[1];
			let year = dateParts[2].length === 2 ? `${yearPadding}${dateParts[2]}` : dateParts[2];
			formattedDateString = `${month}/${day}/${year}`;
			if(formattedDateString) {
				return formattedDateString;
			}
		}
		return dateString;
	}

	private renderCsvReader(buttonLabel?: string) {
		return (
			<CsvReader 
				buttonLabel={buttonLabel}
				onUpload={(results, invalidHeaders) => {
				this.clear();
				this.setState({invalidHeaders: invalidHeaders})
				const leadImports: NewLeadImport[] = results.map((result) => {
					const leadImport: NewLeadImport = {
						id: Math.random().toString().split(".")[1],
						address: result.address,
						email: result.email,
						city: result.city,
						name: result.name,
						phone: result.phone,
						state: result.state,
						zip: result.zip,
						birthday: result.birthday ? this.formattedDateString(result.birthday) : undefined,
						contactType: result.type,
						agentId: result.agentid ? parseInt(result.agentid) : undefined,
						transactionAnniversary: result.anniversary ? this.formattedDateString(result.anniversary) : undefined,
						pipelineType: result.pipeline,
						tags: result.tags ? result.tags.split(",").map((tag: string) => tag.trim()): undefined,
						note: result.note,
						textMessageOptIn: result.texting && result.texting.toLowerCase() === "true" ? true : false,
						marketIds: result.marketids ? result.marketids.split(",").map((market: string) => parseInt(market)) : undefined,
						campaignIds: result.campaignids ? result.campaignids.split(",").map((campaign: string) => parseInt(campaign)) : undefined,
						leadSource: result.source,
					}
					return leadImport;
				})
				this.setState({importPreview: [...this.state.importPreview, ...leadImports]})
			}}/>
		);
	}

	public beforeUnload = (event: BeforeUnloadEvent) => {
		event.preventDefault();
		event.returnValue = this.leaveMessage;
	}

	public componentWillUnmount() {
		window.removeEventListener("beforeunload", this.beforeUnload);
		this.props.history.block(true);
	}

	public componentDidMount() {
		this.props.reset();
		window.addEventListener("beforeunload", this.beforeUnload);
	}

	public componentDidUpdate(prevProps: Props) {
		if(this.props.loading || this.inProgress()) {
			this.props.history.block(this.leaveMessage);
		} else {
			   this.props.history.block(true);
		}	
	}

	private templateData() {
		const { user } = this.props;
		if(user.permissions.crm) {
			return "Name,Email,Phone,Address,City,State,Zip,Birthday,Type,Anniversary,Pipeline,Texting,Tags,CampaignIDs,MarketIDs,Note,Source"
		}
		if(user.permissions.broker) {
			return "Name,Email,Phone,Address,City,State,Zip,AgentID,MarketIDs,Note,Source"
		}
		if(user.permissions.markets) {
			return "Name,Email,Phone,Address,City,State,Zip,MarketIDs,Note,Source"
		}
		
	}

	public render() {
		const { user, classes } = this.props;
		const title = "Import Leads";
		const leadImportSuccesses = this.props.importResult.filter((leadImport) => {
			return !leadImport.error || leadImport.error === ImportError.action;
		});
		const leadImportFails = this.props.importResult.filter((leadImport) => {
			return leadImport.error === ImportError.duplicate || leadImport.error === ImportError.import;
		});
		return (
			<>
				<DashboardLayout
					permitted={user && user.permissions.leads}
					title={title}
					header={
						<Mui.Typography variant="h1">
							<FeatherIcon>
								<Icons.User />
							</FeatherIcon>
							{title}
						</Mui.Typography>
					}
				>					
					<Mui.Grid container spacing={2} direction="column" justifyContent="center">
						<Mui.Grid item>
							<Mui.Typography>
								Import leads to your account from a CSV. 
							</Mui.Typography>
							<Mui.Typography style={{verticalAlign: "middle", display: "inline-flex"}}>
								<FeatherIcon className={classes.downloadIcon}>
									<Icons.Download />
								</FeatherIcon>
								{this.templateData() && 
									<Mui.Typography >
										<CsvLink
											data={this.templateData() || ""}
											filename="lead-import-template.csv"
											className={classes.csvLink}
										>
											Download Template
										</CsvLink>
									</Mui.Typography>	
								}
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item style={{display: this.state.importPreview.length > 0 ? "none" : undefined}}>
							{this.renderCsvReader()}
						</Mui.Grid>
						{this.state.importPreview.length > 0 && (
							<Mui.Grid item className={classes.importButtonsContainer}>
								<div className={classes.importButtons}>
									{this.progress() !== 100 && 
										<Mui.Grid container spacing={2}>
											<Mui.Grid item>
												<Mui.Button 
													variant="contained" 
													color="secondary" 
													disabled={(this.state.valid !== undefined && !this.state.valid) || this.inProgress()}
													onClick={() => this.save()}
												>
													Import
												</Mui.Button>
											</Mui.Grid>
											<Mui.Grid item>
												<Mui.Button 
													variant="contained"
													onClick={() => {
														if(this.inProgress()) {
															if(window.confirm(this.leaveMessage)) {
																this.clear();			
															}
														} else {
															this.clear();
														}
													}}
												>
													{this.state.valid ? "Cancel" : "Upload Again"}
												</Mui.Button>
											</Mui.Grid>
										</Mui.Grid>
										
									}
									{this.progress() === 100 && 
										<>{this.renderCsvReader("Import Again")}</>
									}
								</div>
							</Mui.Grid>
						)}
						{this.state.importPreview.length > 0 && this.props.importResult.length === 0 &&
							<Mui.Grid item>
								<Mui.Grid container spacing={2} direction="column">
									<Mui.Grid item>
										<Mui.Typography variant="h2">
											Import Preview
										</Mui.Typography>
									</Mui.Grid>
									<Mui.Grid item>
										{this.state.invalidHeaders.length !== 0 &&
											<MuiAlert severity="warning" className={classes.alert}>
												{`Warning! There are columns in your data that are not supported and will not be imported: `}
												{this.state.invalidHeaders.map((text, index) => {
													return (
														<span key={index}>
															{`"${text.toString()}"${(index === (this.state.invalidHeaders.length - 1)) ? "" : ", "}`}
														</span>
													)
												})}
												.
											</MuiAlert>
										}
										{this.state.valid !== undefined && !this.state.valid && (
											<MuiAlert severity="error" className={classes.alert}>
												Oops! We found some errors. Correct the highlighted issues below and upload again.
											</MuiAlert>
										)}
										{this.state.valid && !this.state.warning && (
											<MuiAlert severity="success" className={classes.alert}>
												Looks Good! The data has passed our tests. Continue to import.
											</MuiAlert>
										)}
										{this.state.valid && this.state.warning && (
											<MuiAlert severity="warning" className={classes.alert}>
												The data can be imported but we found some potential issues. Please review the highlighted issues before you import.
											</MuiAlert>
										)}											
										<TableDisplay 
											validate
											onLoad={(valid, warning) => this.setState({valid, warning})}
											leadImports={this.state.importPreview}
										/>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						}				
						{this.props.importResult.length > 0 && (
							<Mui.Grid item>
								{this.progress() !== 100 && this.state.importPreview.length > 0 && (		
									<LinearProgress 
										color="secondary"
										variant="determinate" 
										value={this.progress()} 
									/>
								)}
								{this.state.importPreview.length > 0 && (
									<Mui.Grid container direction="column" spacing={2}>
										<Mui.Grid item>
											{`${this.props.loading ? "loading..." : "Import Complete:"}`}
											<Mui.Typography>
												{`${leadImportSuccesses.length} of ${this.state.importPreview.length} Imported`}
											</Mui.Typography>
										</Mui.Grid>
									</Mui.Grid>
								)}
							</Mui.Grid>
						)}
						{leadImportFails.length > 0 && (
							<Mui.Grid item>
								<Mui.Grid container spacing={2} direction="column">
									<Mui.Grid item>
										<Mui.Typography variant="h2">
											Failed To Import:
										</Mui.Typography>
									</Mui.Grid>
									<Mui.Grid item>
										<TableDisplay validate leadImports={leadImportFails}/>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						)}
						{leadImportSuccesses.length > 0  && this.progress() === 100 && (
							<Mui.Grid item>
								<Mui.Grid container spacing={2} direction="column">
									<Mui.Grid item>
										<Mui.Typography variant="h2">
											Import Successful:
										</Mui.Typography>
									</Mui.Grid>
									<Mui.Grid item>
										<TableDisplay leadImports={leadImportSuccesses} />
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						)}
					</Mui.Grid>
				</DashboardLayout>
			</>
		);
	}

}

export const LeadImportPage = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));