import React from 'react';
import * as Mui from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from 'redux/store';
import { styles } from './style';
import { User } from 'model/user';
import { Agent } from 'model/agent';
import * as Icons from "react-feather";
import { Avatar } from "component/shared/avatar";
import { Dialog } from 'component/shared/dialog';
import { DialogTitle } from 'component/shared/dialog/title';
import { PersonalInfo } from './personal-info';
import { Photo } from './photo';
import { Login } from './login';

interface Props extends OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {}

interface OwnProps {
	user: User;
	agent: Agent;
}

interface State {
	openDialog?: boolean;
	dialog?: "info" | "login" | "photo";
	dialogTitle: string;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{},
		dispatch
	);
};

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			dialogTitle: "",
		};
	}

	private dialogContent() {
		const { dialog } = this.state;
		switch (dialog) {
			case "photo":
				return <Photo onClose={() => this.setState({dialog: undefined})} agent={this.props.agent} />
			case "info":
				return <PersonalInfo onClose={() => this.setState({dialog: undefined})} agent={this.props.agent} />
			case "login":
				return <Login onClose={() => this.setState({dialog: undefined})}agent={this.props.agent} />
			default:
				return;
		}
	}

	public render() {
		const { agent, classes } = this.props;
		const { dialogTitle, dialog } = this.state;

		return (
			<>
				{dialog && (
					<Dialog open onClose={() => this.setState({dialog: undefined})} >
						<div className={classes.dialog}>
							<DialogTitle title={dialogTitle} onClose={() => this.setState({dialog: undefined})} />
							<Mui.DialogContent >
								{this.dialogContent()}
							</Mui.DialogContent>
						</div>
					</Dialog>
				)}
				<Mui.Grid container direction="column" spacing={1}>
					<Mui.Grid item>
						<Mui.Typography className={classes.sectionTitle}>
							Profile Photo
							<Mui.Button 
								className={classes.edit}
								onClick={() => this.setState({dialog: "photo", dialogTitle: "Update Your Photo"})}
							>
								<Icons.Edit2 size={15} />
							</Mui.Button>
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Grid container alignItems="center" spacing={2}>
							<Mui.Grid item>
								<Avatar phrase={agent.label} src={agent.photoUrl} diameter={50}/>	
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Typography>
									(Appears on agent roster & page)
								</Mui.Typography>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Typography className={classes.sectionTitle}>
							Personal info 
							<Mui.Button 
								className={classes.edit} 
								onClick={() => this.setState({dialog: "info", dialogTitle: "Update Your Information"})}
							>
								<Icons.Edit2 size={15} />
							</Mui.Button>
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Grid container alignItems="center" spacing={2}>
							<Mui.Grid item>
								Name:
							</Mui.Grid>
							<Mui.Grid item>
								{agent.name}
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Grid container alignItems="center" spacing={2}>
							<Mui.Grid item>
								Title/Designation:
							</Mui.Grid>
							<Mui.Grid item>
								{agent.designation}
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Grid container alignItems="center" spacing={2}>
							<Mui.Grid item>
								Email:
							</Mui.Grid>
							<Mui.Grid item>
							{agent.email}
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Grid container alignItems="center" spacing={2}>
							<Mui.Grid item>
								Home Phone:
							</Mui.Grid>
							<Mui.Grid item>
								{agent.homePhone}
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Grid container alignItems="center" spacing={2}>
							<Mui.Grid item>
								Office Phone:
							</Mui.Grid>
							<Mui.Grid item>
								{agent.officePhone}
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Grid container alignItems="center" spacing={2}>
							<Mui.Grid item>
								Cell Phone:
							</Mui.Grid>
							<Mui.Grid item>
								{agent.officePhone}
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Typography className={classes.sectionTitle}>
							Login
							<Mui.Button 
								className={classes.edit} 
								onClick={() => this.setState({dialog: "login", dialogTitle: "Change Your Username and Password"})}
							>
								<Icons.Edit2 size={15} />
							</Mui.Button>
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Grid container alignItems="center" spacing={2}>
							<Mui.Grid item>
								Username: 
							</Mui.Grid>
							<Mui.Grid item>
								{agent.username}
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Grid container alignItems="center" spacing={2}>
							<Mui.Grid item>
								Password: 
							</Mui.Grid>
							<Mui.Grid item>
								***********
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Typography className={classes.sectionTitle}>
							More
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Grid container alignItems="center" spacing={2}>
							<Mui.Grid item>
								ID: {agent.id}
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					{agent.admin && (
						<Mui.Grid item>
							<Mui.Grid container alignItems="center" spacing={2}>
								<Mui.Grid item>
									Administrator
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					)}
				</Mui.Grid>
			</>
		);
	}
}

export const Info = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));