import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "api/api";
import { AgentBoard, NewAgentBoard } from "model/agent-board";
import { loginReset } from "redux/slice/authentication";
import { arrayToByForeignId, arrayToById, mergeByForeignId, objectToByForeignId, objectToById } from "redux/normalize";
import { AgentBoardResource } from "api/resource/agent-board";
export interface AgentBoardsState {
    loading?: boolean;
    error?: string | null;
    byId: {
        [key: number]: AgentBoardResource;
    };
    byAgentId: {
        [key: number]: number[];
    }
};

const initialState: AgentBoardsState = {
    byId: {},
    byAgentId: {},
};

const fetchAgentBoards = createAsyncThunk("agentBoards/fetchAgentBoards", async (payload, thunkAPI) => {
    thunkAPI.dispatch(agentBoardsLoading());
    const result = api.getAgentBoards();
    result 
        .then((agentBoards) => thunkAPI.dispatch(agentBoardsLoaded(agentBoards)))
        .catch((error) => thunkAPI.dispatch(agentBoardsFailed(error)));
    return result;
});

const updateAgentBoard = createAsyncThunk("agentBoards/saveExistingAgentBoard", async (payload: {agentId: number, agentBoard: AgentBoard}, thunkAPI) => {
	thunkAPI.dispatch(agentBoardsLoading());
	payload.agentBoard.agentId = payload.agentId;
	const result = api.updateAgentBoard(payload.agentBoard);
	result
        .then((agentBoard) => thunkAPI.dispatch(agentBoardLoaded(agentBoard)))
        .catch((error) => thunkAPI.dispatch(agentBoardsFailed(error)));
    return result;
});

const createAgentBoard = createAsyncThunk("agentBoards/saveNewAgentBoard", async (payload: {agentId: number, agentBoard: NewAgentBoard}, thunkAPI) => {
	thunkAPI.dispatch(agentBoardsLoading());
	payload.agentBoard.agentId = payload.agentId;
	const result = api.createAgentBoard(payload.agentBoard);
	result
        .then((agentBoard) => thunkAPI.dispatch(agentBoardLoaded(agentBoard)))
        .catch((error) => thunkAPI.dispatch(agentBoardsFailed(error)));
    return result;
});
 
const deleteAgentBoard = createAsyncThunk("agentBoards/deleteAgentBoard", async (payload: {agentBoard: AgentBoard}, thunkAPI) => {
    thunkAPI.dispatch(agentBoardDeleted(payload.agentBoard));
    const result = api.deleteAgentBoard(payload.agentBoard);
    result
        .then((agentBoard) => thunkAPI.dispatch(agentBoardDeleted(agentBoard)))
        .catch((error) => thunkAPI.dispatch(agentBoardsFailed(error)));
    return result;
});

export const agentBoardsSlice = createSlice({
    name: "agentBoards",
    initialState,
    reducers: {
        agentBoardsLoading: (state) => {
            state.loading = true;
        },
        agentBoardsLoaded: (state, action: PayloadAction<AgentBoardResource[]>) => {
            state.loading = false;
            state.error = null;
            state.byId = arrayToById(action.payload, "id");
            state.byAgentId = arrayToByForeignId(action.payload, "id", "agentId");
        },
        agentBoardLoaded: (state, action: PayloadAction<AgentBoardResource>) => {
            const byId = objectToById(action.payload, "id");
            state.loading = false;
            state.error = null;
            state.byId = { 
                ...state.byId,
                ...byId,
            }
            state.byAgentId = mergeByForeignId(state.byAgentId, objectToByForeignId(action.payload, "id", "agentId"));
        },
        agentBoardsFailed: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        },
        agentBoardDeleted: (state, action: PayloadAction<AgentBoardResource>) => {
            const { id } = action.payload;
            const result = {
                ...state,
                loading: false,
                error: null,
                byId: {
                    ...state.byId,
                }
            }
            delete result.byId[id];
            return result;
        },
        default: (state) => {
            return state;
        }
    },
    extraReducers(builder) {
        builder.addCase(loginReset, () => {
            return initialState;
        });
    },
});

const { agentBoardsLoading, agentBoardsLoaded, agentBoardsFailed, agentBoardLoaded, agentBoardDeleted } = agentBoardsSlice.actions;
export { fetchAgentBoards, deleteAgentBoard, updateAgentBoard, createAgentBoard };
