import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { CriterionComponent } from "component/shared/criterion-component";
import { styles } from "./style";
import { Lead } from "model/lead";
import { SavedSearch } from "model/saved-search";
import { updateSavedSearch } from "redux/slice/saved-searches"
import { Dialog } from "component/shared/dialog";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";

const mapStateToProps = (state: RootState) => {
	return {}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateSavedSearch,
}, dispatch);

interface Props extends
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
	lead: Lead;
	savedSearch: SavedSearch;
	openEditDialogue: Function;
}

interface State {
	showConfirmDialog: boolean;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			showConfirmDialog: false,
		};
	}

	setConfirmDialog = (showConfirmDialog: boolean) => {
		this.setState({ showConfirmDialog })
	}

	saveSearch = (type: string) => {
		const inactive = true;
		let updatedSavedSearch = {
			...this.props.savedSearch,
		}

		if(type === "delete") {
			updatedSavedSearch.inactive = inactive;
		} else if(type ==="emailAlert") {
			updatedSavedSearch.recieveEmails = !updatedSavedSearch.recieveEmails;
		}
		this.props.updateSavedSearch({savedSearch: updatedSavedSearch});
	}

	renderConfirmationDialog = () => {
		const { classes } = this.props;
		return (
			<Dialog
				open={this.state.showConfirmDialog}
				onClose={() => this.setConfirmDialog(false)}
			>
				<Mui.DialogTitle>Delete Saved Search</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<p>Are you sure you want to delete this search?</p>
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
					<Mui.Button
						variant="contained"
						color="secondary"
						onClick={() => {
							this.saveSearch("delete");
							this.setConfirmDialog(false);
						}}
					>
						Yes
					</Mui.Button>
					<Mui.Button
						variant="outlined"
						onClick={() => this.setConfirmDialog(false)}
					>
						No
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
		);};

	public render() {
		const { savedSearch, classes } = this.props;
		return (
			<>
				{this.renderConfirmationDialog()}
				<Mui.Card className={classes.cardSpacing}>
					<Mui.CardHeader
						component={() => {
							return <Mui.Grid container justifyContent="space-between" alignItems="center" className={classes.cardHeader}>
								<Mui.Grid item>
									<Mui.Typography variant="h4">{savedSearch.label}</Mui.Typography>
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Button
										color="secondary"
										onClick={() => {
											this.props.openEditDialogue();
										}}
									>
										Edit
									</Mui.Button>
								</Mui.Grid>
							</Mui.Grid>}
						}
					/>
					<Mui.CardContent>
						<Mui.Grid container 
							justifyContent="space-between" 
							spacing={2}
							direction="row" 
						>
							<Mui.Grid item xs={12}>
								<CriterionComponent criteria={savedSearch.criteria} />
							</Mui.Grid>
							<Mui.Grid item xs={12}>
								<Mui.FormControlLabel
									label="Email Alerts"
									labelPlacement="top"
									control={
										<Mui.Switch
											checked={savedSearch.recieveEmails}
											onChange={() => {
												this.saveSearch("emailAlert");
											}}
											color="secondary"
										/>
									}
								/>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.CardContent>
					<Mui.CardActions>
						<Mui.Grid container justifyContent="space-between" alignItems="center">
							<Mui.Grid item />
							<Mui.Grid item>
									{savedSearch.resultCount === 0 &&
										<Mui.Typography>
											No active listings match search criteria
										</Mui.Typography>
									}
									{savedSearch.resultCount === 1 &&
										<Mui.Link href={savedSearch.resultsUrl} target="_blank">	
											<Mui.Typography>
												1 active listing matches search criteria
											</Mui.Typography>
										</Mui.Link>
									}
									{!!savedSearch.resultCount && savedSearch.resultCount > 1 &&
										<Mui.Link href={savedSearch.resultsUrl} target="_blank">
											<Mui.Typography>
												{savedSearch.resultCount} active listings match search criteria
											</Mui.Typography>
										</Mui.Link>
									}
							</Mui.Grid>
							
							<Mui.Grid item>
								<Mui.IconButton
									onClick={() => this.setConfirmDialog(true)} 
								>
									<FeatherIcon>
										<Icons.Trash />
									</FeatherIcon>
								</Mui.IconButton>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.CardActions>
				</Mui.Card>
			</>
		);
	}
}

export const SavedSearchCard = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
