import React from "react";
import * as Mui from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { X } from "react-feather";
import { styles } from "./style";
import { ListingSearch } from "component/shared/listing-search";
import { User } from "model/user";
import { Lead } from "model/lead";
import { bindActionCreators } from "redux";
import { updateSavedListing, createSavedListing } from "redux/slice/saved-listings";
import { createSavedSearch, updateSavedSearch } from "redux/slice/saved-searches";
import { connect } from "react-redux";
import { FeatherIcon } from "component/shared/feather-icon";
import { RootState } from "redux/store";
import { getActiveSavedListingsForLead } from "redux/selector";
import { getActiveSavedSearchesForLead } from "redux/selector";

const mapStateToProps = (state: RootState, ownProps: any) => {
	return {
		savedListings: getActiveSavedListingsForLead(state, ownProps.lead.id),
		savedSearches: getActiveSavedSearchesForLead(state, ownProps.lead.id),
	}
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
	updateSavedListing,
	createSavedListing,
	createSavedSearch,
	updateSavedSearch,
}, dispatch)

interface Props extends 
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
	user: User;
	lead: Lead;
	open: boolean;
	onClose: Function;
	savedSearchId: number;
}

interface State {}

const Transition = React.forwardRef((props: TransitionProps, ref) => {
	return <Mui.Slide direction="up" ref={ref} {...props} />;
});

class Component extends React.Component<Props, State> {

	public render() {
		const { classes, user, lead, open, onClose, updateSavedListing, createSavedListing, createSavedSearch, updateSavedSearch, savedListings, savedSearches, savedSearchId } = this.props;
		const savedListingsIds = savedListings.map(savedListing => {
			return savedListing.listing && savedListing.listing.id
		}).filter(Boolean) as string[];
		const savedSearch = savedSearches.find(savedSearch => savedSearch.id === savedSearchId);
		return (
			<Mui.Dialog
				fullScreen
				open={open}
				TransitionComponent={Transition}
			>
				<Mui.DialogTitle>
					<Mui.Grid container justifyContent="space-between" alignItems="center">
						<Mui.Grid item>
							<Mui.Typography>Property Organizer</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.IconButton color="inherit" 
								onClick={() => onClose()}
							>
								<FeatherIcon>
									<X />
								</FeatherIcon>
							</Mui.IconButton>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogTitle>
				<Mui.DialogContent className={classes.content}>
					<ListingSearch
						user={user}
						onSaveSearch={(criteria) => {
							if(savedSearch) {
								updateSavedSearch({ savedSearch: {
									...savedSearch, 
									criteria,
								}})
							} else {
								createSavedSearch({ savedSearch: {
									leadId: lead.id,
									criteria,
									recieveEmails: true,
									inactive: false,
								}});
							}
							onClose();
						}}
						onSaveListing={(listingId) => {
							createSavedListing({ savedListing: {
								lead,
								listing: {
									id: listingId,
								},
							}});
						}}
						onUnsaveListing={(listingId) => {
							savedListings.forEach(savedListing => {
								if(savedListing.listing && savedListing.listing.id === listingId) {
									updateSavedListing({ savedListing: {
										...savedListing,
										deleted: true,
									}});
								}
							});
						}}
						savedListings={savedListingsIds ? savedListingsIds : []}
						criteria={savedSearch && savedSearch.criteria}
					/>
				</Mui.DialogContent>
			</Mui.Dialog>
		);
	}
}

export const SearchDialog = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
