import { Moment } from "moment";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MobileDialogDateState {
	date?: string;
};

const initialState: MobileDialogDateState = {}; 

export const mobileDialogDateSlice = createSlice({
	name: "mobileDialogDate",
	initialState,
	reducers: {
		resetMobileDialogDate: (state, action: PayloadAction<Moment>) => {
			const date = action.payload.toISOString()
			state.date = date;
		}
	}
});

export const { resetMobileDialogDate } = mobileDialogDateSlice.actions;