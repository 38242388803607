import React from "react";
import * as Mui from "@material-ui/core";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { styles } from "./style";
import { connect } from "react-redux";
import { Dialog } from "component/shared/dialog";
import { getAgents } from "redux/selector";
import { RoundRobinRoute, NewRoundRobinRoute } from "model/round-robin-route";
import { Agent } from "model/agent";
import { updateRoundRobinRoutes } from "redux/slice/round-robin-routes";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';

const mapStateToProps = (state: RootState) => {
	return {
		loading: state.leads.loading,
		agents: getAgents(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateRoundRobinRoutes
}, dispatch);

interface OwnProps {
	open: boolean;
	onClose: Function;
	roundRobinRoutes : RoundRobinRoute[]; 
}

interface Props extends
	OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{}

interface State {
	submitted: boolean;
	selectedAgents : Agent[]
}

const initialState: State = {
	submitted: false,
	selectedAgents: [],
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}
	
	public componentDidUpdate(prevProps: Props) {
		if (!prevProps.open && this.props.open) {
			this.setState(initialState);
		}
	}


	private submit = () => {
		const { selectedAgents } = this.state;
		const roundRobinRoutes: NewRoundRobinRoute[] = this.props.roundRobinRoutes.map(roundRobinRoute => ({
			agent: roundRobinRoute.agent,
		}));
		selectedAgents.forEach((agent) => {
			roundRobinRoutes.push({
				agent,
			})
		});
		this.setState({ submitted: true });
		this.props.updateRoundRobinRoutes({ roundRobinRoutes })	
		this.props.onClose();
	}

	public render() {
		const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
		const checkedIcon = <CheckBoxIcon fontSize="small" />;
		const { open, onClose, agents } = this.props;
		//this.state = initialState;
	
		return (
			<Dialog open={open} onClose={() => onClose()} scroll="paper">
				<Mui.DialogTitle>
					Add Agent to Round Robin
				</Mui.DialogTitle>
				<Mui.DialogContent
					style={{
						padding: "8px 50px",
						width:"450px",
						height:"400px",
					}}
				>
					<Mui.Grid container spacing={5} direction="column">
						<Mui.Grid item xs={12}>
						</Mui.Grid>
						<Mui.Grid item xs={12}>
						<Autocomplete
							multiple
							id="checkboxes-tags-demo"
							onChange={ (event, value) => this.setState({ selectedAgents: value })}
							options={Array.from(agents)}
							disableCloseOnSelect
							getOptionLabel={(option) => option.label}
							getOptionSelected={(option, value) => option === value}
							renderOption={(option, { selected }) => (
								<React.Fragment>
								<Checkbox
									icon={icon}
									checkedIcon={checkedIcon}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{option.label}
								</React.Fragment>
							)}
							renderInput={(params) => (
								<TextField {...params} variant="outlined" label="Select an agent" />
							)}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Button
								variant="contained"
								color="secondary"
								onClick={() => this.submit()}
								style={{ marginRight: 32 }}
								>
									Configure
							</Mui.Button>
							<Mui.Button
								variant="outlined"
								color="secondary"
								onClick={() => onClose()}
							>
								Cancel
							</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
			</Dialog>
			
		)
	}
}
export const SelectMultipleAgentsDialog = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);
