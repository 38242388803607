import { arrayToById } from "redux/normalize";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { CampaignStatResource } from "api/resource/campaign-stat";

export interface CampaignStatsState {
	loading?: boolean;
	error?: string | null;
	byCampaignId: {
		[key: number]: CampaignStatResource;
	};
};

const initialState: CampaignStatsState = {
	byCampaignId: {},
};

const fetchCampaignStats = createAsyncThunk("campaignStats/fetchCampaignStats", async (payload, thunkAPI) => {
	thunkAPI.dispatch(campaignStatsLoading());
	const result = api.getCampaignStats();
	result
		.then((campaignStats) => thunkAPI.dispatch(campaignStatsLoaded(campaignStats)))
		.catch(error => thunkAPI.dispatch(campaignStatsFailed(error)));
	return result;
});

export const campaignStatsSlice = createSlice({
	name: "campaignStats",
	initialState,
	reducers: {
		campaignStatsLoading: (state) => {
			state.loading = true;
		},
		campaignStatsLoaded: (state, action: PayloadAction<CampaignStatResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byCampaignId = arrayToById(action.payload, "campaignId");
		},
		campaignStatsFailed: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});

export const { campaignStatsLoading, campaignStatsLoaded, campaignStatsFailed } = campaignStatsSlice.actions;
export { fetchCampaignStats };