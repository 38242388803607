import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import * as Router from "react-router-dom";
import { connect } from "react-redux";
import { resetTasksPageFilters, setTasksPageFilters } from "redux/slice/tasks-page"
import { getUserUnsafe } from "redux/selector";
import { Dialog } from "component/shared/dialog";
import { getTasksPageFilters } from "redux/selector";
import { TaskStatus } from "type/task-status";
import { Select } from "component/shared/select";
import { TaskType } from "type/task-type";
import { getLeadsWithTasks } from "redux/selector";
import { FiltersChips } from "./filter-criteria-chips/";

const mapStateToProps = (state: RootState) => {
	const filters = getTasksPageFilters(state);
	const user = getUserUnsafe(state);
	const leads = getLeadsWithTasks(state).sort((a, b) =>
		a.label < b.label ? -1 : 1
	);
	return {
		user,
		filters,
		leads,
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	setTasksPageFilters,
	resetTasksPageFilters,
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles>
{ 
	open: boolean;
	onClose: Function;
	count: number;
}

interface State { 
}

const initialState: State = {
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}

	public render() {
		const {
			user,
			classes,
			open,
			onClose,
			count,
			filters,
			leads,
			setTasksPageFilters,
			resetTasksPageFilters
		} = this.props;
		if (!user) {
			throw new Error();
		}
		return (
			<>	
				<Dialog open={open} onClose={() => onClose()} scroll="paper" fullWidth={true} maxWidth="md">
					<Mui.DialogTitle>Filters</Mui.DialogTitle>
					<Mui.DialogContent style={{height: 450}}>
						<Mui.Grid container spacing={2}>
							<Mui.Grid item xs={12} className={classes.chipContainer}>
								<FiltersChips />
							</Mui.Grid>
							<Mui.Grid container item md={user.permissions.crmTags ? 6 : 12} spacing={1} justifyContent="space-between">
								<Mui.Grid item xs={12} sm="auto" className={classes.header}>
									<Mui.Typography variant="h3">Task Filters</Mui.Typography>
								</Mui.Grid>
								
								<Mui.Grid item xs={12}>
									<Select
										placeholder="Status"
										variant="outlined"
										multiple
										fullWidth
										value={filters.status}
										options={TaskStatus.values().filter(status => status.id !== 3)}
										valueExtractor={(taskStatus) => taskStatus.id}
										labelExtractor={(taskStatus) => taskStatus.name}
										optionRenderer={(taskStatus) => (
											<Mui.ListItemText primary={taskStatus.name} />
										)}
										onChange={(values) => {
											setTasksPageFilters({
												...filters,
												status: values,
											});
										}}
									/>
								</Mui.Grid>

								<Mui.Grid item xs={12}>
									<Select
										placeholder="Associated Lead"
										variant="outlined"
										fullWidth
										value={filters.lead}
										options={leads}
										valueExtractor={(lead) => lead.id}
										labelExtractor={(lead) => lead.label}
										optionRenderer={(lead) => (
											<Mui.ListItemText primary={lead.label} />
										)}
										onChange={(value) => {
											setTasksPageFilters({
												...filters,
												lead: value,
											});
										}}
									/>
								</Mui.Grid>
		
								<Mui.Grid item xs={12}>
									<Mui.Grid container wrap="nowrap" alignItems="center">
										<Mui.Grid item>
											<Mui.FormControlLabel 
												control={
													<Mui.Checkbox 
														className={classes.checkbox}
														checked={!!filters.priority}
														onChange={() => {
															setTasksPageFilters({
																...filters,
																priority: !filters.priority,
															});
														}}
													/>
												}
												label="Priority"
											/>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>

								<Mui.Grid item xs={12}>
									<Mui.Grid container wrap="nowrap" alignItems="center">
										<Mui.Grid item>
											<Mui.FormControlLabel 
												control={
													<Mui.Checkbox 
														className={classes.checkbox}
														checked={!!filters.today}
														onChange={() => {
															setTasksPageFilters({
																...filters,
																today: !filters.today,
															});
														}}
													/>
												}
												label="Due Today"
											/>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>

							</Mui.Grid>
							{user.permissions.crmTags && 
								<Mui.Grid container item md={6}>
									<Mui.Grid item xs={12} sm="auto" className={classes.header}>
										<Mui.Typography variant="h3">Task Type</Mui.Typography>
									</Mui.Grid>
									<Mui.Grid item xs={12} className={classes.filters}>
										<Select
											multiple
											expanded
											fullWidth
											numberVisible={6}
											variant="outlined"
											placeholder="Select Types"
											value={filters.type}
											options={TaskType.values()}
											valueExtractor={(value) => value.id}
											labelExtractor={(value) => value.name}
											optionRenderer={(value) => (
												<Mui.ListItemText primary={value.name} />
											)}
											onChange={(values) => {
												setTasksPageFilters({
													...filters,
													type: values,
												});
											}}
										/>
									</Mui.Grid>
								</Mui.Grid>
							}
						</Mui.Grid>
					</Mui.DialogContent>
					<Mui.DialogActions className={classes.dialogActions}>
						<Mui.Button 
							disabled={count < 1}
							variant="contained"
							color="secondary"
							onClick={() => {
									onClose()
								}}
								>
								{count > 0 ? "View " + count : "No"} Result{count === 1 ? "": "s"}
						</Mui.Button>
						<Mui.Button 
							variant="outlined"
							color="primary"
							onClick={() => {
									resetTasksPageFilters();
									onClose()
								}}
								>
								Clear
						</Mui.Button>
					</Mui.DialogActions>
				</Dialog>
				
			</>
		);
	}
}

export const FilterDialog = Mui.withStyles(styles)(
	Router.withRouter(
		connect(mapStateToProps, mapDispatchToProps)(Component)
	),
);