import React from "react";
import * as Mui from "@material-ui/core";
import * as yup from "yup";
import * as Icons from "react-feather";
import { styles } from "./style";
import { validate } from "shared/yup";

interface Props extends 
	Mui.WithStyles<typeof styles> 
{
	baseUrl: string;
	path: string;	
	save: () => void;
	onChange: (value: string) => void;
}


interface State {
	baseUrl: string;
	edit: boolean;
	path: string;
}


class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			baseUrl: this.props.baseUrl,
			edit: false,
			path: this.props.path,
		};
	}

	public componentDidUpdate(prevProps: Props) {
		if(this.props.path !== prevProps.path) {
			this.setState({path: this.props.path})
		}
		if(this.props.baseUrl !== prevProps.baseUrl) {
			this.setState({baseUrl: this.props.baseUrl})
		}
	}
	

	private handlePath(value: string) {
		if(value && !value.startsWith("/")) {
			value = `/${value}`
		}
		this.props.onChange(value)
		return value;
	}

	private handleUpdate(value: string) {
		if (value && !value.includes("//")) {
			this.setState({edit: false, path: value})
			this.props.save()
		}
	}

	

	private validationSchema = {
		path: yup
			.string()
			.test("path", "Cannot have two or more \"/\"s in a row", (value) => {
				if(value && value.includes("//")) {
					return false;
				}
				return true;
			})
	};

	public render() {
		const {
			classes,
		} = this.props;
		const {
			baseUrl,
			path,
		} = this.state;
		const errors = validate(this.validationSchema, this.state);
		
		return (
			<>
				<Mui.Paper className={`${classes.block} ${classes.pageBlock}`} >
					<Mui.Grid container direction="row" spacing={2} alignItems="center">
						<Mui.Grid item>
							{!this.state.edit && (baseUrl && path) &&
								<>{baseUrl + path}</>
							}
							{this.state.edit && (
								<Mui.Grid container direction="row" alignItems="center" spacing={2}>
									<Mui.Grid item>
										<Mui.Chip label={this.state.baseUrl}  />
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.TextField 
											value={this.state.path} 
											variant="outlined" 
											size="small"
											onChange={(event) => this.setState({path: this.handlePath(event.currentTarget.value)})}
											onKeyDown={(event) => {
												if(event.key === "Enter") {
													this.handleUpdate(this.state.path);
												}
											}}
											className={classes.input}
											error={!!errors}
											helperText={errors && errors.path}
										/>
									</Mui.Grid>
										
								</Mui.Grid>
								
							)}
						</Mui.Grid>
						<Mui.Grid item xs>
							{!this.state.edit && (
								<Mui.Box display="flex" justifyContent="flex-end">
									<Mui.Tooltip title="Edit Page URL">
										<Mui.IconButton size="small" onClick={() => this.setState({edit: !this.state.edit})} id="edit-url" >
											<Icons.Edit2 size={18} style={{color: this.state.edit ? "#3a82b68a" : undefined}} />
										</Mui.IconButton>
									</Mui.Tooltip>
								</Mui.Box>
							)}
							{this.state.edit && (
								<Mui.Box display="flex" whiteSpace="nowrap">
									
										<Mui.Grid container>
											<Mui.Button
												color="secondary"
												variant="contained"
												onClick={() => this.handleUpdate(this.state.path)}
												size="small"
											>
											Save Changes
											</Mui.Button>
										</Mui.Grid>
										<Mui.Grid container>
											<Mui.Button
												variant="contained"
												onClick={() => this.setState({edit: !this.state.edit, path: this.handlePath(this.props.path)})}
												size="small"
											>
											Cancel
											</Mui.Button>
										</Mui.Grid>
									
								</Mui.Box>
							)}
						</Mui.Grid>
					</Mui.Grid>
					
				</Mui.Paper>
			</>
		);
	}
}

export const DefaultPagePath = Mui.withStyles(styles)(Component);
