import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { validate } from "shared/yup";
import * as yup from "yup";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { saveIntegration } from "redux/slice/integrations";
import { Integration } from "model/integration";
import { Card } from "../card";

const mapStateToProps = (state: RootState) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveIntegration,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
	integration: Integration;
}

interface State {
	name?: string;
	url?: string;
	showValidation: boolean;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			name: props.integration.name,
			url: props.integration.data.url as string,
			showValidation: false
		};
	}

	private validationSchema = {
		name: yup.string()
			.required("Name is required"),
		url: yup.string()
			.required("Url is required")
			.matches(/^https?:\/\//i, 'Url must start with http:// or https://')
	};

	public render() {
		const { integration } = this.props;
		const { showValidation, name, url } = this.state;
		const errors = validate(this.validationSchema, this.state);

		return (
			<Card
				integration={integration}
				deletable={true}
				content={
					<Mui.Grid container direction="column" spacing={2}>
						<Mui.Grid item>
							<Mui.TextField
								label="Name"
								placeholder={integration.integrationType.name}
								value={name || ""}
								onChange={event => this.setState({ name: event.target.value })}
								helperText={showValidation && errors && errors.name}
								error={showValidation && errors && !!errors.name}
								fullWidth
								margin="dense"
								variant="outlined"
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.TextField
								label="URL"
								value={url || ""}
								onChange={event => this.setState({ url: event.target.value })}
								helperText={showValidation && errors && errors.url}
								error={showValidation && errors && !!errors.url}
								fullWidth
								margin="dense"
								variant="outlined"
							/>
						</Mui.Grid>
					</Mui.Grid>
				}
				actions={
					<Mui.Grid container spacing={2}>
						<Mui.Grid item>
							<Mui.Button
								variant="contained"
								color="secondary"
								onClick={() => this.handleSave()}
							>
								Save
							</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
				}
			/>
		);
	}

	public handleSave = () => {
		const { integration, saveIntegration } = this.props;
		const { name, url } = this.state;
		const errors = validate(this.validationSchema, this.state);
		this.setState({showValidation: true})
		if(!errors) {
			saveIntegration({ integration: {
				...integration,
				name,
				data: {
					...integration.data,
					url,
				},
			}})
		}
	}

}

export const CustomLtsIntegration = connect(mapStateToProps, mapDispatchToProps)(Component);