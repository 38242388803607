import React from "react";

class Component extends React.Component {

	private iframe = React.createRef<HTMLIFrameElement>();

	componentDidMount() {
		if(this.iframe.current && this.iframe.current.contentDocument) {
			this.iframe.current.contentDocument.write("<h2>Mock iFrame</h2>")
		}
	}

	public render() {
		return (
			<iframe ref={this.iframe} title="iframe" />
		);
	}

}

export const MockIframe = Component;