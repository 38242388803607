import React from "react";
import * as Mui from "@material-ui/core";
import * as Icon from "react-feather";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { MarketListing } from "model/market-listing";
import { DragDropContext, Droppable, Draggable, DropResult, DragUpdate } from "react-beautiful-dnd";
import { styles } from "./styles";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { updateMarketListing, updateMarketListings, deleteMarketListing } from "redux/slice/market-listings";
import { Market } from "model/market";

interface Props extends 
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapDispatchToProps> {
	marketListings: MarketListing[];
	market: Market
}

interface State {
	marketListings: MarketListing[];
	dragging: string | null;
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateMarketListing,
	updateMarketListings,
	deleteMarketListing,
}, dispatch);

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props)
		this.state = {
			marketListings: this.props.marketListings,
			dragging: null,
		}
	}

	private save = () => {
		this.props.updateMarketListings({marketListings: this.state.marketListings});
	}

	private delete = (marketListing: MarketListing) => {
		const { deleteMarketListing } = this.props
		const {marketListings} = this.state;
		const i = marketListings.findIndex(value => value.id === marketListing.id)
		marketListings.splice(i, 1);
		deleteMarketListing({ marketListing: {...marketListing}} )
		this.setState({marketListings})
	}
	
	private onDragStart = (result: DragUpdate ) => {
		this.setState({dragging: result.draggableId});
	}

	private onDragEnd = (result: DropResult) => {
		const { marketListings } = this.state;
		const { draggableId, source, destination } = result;
		const dropped = marketListings.find((value) => value.id === parseInt(draggableId));
		if (!dropped || !destination) {
			this.setState({dragging: null});
			return;
		}
		if ((destination.droppableId === source.droppableId) && (destination.index === source.index)) {
			this.setState({dragging: null});
			return;
		}
		marketListings.splice(source.index, 1);
		marketListings.splice(destination.index, 0, {...dropped});
		
		this.setState({
			marketListings: marketListings,
			dragging: null,
		}, () => {
			this.save();
		});
	}
	
	
	render() {
		const {classes, marketListings} = this.props;
		return (
			<Mui.Grid item>
				<Mui.Typography variant="h2" >
					{`Manual Saved Search Listings (${this.props.marketListings.length})`}
				</Mui.Typography>
				<Mui.Table>
					<Mui.TableHead>
						<Mui.TableRow>
							<Mui.TableCell>{/* Slot for drag icon */}</Mui.TableCell>
							<Mui.TableCell>Listing Info</Mui.TableCell>
							<Mui.TableCell align="left">Address</Mui.TableCell>
							<Mui.TableCell align="left">Price</Mui.TableCell>
							<Mui.TableCell align="left">Type</Mui.TableCell>
							<Mui.TableCell align="left">Order</Mui.TableCell>
						</Mui.TableRow>
					</Mui.TableHead>
						<DragDropContext onDragStart={() => this.onDragStart} onDragEnd={(param) => this.onDragEnd(param)}>
							<Droppable droppableId="column-1">
								{provided => (
									<Mui.TableBody
										innerRef={provided.innerRef}
										{...provided.droppableProps}
									>
										{marketListings.map((marketListing, index) => {
											return (
												<>
													{marketListing.listing ? (
														<Draggable draggableId={marketListing.id.toString()} index={index} key={marketListing.id}>
															{(provided, snapshot) => (
																<Mui.TableRow
																className={classes.tableRow}
																innerRef={provided.innerRef}
																{...provided.draggableProps}
																style={{
																	...provided.draggableProps.style, 
																	backgroundColor: snapshot.isDragging ? "white" : "none",
																}}
																{...marketListings.length > 1 && {...provided.dragHandleProps}}
																>
																	<Mui.TableCell align="left">{marketListings.length > 1 && <DragIndicatorIcon style={{color: "lightgray"}}/>}</Mui.TableCell>
																	<Mui.TableCell component="th" scope="row">{marketListing.listing?.listingNumber}</Mui.TableCell>
																	<Mui.TableCell align="left" onClick={()=>{}}>{`${marketListing.listing?.addressOneLine}`}</Mui.TableCell>
																	<Mui.TableCell align="left">{marketListing.listing?.priceLabel}</Mui.TableCell>
																	<Mui.TableCell align="left">{index + 1}</Mui.TableCell>
																	<Mui.TableCell align="left">
																		<Mui.Tooltip title="Remove Listing">
																			<Icon.Trash className={classes.trashCan} onClick={() => this.delete(marketListing)}/>
																		</Mui.Tooltip>
																	</Mui.TableCell>
																</Mui.TableRow>
															)}
														</Draggable>
													): 
														<Draggable draggableId={marketListing.id.toString()} index={index}>
															{(provided, snapshot) => (
																<Mui.TableRow
																	className={classes.tableRow}
																	innerRef={provided.innerRef}
																	{...provided.draggableProps}
																	style={{
																		...provided.draggableProps.style, 
																		backgroundColor: snapshot.isDragging ? "#f7f7f7" : "none",
																	}}
																	{...marketListings.length > 1 && {...provided.dragHandleProps}}
																>
																	<Mui.TableCell align="left">{marketListings.length > 1 && <DragIndicatorIcon style={{color: "lightgray"}}/>}</Mui.TableCell>
																	<Mui.TableCell component="th" scope="row">{marketListing.value} (Not Found or Off Market)</Mui.TableCell>
																	<Mui.TableCell align="left">{marketListing.listing && marketListing.listing.address ? marketListing.listing.address : "N/A"}</Mui.TableCell>
																	<Mui.TableCell align="left">{marketListing.listing && marketListing.listing.price ? marketListing.listing.price : "N/A"}</Mui.TableCell>
																	<Mui.TableCell align="left">{index + 1}</Mui.TableCell>
																	<Mui.TableCell align="left">
																		<Mui.Tooltip title="Remove Listing">
																			<Icon.Trash className={classes.trashCan} onClick={() => this.delete(marketListing)}/>
																		</Mui.Tooltip>
																	</Mui.TableCell>
																</Mui.TableRow>
															)}
														</Draggable>
													}
													</>			
											)
										})}
										{provided.placeholder}
									</Mui.TableBody>
								)}
							</Droppable>
						</DragDropContext>
				</Mui.Table>
			</Mui.Grid>	
		)
	}
}

export const MarketListingTableRow = Mui.withStyles(styles)(
	connect(undefined, mapDispatchToProps)(Component)
	);