import React from "react";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Mui from "@material-ui/core";
import { BulkActionTypes } from "type/bulk-action-types";
import { BulkAddTagsDialog } from "./dialogs/bulk-add-tags-dialog";
import { BulkRemoveTagsDialog } from "./dialogs/bulk-remove-tags-dialog";
import { BulkCampaignDialog } from "./dialogs/bulk-campaign-dialog";
import { BulkPipelineStageDialog } from "./dialogs/bulk-pipeline-stage-dialog";
import { BulkExportCSVDialog } from "./dialogs/bulk-export-csv-dialog";
import { Select } from "component/shared/select";

const mapStateToProps = (state: RootState) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
	leadIds: number[];
}

interface State {
	bulkActionId: number;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			bulkActionId: 0,
		};
	}

	private onClose = () => {
		this.setState({ bulkActionId: 0 });
	}

	private placeholder = () => {
		const { leadIds } = this.props;
		const total = leadIds.length;
		if(total === 1) {
			return "Edit 1 Lead";
		}
		return `Edit ${total} Leads`;
	}

	 render() {
		const { leadIds } = this.props;
		const { bulkActionId } = this.state;
		const total = leadIds.length;
        return (
			<>
				<BulkAddTagsDialog
					open={bulkActionId === BulkActionTypes.ADD_TAGS.id} 
					onClose={()=> this.onClose()} 
					leadIds={leadIds}
				/>
				<BulkRemoveTagsDialog
					open={bulkActionId === BulkActionTypes.REMOVE_TAGS.id} 
					onClose={()=> this.onClose()} 
					leadIds={leadIds}
				/>
				<BulkPipelineStageDialog
					open={bulkActionId === BulkActionTypes.UPDATE_STAGE.id} 
					onClose={()=> this.onClose()} 
					leadIds={leadIds}
				/>
				<BulkCampaignDialog
					open={bulkActionId === BulkActionTypes.ADD_TO_CAMPAIGN.id} 
					onClose={()=> this.onClose()} 
					leadIds={leadIds}
				/>
				<BulkExportCSVDialog 
					open={bulkActionId === BulkActionTypes.EXPORT_TO_CSV.id} 
					onClose={()=> this.onClose()} 
					leadIds={leadIds}
				/>
				<Select
					disabled={!total}
					placeholder={this.placeholder()}
					value={bulkActionId}
					options={BulkActionTypes.values()}
					valueExtractor={(type) => type.id}
					labelExtractor={(type) => type.label}
					numberVisible={BulkActionTypes.values().length}
					optionRenderer={(type) => (
						<Mui.ListItemText key={leadIds.length} primary={type.label} />
					)}
					onChange={(bulkActionId) => {
						this.setState({ bulkActionId });
					}}
				/>
			</>
		)
	}
}

export const BulkActions = connect(mapStateToProps, mapDispatchToProps)(Component)