import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		alert: {
			marginBottom: 15,
		},
		importButtonsContainer: {
			position: "sticky", 
			top: 64, 
			zIndex: 1000, 
			backgroundColor: theme.palette.background.default
		},
		importButtons: {
			paddingTop: 5,
			paddingBottom: 5,
		},
		downloadIcon: {
			marginRight: 5,
		},
		csvLink: {
			textDecoration: theme.palette.text.primary,
			color: theme.palette.secondary.main,
		}
	});
};
