import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		header: {
			position: "relative"
		},
		flex: {
			flex: 1
		},
		content: {
			height: "calc(100vh - 64px)",
			overflowY: "auto",
			padding: 0,
		},
	});
}