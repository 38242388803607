import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { getSentBlastCampaigns, getBlastCampaignSubscriptions } from "redux/selector";
import { Card } from "./card";
import { missingEmailCalendarIntegration } from "redux/selector";
import { Pagination } from "@material-ui/lab";
import { Campaign } from "model/campaign";
import { LoadingIndicator } from "component/shared/loading-indicator";

const mapStateToProps = (state: RootState) => {
	return {
		campaigns: getSentBlastCampaigns(state),
		campaignSubscriptions: getBlastCampaignSubscriptions(state),
		missingEmailCalendarIntegration: missingEmailCalendarIntegration(state),
		loading: state.campaigns.loading || state.campaignSubscriptions.loading || state.campaignCampaignMessages.loading || state.campaignEmails.loading || state.integrations.loading,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);


interface Props extends
	PageProps,
	Router.RouteComponentProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
}

interface State { 
	page: number,
}

const initialState: State = {
	page: 1,
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}

	private get campaignSubscriptionsCount() {
		const { campaigns, campaignSubscriptions } = this.props;
		
		const reducer = (accumulator: any, campaign: Campaign) => {
			let campaignId: number = campaign.id;
			let campaignSubscriptionsCount = campaignSubscriptions.filter((campaignSubscription) => campaignSubscription.campaign.id === campaignId).length
			accumulator[campaignId] = campaignSubscriptionsCount
			return accumulator;
		} 

		let dictionary: { [key: number]: number }  = campaigns.reduce(reducer, {});

		return dictionary;
	}

	private get sentCampaigns() {
		return this.props.campaigns
			.filter((campaign) => {
				return campaign.campaignCampaignMessages[0] && campaign.campaignCampaignMessages[0].campaignMessage;
			})
			.sort((a, b) => b.dateCreated.valueOf() - a.dateCreated.valueOf())
	}

	private get paginatedCampaigns() {
		const { page } = this.state;
		const campaigns = this.sentCampaigns;
		const lastCampaignIndex = page*10;
		let paginatedCampaigns = [];
		for(let i = lastCampaignIndex - 10; i < lastCampaignIndex; i++) {
			if(campaigns[i]) {
				paginatedCampaigns.push(campaigns[i]);
			} 
		}
		return paginatedCampaigns;
	}

	private get count() {
		const campaignsLength =  this.sentCampaigns.length;
		return Math.ceil(campaignsLength/10);
	}
	
	public render() {
		const { user, loading, campaigns } = this.props;
		const title = "Sent Emails";
		const { sentCampaigns, paginatedCampaigns, count, campaignSubscriptionsCount } = this;
		return (
			<DashboardLayout
				permitted={user && user.permissions.blastCampaigns}
				fullScreen={false}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Send />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<Mui.Grid container direction="column" spacing={3} style={{paddingTop: 25}}>
					{loading ? 
						<LoadingIndicator />
						: 
						<>
							{
								<>
									{campaigns && campaigns.length 
									? 
									<>
										{paginatedCampaigns.map((campaign) => (
											<Mui.Grid key={campaign.id} item>
												<Card campaign={campaign} campaignSubscriptionsCount={campaignSubscriptionsCount[campaign.id]}/>
											</Mui.Grid>
										))}
										{sentCampaigns.length > 10 &&
											<Mui.Grid item>
												<Mui.Grid container justifyContent="center">
													<Mui.Grid item>
														<Pagination 
															count={count} 
															variant="outlined" 
															shape="rounded" 
															onChange={(event: object, page: number) => {
																this.setState({ page });
															}}
														/>
													</Mui.Grid>
												</Mui.Grid>
											</Mui.Grid>
										}
									</>
									: 
									<Mui.Grid item>
										<Mui.Typography variant="h3" align="center">
											You have not sent any emails yet. Once you have sent an email, sent email records will appear here.
										</Mui.Typography>
									</Mui.Grid>
									}
								</>
							
							}
						</>
					}
				</Mui.Grid>
			</DashboardLayout>
		);
	}

}

export const CampaignSentEmailsPage = connect(mapStateToProps, mapDispatchToProps)(Component);