import { arrayToById, objectToById, arrayToByForeignId, objectToByForeignId, mergeByForeignId } from "redux/normalize";
import { MarketReportSubscription, NewMarketReportSubscription } from "model/market-report-subscription";
import { MarketReportSubscriptionResource } from "api/resource/market-report-subscription";
import { Market } from "model/market";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";

export interface MarketReportSubscriptionsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: MarketReportSubscriptionResource;
	};
	byLeadId: {
		[key: number]: number[];
	};
	byMarketReportId: {
		[key: number]: number[];
	};
};

const initialState: MarketReportSubscriptionsState = {
	byId: {},
	byLeadId: {},
	byMarketReportId: {},
};

const fetchMarketReportSubscriptions = createAsyncThunk("marketReportSubscriptions/fetchMarketReportSubscriptions", async (payload, thunkAPI) => {
	thunkAPI.dispatch(subscriptionsLoading());
	const result = api.getMarketReportSubscriptions();
	result
		.then((subscriptions) => thunkAPI.dispatch(subscriptionsLoaded(subscriptions)))
		.catch(error => thunkAPI.dispatch(subscriptionsFailed(error)));
	return result;
});

const createMarketReportSubscription = createAsyncThunk("marketReportSubscriptions/createMarketReportSubscription", async (payload: {subscription: NewMarketReportSubscription}, thunkAPI) => {
	thunkAPI.dispatch(subscriptionsLoading())
	const result = api.createMarketReportSubscription(payload.subscription);
	result
		.then(subscription => thunkAPI.dispatch(subscriptionLoaded(subscription)))
		.catch(error => thunkAPI.dispatch(subscriptionsFailed(error)));
	return result;
});

const updateMarketReportSubscription = createAsyncThunk("marketReportSubscriptions/updateMarketReportSubscription", async (payload: {subscription: MarketReportSubscription}, thunkAPI) => {
	thunkAPI.dispatch(subscriptionsLoading())
	const result = api.updateMarketReportSubscription(payload.subscription);
	result
		.then(subscription => thunkAPI.dispatch(subscriptionLoaded(subscription)))
		.catch(error => thunkAPI.dispatch(subscriptionsFailed(error)));
	return result;
});

const updateMarketSubscriptions = createAsyncThunk("marketReportSubscriptions/updateMarketSubscriptions", async (payload: {market: Market, subscriptions: (MarketReportSubscription | NewMarketReportSubscription)[]}, thunkAPI) => {
	thunkAPI.dispatch(subscriptionsLoading())
	const result = api.updateMarketSubscriptions(payload.market.id, payload.subscriptions);
	result
		.then(subscriptions => thunkAPI.dispatch(subscriptionsLoaded(subscriptions)))
		.catch(error => thunkAPI.dispatch(subscriptionsFailed(error)));
		console.log(result, "market report subscription")
	return result;
});

export const marketReportSubscriptionsSlice = createSlice({
	name: "marketReportSubscriptions",
	initialState,
	reducers: {
		subscriptionsLoading: (state) => {
			state.loading = true;
		},
		subscriptionsLoaded: (state, action: PayloadAction<any[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
			state.byLeadId = arrayToByForeignId(action.payload, "id", "leadId");
			state.byMarketReportId = arrayToByForeignId(action.payload, "id", "marketId");
		},
		subscriptionLoaded: (state, action: PayloadAction<MarketReportSubscriptionResource>) => {
			const byId = objectToById(action.payload, "id");
			const byLeadId = mergeByForeignId(state.byLeadId, objectToByForeignId(action.payload, "id", "leadId"));
			const byMarketReportId = mergeByForeignId(state.byMarketReportId, objectToByForeignId(action.payload, "id", "marketReportId"));
			state.loading = false;
			state.error = null;	
			state.byId = {
				...state.byId, 
				...byId
			};
			state.byLeadId = byLeadId;
			state.byMarketReportId = byMarketReportId;
		},
		subscriptionsFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
            return state;
        }
    },
    extraReducers(builder) {
        builder.addCase(loginReset, () => {
            return initialState;
        });
    }
});

export const { subscriptionsLoading, subscriptionsLoaded, subscriptionLoaded, subscriptionsFailed } = marketReportSubscriptionsSlice.actions;
export { fetchMarketReportSubscriptions, updateMarketSubscriptions, updateMarketReportSubscription, createMarketReportSubscription };