import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { Criterion } from "model/criterion";
import { formatPrice } from "shared/price-formatter";
import { styles } from "./style";
import Measure from "react-measure"
import { getCities } from "redux/selector";
import { getNeighborhoods } from "redux/selector";
import { City } from "model/city";
import { PropertyType } from "type/property-type";
import { Neighborhood } from "model/neighborhood";

interface Props extends Mui.WithStyles<typeof styles> {
	criteria: Criterion[];
}

const maxHeight = 120; 

const mapStateToProps = (state: RootState) => {
	return {
		cities: getCities(state),
		neighborhoods: getNeighborhoods(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{
	criteria: Criterion[];
}

interface State {
	showAdditionalCriteria: boolean;
	rendered: boolean;
	height: number; 
	expandable: boolean;
}

class Component extends React.Component<Props, State> {
	
	public constructor(props: Props) {
		super(props);
		this.state = { 
			showAdditionalCriteria: false,
			rendered: false, 
			height: 0,
			expandable: false, 
		};
	}

	public componentDidMount() {
		this.setState({rendered: true});
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		const { height } = this.state; 
		if(prevState.height === 0 && height !== 0){
			if(height > maxHeight) {
				this.setState({ expandable: true});
			}
		}
	}

	private build() {
		let cities: City[] = [];
		let neighborhoods: Neighborhood[] = [];
		let areas: string[] = [];
		let propertyTypes: PropertyType[] = [];
		let minPrice: number | undefined;
		let maxPrice: number | undefined;
		let bedrooms: number | undefined;
		let bathrooms: number | undefined;
		const propertyFeatures: string[] = [];
		const others: Criterion[] = [];
		this.props.criteria.forEach((criterion) => {
			if (criterion.name === "cityId") {
				const ids = criterion.value as number[];
				cities = this.props.cities.filter((city) => {
					return ids.some((id) => id === city.id);
				});
			} else if (criterion.name === "neighborhoodId") {
				const ids = criterion.value as number[];
				neighborhoods = this.props.neighborhoods.filter((neighborhood) => {
					return ids.some((id) => id === neighborhood.id);
				});
			} else if (criterion.area) {
				if (typeof criterion.value === "string") {
					areas.push(criterion.value);
				}
				if(criterion.value instanceof Array) {
					criterion.value.forEach((value: string | number) => {
						if(typeof value === "string") {
							areas.push(value);
						}
					});
				}
			} else if (criterion.name === "propertyType") {
				const ids = criterion.value as string[];
				propertyTypes = PropertyType.values().filter((propertyType) => {
					return ids.some((id) => id === propertyType.id);
				});
			} else if (criterion.name === "minListPrice") {
				minPrice = criterion.value as number;
			} else if (criterion.name === "maxListPrice") {
				maxPrice = criterion.value as number;
			} else if (criterion.name === "bedrooms") {
				bedrooms = criterion.value as number;
			} else if (criterion.name === "bathCount") {
				bathrooms = criterion.value as number;
			} else if (criterion.name === "boundaryWKT") {
				propertyFeatures.push("Map Search");
			} else if (criterion.value === true) {
				propertyFeatures.push(criterion.label);
			} else {
				others.push(criterion);
			}
		});
		return {
			cities,
			neighborhoods,
			areas,
			propertyTypes,
			minPrice,
			maxPrice,
			bedrooms,
			bathrooms,
			propertyFeatures,
			others,
		}
	}

	public render() {
		const { classes } = this.props;
		const { showAdditionalCriteria, height, rendered, expandable } = this.state;
		let heightStyle: React.CSSProperties;
		if(!showAdditionalCriteria && height >= maxHeight) {
			heightStyle = {
				overflow: "hidden",
				height: maxHeight,
			}
		}
		const {
			cities,
			neighborhoods,
			areas,
			propertyTypes,
			minPrice,
			maxPrice,
			bedrooms,
			bathrooms,
			propertyFeatures,
			others,
		} = this.build();
		return (
			<Measure
				bounds
				onResize={contentRect => {
					if(contentRect && contentRect.bounds) {
						this.setState({ height: Math.floor(contentRect.bounds.height) })
					}
				}}
			>
				{({ measureRef }) => (
					<Mui.Grid container direction="column" spacing={1}>
						<Mui.Grid
							item
							style={(!rendered && undefined) || heightStyle}
							ref={measureRef}
						>
							<Mui.Grid container direction="column" spacing={1}>
								{!!cities.length && (
									<Mui.Grid item>
										<Mui.Typography className={classes.criteriaLabel}>
											City:
										</Mui.Typography>
										{cities.map((city, index) => (
											<Mui.Chip key={index} className={classes.criterion} label={city.name} />
										))}
									</Mui.Grid>
								)}
								{!!neighborhoods.length && (
									<Mui.Grid item>
										<Mui.Typography className={classes.criteriaLabel}>
											Neighborhood:
										</Mui.Typography>
										{neighborhoods.map((neighborhood, index) => (
											<Mui.Chip key={index} className={classes.criterion} label={neighborhood.name} />
										))}
									</Mui.Grid>
								)}
								{!!areas.length &&
									<Mui.Grid item>
										<Mui.Typography className={classes.criteriaLabel}>Area:</Mui.Typography>
										{areas.map((area, index) => (
											<Mui.Chip key={index} className={classes.criterion} label={area} />
										))}
									</Mui.Grid>
								}
								{!!propertyTypes.length &&
									<Mui.Grid item>
										<Mui.Typography className={classes.criteriaLabel}>
											Property Type:
										</Mui.Typography>
										{propertyTypes.map((propertyType, index) => (
											<Mui.Chip key={index} className={classes.criterion} label={propertyType.label} />
										))}
									</Mui.Grid>
								}
								<Mui.Grid item>
									{!!minPrice && !!maxPrice &&
										<>
											<Mui.Typography className={classes.criteriaLabel}>Price:</Mui.Typography>
											<Mui.Chip className={classes.criterion} label={`${formatPrice(minPrice)} - ${formatPrice(maxPrice)}`} />
										</>
									}
									{!!minPrice && !maxPrice && (
										<>
											<Mui.Typography className={classes.criteriaLabel}>
												Min. Price:
											</Mui.Typography>
											<Mui.Chip className={classes.criterion} label={formatPrice(minPrice)} />
										</>
									)}
									{!minPrice && !!maxPrice && (
										<>
											<Mui.Typography className={classes.criteriaLabel}>
												Max. Price:
											</Mui.Typography>
											<Mui.Chip className={classes.criterion} label={formatPrice(maxPrice)} />
										</>
									)}
									{!!bedrooms && (
										<>
											<Mui.Typography className={classes.criteriaLabel}>
												Bedrooms:
											</Mui.Typography>
											<Mui.Chip className={classes.criterion} label={bedrooms} />
										</>
									)}
									{!!bathrooms && (
										<>
											<Mui.Typography className={classes.criteriaLabel}>
												Bathrooms:
											</Mui.Typography>
											<Mui.Chip className={classes.criterion} label={bathrooms} />
										</>
									)}
								</Mui.Grid>
								{!!propertyFeatures.length &&
									<Mui.Grid item>
										<Mui.Typography className={classes.criteriaLabel}>
											Property Features:
										</Mui.Typography>
										{propertyFeatures.map((propertyFeature, index) => (
											<Mui.Chip key={index} className={classes.criterion} label={propertyFeature} />
										))}
									</Mui.Grid>
								}
								{others.map((criterion, index) => {
									return (
										<Mui.Grid item key={index}>
											<Mui.Typography className={classes.criteriaLabel}>
												{criterion.label}:
											</Mui.Typography>
											{criterion.value instanceof Array &&
												(criterion.value as string[]).map((value, index) => (
													<Mui.Chip key={index} className={classes.criterion} label={value} />
												))}
											{!(criterion.value instanceof Array) && (
												<Mui.Chip className={classes.criterion} label={criterion.value} />
											)}
										</Mui.Grid>
									);
								})}
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid item>
							{expandable &&
								<Mui.Button
									variant="text"
									color="secondary"
									onClick={() => this.setState({ showAdditionalCriteria: !showAdditionalCriteria })}
								>
									{showAdditionalCriteria && 
										"Hide"
									}
									{!showAdditionalCriteria && 
										"See"
									}
									{" Additional Criteria"}
								</Mui.Button>
							}
						</Mui.Grid>
					</Mui.Grid>
				)}
			</Measure>
		);
	}
}

export const CriterionComponent = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
