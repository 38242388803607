import React from "react";
import { connect } from "react-redux";
import { RootState } from "redux/store";
import { getActiveLeads } from "redux/selector";
import { LeadRanking } from "type/lead-ranking";
import { Stars } from "./stars";

interface OwnProps {
	leadId: number;
}

interface State {}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	let ranking = LeadRanking.NO_RANKING;
	let allLeads = getActiveLeads(state).sort(function (lead1, lead2) {
		return (lead1.ranking || 0) > (lead2.ranking || 0) ? -1 : 1;
	});
	let rating = 0;
	let ratingSize = 0;
	allLeads.forEach(function (lead) {
		if (lead.ranking && lead.ranking > 0) {
			ratingSize = ratingSize + 1;
		}
	});
	allLeads.forEach(function (lead) {
		rating++;
		if (lead.id === ownProps.leadId && lead.ranking && lead.ranking > 0) {
			if (rating > (ratingSize / 3) * 2) {
				ranking = LeadRanking.LOW;
			} else if (rating > ratingSize / 3) {
				ranking = LeadRanking.MEDIUM;
			} else {
				ranking = LeadRanking.HIGH;
			}
		}
	});
	return {
		leadRanking: ranking,
	};
};

interface Props extends ReturnType<typeof mapStateToProps> {
	leadId: number;
}

interface OwnProps {
	leadId: number;
}

class Component extends React.Component<Props, State> {
	public render() {
		const { leadRanking } = this.props;
		return <Stars leadRanking={leadRanking} />;
	}
}

export const Ranking = connect(mapStateToProps)(Component);
