import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Avatar } from "component/shared/avatar";
import { FeatherIcon } from "component/shared/feather-icon";
import { Table } from "component/shared/table";
import { Agent } from "model/agent";
import { deleteAgent } from "redux/slice/agents";
import { RootState } from "redux/store";
import { numberedDateFormatter } from "shared/date-formatter";
import { AgentColumnType } from "type/agent-column-type";
import { DeleteAgentDialog } from "./delete-dialog";
import { styles } from "./style";
import { AddEditAgentDialog } from "../add-edit-agent-dialog";
import { urls } from "routes/urls";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { getUser } from "redux/selector";
import * as env from "shared/env";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	let agentColumnTypes = [];
	agentColumnTypes.push(AgentColumnType.AVATAR);
	agentColumnTypes.push(AgentColumnType.LABEL);
	agentColumnTypes.push(AgentColumnType.LAST_LOGIN);
	agentColumnTypes.push(AgentColumnType.ACTION);
	return {
		agentColumnTypes,
		user: getUser(state)
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			deleteAgent,
		},
		dispatch
	);

interface Props	extends OwnProps,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		Mui.WithStyles<typeof styles> {}

interface OwnProps {
	agents: Agent[];
}

interface State {
	addAgentDialogIsOpen: boolean;
	agentToDelete: Agent | null;
	agentToEdit: Agent | null;
	deleteAgentDialogIsOpen: boolean;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			addAgentDialogIsOpen: false,
			agentToDelete: null,
			agentToEdit: null,
			deleteAgentDialogIsOpen: false,
		};
	}

	private showAddAgentDialog = () =>
		this.setState({ addAgentDialogIsOpen: true, agentToEdit: null });

	private showEditAgentDialog = (agent: Agent) =>
		this.setState({ addAgentDialogIsOpen: true, agentToEdit: agent });

	private hideAddAgentDialog = () =>
		this.setState({ addAgentDialogIsOpen: false });

	private showDeleteAgentDialog = (agent: Agent) => {
		this.setState({
			agentToDelete: agent,
			deleteAgentDialogIsOpen: true,
		});
	};

	private hideDeleteAgentDialog = () =>
		this.setState({
			agentToDelete: null,
			deleteAgentDialogIsOpen: false,
		});

	private deleteAgent = () => {
		const { agentToDelete } = this.state;
		if (agentToDelete) {
			this.props.deleteAgent({ agent: {
				...agentToDelete,
			}});
		}
		this.hideDeleteAgentDialog();
	};

	public render() {
		const { agents, classes, agentColumnTypes, user } = this.props;
		const {
			addAgentDialogIsOpen,
			agentToDelete,
			deleteAgentDialogIsOpen,
		} = this.state;
		return (
			<>
				{addAgentDialogIsOpen && (
					<AddEditAgentDialog
						agents={agents}
						open={addAgentDialogIsOpen}
						onClose={this.hideAddAgentDialog}
					/>
				)}
				{agentToDelete && (
					<DeleteAgentDialog
						agent={agentToDelete}
						deleteAgent={this.deleteAgent}
						deleteAgentDialogIsOpen={deleteAgentDialogIsOpen}
						hideDeleteAgentDialog={this.hideDeleteAgentDialog}
					/>
				)}
				<Mui.Grid container direction="column" spacing={4}>
					<Mui.Grid item>
						<Mui.Grid
							container
							item
							alignItems="center"
							justifyContent="space-between"
							spacing={4}
						>
							<Mui.Grid item>
								<Mui.Button
									variant="contained"
									color="secondary"
									onClick={() => this.showAddAgentDialog()}
								>
									<Mui.Typography>Add New</Mui.Typography>
								</Mui.Button>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<Table
							items={agents}
							columns={[
								{
									id: "avatar",
									hidden: !agentColumnTypes.includes(AgentColumnType.AVATAR),
									width: 70,
									value: (agent) => {
										return (
											<div className={this.props.classes.centeredCell}>
												<Avatar phrase={agent.label} src={agent.photoUrl} />
											</div>
										);
									},
								},
								{
									id: "label",
									hidden: !agentColumnTypes.includes(AgentColumnType.LABEL),
									title: AgentColumnType.LABEL.label,
									sort: (agent1, agent2) => {
										return (agent1.lastName || "").toLowerCase() < (agent2.lastName || "").toLowerCase()
											? -1
											: 1;
									},
									value: (agent) => {
										return (
											<Mui.Link
												className={classes.link}
												component={RouterLinkWrapper} to={urls.settingsAgent(agent)}
											>
												{agent.label}
											</Mui.Link>
										);
									},
								},
								{
									id: "id",
									title: AgentColumnType.ID.label,
									value: (agent) => {
										return (
											<Mui.Typography>
												{agent.id}
											</Mui.Typography>
										);
									},
								},
								{
									id: "role",
									hidden: !agentColumnTypes.includes(AgentColumnType.LABEL),
									title: AgentColumnType.ROLE.label,
									value: (agent) => {
										return (
											<Mui.Typography>
												{agent.admin ? "Admin" : ""}
											</Mui.Typography>
										);
									},
								},
								{
									id: "lastLogin",
									title: AgentColumnType.LAST_LOGIN.label,
									value: (agent) => {
										let lastLoginDate = agent.lastLoginDate;
										if (!lastLoginDate) {
											return null;
										}
										return numberedDateFormatter(lastLoginDate);
									},
								},
								{
									id: "action",
									title: AgentColumnType.ACTION.label,
									value: (agent) => {
										const url = agent.agentPageUrl;
										return url ? (
											<Mui.Grid container alignItems="center" direction="row" spacing={5}>
												{/*
												//TODO: Handle login
												<Mui.Grid item>
													<Mui.Typography align="center">-------</Mui.Typography>
												</Mui.Grid>*/}
												<Mui.Grid item>
													<Mui.Link href={url} target="_blank" rel="noopener">
														View Agent Page
													</Mui.Link>
												</Mui.Grid>
											</Mui.Grid>
										) : null;
									},
								},
								{
									id: "delete",
									width: 70,
									showOnHover: true,
									value: (agent) => {
										return (
											<>
												{user.admin && (
													<Mui.IconButton
														onClick={() => {
															this.showDeleteAgentDialog(agent);
														}}
														disabled={(env.agentAdminDev && (user.agentId === agent.id))}
													>
														<FeatherIcon>
															<Icons.Trash />
														</FeatherIcon>
													</Mui.IconButton>
												)}
											</>
										);
									},
								},
							]}
						/>
					</Mui.Grid>
				</Mui.Grid>
			</>
		);
	}
}

export const AgentTable = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
