import { ListingResource } from "api/resource/listing";

export const listings: ListingResource[] = [
	{
		id: "1001686854_2",
		listingNumber: "1001686854",
		boardId: 2,
		address: "3 W KING STREET\\n ABBOTTSTOWN, PA 17301",
		bedrooms: 8,
		fullBathrooms: 6,
		price: 14320000,
		photoUrl: "https://media.crmls.org/mediaz/F81C0BCE-825A-4060-AA66-BAB04158B022.jpg",
		detailUrl: "https://example.com",
	},
	{
		id: "1001686855_2",
		listingNumber: "1001686855",
		boardId: 2,
		address: "2000 1st STREET\\n Otto, PA 17305",
		bedrooms: 4,
		fullBathrooms: 3,
		partialBathrooms: 1,
		price: 849000,
		photoUrl: "https://media.crmls.org/medias/5b3e111e-8c71-413a-b679-ff29ea4a5ec7.jpg",
		detailUrl: "https://example.com",
	},
	{
		id: "1001686856_2",
		listingNumber: "1001686856",
		boardId: 2,
		address: "2000 1st STREET\\n Otto, PA 17305",
		bedrooms: 2,
		fullBathrooms: 2,
		price: 450000,
		photoUrl: "https://media.crmls.org/medias/348a32fe-748d-48a1-912e-630150e1aa1a.jpg",
		detailUrl: "https://example.com",
	},
];