import React from "react"
import * as Mui from "@material-ui/core";
import { bindActionCreators, Dispatch } from "redux";
import { Market } from "model/market";
import { connect } from "react-redux";
import { updateMarket } from "redux/slice/markets";
import { updateMarketReport } from "redux/slice/market-reports";
import { SaveButton } from "component/shared/save-button";
import { RootState } from "redux/store";
import { styles } from "./style";

interface Props extends 
	ReturnType<typeof mapDispatchToProps>,
	ReturnType<typeof mapStateToProps>,
	Mui.WithStyles<typeof styles> {
		market: Market;
	}

interface State {
	webIntroText: string, 
	emailIntroText: string, 
	limitEmailToFeatured: boolean,
	navigationDisplay: boolean,
	saved: boolean;
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateMarket,
	updateMarketReport,
}, dispatch);

const mapStateToProps = (state: RootState) => {
	return {
		reportsLoading: state.marketReports.loading,
		marketsLoading: state.markets.loading,
	};
}

class Component extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		const { market } = this.props
		const openHomesReport = market.openHomesReport;
		this.state = {
			webIntroText: openHomesReport && openHomesReport.webpageIntroText ? openHomesReport.webpageIntroText : "",
			emailIntroText: openHomesReport && openHomesReport.emailIntroText ? openHomesReport.emailIntroText : "",
			limitEmailToFeatured: market.limitEmailToFeatured ? market.limitEmailToFeatured : false,
			navigationDisplay: openHomesReport && openHomesReport.displayInNavigation ? openHomesReport.displayInNavigation : false,
			saved: false,
		}
	}

	private async save() {
		const { market } = this.props;
		const { emailIntroText, navigationDisplay, webIntroText, limitEmailToFeatured } = this.state;
		if(market.openHomesReport) {
			await this.props.updateMarket({ market: {
				...market,
				limitEmailToFeatured: limitEmailToFeatured,
			}});
			this.props.updateMarketReport({ marketReport: {
				...market.openHomesReport,
				displayInNavigation: navigationDisplay,
				emailIntroText: emailIntroText,
				webpageIntroText: webIntroText,
			}});
		}
	}

	render() {
		const { market, marketsLoading, reportsLoading, classes } = this.props;
		const { emailIntroText, navigationDisplay, saved, webIntroText, limitEmailToFeatured } = this.state;
		return (
			<Mui.Grid container direction='column' spacing={2}>
				<Mui.Grid item >
					<Mui.Grid container direction='row' justifyContent="space-between" wrap="nowrap">
						<Mui.Grid item xs={2}>
							<Mui.Typography>Page URL</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={9}>
							<Mui.Link
								href={market.openHomesReport?.pageUrl}
								target="_blank"
							>{market.openHomesReport?.pageUrl}</Mui.Link>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item >
					<Mui.Grid container direction='row' justifyContent="space-between" wrap="nowrap">
						<Mui.Grid item xs={2}>
							<Mui.Typography>Webpage Intro Text</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={9}>
							<Mui.TextField
								multiline
								minRows={5}
								variant="outlined"
								fullWidth
								value={webIntroText}
								onChange={(event) => {
									this.setState({webIntroText: event.target.value});
								}}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Grid container direction='row' justifyContent="space-between" wrap="nowrap">
						<Mui.Grid item xs={2}>
							<Mui.Typography>Email Intro Text</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={9}>
							<Mui.TextField
								multiline
								minRows={5}
								variant="outlined"
								fullWidth
								value={emailIntroText}
								onChange={(event) => {
									this.setState({emailIntroText: event.target.value})
								}}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Grid container direction='row' justifyContent="space-between" wrap="nowrap">
						<Mui.Grid item xs={2}>
							<Mui.Typography>Show Only Featured Listings in Email</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={9}>
							<Mui.Checkbox
								checked={limitEmailToFeatured}
								onChange={(event) => {
									this.setState({limitEmailToFeatured: !limitEmailToFeatured})
								}}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Grid container direction='row' justifyContent="space-between" wrap="nowrap">
						<Mui.Grid item xs={2}>
							<Mui.Typography>Display in Navigation</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={9}>
							<Mui.Checkbox
								checked={navigationDisplay}
								onChange={(event) => {
									this.setState({navigationDisplay: !navigationDisplay})
								}}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item className={classes.saveRow}>
					<SaveButton 
						loading={(!!reportsLoading || !!marketsLoading) && saved} 
						label={{
							primary: "Save", 
							inProgress: "Saving...", 
							completed: "Saved" 
						}}
						snackBar={true}
						onClick={() => {
							this.setState({saved: true});
							this.save();
						}}
						onReset={() => this.setState({saved: false})} 
					/>
				</Mui.Grid>
			</Mui.Grid>
		)
	}
}

export const OpenHomeReportContent = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));