import * as env from "shared/env";

export const user = (): string => {
	return base() + `/user.json`;
}

export const userAuth = (): string => {
	return base() + `/user/auth.json`;
}

export const adminMode = (): string => {
	return `${env.coldFusionUrl}/idx/controllers/AdminMode.cfm`;
}

export const oneGetUser = (): string => {
	return oneBase() + `/users/current`;
}

export const reseller = (resellerId: number): string => {
	return base() + `/reseller/${resellerId}.json`;
}

export const client = (clientId: number): string => {
	return base() + `/client/${clientId}.json`;
}

export const quota = () => {
	return base() + `/quota.json`;
}

export const leads = () => {
	return base() + `/leads.json`;
}

export const lead = (leadId?: number) => {
	return base() + `/lead/${leadId}.json`;
}

export const savedSearches = (): string => {
	return base() + `/saved-searches.json`;
}

export const savedSearch = (savedSearchId: number): string => {
	return base() + `/saved-searches/${savedSearchId}.json`;
}

export const savedSearchResultTotal = (savedSearchId: number): string => {
	return base() + `/saved-search/${savedSearchId}/result-total.json`;
}

export const savedListings = (leadId?: number): string => {
	const query = querystring({ leadId });
	return base() + `/saved-listings.json${query}`;
}

export const savedListing = (savedListingId: number): string => {
	return base() + `/saved-listings/${savedListingId}.json`;
}

export const emailSents = (leadId?: number): string => {
	const query = querystring({ leadId });
	return base() + `/email-sents.json${query}`;
}

export const emailClicks = (leadId?: number): string => {
	const query = querystring({ leadId });
	return base() + `/email-clicks.json${query}`;
}

export const leadPhones = (): string => {
	return base() + `/lead-phones.json`;
}

export const leadPhone = (leadPhoneId: number): string => {
	return base() + `/lead-phone/${leadPhoneId}.json`;
}

export const leadPipelines = (): string => {
	return base() + `/lead-pipelines.json`;
}

export const leadPipeline = (leadPipelineId: number): string => {
	return base() + `/lead-pipelines/${leadPipelineId}.json`;
}

export const leadAnniversaries = (): string => {
	return base() + `/lead-anniversaries.json`;
}

export const leadAnniversary = (leadAnniversaryId: number): string => {
	return base() + `/lead-anniversary/${leadAnniversaryId}.json`;
}

export const leadImport = (): string => {
	return base() + `/lead-imports.json`;
}

export const leadImports = (): string => {
	return base() + `/lead-imports.json`;
}

export const leadNotes = (): string => {
	return base() + `/lead-notes.json`;
}

export const leadNote = (leadNoteId: number): string => {
	return base() + `/lead-note/${leadNoteId}.json`;
}

export const leadTags = (): string => {
	return base() + `/lead-tags.json`;
}

export const leadTag = (leadTagId: number): string => {
	return base() + `/lead-tag/${leadTagId}.json`;
}

export const leadActivities = (leadId?: number): string => {
	const query = querystring({ leadId });
	return base() + `/lead-activities.json${query}`;
}

export const leadActivityStats = (): string => {
	return base() + `/lead-activity-stats.json`;
}

export const leadLastActivities = (): string => {
	return base() + `/lead-last-activities.json`;
}

export const campaigns = () => {
	return base() + `/campaigns.json`;
}

export const campaign = (campaignId: number) => {
	return base() + `/campaign/${campaignId}.json`;
}

export const geographicRoutes = () => {
	return base() + `/geographic-routes.json`;
}

export const geographicRoute = (geographicRouteId: number) => {
	return base() + `/geographic-route/${geographicRouteId}.json`;
}

export const roundRobinRoutes = () => {
	return base() + `/round-robin-routes.json`;
}


export const postalCodesRoutes = () => {
	return base() + `/postal-codes.json`;
}

export const roundRobinRoute = (roundRobinRouteId: number) => {
	return base() + `/round-robin-route/${roundRobinRouteId}.json`;
}

export const campaignMessages = () => {
	return base() + `/campaign/campaign-messages.json`;
}

export const campaignMessage = (campaignMessageId: number) => {
	return base() + `/campaign/campaign-message/${campaignMessageId}.json`;
}

export const campaignCampaignMessages = () => {
	return base() + `/campaign/campaign-campaign-messages.json`;
}

export const campaignCampaignMessage = (campaignCampaignMessageId: number) => {
	return base() + `/campaign/campaign-campaign-message/${campaignCampaignMessageId}.json`;
}

export const campaignCampaignMessageSendTestEmail = () => {
	return base() + `/campaigns/campaign-campaign-message/sendTest.json`;
}

export const campaignSubscriptions = () => {
	return base() + `/campaign/subscriptions.json`;
}

export const campaignSubscription = (campaignSubscriptionid: number) => {
	return base() + `/campaign/subscription/${campaignSubscriptionid}.json`;
}

export const campaignEmails = (leadId?: number, campaignId?: number, blast?: boolean) => {
	const query = querystring({ leadId, campaignId, blast });
	return base() + `/campaign-emails.json${query}`;
}

export const campaignStats = () => {
	return base() + `/campaign-stats.json`;
}

export const campaignSubscriptionStats = () => {

	return base() + `/campaign/subscription-stats.json`;
}

export const campaignCampaignMessageStats = () => {
	return base() + `/campaign-campaign-message-stats.json`;
}

export const campaignRoute = (campaignRouteId: number) => {
	return base() + `/campaign-routes/${campaignRouteId}.json`;
}

export const campaignRoutes = () => {
	return base() + `/campaign-routes.json`;
}

export const campaingsSettings = () => {
	return base() + `/campaigns-settings.json`;
}

export const markets = () => {
	return base() + `/markets.json`;
}

export const market = (id?: number) => {
	return base() + `/market/${id}.json`;
}

export const marketListing = (id?: number) => {
	return base() + `/market-listing/${id}.json`;
}

export const marketListings = (): string => {
	return base() + `/market-listings.json`;
}

export const marketReports = () => {
	return base() + `/market-reports.json`;
}

export const marketReport = (id?: number) => {
	return base() + `/market-report/${id}.json`;
}

export const marketReportSubscriptions = () => {
	return base() + `/market-report/subscriptions.json`;
}

export const marketSubscriptions = (marketId: number) => {
	return base() + `/market/${marketId}/subscriptions.json`;
}

export const marketReportSubscription = (marketReportSubscriptionId: number) => {
	return base() + `/market-report/subscription/${marketReportSubscriptionId}.json`;
}

export const agent = (agentId: number) => {
	return base() + `/agent/${agentId}.json`;
}

export const agents = () => {
	return base() + `/agents.json`;
}

export const boards = () => {
	return base() + `/boards.json`;
};

export const agentBoard = (agentBoardId: number): string => {
	return base() + `/agent-board/${agentBoardId}.json`;
}

export const agentBoards = () => {
	return base() + `/agent-boards.json`
}

export const office = (officeId: number) => {
	return base() + `/office/${officeId}.json`;
}

export const offices = () => {
	return base() + `/offices.json`;
}

export const officeBoard = (officeBoardId: number): string => {
	return base() + `/office-board/${officeBoardId}.json`;
}

export const officeBoards = () => {
	return base() + `/office-boards.json`;
}

export const cities = () => {
	return base() + `/cities.json`;
}

export const neighborhoods = () => {
	return base() + `/neighborhoods.json`;
}

export const aggregateStats = () => {
	return base() + `/aggregate-stats.json`;
}

export const tasks = () => {
	return base() + `/tasks.json`;
}

export const task = (taskId: number) => {
	return base() + `/task/${taskId}.json`;
}

export const integrations = () => {
	return base() + `/integrations.json`;
}

export const integration = (integrationId: number) => {
	return base() + `/integration/${integrationId}.json`;
}

export const integrationTest = (integrationId: number) => {
	return base() + `/integration/${integrationId}/test.json`;
}

export const integrationCalendars = (integrationId: number) => {
	const query = querystring({ integrationId });
	return base() + `/integration-calendars.json${query}`;
}

export const siteLinks = () => {
	return base() + `/idx-links.json`;
}

export const sendEmail = (contactEmail: string, contactName: string, subject: string, emailBody: string) => {
	const query = querystring({ contactEmail, contactName, subject, emailBody});
	return base() + `/email-sync.json${query}`;
}

export const uploadSignature = (uploadPreset: string) => {
	return base() + `/upload-signature/${uploadPreset}.json`;
}

export const geocode = (address?: string) => {
	const query = querystring({ address });
	return base() + `/geocode.json${query}`;
}

export const pages = () => {
	return base() + `/pages.json`;
}

export const page = (pageId: number) => {
	return base() + `/page/${pageId}.json`;
}

export const languages = () => {
	return base() + `/languages.json`;
}

const base = (): string => {
	return env.apiUrl + `/api/control-panel`;
}

const oneBase = (): string => {
	return env.oneApiUrl + "";
}

const querystring = (params: { [k: string]: any }) => {
	let result = Object
		.keys(params)
		.map((name) => {
			const value = params[name];
			if(value !== undefined && value !== null) {
				return encodeURIComponent(name) + "=" + encodeURIComponent(value);
			}
			return null;
		})
		.filter(Boolean)
		.join("&")
	;
	if(result) {
		result = "?" + result;
	}
	return result;
}
