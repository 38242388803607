import React from "react";
import { RootState, getPayload } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as Mui from "@material-ui/core";
import { styles } from "./styles";
import { createCampaignCampaignMessage, sendCampaignCampaignMessageTestEmail } from "redux/slice/campaign-campaign-messages";
import { updateCampaignMessage, createCampaignMessage, deleteCampaignMessage } from "redux/slice/campaign-messages";
import { withMediaQuery, WithMediaQuery } from "component/shared/with-media-query";
import { CampaignCampaignMessage } from "model/campaign-campaign-message";
import { DroppableProvided, Draggable } from "react-beautiful-dnd";
import { ColumnLabel } from "component/shared/column-label";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { SendAfter } from "./send-after";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Icons from "react-feather";
import DragIndicator from '@material-ui/icons/DragIndicator';
import { CampaignMessage } from "model/campaign-message";
import { SendTestEmail } from "./send-test-email";
import { urls } from "routes/urls";
import { getCampaignCampaignMessagesCount } from "redux/selector";
import { CampaignMessageStatus } from "type/campaign-message-status";
import { CampaignMessageType } from "type/campaign-message";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const campaignCampaignMessagesCount = getCampaignCampaignMessagesCount(state, ownProps.campaignCampaignMessage.campaignId);
	return {
		campaignCampaignMessagesCount,
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createCampaignCampaignMessage,
	updateCampaignMessage,
	createCampaignMessage,
	deleteCampaignMessage,
	sendCampaignCampaignMessageTestEmail,
}, dispatch);

interface OwnProps {
	campaignCampaignMessage: CampaignCampaignMessage;
	provided: DroppableProvided,
	index: number,
	dragging: string,
	onCopy: () => void,
}

type Props = OwnProps &
	Mui.WithStyles<typeof styles> &
	WithMediaQuery &
	ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps>;

interface State {
	anchorElement: HTMLElement | null,
	openSendEmailTestDialog: boolean,
	disabled: boolean;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			anchorElement: null,
			openSendEmailTestDialog: false,
			disabled: false,
		}
	}

	public componentDidUpdate(prevProps: Props) {
		const {campaignCampaignMessage} = this.props;
		const campaignMessage = campaignCampaignMessage.campaignMessage;
		if(prevProps.campaignCampaignMessage !== campaignCampaignMessage) {
			if (campaignMessage.status === CampaignMessageStatus.INACTIVE) {
				this.setState({disabled: true})
			}
			if (campaignMessage.status === CampaignMessageStatus.ACTIVE) {
				this.setState({disabled: false})
			}
		}
		
	}

	private handleActionsClick(event: HTMLElement) {
		this.setState({anchorElement: event})
	}

	private async handleCopy() {
		const { createCampaignMessage, createCampaignCampaignMessage} = this.props;
		this.setState({anchorElement: null});
		const newCampaignMessage = getPayload(await createCampaignMessage({ campaignMessage: {
			...this.props.campaignCampaignMessage.campaignMessage,
			name: this.props.campaignCampaignMessage.campaignMessage.name + " (Copy)",
			status: CampaignMessageStatus.ACTIVE,
			isInactiveYn: false,
		}}));
		if(newCampaignMessage.id) {
			getPayload(await createCampaignCampaignMessage({ campaignCampaignMessage: {
				campaignId: this.props.campaignCampaignMessage.campaignId,
				campaignMessageId: newCampaignMessage.id,
				messageOrder: this.props.campaignCampaignMessagesCount > 0 ? this.props.campaignCampaignMessagesCount + 1 : 1,
			}}));
			this.props.onCopy();
		}
	}

	private handleDelete() {
		this.setState({anchorElement: null});
		const { updateCampaignMessage, campaignCampaignMessage } = this.props;
		if(campaignCampaignMessage.campaignMessage && window.confirm("Delete this message?")) {
			const campaignMessage: CampaignMessage = {
				...campaignCampaignMessage.campaignMessage,
				isInactiveYn: true,
			}
			updateCampaignMessage({campaignMessage});
		}
	}

	private handleDeactivateToggle() {
		this.setState({anchorElement: null});
		const { campaignCampaignMessage, updateCampaignMessage } = this.props;
		const campaignMessage = campaignCampaignMessage.campaignMessage;
		if(campaignMessage.status === CampaignMessageStatus.ACTIVE) {
			campaignMessage.status = CampaignMessageStatus.INACTIVE;
		} else {
			campaignMessage.status = CampaignMessageStatus.ACTIVE;
		}
		updateCampaignMessage({campaignMessage});

	}

	private handleSendTestEmail() {
		this.setState({anchorElement: null});
		this.setState({openSendEmailTestDialog: true});
	}

	get statsClick(): string {
		const stats = this.props.campaignCampaignMessage.stats;
		if(stats && stats.clicks) {
			return stats.clicks.toString();	
		} else {
			return "N/A";
		}
	}

	get statsOpens(): string {
		const stats = this.props.campaignCampaignMessage.stats;
		if(stats && stats.opens) {
			return stats.opens.toString();	
		} else {
			return "N/A";
		}
	}

	private renderStats() {
		const { classes } = this.props;
		
		return (
			<>
				<Mui.Grid container className={classes.stats} justifyContent={"center"}>
					<Mui.Grid item className={classes.stat}>
						<Mui.Grid container direction="column" >
							<Mui.Grid item xs={12}>
								{this.statsClick}
							</Mui.Grid>
							<Mui.Grid item xs={12}>
								<ColumnLabel>
									Clicks*
								</ColumnLabel>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Grid container direction="column">
							<Mui.Grid item xs={12}>
								{this.statsOpens}
							</Mui.Grid>
							<Mui.Grid item xs={12}>
								<ColumnLabel>
									Opens*
								</ColumnLabel>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</>
		);
	}

	private getEditUrl(campaignCampaignMessage: CampaignCampaignMessage) {
		if(campaignCampaignMessage.campaignMessage.messageType === CampaignMessageType.EMAIL) {
			return urls.campaignCampaignMessage(campaignCampaignMessage);
		}
		if(campaignCampaignMessage.campaignMessage.messageType === CampaignMessageType.TEXT) {
			return urls.campaignCampaignTextMessage(campaignCampaignMessage);
		}
	}

	private renderTitle = () => {
		const { campaignCampaignMessage, classes } = this.props;
		const messageType = campaignCampaignMessage.campaignMessage.messageType;
		return (
			<Mui.Grid container spacing={1} direction="row" wrap="nowrap" alignItems="center">
				<Mui.Grid item >
					{messageType === CampaignMessageType.TEXT && 
						<FeatherIcon className={classes.centerIcon}>
							<Icons.MessageSquare />
						</FeatherIcon>
					}
					{(!messageType === null) || (messageType === CampaignMessageType.EMAIL) ? 
						(<FeatherIcon className={classes.centerIcon}>
							<Icons.Mail />
						</FeatherIcon>): undefined
					}	
					
				</Mui.Grid>
				<Mui.Grid item>
					<span className={classes.messageLabel}>
						Message:
					</span>
					<Mui.Link 
						component={RouterLinkWrapper} 
						to={this.getEditUrl(campaignCampaignMessage)}
						className={classes.message}
					>
						{campaignCampaignMessage.campaignMessage ? campaignCampaignMessage.campaignMessage.name : ""}
					</Mui.Link>
				</Mui.Grid>
			</Mui.Grid>		
		);
	}

	private renderActionButton = () => {
		const { classes, mediaQuery } = this.props;
		return (
			<>
			<Mui.Grid container justifyContent={mediaQuery ? "flex-start" :"flex-end"}>

				<Mui.Button 
					color="secondary"
					variant="contained"
					aria-controls="simple-menu"
					aria-haspopup="true"
					onClick={(event) => this.handleActionsClick(event.currentTarget)}>
						Actions 
						<Icons.ChevronDown size={16} className={classes.actionsIcon} />
				</Mui.Button>
				<Mui.Menu
					id="simple-menu"
					
					anchorEl={this.state.anchorElement}
					keepMounted
					open={Boolean(this.state.anchorElement)}
					onClose={() => this.setState({anchorElement: null})}
				>
					<Mui.MenuItem onClick={() => this.handleCopy()}>Copy</Mui.MenuItem>
					<Mui.MenuItem onClick={() => this.handleDelete()}>Delete</Mui.MenuItem>
					{this.props.campaignCampaignMessage.campaignMessage.messageType === CampaignMessageType.EMAIL &&
						<Mui.MenuItem onClick={() => this.handleSendTestEmail()}>Send Test</Mui.MenuItem>
					}
					<Mui.MenuItem onClick={() => this.handleDeactivateToggle()}>
						{!this.state.disabled && 
							<>Deactivate</>
						}
						{this.state.disabled && 
							<>Activate</>
						}
					</Mui.MenuItem>
				</Mui.Menu>
			</Mui.Grid>
			</>
		);
	}

	private renderStage = () => {
		const { classes, index } = this.props;
		const { disabled } = this.state;
		return (
			<Mui.Grid item className={classes.stage} style={{opacity: disabled ? .5 : undefined}}>
				<DragIndicator className={classes.dragIndicator} />
				<Mui.Typography variant="h3">
					Stage {index + 1}
				</Mui.Typography>
			</Mui.Grid>
		);
		
	}

	private renderDay = () => {
		const { campaignCampaignMessage, classes } = this.props;
		const dayNumber = campaignCampaignMessage.dayNumber;
		return(
			<>
				{!this.state.disabled && 
					<Mui.Chip
						size="medium"
						label={"Day " + dayNumber}
						color="secondary"
						className={classes.chip}
					/>
				}
				{this.state.disabled && 
					<Mui.Chip
						size="medium"
						label={"Inactive"}
						className={classes.chip}
						style={{backgroundColor: "#6c757d", color: "#fff"}}
					/>
				}
			</>
		);
	}

	public render() {
		const { campaignCampaignMessage, classes, dragging } = this.props;
		const { openSendEmailTestDialog, disabled } = this.state;
		return (
			<>
				<SendTestEmail open={openSendEmailTestDialog} onClose={() => this.setState({openSendEmailTestDialog: false})} campaignCampaignMessage={campaignCampaignMessage}/>
				<Draggable draggableId={campaignCampaignMessage.id.toString()} index={this.props.index}>
					{(provided) => (
						<Mui.Grid item container direction="column" wrap={"nowrap"} {...provided.draggableProps} {...provided.dragHandleProps} innerRef={provided.innerRef}>
							<Mui.Grid item>
								<Mui.Card >
									<Mui.CardHeader
										avatar={
											<>
												{this.renderStage()}
											</>								
										}
										action={
											<>
												{this.renderDay()}
											</>
										}
										className={classes.header}
									/>
									<Mui.CardContent>
											<Mui.Grid container direction="row" spacing={2} className={classes.container}>
												<Mui.Grid item xs={12} sm={6} lg={4} xl={4} style={{opacity: disabled ? .5 : undefined}}>
													<Mui.Grid item>
														{this.renderTitle()}
													</Mui.Grid>
													<Mui.Grid item>
														<SendAfter campaignCampaignMessage={campaignCampaignMessage} />
													</Mui.Grid>
												</Mui.Grid>
												<Mui.Grid item xs={12} sm={6} lg={4} xl={4} style={{opacity: disabled ? .5 : undefined}}>
													{this.renderStats()}
												</Mui.Grid>
												<Mui.Grid item xs={12} sm={12} lg={4} xl={4}>
													{this.renderActionButton()}
												</Mui.Grid>
											</Mui.Grid>
									</Mui.CardContent>
								</Mui.Card>
							</Mui.Grid>
							<Mui.Grid item className={classes.timeline} style={{display: dragging === campaignCampaignMessage.id.toString() ? "none" : undefined}}>
								<Mui.Typography className={classes.TimelineIcon}>
									<FeatherIcon fontSize="inherit" >
										<Icons.ArrowDown/>
									</FeatherIcon>	
								</Mui.Typography>
							</Mui.Grid>
					</Mui.Grid>
					)}
				</Draggable>
			</>
		)
	}
}

export const Card = withMediaQuery("(max-width:600px)")(Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
));