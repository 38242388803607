import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { getPayload, RootState } from "redux/store";
import { Agent } from "model/agent";
import { Select } from "component/shared/select";
import { getAgentBoardsForAgent, getLanguages, getOffices } from "redux/selector";
import { MlsSelector } from "./mls-selector";
import { updateAgent } from "redux/slice/agents";
import { AgentBoard } from "model/agent-board";
import { createAgentBoard, deleteAgentBoard, updateAgentBoard } from "redux/slice/agent-boards";
import { SaveButton } from "component/shared/save-button";

interface Props extends OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
	{}

interface OwnProps {
	agent: Agent;
}

interface State {
	code: string;
	officeId: number;
	agentBoards: AgentBoard[];
	languageIds: number[];
	agentCode: string;
	hasOwnSite: boolean;
	redirectUrl: string;
	enableAgentLink: boolean;
	allowModifySubscribers: boolean;
	showOnSignupDropDown: boolean;
	sendEmailUpdatesToAgent: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		offices: getOffices(state),
		agentBoards: getAgentBoardsForAgent(state, ownProps.agent.id),
		languages: getLanguages(state),
		loading: (state.agents.loading || state.agentBoards.loading) || false,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{
			updateAgent,
			createAgentBoard,
			updateAgentBoard,
			deleteAgentBoard,
		},
		dispatch
	);
};

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			code: this.props.agent.code || "",
			officeId: this.props.agent.office?.id || 0,
			agentCode: this.props.agent.code || "",
			agentBoards: this.props.agentBoards,
			languageIds: this.props.agent.languageIds || [],
			hasOwnSite: this.props.agent.hasOwnSite,
			redirectUrl: this.props.agent.agentPageUrl || "",
			enableAgentLink: this.props.agent.enableAgentLink || false,
			allowModifySubscribers: this.props.agent.allowModifySubscribers || false,
			showOnSignupDropDown: this.props.agent.showOnSignupDropDown,
			sendEmailUpdatesToAgent: this.props.agent.sendEmailUpdatesToAgent || false,
		};
	}

	private async save() {
		const { agent, offices } = this.props;
		const { 
			code, 
			officeId, 
			sendEmailUpdatesToAgent, 
			agentBoards: selectedAgentBoards, 
			redirectUrl, 
			hasOwnSite, 
			allowModifySubscribers, 
			enableAgentLink, 
			showOnSignupDropDown 
		  } = this.state;
		  	const agentResource = getPayload(await this.props.updateAgent( 
			{ agent: 
				{
					...agent,
					code,
					office: offices.find((office) => office.id === officeId),
					hasOwnSite,
					redirectUrl,
					enableAgentLink,
					allowModifySubscribers,
					showOnSignupDropDown,
					sendEmailUpdatesToAgent
				}
			})
		);
		if(agentResource.id) {
			const agentId = agentResource.id;
			{
				const agentBoards = selectedAgentBoards.filter(agentBoard => agentBoard.id && !agentBoard.agentId);
				for(const agentBoard of agentBoards) {
					await this.props.deleteAgentBoard({agentBoard});					
				}
			}
			{
				const agentBoards = selectedAgentBoards.filter(agentBoard => agentBoard.id && agentBoard.agentCode);
				for(const agentBoard of agentBoards) {
					await this.props.updateAgentBoard({agentBoard, agentId});					
				}
			}
			{
				const agentBoards = selectedAgentBoards.filter(agentBoard => !agentBoard.id);
				for(const agentBoard of agentBoards) {
					await this.props.createAgentBoard({agentBoard, agentId});					
				}
			}
			this.setState({agentBoards: this.props.agentBoards});
		}
	}

	render() {
		const { agent, offices, loading, languages  } = this.props;
		const { 
			code, 
			officeId, 
			agentBoards, 
			languageIds, 
			redirectUrl, 
			hasOwnSite, 
			enableAgentLink, 
			allowModifySubscribers, 
			showOnSignupDropDown, 
			sendEmailUpdatesToAgent 
		} = this.state;
		return (
			<Mui.Grid container spacing={1}>
				<Mui.Grid item xs={12}>
					{offices.length > 0 && (
						<Select
							fullWidth
							valueExtractor={(office) => office && office.id}
							labelExtractor={(office) => office && office.name}
							margin="dense"
							onChange={(event) => {
								this.setState({officeId: event.target.value});
							}}
							options={offices}
							optionRenderer={(office) => (
								<Mui.ListItemText primary={office.name} key={office.id} />
							)}
							label="Office Member"
							value={officeId}
							variant="outlined"
						/>
					)}
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Mui.TextField
						fullWidth
						label="Agent Code For Display (optional)"
						margin="dense"
						onChange={(event) => {
							this.setState({agentCode: event.target.value})
						}}
						placeholder="Agent Code For Display (optional)"
						value={code}
						variant="outlined"
					/>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Select
						fullWidth
						valueExtractor={(lang) => lang && lang.id}
						labelExtractor={(lang) => lang && lang.name}
						margin="dense"
						multiple
						onChange={(languageIds) => {
							this.setState({languageIds: languageIds});
						}}
						options={languages.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : 0)}
						optionRenderer={(lang) => (
							<Mui.ListItemText primary={lang.name} key={lang.id} />
						)}
						label="Languages"
						value={languageIds}
						variant="outlined"
						placeholder={languages.filter(language => languageIds.includes(language.id)).map(language => language.name).join(', ')}
					/>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Mui.FormControlLabel
						control={
							<Mui.Switch 
								checked={enableAgentLink} 
								onChange={() => this.setState({enableAgentLink: !enableAgentLink})} 
								name="link" 
							/>
						}
						label="Enable Agent Link on Roster"
					/>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Mui.FormControlLabel
						control={
							<Mui.Switch 
								checked={hasOwnSite} 
								onChange={() => this.setState({hasOwnSite: !hasOwnSite})} 
								name="website" 
							/>
						}
						label="Link to agent's own website instead of Bio"
					/>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Mui.Collapse in={hasOwnSite}>
						<Mui.TextField
							fullWidth
							margin="dense"
							onChange={(event) =>
								this.setState({ redirectUrl: event.target.value })
							}
							label="Website URL"
							required
							value={redirectUrl}
							variant="outlined"
						/>
					</Mui.Collapse>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Mui.FormControlLabel
						control={
							<Mui.Switch 
								checked={allowModifySubscribers} 
								onChange={() => this.setState({allowModifySubscribers: !allowModifySubscribers})} 
								name="website" 
							/>
						}
						label="Allow Agent to Manage Leads Receiving Email Updates"
					/>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Mui.FormControlLabel
						control={
							<Mui.Switch 
								checked={showOnSignupDropDown} 
								onChange={() => this.setState({showOnSignupDropDown: !showOnSignupDropDown})} 
								name="website" 
							/>
						}
						label="Show on Signup Agent Selector"
					/>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Mui.FormControlLabel
						control={
							<Mui.Switch 
								checked={sendEmailUpdatesToAgent} 
								onChange={() => this.setState({sendEmailUpdatesToAgent: !sendEmailUpdatesToAgent})} 
								name="website" 
							/>
						}
						label="Receive a Copy of Email Updates Sent To Leads"
					/>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Mui.Typography>Agent MLS IDs</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<MlsSelector agent={agent} agentBoards={agentBoards} onChange={(agentBoards) => this.setState({agentBoards})}/>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<SaveButton 
						loading={loading}
						snackBar
						label={{
							primary: "Save",
							inProgress: "Saving...",
							completed: "Saved"
						}}
						onClick={() => this.save()}					
					/>
				</Mui.Grid>
			</Mui.Grid>
			
		);
	}
}

export const AgentSettingsTab = connect(mapStateToProps, mapDispatchToProps)(Component);