import { arrayToById, objectToById } from "redux/normalize";
import * as api from "api/api";
import { CampaignRoute, NewCampaignRoute } from "model/campaign-route";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loginReset } from "redux/slice/authentication";
import { CampaignRouteResource } from "api/resource/campaign-route";

export interface CampaignRoutesState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: CampaignRouteResource;
	};
};

const initialState: CampaignRoutesState = {
	byId: {},
};

const fetchCampaignRoutes = createAsyncThunk("campaignRoutes/fetchCampaignRoutes", async (payload, thunkAPI) => {
	thunkAPI.dispatch(campaignRoutesLoading());
	const result = api.getCampaignRoutes();
	result
		.then((campaignRoutes) => thunkAPI.dispatch(campaignRoutesLoaded(campaignRoutes)))
		.catch(error => thunkAPI.dispatch(campaignRoutesFailed(error)));
	return result;
});

const createCampaignRoute = createAsyncThunk("campaignRoutes/createCampaignRoute", async (payload: {campaignRoute: NewCampaignRoute}, thunkAPI) => {
	thunkAPI.dispatch(campaignRoutesLoading());
	const result = api.createCampaignRoute(payload.campaignRoute);
	result
		.then(campaignRoute => thunkAPI.dispatch(campaignRouteLoaded(campaignRoute)))
		.catch(error => thunkAPI.dispatch(campaignRoutesFailed(error)));
	return result;
});

const updateCampaignRoute = createAsyncThunk("campaignRoutes/updateCampaignRoute", async (payload: {campaignRoute: CampaignRoute}, thunkAPI) => {
	thunkAPI.dispatch(campaignRoutesLoading());
	const result = api.updateCampaignRoute(payload.campaignRoute);
	result
		.then(campaignRoute => thunkAPI.dispatch(campaignRouteLoaded(campaignRoute)))
		.catch(error => thunkAPI.dispatch(campaignRoutesFailed(error)));
	return result;
});

const deleteCampaignRoute = createAsyncThunk("campaignRoutes/deleteCampaignRoute", async (payload: {campaignRoute: CampaignRoute}, thunkAPI) => {
	const result = api.deleteCampaignRoute(payload.campaignRoute);
	result
		.then(campaignRoute => thunkAPI.dispatch(campaignRouteDeleted(campaignRoute)))
		.catch(error => thunkAPI.dispatch(campaignRoutesFailed(error)));
	return result;
});

export const campaignRoutesSlice = createSlice({
	name: "campaignRoutes",
	initialState,
	reducers: {
		campaignRoutesLoading: (state) => {
			state.loading = true;
		},
		campaignRoutesLoaded: (state, action: PayloadAction<CampaignRouteResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");;
		},
		campaignRouteLoaded: (state, action: PayloadAction<CampaignRouteResource>) => {
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...objectToById(action.payload, "id"),
			};
		},
		campaignRouteDeleted: (state, action: PayloadAction<CampaignRouteResource>) => {
			const {id} = action.payload;
			const result = {
				...state,
				loading: false,
				error: null,
				byId: {
					...state.byId,
				}
			};
			 delete result.byId[id];
			 return result;
		},
		campaignRoutesFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});

export const { campaignRouteDeleted, campaignRouteLoaded, campaignRoutesFailed, campaignRoutesLoaded, campaignRoutesLoading } = campaignRoutesSlice.actions;
export { fetchCampaignRoutes, createCampaignRoute, updateCampaignRoute, deleteCampaignRoute};
