import * as React from "react";
import * as env from "shared/env";
import { Eureka } from "./eureka";
// import { Kestrel } from "./kestrel";
import { Mock } from "./mock";
import { BaseProps } from "./types";

interface State {
}

interface Props extends BaseProps {
}

class Component extends React.Component<Props, State> {

	public render() {
		// const { user } = this.props;
		if(env.live) {
			// if(user.kestrelVersion.all) {
			// 	return <Kestrel {...this.props} />
			// } else {
				return <Eureka {...this.props} />
			// }
		}
		return <Mock {...this.props} />;
	}

}
export const ListingSearch = Component;
