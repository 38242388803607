import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";

interface OwnProps {
	enable?: boolean;
	size?: number;
}

interface Props extends OwnProps, Mui.WithStyles<typeof styles> {	
}

interface State {
	showIndicator: boolean;
}

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			showIndicator: false,
		};
	}

	componentDidMount() {
		if(this.props.enable) {
			this.setState({showIndicator: true});
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	enableIndicator = () => {
		this.setState({ showIndicator: true });
	};

	timer: NodeJS.Timer = setTimeout(this.enableIndicator, 1000);

	render() {
		const { size } = this.props;
		return this.state.showIndicator ? (
			<div className={this.props.classes.container}>
				<Mui.CircularProgress color="secondary" size={size ? size : undefined}/>
			</div>
		) : null;
	}
}

export const LoadingIndicator = Mui.withStyles(styles)(Component);
