import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Router from "react-router-dom";
import { urls } from "routes/urls";
import { logout } from "redux/slice/authentication";
import { getUserUnsafe } from "redux/selector";
import { adminModeSetView } from "redux/slice/admin-mode";
import { getAdminMode, getViewType } from "redux/selector";
import { UserAvatar } from "component/shared/user-avatar";
import { styles } from "./style";
import * as env from "shared/env";

const mapStateToProps = (state: RootState) => {
	return {
		user: getUserUnsafe(state),
		adminMode: getAdminMode(state),
		viewType: getViewType(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	logout,
	adminModeSetView,
}, dispatch);

interface Props extends ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles>
{
}

interface State {
	anchorEl: HTMLElement | null;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			anchorEl: null,
		};
	}

	public render() {
		const { classes, user, adminMode, viewType, adminModeSetView, history } = this.props;
		const { anchorEl } = this.state;
		if (!user) {
			return null;
		}
		return (
			<>
				<Mui.Button
					className={classes.dropdownButton}
					onClick={event => this.setState({ anchorEl: event.currentTarget })}
				>
					<UserAvatar user={user} />
					<Mui.Hidden xsDown>
						<div style={{ paddingLeft: 10 }}>
							<Mui.Typography noWrap className={classes.line1}>
								{user.name}
							</Mui.Typography>
							<Mui.Typography noWrap className={classes.line2}>
								{user.company}
							</Mui.Typography>
						</div>
						<FeatherIcon fontSize="small">
							<Icons.ChevronDown />
						</FeatherIcon>
					</Mui.Hidden>
				</Mui.Button>
				<Mui.Popper anchorEl={anchorEl} open={!!anchorEl} style={{ zIndex: 1200 }}>
					<Mui.ClickAwayListener
						onClickAway={() => this.setState({ anchorEl: null })}
					>
						<Mui.Paper>
							<Mui.MenuList>
								{(env.agentAdminDev && user.admin && user.type.agent) && (
									<Mui.MenuItem
										className="ihf-profile"
										onClick={() => {
											history.push(urls.settingsProfile);
											this.setState({ anchorEl: null });
										}}
									>
										<FeatherIcon>
											<Icons.User />
										</FeatherIcon>
										<Mui.Typography className={classes.menuItemText}>
											My Profile
										</Mui.Typography>
									</Mui.MenuItem>
								)}
								{(!env.agentAdminDev || (env.agentAdminDev && user.admin && !user.type.agent)) && (
									<Mui.MenuItem
										className="ihf-myaccount"
										onClick={() => {
											history.push(urls.settingsAccount);
											this.setState({ anchorEl: null });
										}}
									>
										<FeatherIcon>
											<Icons.UserPlus />
										</FeatherIcon>
										<Mui.Typography className={classes.menuItemText}>
											Update Account
										</Mui.Typography>
									</Mui.MenuItem>
								)}
								{((!env.agentAdminDev && user.admin && user.type.agent && user.permissions.modifyCredentials) || 
									(env.agentAdminDev && user.admin && !user.type.agent)) && (
									<Mui.MenuItem
										className="ihf-changepassword"
										onClick={() => {
											history.push(urls.settingsPassword);
											this.setState({ anchorEl: null });
										}}
									>
										<FeatherIcon>
											<Icons.Lock />
										</FeatherIcon>
										<Mui.Typography className={classes.menuItemText}>
											Change Password
										</Mui.Typography>
									</Mui.MenuItem>
								)}
								{user.reseller.direct && (
									<Mui.MenuItem
										className="ihf-supportcenter"
										onClick={() => {
											history.push(urls.supportCenter);
											this.setState({ anchorEl: null });
										}}
									>
										<FeatherIcon>
											<Icons.Database />
										</FeatherIcon>
										<Mui.Typography className={classes.menuItemText}>
											Support Center
										</Mui.Typography>
									</Mui.MenuItem>
								)}
								<Mui.Divider />
								{adminMode &&
									<Mui.MenuItem
										onClick={() => adminModeSetView(viewType.inverse)}
									>
										<FeatherIcon>
											<viewType.inverse.icon />
										</FeatherIcon>
										<Mui.Typography className={classes.menuItemText}>
											View as {viewType.inverse.label}
										</Mui.Typography>
									</Mui.MenuItem>
								}
								<Mui.MenuItem
									onClick={() => {
										this.logout();
										this.setState({ anchorEl: null });
									}}
								>
									<FeatherIcon>
										<Icons.LogOut />
									</FeatherIcon>
									<Mui.Typography className={classes.menuItemText}>
										Sign Out
									</Mui.Typography>
								</Mui.MenuItem>
							</Mui.MenuList>
						</Mui.Paper>
					</Mui.ClickAwayListener>
				</Mui.Popper>
			</>
		);
	}

	private logout() {
		this.props.logout();
	}

}

export const AccountMenu = Mui.withStyles(styles)(
	Router.withRouter(
		connect(mapStateToProps, mapDispatchToProps)(Component)
	)
);
