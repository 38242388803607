import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		errorMessage: {
			color: theme.palette.error.main,
			fontSize: "0.75rem",
			fontWeight: "bold",
		},
	})
};
