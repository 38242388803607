import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { getAgents, getAgentById } from "redux/selector";
import { setAgentValue } from "redux/slice/agents";
import Fuse from 'fuse.js';
import * as Icon from "react-feather";
import { Avatar } from "component/shared/avatar";
import { Agent } from "model/agent";

const mapStateToProps = (state: RootState) => {
  const agents = getAgents(state);
  const value = state.agents.value;
  const agent = getAgentById(state, value)
  return {
    agents,
    value,
    agent,
    loading: state.agents.loading
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setAgentValue,
    },
    dispatch
  );

interface State {
  searchResults: Agent[];
  anchorEl: any;
  searchQuery: string;
}

interface Props
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps>,
    Mui.WithStyles<typeof styles> {}

class Component extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchResults: this.props.agents,
      anchorEl: null,
      searchQuery: "",
    };
  }

  handleChange = (newValue: number) => {
    this.props.setAgentValue(newValue)
    localStorage.setItem("agentId", newValue.toString())
    this.setState({ anchorEl: null });
  };
  handleUnassign = () => {
    localStorage.setItem("agentId", "unassigned")
    this.setState({ anchorEl: null });
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { agents } = this.props;
    const searchQuery = e.target.value;

    if (!searchQuery) {
      this.setState({ searchResults: agents, searchQuery: "" });
    } else {
      const fuseOptions = {
        keys: ['name'],
        threshold: 0.3,
      };
      const fuse = new Fuse(agents, fuseOptions);
      const searchResults = fuse.search(searchQuery).map((result) => result.item);
      this.setState({ searchResults, searchQuery });
    }
  };

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { agents } = this.props;
    const { searchResults } = this.state;

    if (searchResults.length === 0) {
      this.setState({ searchResults: agents });
    }

    this.setState({ anchorEl: event.currentTarget });
  };
  
  render() {
    const { value, classes, agent, agents, loading } = this.props;
    const { searchResults, anchorEl, searchQuery } = this.state;

    const storedValue = localStorage.getItem("agentId");
    if (storedValue) {
      this.props.setAgentValue(parseFloat(storedValue));
    } else if (!storedValue) {
      if (value) {
        localStorage.setItem("agentId", value.toString());
      } else {
        localStorage.setItem("agentId", "0");
      }
    }

	const unassigned = storedValue === "unassigned"

    const highlightSearchQuery = (text: string, query: string) => {
      if (!query) return text;

      const regex = new RegExp(`(${escapeRegExp(query)})`, 'gi');
      return text.replace(regex, '<span style="color: #039BE5;">$1</span>');
    };

    const escapeRegExp = (string: string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    return (
      <Mui.FormControl className={classes.form}>
        <Mui.Button 
          variant="outlined"
          color="primary"
          onClick={this.handleClick}
          endIcon={anchorEl ? (<Icon.ChevronUp size={16} />) : (<Icon.ChevronDown size={16} />)}
          disabled={loading || !agents}
        >
          {agent ? (
            <Mui.Grid container wrap="nowrap" alignItems="center" className={classes.nameContainer}>
              <Mui.Grid item>
                <Avatar phrase={agent.label} src={agent.photoUrl} />
              </Mui.Grid>
              <Mui.Grid item>
                <Mui.Typography noWrap className={classes.agentName}>
                  {agent.name}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          ): 
          <>
		  	{unassigned ? <Icon.User className={classes.usersIcon} size={36}/> : <Icon.Users className={classes.usersIcon} size={36}/>}
            <Mui.Typography noWrap className={classes.agentName}>{unassigned ? "Unassigned" : "All Agents"}</Mui.Typography>
          </>
          }
        </Mui.Button>
        <Mui.Popover
          keepMounted
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => this.setState({anchorEl: null})}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          className={classes.popover}
        >
          <Mui.TextField 
            id="agent-search"
            onChange={this.handleSearch}
            placeholder="Search Agents"
            color="secondary"
            InputProps={{
              startAdornment: (
                <Icon.Search className={classes.searchIcon}/>
              ),
              style: { color: 'black' },
            }}
            fullWidth
            className={classes.textField}
          />
          <Mui.MenuItem 
            value={0}
            onClick={() => this.handleChange(0)}
          >
            <Icon.Users className={classes.usersIcon} size={36}/>
            <Mui.Typography className={classes.agentName}>
              All Agents
            </Mui.Typography>
          </Mui.MenuItem>
          {searchResults.map((agent, i) => (
            <Mui.MenuItem 
              key={i}
              value={agent.id} 
              onClick={() => {
                this.handleChange(agent.id)
              }}
              selected={agent.id === value}
            >
              <Avatar phrase={agent.label} src={agent.photoUrl} />
              <Mui.Typography className={classes.agentName}>
                <Mui.Grid dangerouslySetInnerHTML={{ __html: highlightSearchQuery(agent.name, searchQuery) }} />
              </Mui.Typography>
            </Mui.MenuItem>
          ))}
          <Mui.MenuItem 
		  	onClick={() => this.handleUnassign()}
		  >
            <Icon.User size={36} className={classes.usersIcon}/>
            <Mui.Typography className={classes.agentName}>
              Unassigned
            </Mui.Typography>
          </Mui.MenuItem>
        </Mui.Popover>
      </Mui.FormControl>
    );
  }
}

export const AgentSelector = Mui.withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Component)
);
