import React from "react";
import * as Mui from "@material-ui/core";
import { SelectMultipleAgentsDialog } from "../select-multiple-agents-dialog"
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RoundRobinRoute } from "model/round-robin-route";
import * as Router from "react-router-dom";
import { styles } from "./styles";
import * as Icons from "react-feather";
import { updateRoundRobinRoutes } from "redux/slice/round-robin-routes";
import { Avatar } from "component/shared/avatar";
import { FeatherIcon } from "component/shared/feather-icon";
import { DragDropContext, Droppable, Draggable, DropResult, DragUpdate } from "react-beautiful-dnd";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { DeleteDialog } from "../delete-dialog";
import { getUserUnsafe } from "redux/selector";
import { RoundRobinSetting } from "../settings/roundrobin-setting"
import { fetchRoundRobinRoutes } from "redux/slice/round-robin-routes"



const mapStateToProps = (state: RootState) => {
	const user = getUserUnsafe(state);
	return {
		user,
	};
};

interface Props	extends OwnProps,
ReturnType<typeof mapDispatchToProps>,
ReturnType<typeof mapStateToProps>,
Mui.WithStyles<typeof styles>,
Router.RouteComponentProps
{}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateRoundRobinRoutes,
	fetchRoundRobinRoutes,
}, dispatch);


interface OwnProps {
	roundRobinRoutes: RoundRobinRoute[];
}

interface State {
	dndOrder: number[];
	roundRobinRouteToDelete: RoundRobinRoute | undefined;
	deleteLeadDialogIsOpen: boolean;
	addAgentIsOpen: boolean
	roundRobinRoute: RoundRobinRoute | null
	dragging: string | null;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			dndOrder: [],
			roundRobinRouteToDelete: undefined,
			deleteLeadDialogIsOpen: false,
			addAgentIsOpen: false,
			roundRobinRoute:null,
			dragging: null,
		};
	}

	public componentDidMount() {
		if(this.props.roundRobinRoutes) {
			this.props.fetchRoundRobinRoutes();
			const dndOrder = this.setDndOrder();
			this.setState({dndOrder});
		}
	}

	public componentDidUpdate(prevProps: Props) {
		if(prevProps.roundRobinRoutes.length !== this.props.roundRobinRoutes.length) {
			this.props.fetchRoundRobinRoutes();
			const dndOrder = this.setDndOrder();
			this.setState({dndOrder});
		}
	}

	 private  save = async (roundRobinRoutes: RoundRobinRoute[]) => {
		if (await this.props.updateRoundRobinRoutes({ roundRobinRoutes })) {
			const dndOrder = this.setDndOrder();
			this.setState({dndOrder});
		}
	}


	private onDragStart = (result: DragUpdate ) => {
		this.setState({dragging: result.draggableId});
	}

	private setDndOrder = () : number[] => {
		const {roundRobinRoutes} = this.props;
		const dndOrder: number[] = []
		roundRobinRoutes.forEach((roundRobinRoute) => {
			dndOrder.push(roundRobinRoute.id)
		});
		return dndOrder;
	}

	private onDragEnd = (result: DropResult) => {
		const { roundRobinRoutes } = this.props;
		const { draggableId, source, destination } = result;
		const dropped = roundRobinRoutes.find((roundRobinRoute) => 
			roundRobinRoute.id === parseInt(draggableId));
		if (!dropped || !destination) {
			this.setState({dragging: null});
			return;
		}
		
		if ((destination?.droppableId === source.droppableId) && (destination.index === source.index)) {
			this.setState({dragging: null});
			return;
		}
		roundRobinRoutes.splice(source.index, 1);
		roundRobinRoutes.splice(destination.index, 0, {...dropped});			
		this.save(roundRobinRoutes)	
		this.setState({dragging: null});
	}


	public render() {
		const { roundRobinRoutes, classes, user } = this.props;
		const {
			addAgentIsOpen,
			roundRobinRouteToDelete,
		} = this.state;
		return (
			<>
				<SelectMultipleAgentsDialog
					open={addAgentIsOpen}
					onClose={() => this.setState({ addAgentIsOpen: false })}
					roundRobinRoutes = {roundRobinRoutes}
				/>
				{roundRobinRouteToDelete && (
					<DeleteDialog
						onClose={() => this.setState({
							roundRobinRouteToDelete: undefined,
						})}
						roundRobinRoute={roundRobinRouteToDelete}
						roundRobinRoutes={roundRobinRoutes}
					/>
				)}
				
				<Mui.Grid container item xs={12} md={12}>
					<Mui.Grid
						container
						item
						spacing={4}
						direction="row"
						justifyContent="space-between"
					>
						<Mui.Grid item xs={12}>
							<Mui.Grid container direction="column" spacing={10} >
								
								<Mui.Grid item>
									<Mui.Card>
										<Mui.CardHeader
											title={
												<Mui.Typography variant="h4">Round Robin Lead Routing</Mui.Typography>
												
											}
										/>
										<Mui.CardContent>
											<Mui.Grid container spacing={2} direction="column">
												<Mui.Grid item>
													<Mui.Typography>
														Create a list of agents for rotating lead assignment. 
														Agents can be added to the list more than once, for weighted distribution.
														If Geographic Routing is on, those rules will be applied before Round Robin.
													</Mui.Typography>
												</Mui.Grid>
											</Mui.Grid>
										</Mui.CardContent>
									</Mui.Card>	
								</Mui.Grid>
								<Mui.Grid item>
									<RoundRobinSetting/>
								</Mui.Grid>
								</Mui.Grid>
								{
								user?.roundRobinRouting && (
								<Mui.Grid item>

								<Mui.Grid item>

								<DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
									<Droppable droppableId="column-1">
										{provided => (
											<Mui.Grid container direction="column" wrap="nowrap" innerRef={provided.innerRef} {...provided.droppableProps}>
												{this.state.dndOrder.length > 0 && this.state.dndOrder.map((id, index) => {	
													const roundRobinRoute = roundRobinRoutes.find((roundRobinRoute) => roundRobinRoute.id === id);									
													return (
														<Mui.Grid item key={index}>
																{
																roundRobinRoute && (
																<Draggable draggableId={roundRobinRoute.id.toString()} index={index}>
																	{(provided) => (
																		<Mui.Grid item container direction="column" wrap={"nowrap"} {...provided.draggableProps} {...provided.dragHandleProps} innerRef={provided.innerRef}>
																			<Mui.Grid item>
																				<Mui.Card >
																					<Mui.CardHeader
																						
																						avatar={
																							<>
																								<DragIndicatorIcon style={{color: "gray"}}/>
																							</>								
																						}
																						
																						className={classes.header}
																						title={
																							'Agent '+(index + 1).toString()
																						}
																					/>
																					
																					<Mui.CardContent>
																							<Mui.Grid container direction="row" spacing={1} className={classes.container}>
																								<Mui.Grid item xs={12} sm={6} lg={1} xl={4}>
																									<Mui.Grid item>
																									<Avatar 
																										phrase={roundRobinRoute.agent.label} 
																										src={roundRobinRoute.agent.photoUrl} 
																									/>
																									</Mui.Grid>
																								</Mui.Grid>
																								<Mui.Grid item xs={12} sm={6} lg={7} xl={4}>
																									{roundRobinRoute.agent.label}
																								</Mui.Grid>
																								<Mui.Grid item xs={12} sm={12} lg={4} xl={4}>
																									<Mui.Tooltip title="Remove Rule">
																										<Mui.IconButton
																											onClick={() => this.setState({
																												roundRobinRouteToDelete: roundRobinRoute,
																											})}
																										>
																											<FeatherIcon>
																												<Icons.Trash />
																											</FeatherIcon>
																										</Mui.IconButton>
																									</Mui.Tooltip>
																								</Mui.Grid>
																							</Mui.Grid>
																					</Mui.CardContent>
																				</Mui.Card>
																			</Mui.Grid>
																			<Mui.Grid item className={classes.timeline} style={{display: this.state.dragging === roundRobinRoute?.id.toString() ? "none" : undefined}}>
																				<Mui.Typography className={classes.TimelineIcon}>
																					<FeatherIcon fontSize="inherit" >
																						<Icons.ArrowDown/>
																					</FeatherIcon>	
																				</Mui.Typography>
																			</Mui.Grid>
																	</Mui.Grid>
																	)}
																</Draggable>
																)
																}

														</Mui.Grid>
													)
												})}
												{provided.placeholder}
											</Mui.Grid>
										)}
									</Droppable>
								</DragDropContext>
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Button
										variant="contained"
										color="secondary"
										onClick={() => this.setState({ addAgentIsOpen: true})}
									>
										ADD AGENT
									</Mui.Button>
							</Mui.Grid>
						</Mui.Grid>
						)}
					</Mui.Grid>
				</Mui.Grid>
				</Mui.Grid>
			</>
		);
	}
}

export const RoundRobinRoutes = (Mui.withStyles(styles)(
	Router.withRouter(
		connect(mapStateToProps, mapDispatchToProps)(Component)
	)
));