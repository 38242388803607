import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import { styles } from "./style";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { AddRoute } from "./add-route";
import Paper from '@material-ui/core/Paper';
import { getCampaignRoutes } from "redux/selector";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { getActiveDripCampaigns } from "redux/selector";
import { urls } from "routes/urls";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { CampaignRoute } from "model/campaign-route";
import { deleteCampaignRoute } from "redux/slice/campaign-routes";
import { LeadSourceType } from "type/lead-source-type";

interface OwnProps {}

interface Props extends 
	OwnProps,
	PageProps,
	Router.RouteComponentProps ,
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
}

interface State {
	openDialog: boolean,
	campaignRoute?: CampaignRoute,
	edit?: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		campaignRoutes: getCampaignRoutes(state),
		campaigns: getActiveDripCampaigns(state),
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	deleteCampaignRoute
}, dispatch);

interface OwnProps {
	open: boolean;
	onClose: () => void;
}

class Component extends React.Component<Props, State> {

	public constructor (props: Props) {
		super(props);
		this.state = {
			openDialog: false,
		}
	}

	public getCampaignRoutesWithDefault() {
		let leadSources = this.props.campaignRoutes.filter((campaignRoute) => campaignRoute.leadSource);
		const defaultLeadSource = this.props.campaignRoutes.filter((campaignRoute) => !campaignRoute.leadSource);
		const result: CampaignRoute[] = [
			...leadSources,
			...defaultLeadSource, 
		];
		return result;
	}

	public render() {
		const { user, classes } = this.props;
		const { openDialog, campaignRoute, edit  } = this.state;
		const title = "Automation Rules";
		const campaignRoutes = this.getCampaignRoutesWithDefault();
		return (
			<DashboardLayout
				permitted={user && user.permissions.campaigns}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Send />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<AddRoute
					campaignRoute={campaignRoute}
					open={openDialog}
					edit={edit}
					onClose={() => {
						this.setState({
							openDialog: false,
							campaignRoute: undefined,
						});
					}}
				/>
				<Mui.Grid container direction="column" spacing={2}>
					<Mui.Grid item>
						<Mui.Button
							variant="contained"
							color="secondary"
							onClick={() => this.setState({
								openDialog: true,
								edit: false,
							})}
						>
							Add Rule
						</Mui.Button>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.TableContainer component={Paper}>
							<Mui.Table aria-label="Campaign Routes">
								<Mui.TableBody>
									{campaignRoutes.map(((campaignRoute, index) => {
										const knownLeadSource = LeadSourceType.getById(campaignRoute.leadSource ? campaignRoute.leadSource : "");
										const leadsourceLabel = knownLeadSource ? knownLeadSource.label : campaignRoute.leadSource;
										return (
											<Mui.TableRow key={index}>
												<Mui.TableCell align="left">
													{!campaignRoute.leadSource && (
														campaignRoutes.length === 1 ? (
															"Add all leads to the "
														) : (
															"Otherwise, add the lead to the "
														)
													)}
													{campaignRoute.leadSource && (
														`${(index + 1)}. If the source is "${leadsourceLabel}", add the lead to the `
													)}
													<RouterLinkWrapper className={classes.link} to={urls.campaign(campaignRoute.campaign)}>
														{campaignRoute.campaign.name}
													</RouterLinkWrapper>
													{" campaign."}
												</Mui.TableCell>
												<Mui.TableCell align="right">
													<Mui.IconButton
														onClick={() => {
															this.setState({
																campaignRoute: campaignRoute,
																openDialog: true,
																edit: true,
															});
														}}
													>
														<FeatherIcon>
															<Icons.Edit />
														</FeatherIcon>
													</Mui.IconButton>
													<Mui.IconButton
														onClick={() => {
															if(window.confirm("Delete Rule")) {
																this.props.deleteCampaignRoute({campaignRoute});
															}
														}}
													>
														<FeatherIcon>
															<Icons.Trash />
														</FeatherIcon>
													</Mui.IconButton>
												</Mui.TableCell>
											</Mui.TableRow>
										)
									}))}
								</Mui.TableBody>
							</Mui.Table>
						</Mui.TableContainer>
					</Mui.Grid>
				</Mui.Grid>
			</DashboardLayout>
		);
	}

}

export const CampaignRoutingPage = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);