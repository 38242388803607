import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import SyntaxHighlighter from "react-syntax-highlighter";
import { github as syntaxStyle } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { styles } from "./style";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MuiAlert from "@material-ui/lab/Alert";
import classNames from "classnames";

type Props = OwnProps
	& Mui.WithStyles<typeof styles>
;

interface OwnProps {
	code: string;
	language?: string;
	className?: string;
}

export interface State {
	snackBarMessage?: string;
}

class Component extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props)
		this.state= {
			snackBarMessage: "",
		}
	}

	private openSnackBar(message: string) {
		this.setState({snackBarMessage: undefined}, () => {
			setTimeout(() => {
				this.setState({snackBarMessage: message});	
			}, 250);
			
		});
	}
	public render() {
		const { classes, code, language, className } = this.props;
		const { snackBarMessage } = this.state;
		return (
			<>
				<Mui.Paper className={classNames(classes.block, className)}>
					<CopyToClipboard
						onCopy={() => this.openSnackBar("Embed code copied to clipboard")}
						text={code}
					>
						<Mui.Fab
							aria-label={"label"}
							color={"secondary"} 
							size="small" 
							variant="extended" 
							className={classes.codeBlockCopy}
						>
							Copy
							<FeatherIcon className={classes.codeBlockCopyIcon}> 
								<Icons.Copy  />
							</FeatherIcon>
						</Mui.Fab>
					</CopyToClipboard>
					<SyntaxHighlighter
						language={language}
						style={syntaxStyle}
						showLineNumbers
					>
						{code}
					</SyntaxHighlighter>
				</Mui.Paper>
				<Mui.Snackbar
					open={!!snackBarMessage}
					message={snackBarMessage}
					autoHideDuration={6000}
					onClose={() => this.setState({ snackBarMessage: undefined })}
					action={
						<Mui.IconButton
							size="small"
							aria-label="close"
							color="inherit"
							onClick={() => this.setState({ snackBarMessage: undefined })}
						>
							<Icons.X fontSize="small" />
						</Mui.IconButton>
					}
				>
					<MuiAlert severity="info">
						<Mui.Typography>{snackBarMessage}</Mui.Typography>
					</MuiAlert>
				</Mui.Snackbar>
			</>
		);
	}
}

export const CodeBlock = Mui.withStyles(styles)((Component));