import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { NewRoundRobinRoute } from "model/round-robin-route";
import { arrayToById } from "redux/normalize";
import { RoundRobinRouteResource } from "api/resource/round-robin-route";

export interface RoundRobinRoutesState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: RoundRobinRouteState;
	};
};

export interface RoundRobinRouteState {
	id: number;
	agentId: number;
	createdOn: string;
};

const initialState: RoundRobinRoutesState = {
	byId: {},
};

const fetchRoundRobinRoutes = createAsyncThunk("roundRobinRoutes/fetchRoundRobinRoutes", async (payload, thunkAPI) => {
	thunkAPI.dispatch(roundRobinRoutesLoading());
	const result = api.getRoundRobinRoutes();
	console.log(result)
	result
		.then((roundRobinRoutes) => thunkAPI.dispatch(roundRobinRoutesLoaded(roundRobinRoutes)))
		.catch(error => thunkAPI.dispatch(roundRobinRoutesFailed(error)));
	return result;
});

const updateRoundRobinRoutes = createAsyncThunk("roundRobinRoutes/updateRoundRobinRoutes", async (payload: {roundRobinRoutes: NewRoundRobinRoute[]}, thunkAPI) => {
	thunkAPI.dispatch(roundRobinRoutesLoading());
	const result = api.updateRoundRobinRoutes(payload.roundRobinRoutes);
	result
		.then(roundRobinRoutes => thunkAPI.dispatch(roundRobinRoutesLoaded(roundRobinRoutes)))
		.catch(error => thunkAPI.dispatch(roundRobinRoutesFailed(error)));
	return result;
});

export const roundRobinRouteSlice = createSlice({
  name: "roundRobinRoutes",
  initialState,
  reducers: {
	roundRobinRoutesLoading: (state) => {
		state.loading = true;
	},
	roundRobinRoutesLoaded: (state, action: PayloadAction<RoundRobinRouteResource[]>) => {
		console.log(action)
		state.loading = false;
		state.error = null;
		state.byId = arrayToById(action.payload, "id");
	},
	roundRobinRoutesFailed: (state, action: PayloadAction<any>) => {
		state.loading = false;
		state.error = action.payload.message;
	},
	default:() => {
		return;
	}
  }, 
  extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});

const { roundRobinRoutesLoaded, roundRobinRoutesFailed, roundRobinRoutesLoading } = roundRobinRouteSlice.actions;
export { fetchRoundRobinRoutes, updateRoundRobinRoutes };



