import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { getActiveLeads, getActiveLeadsByAgentId } from "redux/selector";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { CallsLogged } from "./calls-logged";
import { CallsToMake } from "./calls-to-make";
import { SubscribersAdded } from "./subscribers-added";
import { SubscribersToNurture } from "./subscribers-to-nurture";
import { LeadsUpdated } from "./leads-updated";
import { LeadsToUpdate } from "./leads-to-update";

interface Props
	extends Mui.WithStyles<typeof styles>,
		ReturnType<typeof mapStateToProps> {}

const mapStateToProps = (state: RootState) => {
	const agentId = state.agents.value;
	const leads = getActiveLeads(state);
	const leadsByAgent = getActiveLeadsByAgentId(state, agentId);
	const leadsByUnassigned = leads.filter(lead => !lead.agent);
	const unassigned = localStorage.getItem("agentId") === "unassigned";
	return {
		loading: state.leads.loading,
		leads: !agentId ? (!unassigned ? leads : leadsByUnassigned) : leadsByAgent
	};
};

class Component extends React.Component<Props> {
	private renderHeader = (label: string) => {
		return (
			<Mui.Typography
				align="center"
				variant="h3"
				className={this.props.classes.header}
			>
				{label}
			</Mui.Typography>
		);
	};

	public render() {
		const { leads, loading, classes } = this.props;
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Mui.Typography variant="h3">Action Center</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Card>
						<Mui.CardContent>
								{!leads.length && !loading ? (
									<Mui.Grid item>
										<Mui.Typography align="center">Start adding leads to take action.</Mui.Typography>
									</Mui.Grid>
								) : (
									<Mui.Grid container direction="row">
										<Mui.Hidden only="md">
											<Mui.Grid
												className={classes.left}
												container
												item
												xs={6}
												direction="column"
												justifyContent="center"
												alignContent="center"
											>
												<Mui.Grid container item justifyContent="center" className={classes.row}>
													{this.renderHeader("Past 7 Days")}
												</Mui.Grid>
												<CallsLogged />
												<SubscribersAdded />
												<LeadsUpdated />
											</Mui.Grid>
										</Mui.Hidden>
										<Mui.Grid
											className={classes.right}
											container
											item
											xs={6}
											md={12}
											lg={6}
											direction="column"
											justifyContent="center"
											alignContent="center"
										>
											<Mui.Grid container item justifyContent="center" className={classes.row}>
												{this.renderHeader("Today")}
											</Mui.Grid>
											<CallsToMake />
											<SubscribersToNurture />
											<LeadsToUpdate />
										</Mui.Grid>
									</Mui.Grid>
								)}
						</Mui.CardContent>
					</Mui.Card>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const ActionCenter = Mui.withStyles(styles)(
	connect(mapStateToProps)(Component)
);
