import React from "react";
import * as Mui from "@material-ui/core";
import { Chart } from "./chart";
import { fetchAggregateStats } from "redux/slice/aggregate-stats";
import { getChronologicalAggregateStats, getLatestExtrapolatedAggregateStat, getLatestAggregateStat } from "redux/selector";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { LoadingIndicator } from "component/shared/loading-indicator";

const mapStateToProps = (state: RootState) => {
	return {
		aggregateStats: getChronologicalAggregateStats(state),
		lastStat: getLatestAggregateStat(state),
		extrapolatedStat: getLatestExtrapolatedAggregateStat(state),
		loading: state.aggregateStats.loading,
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	fetchAggregateStats
}, dispatch)

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
}

interface State {
	tabIndex: number;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			tabIndex: 0,
		};
	}

	public componentDidMount() {
		this.props.fetchAggregateStats();
	}

	public render() {
		const { extrapolatedStat, aggregateStats, lastStat, loading } = this.props;
		const { tabIndex } = this.state;
		let type;
		if(tabIndex === 0) {
			type = "leadActions";
		} else if (tabIndex === 1) {
			type = "viewedListings";
		} else {
			type = "siteSearches";
		}
		
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item xs>
					<Mui.Typography variant="h3">Lead Metric Comparisons - Past 12 Months</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item xs>
					<Mui.Card>
						{loading ? (
							<Mui.CardContent>
								<LoadingIndicator />
							</Mui.CardContent>
						) : (
							<>
								{(!aggregateStats.length || !extrapolatedStat || !lastStat) ? (
									<Mui.CardContent>
										<Mui.Grid container justifyContent="center">
											<Mui.Grid item>
												<Mui.Typography>Not enough data to generate graph.</Mui.Typography>
											</Mui.Grid>
										</Mui.Grid>
									</Mui.CardContent>
								) : (
									<>
										<Mui.CardContent>
											<Mui.Tabs
												value={tabIndex}
												onChange={(event, tabIndex) => this.setState({ tabIndex })}
												indicatorColor="secondary"
												textColor="secondary"
												variant="fullWidth"
											>
												<Mui.Tab label="Lead Actions" />
												<Mui.Tab label="Viewed Listings" />
												<Mui.Tab label="Site Searches" />
											</Mui.Tabs>
										</Mui.CardContent>
										<Mui.CardContent style={{height: 350}}>
											<Chart 
												aggregateStats={aggregateStats}
												extrapolatedStat={extrapolatedStat}
												lastStat={lastStat}
												type={type}
											/>
										</Mui.CardContent>
									</>
								)}
							</>
						)}
					</Mui.Card>
				</Mui.Grid>
			</Mui.Grid>
		);
	}

}

export const MonthlyStats = connect(mapStateToProps, mapDispatchToProps)(Component);