import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { Lead } from "model/lead";
import { CustomAutocomplete } from "component/shared/auto-complete";
import { styles } from "./styles";
import { RootState } from "redux/store";
import { getLeads } from "redux/selector";
import { getCampaignsSettings } from "redux/selector";
import { updateCampaignsSettings } from "redux/slice/campaigns-settings";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { CampaignsSettings } from "model/campaigns-settings";
import { LoadingIndicator } from "component/shared/loading-indicator";

interface Props extends PageProps,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
}

const mapStateToProps = (state: RootState) => {
	const leads = getLeads(state);
	const campaignsSettings = getCampaignsSettings(state);
	return {
		leads,
		campaignsSettings,
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateCampaignsSettings,
}, dispatch);

interface State {
	edited: boolean,
	address: string,
	leadId: number,
	input: string,
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			edited: false,
			address: "",
			leadId: 0,
			input: "",
		};
	}

	public componentDidMount() {
		this.update();
	}

	public componentDidUpdate(prevProps: Props) {
		if(prevProps.campaignsSettings !== this.props.campaignsSettings) {
			this.update();
		}
	}

	private update() {
		if (this.props.campaignsSettings) {
			this.setState({
				address: this.props.campaignsSettings.dynamicContentAddress || "",
				leadId: this.props.campaignsSettings.lead ? this.props.campaignsSettings.lead.id : 0,
				input: this.getLeadDisplay(this.props.campaignsSettings.lead),
			})
		};
	}

	private getLeadDisplay(lead: Lead | undefined) {
		let displayText: string = "";
		if (lead) {
			if (!lead.name) {
				displayText = lead.label;
			} else {
				displayText = lead.email + " (" + lead.name + ")"
			}
		}
		return displayText;
	}

	private handleSave() {
		const campaignsSettings: CampaignsSettings = {
			dynamicContentAddress: this.state.address,
			lead: this.getSelectedlead(),
		}
		this.props.updateCampaignsSettings({campaignsSettings});
		this.setState({edited: false});
	}

	private getSelectedlead() {
		if (this.state.leadId) {
			return this.props.leads.find((lead) => lead.id === this.state.leadId)
		}
	}

	private get options() {
		let { leads } = this.props;
		const options = leads.map(lead => ({
			id: lead.id,
			email: lead.email,
			label: this.getLeadDisplay(lead),
		}));
		return options;
	}

	public render() {
		const { user, classes } = this.props;
		const { address, input } = this.state;
		const title = "Campaign Settings";
		return (
			<DashboardLayout
				permitted={user && user.permissions.campaigns}
				title={title}
				fullScreen={false}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Send />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<Mui.Grid container direction="column" spacing={4}>
					{!this.props.campaignsSettings ? (
						<Mui.Grid item className={classes.loading}>
							<LoadingIndicator enable={true} />
						</Mui.Grid>
					) : (
						<>
							<Mui.Grid item>
								<Mui.Card>
									<Mui.CardContent>
										<Mui.Grid container direction="column" spacing={2}>
											<Mui.Grid item>
												<Mui.TextField
													id="address"
													label="Smart Content Default Address"
													placeholder="Enter Address"
													InputLabelProps={{ shrink: true }}
													value={address}
													onChange={event => this.setState({
														address: event.target.value,
														edited: true,
													})}
													fullWidth
													margin="dense"
													variant="outlined"
												/>
											</Mui.Grid>
											<Mui.Grid item>
												<CustomAutocomplete
													options={this.options}
													textInputHeight={true}
													label="Select an email for sending test messages"
													optionLabelExtractor={(option) => option.label}
													fullWidth
													margin="dense"
													placeholder="Search for a lead here"
													onInputChange={(event, value, reason) => {
													this.setState({
															input: value,
														});
													}}
													onChange={(event, option) => {
														if(option && option.label) {
															this.setState({ 
																leadId: option.id as number, 
																input: option.label,
																edited: true,
															});
														}
													}}
													inputValue={input}
												/>
											</Mui.Grid>
										</Mui.Grid>
									</Mui.CardContent>
								</Mui.Card>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Button
									disabled={!this.state.edited}
									variant="contained"
									color="secondary"
									onClick={() => this.handleSave()}
								>
									{this.state.edited &&
										<>Save</>
									}
									{!this.state.edited &&
										<>Saved</>
									}
								</Mui.Button>
							</Mui.Grid>
						</>
						)}
					
					
				</Mui.Grid>
			</DashboardLayout>
		);
	}

}

export const CampaignSettingsPage = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);