import React from "react";
import * as Mui from "@material-ui/core";
import { SelectAgentDialog } from "../select-agent-dialog"
import { SelectAgentDialogEdit } from "../select-agent-dialog -edit"
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { GeographicRoute as ModelGeographicRoute } from "model/geographic-route";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { Table } from "component/shared/table";
import { Avatar } from "component/shared/avatar";
import { GeographicSetting } from "../settings/geographic-setting"
import { getUserUnsafe } from "redux/selector";
import { FeatherIcon } from "component/shared/feather-icon";
import { GeogrpahicDeleteDialog } from "../geographic-delete-dialog";
import Chip from '@material-ui/core/Chip';

interface Props	extends OwnProps,
ReturnType<typeof mapDispatchToProps>,
ReturnType<typeof mapStateToProps>,
Router.RouteComponentProps
{}

const mapStateToProps = (state: RootState) => {
	const user = getUserUnsafe(state);
	return {
		user,
	};
};



const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	
	
}, dispatch);

interface OwnProps {
	geographicRoutes: ModelGeographicRoute[];
}

interface State {
	geographicRouteToDelete: ModelGeographicRoute | null;
	geographicRouteToEdit: ModelGeographicRoute | null;
	addAgentIsOpen: boolean
	editAgentIsOpen: boolean
	goegraphicRoute: ModelGeographicRoute | null
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			geographicRouteToDelete: null,
			geographicRouteToEdit: null,
			addAgentIsOpen: false,
			editAgentIsOpen: false,
			goegraphicRoute:null
		};
	}

	

	public render() {
		const { geographicRoutes, user } = this.props;
		const {
			addAgentIsOpen,
			editAgentIsOpen,
			goegraphicRoute,
			geographicRouteToDelete,
			geographicRouteToEdit
		} = this.state;

		const postalCodes = geographicRoutes.reduce(function (r, a) {
			let index = a.agent?.id ? a.agent.id : 0 
			const {
				postalCode,
			  } = a;
			
			  r[index] = [...r[index] || [], {
				postalCode
			  }];

			return r;
		}, Object.create(null))
		const leadRoutes = Object.keys(postalCodes).map((index) => {
			 let leatRoute : any = {}
			 leatRoute.postalCode = Array(postalCodes[parseInt(index)])
			 leatRoute.routes =  geographicRoutes.filter((el)=>{
				return el.agent?.id === parseInt(index)
			})
			 return leatRoute
		})
		return (
			<>
				{geographicRouteToDelete && (
					<GeogrpahicDeleteDialog
						onClose={() => this.setState({
							geographicRouteToDelete: null,
						})}
						geographicRoute={geographicRouteToDelete}
					/>
				)}
			
				{
				user?.geographicRouting && (
				<Mui.Grid container item xs={12} md={12}>
					<Mui.Grid

						container
						item
						spacing={4}
						direction="row"
						justifyContent="space-between"
					>
						<Mui.Grid item xs={12}>
							<Mui.Grid container direction="column" spacing={10} >
								<Mui.Grid item>
									<Mui.Card>
										<Mui.CardHeader
											title={
												<Mui.Typography variant="h4">Geographic Lead Routing</Mui.Typography>
												
											}
										/>
											<Mui.CardContent>
												<Mui.Grid container spacing={2} direction="column">
													<Mui.Grid item>
														<Mui.Typography>
															Route leads geographically by assigning postal codes to specific agents. 
															Postal codes cannot be shared between agents.
														</Mui.Typography>
													</Mui.Grid>
												</Mui.Grid>
											</Mui.CardContent>
									</Mui.Card>	
								</Mui.Grid>
								<Mui.Grid item>
									<GeographicSetting/>
								</Mui.Grid>
								
								<Mui.Grid item>								
						<Mui.Typography>{ leadRoutes.length > 0 ? 'Geographic Routing Rules' : '' }</Mui.Typography>
						<Table
							items={leadRoutes}
							columns={[
								{
									id: "avatar",
									width: 70,
									value: (geographicRouting) => {
										return (
											<div>
												<Avatar 
													phrase={geographicRouting.routes[0].agent.label ? geographicRouting.routes[0].agent.label : '' } 
													src={geographicRouting.routes[0].agent.photoUrl} 
											    />
											</div>
										);
									},
								},
								{
									id: "label",
									value: (geographicRouting) => {
										return (
											<Mui.Typography>{geographicRouting.routes[0].agent.label ? geographicRouting.routes[0].agent.label : '' } </Mui.Typography>
										);
									},
								},
								{
									id: "postlcodes",
									width: 670,
									value: (geographicRouting) => {
										return (
											Object.keys(geographicRouting.postalCode[0]).map((code) => (
												<Chip
													variant="outlined"
													label={geographicRouting.postalCode[0][parseInt(code)].postalCode}
													onDelete={()=> this.setState({
														geographicRouteToDelete: geographicRouting.routes[0],
													})}
												/>
											)) 
										);
									},
								},
								{
									id: "edit",
									showOnHover: true,
									width: 170,
									value: (geographicRouting) => {
										return (
											<Mui.IconButton
												onClick={() => this.setState({
													geographicRouteToEdit: geographicRouting,
													editAgentIsOpen: true 
												})}
													>
											<FeatherIcon>
												<Icons.Edit />
											</FeatherIcon>
										</Mui.IconButton>
										);
									},
								},
								{
									id: "delete",
									showOnHover: true,
									width: 170,
									value: (geographicRouting) => {
										return (
											<Mui.Tooltip title="Remove Rule">
											<Mui.IconButton
												onClick={() => this.setState({
													geographicRouteToDelete: geographicRouting,
												})}
											>
												<FeatherIcon>
													<Icons.Trash />
												</FeatherIcon>
											</Mui.IconButton>
										</Mui.Tooltip>
										);
									},
								}
							]}
						 />
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Button
										variant="contained"
										color="secondary"
										onClick={() => this.setState({ addAgentIsOpen: true})}
										>
											ADD RULE
									</Mui.Button>
								</Mui.Grid>
								<Mui.Grid item>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
						<SelectAgentDialog
							open={addAgentIsOpen}
							onClose={() => this.setState({ addAgentIsOpen: false })}
							geographicRoute = {goegraphicRoute}
						/>
						{
						geographicRouteToEdit && (
							<SelectAgentDialogEdit
							open={editAgentIsOpen}
							onClose={() => this.setState({ editAgentIsOpen: false })}
							geographicRoute = {geographicRouteToEdit}
							/>
							)
						}
						
					</Mui.Grid>
				</Mui.Grid>
				)
				}


			</>
		);
	}
}

export const GeographicRoutes = (
	Router.withRouter(
		connect(mapStateToProps, mapDispatchToProps)(Component)
	)
);