import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { JavaIframe } from "component/shared/iframe/java";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import * as env from "shared/env";
import { bindActionCreators, Dispatch } from 'redux';
import { getMarketById } from "redux/selector";
import { Market } from "model/market";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { FullPageCard } from "component/shared/full-page-card";
import { MarketEditCriteria } from "./criteria";
import { MarketEditSettings } from "./settings";
import { MarketEditSubscriptions } from "./subscriptions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getMarketListingByMarketId } from "redux/selector";
import { MarketTab as Tab } from "type/market-tab";

const mapStateToProps = (
	state: RootState,
	ownProps: Router.RouteComponentProps<RouteParams>
) => {
	const marketId = getId(ownProps);
	return {
		market: getMarketById(state, marketId) as Market,
		marketListings: getMarketListingByMarketId(state, marketId),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

interface Props extends
	PageProps,
	Router.RouteComponentProps<RouteParams>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
}

interface RouteParams {
	id: string;
	tab?: Tab;
}

const getId = (props: Router.RouteComponentProps<RouteParams>) => {
	return parseInt(props.match.params.id);
}

const getTab = (props: Router.RouteComponentProps<RouteParams>) => {
	const tab: Tab | undefined = props.match.params.tab;
	switch (tab) {
		case "search":
			return 0;
		case "settings":
			return 1;
		case "subscribers":
			return 2;
		default:
			return 0;
	}
}

interface State {
	tab: number;
	copiedId: string;
}


class Component extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			tab: 0,
			copiedId: "",
		}
	}

	private displayCriteriaPage() {
		this.setState({ tab: 0 })
	}
	private displaySettingsTab() {
		this.setState({ tab: 1 })
	}
	private displaySubsPage() {
		this.setState({ tab: 2 })
	}

	componentDidMount() {
		this.setState({tab: getTab(this.props)});
	}

	public render() {
		const { user, market, marketListings } = this.props;
		const { tab, copiedId } = this.state;
		const id = getId(this.props);
		const title = "Edit Market";

		return (
			<DashboardLayout
				permitted={user && user.permissions.markets}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.MapPin />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
				iframe={!env.marketsDev ? <JavaIframe url={`/hotSheet/${id}`} /> : null}
			>
				{env.marketsDev && (
					<>
						{market ? (
							<>
								<Mui.Grid container>
									<Mui.Grid item>
										<Mui.Typography variant="h2">{market.name}</Mui.Typography>
									</Mui.Grid>
									<Mui.Grid item>
										<CopyToClipboard 
											text={`${market.id}`}
											onCopy={(id) => this.setState({copiedId: id})}
											>
											<Mui.Tooltip title={copiedId !== `${market.id}` ? `Copy ID` : "Copied!"}>
												<Mui.Button variant="text" size="large" style={{borderRadius: 20}}>
													{market.id}
												</Mui.Button>								
											</Mui.Tooltip>
										</CopyToClipboard>
									</Mui.Grid>
								</Mui.Grid>
								<Mui.Tabs
									value={tab}
									textColor="secondary"
									indicatorColor="secondary"
								>
									<Mui.Tab label="Search Criteria" onClick={() => this.displayCriteriaPage()} />
									<Mui.Tab label="Settings" onClick={() => this.displaySettingsTab()} />
									{market.criteria && !market.criteria.find((criterion) => criterion.name === "ids") && 
										<Mui.Tab label="Subscribers" onClick={() => this.displaySubsPage()} />
									}
								</Mui.Tabs>
								<FullPageCard>
									{tab === 0 && (
										<MarketEditCriteria market={market} marketListings={[...marketListings]}/>
									)}
									{tab === 1 && (
										<MarketEditSettings user={user} market={market} />
									)}
									{tab === 2 && market.marketReportSubscriptions && (
										<MarketEditSubscriptions market={market} />
									)}
								</FullPageCard>
							</>
						) : <LoadingIndicator />}
					</>
				)}
			</DashboardLayout>
		);
	}

}

export const MarketPage = (
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
