import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		dialog: {
			[theme.breakpoints.down("xs")]: {
				width: "100%",
			},
			width: 420,
		},
		sectionTitle: {
			display: "flex", 
			alignItems: "center", 
			fontWeight: "bold", 
			marginTop: 10, 
			marginBottom: 10
		},
		edit: {
			minWidth: 10,
		}
	})
}
