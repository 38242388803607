import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import * as kestrelEmbedCode from "shared/kestrel-embed-code";
import * as yup from "yup";
import { validate } from "shared/yup";
import { getCities } from "redux/selector";
import { PropertyType } from "type/property-type";
import { WidgetConfigLayout } from "../widget-config-layout";
import { getUser } from "redux/selector";
import { ListingSortType } from "type/listing-sort";

const mapStateToProps = (state: RootState) => {
	return {
		user: getUser(state),
		cities: getCities(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
}

interface State {
	propertyTypeId: string;
	cityId?: number;
	beds?: number;
	baths?: number;
	minPrice?: number;
	maxPrice?: number;
	status: string;
	sort: string;
	resultsPerPage: number;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props) 
		this.state = {
			propertyTypeId: "",
			status: "active",
			sort: "",
			resultsPerPage: 15,
		}
	}

	private validationSchema = {
		cityId: yup
			.string()
			.required("City is required."),
		status: yup
			.string()
			.required("Status is required."),
		sort: yup
			.string()
			.required("Sort is required."),
		resultsPerPage: yup
			.number()
			.required("Results Per Page is required.")
			.typeError("Results Per Page is required.")
			.min(1, "Results Per Page is required.")
        	.max(50, "Max 50 results per page"),
	};

	public render() {
		const { user, cities } = this.props;
		const { cityId, propertyTypeId, resultsPerPage, sort, status, beds, baths, minPrice, maxPrice } = this.state;
		const errors = validate(this.validationSchema, this.state);
		const embedCode = kestrelEmbedCode.listingsWidget(
			user.websitePlatform,
			cityId ? cityId : 0,
			propertyTypeId,
			status,
			sort,
			resultsPerPage,
			beds,
			baths,
			minPrice,
			maxPrice,
		);
		return (
			<WidgetConfigLayout user={user} embedCode={!errors ? embedCode : undefined}>
				<Mui.Grid container spacing={2} direction="row">
					<Mui.Grid item xs={12}>
						<Mui.Typography variant="h4">
							Widget Options
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item xs={12} md={6}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								select
								required
								fullWidth
								variant="outlined"
								value={propertyTypeId}
								onChange={(event) => this.setState({propertyTypeId: event.target.value})}
								label="Property Type"
							>
								{PropertyType.activeValues().map((value, index) => 
									<Mui.MenuItem key={index} value={value.id}>{value.label}</Mui.MenuItem>)
								}	
								
							</Mui.TextField>
						</Mui.FormControl>
					</Mui.Grid>
					<Mui.Grid item xs={12} md={6}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								select
								required
								fullWidth
								variant="outlined"
								value={cityId}
								onChange={(event) => this.setState({cityId: parseInt(event.target.value)})}
								label="City"
							>
								{cities.map((value, index) => 
									<Mui.MenuItem key={index} value={value.id}>{value.name}</Mui.MenuItem>)
								}	
								
							</Mui.TextField>
						</Mui.FormControl>
					</Mui.Grid>
					<Mui.Grid item xs={12} md={3}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								type="number"
								variant="outlined"
								value={beds}
								onChange={(event) => this.setState({beds: parseInt(event.target.value)})}
								InputProps={{ inputProps: { min: 1 } }}
								label="Beds"
								helperText={errors && errors.resultsPerPage}
							/>
						</Mui.FormControl>
					</Mui.Grid>
					<Mui.Grid item xs={12} md={3}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								type="number"
								variant="outlined"
								value={baths}
								onChange={(event) => this.setState({baths: parseInt(event.target.value)})}
								InputProps={{inputProps: { min: 1 },								}}
								label="Baths"
								helperText={errors && errors.resultsPerPage}
							/>
						</Mui.FormControl>
					</Mui.Grid>
					<Mui.Grid item xs={12} md={3}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								type="number"
								variant="outlined"
								value={minPrice}
								onChange={(event) => this.setState({minPrice: parseInt(event.target.value)})}
								InputProps={{ 
									inputProps: { min: 1 }, 
									startAdornment: <Mui.InputAdornment position="start">$</Mui.InputAdornment> 
								}}
								label="Min Price"
								helperText={errors && errors.resultsPerPage}
							/>
						</Mui.FormControl>
					</Mui.Grid>
					<Mui.Grid item xs={12} md={3}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								type="number"
								variant="outlined"
								value={maxPrice}
								onChange={(event) => this.setState({maxPrice: parseInt(event.target.value)})}
								InputProps={{ 
									inputProps: { min: 1 }, 
									startAdornment: <Mui.InputAdornment position="start">$</Mui.InputAdornment> 
								}}
								label="Max Price"
								helperText={errors && errors.resultsPerPage}
							/>
						</Mui.FormControl>
					</Mui.Grid>
					<Mui.Grid item xs={12} md={4}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								select
								fullWidth
								variant="outlined"
								value={status}
								onChange={(event) => this.setState({status: event.target.value})}
								label="Status"
							>
								<Mui.MenuItem value={"active"}>Active</Mui.MenuItem>
								<Mui.MenuItem value={"sold"}>Sold</Mui.MenuItem>
							</Mui.TextField>
						</Mui.FormControl>
					</Mui.Grid>
					<Mui.Grid item xs={12} md={5}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								select
								fullWidth
								required
								variant="outlined"
								value={sort}
								onChange={(event) => this.setState({sort: event.target.value})}
								label="Sort"
							>
								{ListingSortType.values().map((value, index) => 
									<Mui.MenuItem key={index} value={value.id}>{value.label}</Mui.MenuItem>)
								}	
							</Mui.TextField>
						</Mui.FormControl>
					</Mui.Grid>
					<Mui.Grid item xs={12} md={3}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								required
								type="number"
								variant="outlined"
								value={resultsPerPage}
								onChange={(event) => this.setState({resultsPerPage: parseInt(event.target.value)})}
								InputProps={{ inputProps: { min: 1, max: 50 } }}
								label="Results Per Page"
								helperText={errors && errors.resultsPerPage}
							/>
						</Mui.FormControl>
					</Mui.Grid>
				</Mui.Grid>
			</WidgetConfigLayout>			
		);
	}
}

export const ListingsConfig = (
	connect(mapStateToProps, mapDispatchToProps)(Component)
);