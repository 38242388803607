import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { arrayToById } from "redux/normalize";
import { CityResource } from "api/resource/city";

export interface CitiesState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: CityResource;
	};
};

const initialState: CitiesState = {
	byId: {},
}

const fetchCities = createAsyncThunk("cities/fetchCities", async (payload, thunkAPI) => {
	thunkAPI.dispatch(citiesLoading());
	const result = api.getCities();
	result
		.then((cities) => thunkAPI.dispatch(citiesLoaded(cities)))
		.catch(error => thunkAPI.dispatch(citiesFailed(error)));
	return result;
});

export const citiesSlice = createSlice({
	name: "cities",
	initialState,
	reducers: {
		citiesLoading: (state) => {
			state.loading = true;
		},
		citiesLoaded: (state, action: PayloadAction<CityResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
		},
		citiesFailed: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});

export const {citiesFailed, citiesLoaded, citiesLoading} = citiesSlice.actions;
export { fetchCities };