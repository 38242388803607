import React from "react";
import * as Mui from "@material-ui/core";
import { SearchDialog } from "../../search-dialog";
import { styles } from "./style";
import { Lead } from "model/lead";
import { RootState } from "redux/store";
import { getActiveSavedSearchesForLead } from "redux/selector";
import { fetchSavedSearchResultTotal } from "redux/slice/saved-search-result-totals";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { SavedSearchCard } from "./saved-search-card";
import { User } from "model/user";
import { LoadingIndicator } from "component/shared/loading-indicator";

const mapStateToProps = (state: RootState, ownProps: any) => {
	return {
		savedSearches: getActiveSavedSearchesForLead(state, ownProps.lead.id),
		loading: state.savedSearches.loading && Object.keys(state.savedSearches.byId).length < 1,
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	fetchSavedSearchResultTotal,
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{
	user: User;
	lead: Lead;
}

interface State {
	searchDialogIsOpen: boolean;
	savedSearchId?: number | null;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			searchDialogIsOpen: false,
		};
	}

	public componentDidMount() {
		const { user, savedSearches } = this.props;
		if(user.permissions.leadSaveSearch) {
			savedSearches.forEach((savedSearch) => {
				this.props.fetchSavedSearchResultTotal({savedSearchId:savedSearch.id});
			});
		}
	}

	openSearchDialog = (searchDialogIsOpen: boolean,savedSearchId?: number) => {
		if(savedSearchId) {
			this.setState({ searchDialogIsOpen, savedSearchId });
		} else {
			this.setState({ searchDialogIsOpen, savedSearchId : null });
		}
	};

	public render() {
		const { user, lead, savedSearches, loading } = this.props;
		const count = savedSearches.length;
		return (
			<>
				<SearchDialog
					user={user}
					lead={lead}
					open={this.state.searchDialogIsOpen}
					onClose={()=>{
						this.openSearchDialog(false);
					}}
					savedSearchId={this.state.savedSearchId}
				/>
				<Mui.Grid container direction="column" spacing={2}>
					{loading ? (
						<div style={{height: "75vh"}}>
							<LoadingIndicator />
						</div>
					) : (
						<>
							<Mui.Grid item>
								<Mui.Grid container 
									justifyContent="space-between" 
									alignItems="center" 
								>
									<Mui.Grid item>
										{count === 1 &&
											<Mui.Typography>1 Saved Search</Mui.Typography>
										}
										{count > 1 &&
											<Mui.Typography>{count} Saved Searches</Mui.Typography>
										}
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Button
											variant="contained"
											color="secondary"
											onClick={() => this.openSearchDialog(true)}
										>
											<Mui.Typography noWrap>Add New</Mui.Typography>
										</Mui.Button>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
							{count === 0 &&
								<Mui.Grid item>
									<Mui.Grid
										container
										direction="column"
										alignItems="center"
										justifyContent="center"
									>
										<Mui.Grid item xs={9}>
											<Mui.Typography align="center" >
												This lead has no saved searches yet. Add a saved search to their Property Organizer account by clicking the ADD NEW button.
											</Mui.Typography>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							}
							<Mui.Grid item>
								{savedSearches.map((savedSearch, index) => (
									<SavedSearchCard 
										key={index} 
										lead={lead} 
										savedSearch={savedSearch} 
										openEditDialogue={() => this.openSearchDialog(true, savedSearch.id)}
									/>
								))}
							</Mui.Grid>
						</>
					)}
				</Mui.Grid>
			</>
		);
	}
}

export const SavedSearches = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
