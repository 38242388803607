import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { User } from "model/user";
import { FeatherIcon } from "component/shared/feather-icon";
import { MenuItem } from "../menu-item";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { getActive, getOpen } from "redux/selector";
import { openChange } from "redux/slice/sidebar-menu";
import { withMediaQuery, WithMediaQuery } from "component/shared/with-media-query";

const mapStateToProps = (state: RootState) => {
	return {
		active: getActive(state),
		open: getOpen(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	openChange,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles>,
	WithMediaQuery
{
	item: MenuItem;
	user: User;
}

class Component extends React.Component<Props> {

	public render() {
		const { user, item, classes } = this.props;
		const { level, open, sidebarCollapsed, backgroundOpacity } = this;
		return (
			<>
				{item.label && (sidebarCollapsed || level > 0) &&
					<div
						className={classNames(classes.itemWrapper, item.className)}
						style={{
							backgroundColor: `rgba(255, 255, 255, ${backgroundOpacity})`,
						}}
						onClick={() => this.toggle()}
					>
						{item.path && !item.target &&
							this.renderInternalLink
						}
						{item.path && item.target &&
							this.renderExtenalLink
						}
						{!item.path && item.children &&
							this.renderGroup
						}
					</div>
				}
				{item.children &&
					<Mui.Collapse in={open}>
						{item.children.map((item, index) => (
							<SidebarMenuItem key={index} user={user} item={item} />
						))}
					</Mui.Collapse>
				}
			</>
		);
	}

	private get id() {
		const { item } = this.props;
		return item.id || "";
	}

	private get parentId() {
		const { id } = this;
		const parts = id.split(".");
		parts.pop();
		const parent = parts.join(".");
		return parent;
	}

	private get level() {
		const { id } = this;
		return id.split(".").length - 1;
	}

	private get active() {
		const { id } = this;
		const { active } = this.props;
		return active === id;
	}

	private get open() {
		const { id } = this;
		const { open } = this.props;
		return open ? open.startsWith(id) : false;
	}

	private toggle() {
		const { id, parentId, open } = this;
		const { openChange } = this.props;
		if(open) {
			openChange(parentId);
		} else {
			openChange(id);
		}
	}

	private get firstChild() {
		const { item } = this.props;
		if(item.children) {
			return item.children && item.children.find((item) => item.path && item.label);
		}
		return undefined;
	}

	private get backgroundOpacity() {
		const { active, open, level, sidebarCollapsed } = this;
		if(!sidebarCollapsed && level === 1 && (!open || active)) {
			return 0;
		}
		if(!active && open) {
			return (level + 1) * .05;
		} else {
			return level * .05;
		}
	}

	private get paddingLeft() {
		const { level } = this;
		return (level * 20) + 25;
	}

	private get sidebarCollapsed() {
		return this.props.mediaQuery;
	}

	private get renderInternalLink() {
		const { item, classes } = this.props;
		const { paddingLeft, active } = this;
		return (
			<Mui.Button
				className={classNames(
					classes.item,
					active && classes.active,
					!active && classes.inactive,
				)}
				style={{
					paddingLeft,
				}}
				variant={active ? "contained" : undefined}
				color={active ? "secondary" : undefined}
				component={RouterLinkWrapper}
				to={item.path}
			>
				{this.renderLabel}
			</Mui.Button>
		);
	}

	private get renderExtenalLink() {
		const { item, classes } = this.props;
		const { paddingLeft } = this;
		return (
			<Mui.Button
				className={classes.item}
				style={{
					paddingLeft,
				}}
				href={item.path || ""}
				target={item.target}
			>
				{this.renderLabel}
			</Mui.Button>
		);
	}

	private get renderGroup() {
		const { item, classes } = this.props;
		const { open, paddingLeft, firstChild, sidebarCollapsed } = this;
		if(!open && firstChild && !sidebarCollapsed) {
			return (
				<Mui.Button
					className={classes.item}
					style={{
						paddingLeft,
					}}
					component={RouterLinkWrapper}
					to={firstChild.path}
				>
					{this.renderLabel}
					{item.children &&
						this.renderChevron
					}
				</Mui.Button>
			);
		} else {
			return (
				<Mui.Button
					className={classes.item}
					style={{
						paddingLeft,
					}}
				>
					{this.renderLabel}
					{item.children &&
						this.renderChevron
					}
				</Mui.Button>
			);
		}
	}

	private get renderChevron() {
		const { classes } = this.props;
		const { open } = this;
		return (
			<FeatherIcon className={classes.chevron}>
				{open ? (
					<Icons.ChevronUp size={16} />
				) : (
					<Icons.ChevronDown size={16} />
				)}
			</FeatherIcon>
		)
	}

	private get renderLabel() {
		const { item, classes } = this.props;
		return (
			<>
				{item.icon && (
					<FeatherIcon className={classes.icon}>
						<item.icon />
					</FeatherIcon>
				)}
				{item.label}
			</>
		);
	} 

}

export const SidebarMenuItem = withMediaQuery("(max-width:600px)")(
	Mui.withStyles(styles)(
		Router.withRouter(
			connect(mapStateToProps, mapDispatchToProps)(Component)
		)
	)
);