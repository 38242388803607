import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./styles";
import * as yup from "yup";
import { CustomAutocomplete } from "component/shared/auto-complete";

interface Item {
	id?: number | string;
	label: string;
	value: string;
}

interface OwnProps {
	items: Item[];
	includedItems?: Item[];
	includedItemsLabel: string;
	reset: boolean;
	onUpdate: (selected: Item[], reset?: boolean) => void;
}

type Props = OwnProps &
	Mui.WithStyles<typeof styles>


interface State {
	input: string;
	selected: Item[];
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			input: "",
			selected: [],
		};
	}

	public componentDidUpdate(prevProps: Props) {
		if(prevProps.reset !== this.props.reset) {
			if(this.props.reset) {
				this.setState({selected: []}, () => {
					this.props.onUpdate(this.state.selected, false)
				});
			}
		}
	}

	private get options() {
		const { items } = this.props;
		const { input } = this.state;
		const options = [...items];
		if(input) {
			const schema = yup.string().email();
			const valid = schema.isValidSync(input);
			if(valid) {
				const exists = options.some(option => option.value === input);
				if(!exists) {
					options.unshift({
						value: input,
						label: `Create "${input}"`,
					});
				}
			}
		}
		return options;
	}

	private select(item: Item) {
		const { selected } = this.state;
		this.setState({
			selected: [...selected, item],
		}, () => this.props.onUpdate(this.state.selected));
	}

	private unselect(item: Item) {
		const selected = [...this.state.selected];
		const itemValue = item.value;
		let index = -1;
		if(item.id) {
			index = selected.findIndex(value => value.id === item.id);
		} else {
			index = selected.findIndex(value => value.value === itemValue);
		}
		selected.splice(index, 1);
		this.setState({
			selected
		}, () => this.props.onUpdate(this.state.selected));
	}

	public render() {
		const { items, classes, includedItems, includedItemsLabel} = this.props;
		const { input, selected } = this.state;
		return (
			<Mui.Grid container direction="column" spacing={2} >
				<Mui.Grid item xs={12}>
					<CustomAutocomplete
						options={this.options}
						optionLabelExtractor={(option) => option.label}
						fullWidth
						margin="dense"
						placeholder="Select Leads or Add a New Email"
						textInputHeight={false}
						onInputChange={(event, value, reason) => {
							if(reason === "reset") {
								value = "";
							}
							this.setState({
								input: value
							})
						}}
						onChange={(event, option) => {
							const item = option as Item;
							if(item) {
								this.select(item);
							}
						}}
						inputValue={input}
					/>
				</Mui.Grid>
				{items.length > 0 && (
					<Mui.Grid className={classes.tags} item>
						<Mui.Grid container spacing={2}>
							{selected.map((item, index) => { 
								const included = includedItems?.some(value => value.id === item.id); 
								return (
									<Mui.Grid key={index} item>
										<Mui.Chip
											
											label={!!included ? item.value + ` (${includedItemsLabel})` : item.value + " (Add)"}
											onDelete={(event) => {
												this.unselect(item);
											}}
										/>
									</Mui.Grid>)
								})
							}
						</Mui.Grid>
					</Mui.Grid>
				)}
			</Mui.Grid>
		);
	}
}

export const FindSelect = Mui.withStyles(styles)(
	(Component)
);