import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Router from "react-router-dom";
import { DashboardLayout } from "component/layout/dashboard";
import { getPayload, RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { PageProps } from "shared/page-props";
import { OptIn } from "./opt-in";
import { SetupDialog } from "./setup-dialog";
import { getUserUnsafe } from "redux/selector";
import { AreaCode } from "./area-code";
import InputMask from "react-input-mask";
import { validationSchema } from "./validation-schema"
import { validate } from "shared/yup";
import { TimeZoneSelect } from "./time-zone";
import Alert from '@material-ui/lab/Alert';
import { UserPartial } from "model/user";
import { saveUser } from "redux/slice/authentication";

interface RouteParams {
	id: string;
}

interface OwnProps extends
	PageProps,
	Router.RouteComponentProps<RouteParams> {
}

interface State {
	dialogOpen: boolean;
	areaCode: string;
	phoneNumber: string;
	timeZone: string;
	timeZoneInputLabel: string;
	agreeToTerms: boolean;
	submitted: boolean;
	setupCompleteAlert: boolean;
	isSaved: boolean;
	dripMMSOptOutDate?: Date | null;
	textMessagePhoneNumber: string;
}

const initialState: State = {
	dialogOpen: false,
	areaCode: "",
	phoneNumber: "",
	timeZone: "",
	timeZoneInputLabel: "",
	agreeToTerms: false,
	submitted: false,
	setupCompleteAlert: false,
	isSaved: false,
	dripMMSOptOutDate: undefined,
	textMessagePhoneNumber: ""
}

const mapStateToProps = (state: RootState) => {
	const user = getUserUnsafe(state);
	if(user) {
		return {
			user,
		};
	}

};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveUser
}, dispatch);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}

	public componentDidMount() {
		const { user } = this.props;

		this.setState({
			areaCode: user.textMessageAreaCode,
			phoneNumber: user.textMessageCallForwarding,
			timeZone: user.tcpaCompliantTimeZone,
			dripMMSOptOutDate: user.dripMMSOptOutDate,
			textMessagePhoneNumber: user.textMessagePhoneNumber
		});
	}

	public componentDidUpdate(prevProps: Props) {
		const { user } = this.props;
		if(user !== prevProps.user) {
			this.setState({
				areaCode: user.textMessageAreaCode,
				phoneNumber: user.textMessageCallForwarding,
				timeZone: user.tcpaCompliantTimeZone,
				dripMMSOptOutDate: user.dripMMSOptOutDate,
				textMessagePhoneNumber: user.textMessagePhoneNumber
			});
		}
	}

	private submit = async () => {
		const user: UserPartial = {
			...this.props.user,
			textMessageAreaCode: this.state.areaCode,
			textMessageCallForwarding: this.state.phoneNumber,
			tcpaCompliantTimeZone: this.state.timeZone,
			dripMMSOptOutDate: this.state.dripMMSOptOutDate
		}
		await getPayload(this.props.saveUser({user}));
		this.setState({isSaved: true});
	};

	public render() {
		const { user } = this.props;
		const { dialogOpen, areaCode, setupCompleteAlert, phoneNumber, submitted, timeZone, dripMMSOptOutDate, textMessagePhoneNumber } = this.state;
		const title = "Texting";
		const errors = validate(validationSchema, this.state);
		return (
			<DashboardLayout
				permitted={user.permissions.dripMms}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.MessageSquare />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<SetupDialog
					open={dialogOpen}
					onClose={(setupCompleteAlert) => this.setState({dialogOpen: false, setupCompleteAlert})}
				/>
				{user.textMessageOptIn ? (
					<>

						<Mui.Grid container	direction="row" spacing={2} alignItems="center">
							{setupCompleteAlert &&
								<Mui.Grid item sm={12}>
									<Alert onClose={() => this.setState({setupCompleteAlert: false})} icon={false}>
										<Mui.Typography variant="h2">
											You're all set up!
										</Mui.Typography>
										<Mui.Typography>
											Your text messaging features should be available in 2-3 days.
										</Mui.Typography>
									</Alert>
								</Mui.Grid>
							}
							<Mui.Grid item sm={2} xs={12}>
								Texting Number Area Code
							</Mui.Grid>
							<Mui.Grid item sm={10} xs={12}>
								<AreaCode
									disabled={!!areaCode}
									onSave={(value) => {
										this.setState({
											areaCode: value,
											setupCompleteAlert: true,
										})
									}}
								/>
								{areaCode &&
									<Mui.Grid container direction="row">
										<Mui.Grid item>
											<FeatherIcon fontSize="small">
												<Icons.AlertTriangle color="orange" />
											</FeatherIcon>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Typography variant="caption">
												Contact our support team if you need to change your area code.
											</Mui.Typography>
										</Mui.Grid>
									</Mui.Grid>
								}

							</Mui.Grid>
							{
								textMessagePhoneNumber && (
									<>
										<Mui.Grid item sm={2} xs={12}>
											Text Message Number
										</Mui.Grid>
										<Mui.Grid item sm={10} xs={12}>
											<InputMask
												mask="(999) 999-9999"
												disabled={true}
												value={textMessagePhoneNumber.charAt(0) === "1" ? textMessagePhoneNumber.substring(1) : textMessagePhoneNumber}
											>
												{() =>
													<Mui.TextField
														disabled={true}
														variant="outlined"
														margin="dense"
														placeholder="Text Message Number"
													/>
												}
											</InputMask>
										</Mui.Grid>
									</>
								)
							}
							<Mui.Grid item sm={2} xs={12}>
								Call Forwarding Number
							</Mui.Grid>
							<Mui.Grid item sm={10} xs={12}>
								<InputMask
									mask="(999) 999-9999"
									value={phoneNumber}
									disabled={false}
									onChange={(event) => {
										this.setState({phoneNumber: event.target.value});
									}}
								>
									{() =>
										<Mui.TextField
											variant="outlined"
											margin="dense"
											placeholder="Phone Number"
											error={submitted && !!errors && !!errors.phoneNumber}
											helperText={submitted && errors && errors.phoneNumber}
										/>
									}
								</InputMask>
							</Mui.Grid>
							<Mui.Grid item sm={2} xs={12}>
								Time Zone
							</Mui.Grid>
							<Mui.Grid item sm={10} xs={12}>
								<TimeZoneSelect value={timeZone} onChange={(value) => this.setState({timeZone: value})} />
							</Mui.Grid>
							<Mui.Grid item sm={2} xs={12}>
								<Mui.Typography>
									Text Messaging
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item sm={10} xs={12}>
								<Mui.Grid container spacing={10} alignItems="center">
									<Mui.Grid item>
										<Mui.Grid container alignItems="center">
											<Mui.Grid item>
												<Mui.Typography>
													OFF
												</Mui.Typography>
											</Mui.Grid>
											<Mui.Grid item>
												<Mui.Switch checked={!dripMMSOptOutDate} onChange={(event, checked) => {
													if(!checked){
														this.setState({dripMMSOptOutDate: new Date()})
													}
													else {
														this.setState({dripMMSOptOutDate: null})
													}} } />
											</Mui.Grid>
											<Mui.Grid item>
												<Mui.Typography>
													ON
												</Mui.Typography>
											</Mui.Grid>
										</Mui.Grid>
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Typography>
											<strong>Note: </strong> (Disabling Text Messaging will unrent your texting <br />number and no further
											campaign text messages will be sent)
										</Mui.Typography>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
							<Mui.Grid item xs={12}>
								<Mui.Button
									variant="contained"
									color="secondary"
									onClick={() => {
										this.submit();

									}}
								>
									Save
								</Mui.Button>
							</Mui.Grid>
							{!dripMMSOptOutDate && (
								<Mui.Grid item xs={12}>
									<Mui.Typography>
										<strong>You've opted into text messaging: </strong>	All contact forms will display an option for leads to be contacted by text message.
									</Mui.Typography>
								</Mui.Grid>
							)}
						</Mui.Grid>
						<Mui.Snackbar
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							open={this.state.isSaved}
							onClose={() => this.setState({isSaved: false})}
							autoHideDuration={6000}
							message="Settings Saved"
						/>
					</>
				) : (
					<OptIn onSubmit={() => this.setState({dialogOpen: true})} />
				)}

			</DashboardLayout>
		);
	}
}

export const CampaignTextingPage = (
	Router.withRouter(
		connect<StateProps, DispatchProps, OwnProps, RootState>(
			mapStateToProps,
			mapDispatchToProps
		)(Component)
	)
);
