import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { GeographicRoutes } from "./geographic-routes"
import { RoundRobinRoutes } from "./round-robin-routes"
import { Settings } from "./settings"
import { connect } from "react-redux";
import { getRoundRobinRoutes } from "redux/selector";
import { getGeographicRoutes } from "redux/selector";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { getUserUnsafe } from "redux/selector";

interface Props
	extends ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Router.RouteComponentProps{}


const mapStateToProps = (state: RootState) => {
	const roundRobinRoutings = getRoundRobinRoutes(state);
	const geographicRoutings = getGeographicRoutes(state);
	const user = getUserUnsafe(state);
	return { roundRobinRoutings, geographicRoutings, user }
}

interface State {
	addAgentIsOpen: boolean
	tab: number;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({}, dispatch);


class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			addAgentIsOpen: false,
			tab: 0
		};
	}


	public render() {
		const title = "Lead Routing";
		let { roundRobinRoutings, geographicRoutings } = this.props;
		const { tab } = this.state;
		const { user } = this.props;
		return (
			<DashboardLayout
				permitted={user && user.permissions.leads}
				title={'Lead Routing'}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.User />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<Mui.Grid container item xs={12} md={12}>

					<Mui.Grid
						container
						item
						spacing={4}
						direction="row"
						justifyContent="space-between"
					>
						<Mui.Grid item xs={12}>
							<Mui.Grid container direction="column" spacing={10} >

							<Mui.Grid item>
								<Mui.Paper>
									<Mui.Tabs
										centered
										indicatorColor="secondary"
										textColor="secondary"
										value={tab}
										onChange={(event, tab) => this.setState({
											tab,
										})}
									>
										<Mui.Tab
											value={0}
											label="Settings"
										/>
										<Mui.Tab
											value={1}
											label="Geographic Lead Routing"
										/>
										<Mui.Tab
											value={2}
											label="Round Robin Lead Routing"
										/>
									</Mui.Tabs>
								</Mui.Paper>
							</Mui.Grid>
							<Mui.Grid item>
								{tab === 0 && (
									<Settings postalCodesCount={geographicRoutings.length}/>
								)}
								{tab === 1 && (
									<GeographicRoutes geographicRoutes={geographicRoutings}/>
								)}
								{tab === 2 && (
									<RoundRobinRoutes roundRobinRoutes={roundRobinRoutings}/>
								)}
							</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</DashboardLayout>
		);
	}

}

export const LeadRoutingPage = (
	connect(mapStateToProps, mapDispatchToProps)(Component)
);