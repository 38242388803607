import React from "react";
import * as Mui from "@material-ui/core";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Icons from "react-feather";
import { styles } from "./style";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { getPayload, RootState } from "redux/store";
import { validate } from "shared/yup";
import { Dialog } from "component/shared/dialog";
import { saveUser } from "redux/slice/authentication";
import { getUserUnsafe } from "redux/selector";
import InputMask from "react-input-mask";
import { TimeZoneType } from "type/time-zone";
import { CustomAutocomplete } from "component/shared/auto-complete";
import { UserPartial } from "model/user";
import { AreaCode } from "../area-code";
import { validationSchema } from "../validation-schema";

const mapStateToProps = (state: RootState) => {
	const user = getUserUnsafe(state);
	return {
		user,
		loading: state.authentication.loading,
		error: state.authentication.error,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveUser,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{
	open: boolean;
	onClose: (enabledCompleted: boolean) => void;
}

interface State {
	areaCode: string;
	activeStep: number;
	phoneNumber: string;
	timeZone: string;
	timeZoneInputLabel: string;
	agreeToTerms: boolean;
	completed1: boolean;
	completed2: boolean;
	emailSignupPhoneRequired: string;
	termsOpen: boolean;
}

const initialState: State = {
	areaCode: "",
	activeStep: 0,
	completed1: false,
	completed2: false,
	phoneNumber: "",
	timeZone: "",
	timeZoneInputLabel: "",
	agreeToTerms: false,
	emailSignupPhoneRequired: "true",
	termsOpen: false
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}

	private submit = async () => {
		const user: UserPartial = {
			...this.props.user,
			textMessageAreaCode: this.state.areaCode,
			textMessageCallForwarding: this.state.phoneNumber,
			tcpaCompliantTimeZone: this.state.timeZone,
			textMessageOptIn: this.state.agreeToTerms,
			emailSignupPhoneRequired: this.state.emailSignupPhoneRequired === "true" ? true : false,
		}
		await getPayload(this.props.saveUser({user}));
		this.props.onClose(true);
	};

	private isComplete = () => {
		if(
			!!this.state.timeZone &&
			!!this.state.agreeToTerms &&
			!!this.state.areaCode &&
			!!this.state.phoneNumber
		) {
			return true;
		}
		return false;
	}

	public componentDidMount() {
		const { user } = this.props;
		if(user) {
			const contactPhone = this.cleanPhoneNumber(user.contactPhone);
			if(user.textMessageAreaCode) {
				this.setState({
					phoneNumber: contactPhone ? contactPhone : "",
					areaCode: user.textMessageAreaCode.toString(),
				});
			}
		}
	}	

	private cleanPhoneNumber(value: string) {
		const phoneDigits = value && value.replace( /[^\d]/g, "")
		if(phoneDigits) {
			const matchedDigits = phoneDigits.match(/^(\d{3})(\d{3})(\d{4})$/);
			if (phoneDigits.length === 10 && matchedDigits) {
				return '(' + matchedDigits[1] + ') ' + matchedDigits[2] + '-' + matchedDigits[3]
			}
		}
	}
	
	public render() {
		const { open, onClose, classes, loading, error } = this.props;
		const {
			phoneNumber,
			timeZone,
			timeZoneInputLabel,
			activeStep,
			agreeToTerms,
			completed1,
			completed2,
			emailSignupPhoneRequired,
			termsOpen,
		} = this.state;
		const errors = validate(validationSchema, this.state);
		return (
			<>
				<Dialog open={open} onClose={() => onClose(false)} scroll="paper" fullWidth={true} width="sm">
					<Mui.DialogTitle>
						Configure Text Messaging
					</Mui.DialogTitle>
					<Mui.DialogContent>
						<Mui.Grid container direction="column">
							<Mui.Grid item style={{}} >
								<Mui.Stepper activeStep={activeStep} orientation="vertical">
									<Mui.Step>
										<Mui.StepButton onClick={() => this.setState({activeStep: 0})}>
											<Mui.Typography variant="h4" style={{fontWeight: "bold"}}>
												Select Your Area Code
											</Mui.Typography>
										</Mui.StepButton>
										<Mui.StepContent>
											<Mui.Grid container direction="column" spacing={2}>
												<Mui.Grid item>
													<Mui.Typography>
														Your subscribers will receive text messages from a number with this area code.
													</Mui.Typography>
													<AreaCode 
														onSave={(savedAreaCode) => {
															this.setState({
																areaCode: savedAreaCode, completed1: (savedAreaCode && savedAreaCode.length === 3) ? true : false
															})
														}}
													/>
													<Mui.Button 
														variant="contained"
														color="secondary" 
														size="small" 
														className={classes.nextButton}
														startIcon={
															<FeatherIcon>
																<Icons.ArrowDown />
															</FeatherIcon>
														}
														onClick={() => {
															this.setState({activeStep: 1});
														}}
														disabled={!(completed1 && !loading && !error)}
													>
														Next
													</Mui.Button>
												</Mui.Grid>
											</Mui.Grid>
										</Mui.StepContent>
									</Mui.Step>
									<Mui.Step >
										<Mui.StepButton onClick={() => this.setState({activeStep: 1})}>
											<Mui.Typography variant="h4" style={{fontWeight: "bold"}}>
												Additional Settings
											</Mui.Typography>
										</Mui.StepButton>
										<Mui.StepContent>
											<Mui.Grid container direction="column" spacing={2}>
												<Mui.Grid item>
													<Mui.Typography>
														Confirm your phone number. This number will be used if a lead calls your texting number.
													</Mui.Typography>
													<InputMask
														mask="(999) 999-9999"
														value={phoneNumber}
														
														disabled={false}
														onChange={(event) => {
															this.setState({phoneNumber: event.target.value});
														}}
													>
														{() => 
															<Mui.TextField 
																variant="outlined"
																margin="dense"
																placeholder="Phone Number"
																error={completed2 && !!errors && !!errors.phoneNumber}
																helperText={completed2 && errors && errors.phoneNumber}
															/>
														}
													</InputMask>
												</Mui.Grid>
												<Mui.Grid item>
													<Mui.Typography>
														Select your time zone to make sure messages are sent at the correct local time.
													</Mui.Typography>
													<CustomAutocomplete
														options={TimeZoneType.values()}
														textInputHeight={true}
														optionLabelExtractor={(option) => option.label}
														margin="dense"
														placeholder="Time Zone"
														onInputChange={(event, value, reason) => {
															if(reason !== "reset") {
																this.setState({
																	timeZone: value,
																	timeZoneInputLabel: value
																});
															}
															if(reason === "reset" && !timeZone) {
																this.setState({
																	timeZone: value,
																	timeZoneInputLabel: value
																});
															}
														}}
														onChange={(event, option) => {
															if(option && option.label) {
																this.setState({ 
																	timeZone: option.id,
																	timeZoneInputLabel: option.label 
																});		
															}
														}}
														helperText={completed2 && errors && errors.timeZone}
														FormHelperTextProps={{
															className: classes.errorMessage,
														}}
														error={completed2 && errors && !!errors.timeZone}
														inputValue={timeZoneInputLabel}
													/>
												</Mui.Grid>
												<Mui.Grid item>	
												<Mui.FormControl>
													<Mui.FormLabel id="phone-on-contact-froms-label">Require phone number on all lead capture forms</Mui.FormLabel>
														<Mui.RadioGroup
															row
															aria-labelledby="phone-on-contact-froms"
															name="phone-on-contact-froms-buttons-group"
															value={emailSignupPhoneRequired}
															onChange={(target) => {
																this.setState({emailSignupPhoneRequired: target.currentTarget.value});
															}}
														>
															<Mui.FormControlLabel value="true" control={<Mui.Radio />} label="On (Recommended)" />
															<Mui.FormControlLabel value="false" control={<Mui.Radio />} label="Off" />
														</Mui.RadioGroup>
													</Mui.FormControl>

												</Mui.Grid>
												<Mui.Grid item>
													<Mui.Button 
														variant="contained"
														color="secondary" 
														size="small" 
														startIcon={
															<FeatherIcon>
																<Icons.ArrowDown />
															</FeatherIcon>
														}
														onClick={() => {
															this.setState({completed2: true}, () => {
																if(!errors) {
																	this.setState({activeStep: 2});
																}
															});
															
														}}
														disabled={!(!!timeZone && !!phoneNumber)}
													>
														Next
													</Mui.Button>
												</Mui.Grid>
											</Mui.Grid>
											
										</Mui.StepContent>
									</Mui.Step>
									<Mui.Step >
										<Mui.StepLabel>
											Agree to Texting Terms of Service
										</Mui.StepLabel>
										<Mui.StepContent>
											<Mui.FormGroup>											
												<Mui.Typography>
													<Mui.FormControlLabel 
														control={<Mui.Checkbox checked={agreeToTerms} onChange={() => this.setState({agreeToTerms: !agreeToTerms})} />} 
														label={
															<>I have read and agreed to the 
																<Mui.Link href="#" onClick={(event) => {
																	event.preventDefault();
																	this.setState({termsOpen: true});
																}}>
																&nbsp;Texting Terms of Service </Mui.Link> which includes:
															</>
														} 
													/>
												</Mui.Typography>
												<Mui.Typography>
													<ul className={classes.termsText}>
														<li>
															Client acknowledges that Client is solely responsible for all acts or omissions or violations 
															of law that may occur in connection with Client’s use of the Licensed Technology.
														</li>
														<li>
															Client represents and warrants that the owners of the phone number to which Client transmits any 
															outbound text messages through the Licensed Technology have expressly consented or otherwise opted-in 
															to the receipt of such messages, in accordance with the TCPA, the TSR, and other applicable federal, state, local laws, statutes and 
															regulations and industry guidelines.
														</li>
														<li>
															Client agrees that Client will not access or otherwise use any third-party list of phone numbers or 
															otherwise engage in unsolicited messaging in connection with the Licensed Technology.
														</li>
													</ul>
												</Mui.Typography>
											</Mui.FormGroup>
											<Mui.Button 
												variant="contained"
												color="secondary" 
												size="small" 
												className={classes.nextButton} 
												onClick={() => {
													if(!errors) {
														this.submit();
													}
												}}
												disabled={!this.isComplete()}
											>
												Agree and Finish
											</Mui.Button>
										</Mui.StepContent>
									</Mui.Step>
								</Mui.Stepper>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.DialogContent>
				</Dialog>
				<Dialog open={termsOpen} onClose={() => this.setState({termsOpen: false})} scroll="paper" fullWidth={false} width="sm" hideBackdrop>
					<Mui.DialogTitle>
						<Mui.Grid container justifyContent="space-between" alignItems="center">
							<Mui.Grid item>
								<Mui.Typography>Terms of Service</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.IconButton 
									color="inherit" 
									className={classes.close}
									onClick={() => this.setState({termsOpen: false})}
								>
									<FeatherIcon>
										<Icons.X />
									</FeatherIcon>
								</Mui.IconButton>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.DialogTitle>
					<Mui.DialogContent>
						<Mui.Typography variant="h2">
							Texting Service Terms
						</Mui.Typography>
						<Mui.Typography paragraph>
							This service does not allow any unsolicited, unexpected, or unwanted text messages (a/k/a Spam).  
							By using this service you (“Client”) agree to comply with all federal, state, and local laws, statutes 
							and regulations, as well as applicable text/SMS/MMS messaging/telecommunications industry guidelines, 
							including without limitation, the following laws and guidelines:
						</Mui.Typography>
						<Mui.Typography paragraph>
							<ul>
								<li>
									Telephone Consumer Protection Act (“TCPA”), 47 U.S.C. §227, et seq., and related regulations, 47 CFR. 
									Part 64.1200, et seq.;
								</li>
								
									Telemarketing Sales Rule (“TSR”), 16 C.F.R. Part 310, et seq.;
								<li>
									Controlling the assault of Non-Solicited Pornography and Marketing (“CAN-SPAM”) Act of 2003;
								</li>
								<li>
									Mobile Marketing Association (“MMA”) U.S. Consumer Best Practices Guidelines for Messaging;
								</li>	
								<li>
									Cellular Telecommunications Industry Association (“CTIA”) Best Practices and Guidelines for Location-Based Services;
								</li>
								<li>
									CTIA Messaging Principles and Best Practices;
								</li>	
								<li>
									CTIA SMS Interoperability Guidelines; and
								</li>
								<li>
									CTIA Short Code Monitoring Program Handbook.
								</li>
							</ul>
						</Mui.Typography>
						<Mui.Typography paragraph>
							Client represents and warrants that the owners of the phone number to which Client transmits any outbound 
							text messages through the Licensed Technology have expressly consented or otherwise opted-in to the receipt 
							of such messages, in accordance with the TCPA, the TSR, and other applicable federal, state, local laws, 
							statutes and regulations and industry guidelines.  Client acknowledges that Client is solely responsible 
							for all acts or omissions or violations of law that may occur in connection with Client’s use of the 
							Licensed Technology.
						</Mui.Typography>
						<Mui.Typography paragraph>
							Client acknowledges and agrees that Client shall have sole responsibility for obtaining any consents for 
							mobile numbers and for complying with any terms and/or conditions that may govern such mobile numbers.
						</Mui.Typography>
						<Mui.Typography paragraph>
							Client agrees that Client will not access or otherwise use any third-party list of phone numbers or otherwise 
							engage in unsolicited messaging in connection with the Licensed Technology.  Additionally, Client agrees not to 
							send messages to any recipient beyond the frequency represented in any disclosures or terms. 
						</Mui.Typography>
						<Mui.Typography paragraph>
							Client agrees to represent truthfully its identity, the products or services, availability of services, pricing, 
							benefits, and any other offering aspects in the messaging. 
						</Mui.Typography>
						<Mui.Typography paragraph>
							Client agrees to comply with all local, state, and federal laws and regulations as well as general industry best 
							practices governing the content or promotion type in the messaging.
						</Mui.Typography>
						<Mui.Typography paragraph>
							The service provider, and its licensors and vendors, reserve the right, in their discretion, to monitor any and 
							all messages created or sent by Client or any third party at any time without prior notice to ensure that they 
							conform to the guidelines and policies pertaining to the Licensed Technology.  Client is solely responsible for 
							any claims or incidents that may result from the removal of any opt-out instructions.  Client agrees that it will
							not initiate any subsequent messages to any individuals after they make an opt-out or STOP request.
						</Mui.Typography>
						<Mui.Typography paragraph>
							Client hereby acknowledges and confirms that it is solely responsible for the content of the messaging.  Client 
							will ensure that any and all consents have been obtained, including without limitation, consent for the delivery of 
							commercial and marketing messages.  Client also agrees to defend, indemnify and hold harmless the service provider and 
							its licensors and vendors from and against any and all claims or damages which may result from Client’s use of the 
							Licensed Technology, including without limitation, claims, damages or lawsuits threatened or filed by third parties as 
							well as inquiries and investigations by local, state and federal regulators.  The service provider and its licensors 
							and vendor reserve the right to hold, suspend or terminate Client’s account or access to the Licensed Technology for 
							any alleged violation of this agreement and/or any unusual or suspicious activity related to Client’s account.
						</Mui.Typography>
						<Mui.Box display="flex" justifyContent="center">
							<Mui.Button 
								color="secondary"
								variant="contained"
								onClick={() => this.setState({termsOpen: false})}
							>
								Close
							</Mui.Button>
						</Mui.Box>

					</Mui.DialogContent>
				</Dialog>
			</>
		);
	}
}

export const SetupDialog = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);


