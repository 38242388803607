import Measure from "react-measure"
import React from "react"; 
import * as Mui from "@material-ui/core";

interface Props {
	columns: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
	children: React.ReactNode[];
	minWidth?: number;
}

interface State {
	width: number;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props)
		this.state = {
			width: 0,
		}
	}

	public render() {
		const { columns: columnCount, spacing, children } = this.props;
		const minWidth = this.props.minWidth || 771;
		const { width } = this.state;
		const columns: React.ReactNode[][] = [];
		for(let i = 0; i < children.length; i++) {
			const column = i % columnCount;
			if(!columns[column]) {
				columns[column] = [];
			}
			columns[column].push(children[i]);
		}
		return (
			<Measure
			bounds
			onResize={contentRect => {
				if(contentRect && contentRect.bounds) {
					this.setState({ width: Math.floor(contentRect.bounds.width) })
				}
			}}
			>
			{({ measureRef }) => (
				<div ref={measureRef}>
					{width > minWidth && (
						<Mui.Grid container direction="row" spacing={spacing}>
							{columns.map((column, columnIndex) => (
								<Mui.Grid key={`column${columnIndex}`} item xs>
									<Mui.Grid container direction="column" spacing={spacing}>
										{column.map((row, rowIndex) => (
											<Mui.Grid key={`column${columnIndex}-row${rowIndex}`} item>
												{row}
											</Mui.Grid>
										))}
									</Mui.Grid>
								</Mui.Grid>
							))}
						</Mui.Grid>
					)}
					{width <= minWidth && (
						<Mui.Grid container direction="column" spacing={spacing}>
							{children.map((child, index) => (
								<Mui.Grid key={index} item>
									{child}
								</Mui.Grid>
							))}
						</Mui.Grid>
					)}
				</div>
			)}
			</Measure>
		)
	}
}

 export const MasonryLayout = Component;