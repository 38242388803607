import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { validate } from "shared/yup";
import { saveUser } from "redux/slice/authentication";
import { getUserUnsafe } from "redux/selector";
import { TimeZoneType } from "type/time-zone";
import { CustomAutocomplete } from "component/shared/auto-complete";
import { validationSchema } from "../validation-schema"

const mapStateToProps = (state: RootState) => {
	const user = getUserUnsafe(state);
	return {
		user,
		loading: state.authentication.loading,
		error: state.authentication.error,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveUser,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{
	value?: string;
	onChange: (value: string) => void;
}

interface State {
	areaCode: string;
	activeStep: number;
	phoneNumber: string;
	timeZone: string;
	timeZoneInputLabel: string;
	agreeToTerms: boolean;
	completed1: boolean;
	completed2: boolean;
	emailSignupPhoneRequired: string;
}

const initialState: State = {
	areaCode: "",
	activeStep: 0,
	completed1: false,
	completed2: false,
	phoneNumber: "",
	timeZone: "",
	timeZoneInputLabel: "",
	agreeToTerms: false,
	emailSignupPhoneRequired: "true",
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}

	public componentDidUpdate(prevProps: Props) {
		if(prevProps.value !== this.props.value) {
			const { value } = this.props;
			const timeZone = value ? TimeZoneType.getById(value) : undefined;
			const label = timeZone ? timeZone.label : "";
			this.setState({
				timeZone: value ? value : "",
				timeZoneInputLabel: label,
			})
		}
	}

	public render() {
		const { classes } = this.props;
		const {
			timeZone,
			timeZoneInputLabel,
			completed2,
		} = this.state;
		
		const errors = validate(validationSchema, this.state);
		return (
			<CustomAutocomplete
				options={TimeZoneType.values()}
				textInputHeight={true}
				optionLabelExtractor={(option) => option.label}
				margin="dense"
				placeholder="Time Zone"
				onInputChange={(event, value, reason) => {
					if(reason !== "reset") {
						this.setState({
							timeZone: value,
							timeZoneInputLabel: value
						}, () => this.props.onChange(value));
					}
					if(reason === "reset" && !timeZone) {
						this.setState({
							timeZone: value,
							timeZoneInputLabel: value
						}, () => this.props.onChange(value));
					}
				}}
				onChange={(event, option) => {
					if(option && option.label) {
						this.setState({ 
							timeZone: option.id,
							timeZoneInputLabel: option.label 
						}, () => this.props.onChange(option.id));		
					}
				}}
				helperText={completed2 && errors && errors.timeZone}
				FormHelperTextProps={{
					className: classes.errorMessage,
				}}
				error={completed2 && errors && !!errors.timeZone}
				inputValue={timeZoneInputLabel}
			/>
		);
	}
}

export const TimeZoneSelect = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);


