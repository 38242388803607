import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { UserPackage } from "model/user-package";
import * as credentials from "shared/credentials";
import { UserPartial } from "model/user";
import { PermissionsResource } from "api/resource/permissions";
import { ResellerResource } from "api/resource/reseller";
import { OneUserResource } from "api/resource/one-user-now";
export interface AuthenticationsState {
	loading?: boolean;
	error?: string | null;
	user?: UserState | null;
};

export interface UserState {
	clientId: number;
	agentId?: number;
	admin?: boolean;
	firstName: string;
	lastName: string;
	username: string;
	email: string;
	company: string;
	website?: string;
	reseller: ResellerResource;
	permissions: PermissionsResource;
	oneUser?: OneUserResource;
	contactName: string;
	contactEmail: string;
	contactPhone: string;
	companyAddress: string;
	companyEmail: string;
	companyCity: string;
	companyState: string;
	companyZip: string;
	companyPhone: string;
	companyFax: string;
	baseUrl: string;
	boardIds: number[];
	userPackage?: UserPackage;
	trial?: boolean;
	annual?: boolean; 
	contractStart: Date;
	listingAgentRouting: boolean;
	geographicRouting:boolean;
	roundRobinRouting: boolean;
	roundRobinPosition: number;
	accountPaidDate?: Date;
	defaultInterestRate: number;
	defaultTerm: number;
	downPaymentPercentage: number;
	includeMonthlyInsurance: boolean;
	includeMonthlyTax: boolean;
	monthlyInsurance: number;
	monthlyTaxPercentage: number;
	kestrelVersion: number;
	settingListingDetailPageStyle: string;
	settingShowDownPaymentAssistanceLinks: boolean;
	settingShowMapLink: boolean;
	settingShowMortgageCalculator: boolean;
	settingShowScheduleShowing: boolean;
	settingShowSchools: boolean;
	settingShowWalkScore: boolean;
	listingDetailSidebarPosition: "left" | "right" | "off";
	listingDetailStickyContent: boolean;
	listingDetailContiguous: boolean;
	listingDetailSimilarListings: boolean;
	listingDetailCarouselThumbnails: boolean;
	listingDetailCarouselCenterMode: boolean;
	listingDetailEmbedVirtualTour: boolean;
	emailUpdates: boolean;
	emailSignupPhoneRequired: boolean;
	showCaptchaOnForms: boolean;
	emailAlertPopup: boolean;
	formHeaderMoreInfo: string;
	formHeaderScheduleShowing: string;
	formHeaderSaveListing: string;
	leadCaptureType: number;
	searchPagePromptType: number;
	searchPagePromptVisits: number;
	searchResultsPromptType: number;
	searchResultsPromptVisits: number;
	searchDetailPromptType: number;
	searchDetailPromptVisits: number;
	hotsheetsInResultsLimit: boolean;
	registrationFormHeader: string;
	registrationFormFooter: string;
	activeResultsSortId?: string;
	automatedFeaturedSolds?: boolean;
	showSupplementalPropertiesOnFeatured?: boolean;
	displayMapGridResults: boolean;
	displayMapListResults: boolean;
	eurekaResultsSortId?: string;
	featuredPendingResultsId?: number;
	featuredSortCodeId?: string;
	hideSoldSearch?: boolean;
	listingResultsDisplayId?: string;
	listingDetailModal?: boolean;
	limitSoldResultsByMonthsId?: number;
	showClientListingsFirst?: boolean;
	soldsOnFeatured?: boolean;
	mapPosition?: "left" | "right";
	displayDrawOnMap: boolean;
	smallLogoUrl: string;
	mapSearchZoom: number;
	mapSearchCenterAddress: string;
	mapSearchMapTypeId: string;
	eurekaEnabled: boolean;
	eurekaSearchHeight: number;
	shadowDomCss: string;
	shadowDomHtml: string;
	cssOverride: string;
	autoDisableDelete: boolean;
	autoDisableEmailClient: boolean;
	autoDisableEmailRange: number;
	autoDisableEmailSummary: boolean;
	autoDisableLoginRange: number;
	autoDisableSignupRange: number;
	requirePropertyOrganizerPassword: boolean;
	openHouseDisplayTypeId: number;
	openHouseSearchTypeId: number;
	brandingUrl: string;
	defaultFooterHtml: string;
	defaultHeaderHtml: string;
	textMessageAreaCode: string;
	textMessageCallForwarding: string;
	tcpaCompliantTimeZone: string;
	textMessageOptIn: boolean;
	dripMMSOptOutDate?: Date | null;
	colorScheme: string;
	darkMode: number;
	resultsPerPage: number;
	websitePlatform: string;
	activationToken: string;
};

const initialState: AuthenticationsState = {};

const login = createAsyncThunk("authentication/login", async (payload: {username: string, password: string}) => {
	return credentials.set(payload.username, payload.password);
});

const logout = createAsyncThunk("authentication/logout", async (_, thunkAPI) => {
	credentials.remove();
	thunkAPI.dispatch(authenticationFailed(null));
  });

/**
 * Checks for changes in credentials. Handles keeping multiple tabs in sync.
 */
const monitorCredentials = createAsyncThunk("authentication/monitorCredentials", async (payload, thunkAPI) => {
	let previous = credentials.get();
	if(previous) {
		thunkAPI.dispatch(authenticate());
	} else {
		const error = new Error("No user found. Please login.");
		thunkAPI.dispatch(authenticationFailed(error));
	}
	setInterval(() => {
		const current = credentials.get();
		if(JSON.stringify(previous) !== JSON.stringify(current)) {
			reset();
			thunkAPI.dispatch(authenticate());
		}
		previous = current;
	}, 250); 
});

const saveUser = createAsyncThunk("authentication/saveUser", async (payload: {user: UserPartial}, thunkAPI) => {
	thunkAPI.dispatch(saveUserLoading());
	const result = api.saveUser(payload.user)
	result
		.then(user => thunkAPI.dispatch(saveUserLoaded(user)))
		.catch(error => thunkAPI.dispatch(saveUserFailed(error)));
	return result;
});

const authenticate = createAsyncThunk("authentication/authenticate", async (payload, thunkAPI) => {
	thunkAPI.dispatch(authenticationLoading());
	const creds = credentials.get();
	if (creds) {
		const result = api.getUser();
		result
			.then((user) => thunkAPI.dispatch(authenticationLoaded(user)))
			.catch((error) => thunkAPI.dispatch(authenticationFailed("Incorrect credentials")));
		return result;
	}
});

const reset = createAsyncThunk("authentication/reset", async (payload, thunkAPI) => {
	thunkAPI.dispatch(loginReset());
});

export const authenticationSlice = createSlice({
	name: "authentication",
	initialState,
	reducers: {
		authenticationLoading: (state) => {
			state.loading = true;
		},
		authenticationLoaded: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = null;
			state.user = action.payload;
		},
		authenticationFailed: (state, action) => {
			credentials.remove();
			state.loading = false;
			state.error = action.payload;
			state.user = null;
		},
		saveUserLoading: (state) => {
			state.loading = true;
		},
		saveUserLoaded: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.user = action.payload;
			state.error = null;
		},
		saveUserFailed: (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		},
		loginReset: (state) => {
			const error = state.error;
			let result = {
				...initialState,
				error,
			}
		   return result;
		},
	},
});

export const { authenticationFailed, loginReset, authenticationLoaded, authenticationLoading, saveUserFailed, saveUserLoading, saveUserLoaded } = authenticationSlice.actions;
export { login, logout, monitorCredentials, authenticate, saveUser, reset };