import { MarketListingResource } from "api/resource/market-listing";
import { listings } from "./listings";

export const marketListings: MarketListingResource[] = [
	{
		id: 1,
		marketId: 1005,
		value: listings[0].listingNumber ? listings[0].listingNumber : "000",
		listing: listings[0],
	},
	{
		id: 2,
		marketId: 1005,
		value: listings[1].listingNumber ? listings[1].listingNumber : "000",
		listing: listings[1],
	},
];