import { Lead } from "model/lead";
import { Market } from "model/market";
import { MarketSubscription } from "./market-subscription";

export const marketSubscriptionsFactory = () => {
	let count = 0;
	return {
		create: (lead: Lead, market: Market) => {
			const listingReport = market.listingReport ? {
				inactive: true,
				lead,
				report: market.listingReport,
			} : undefined;
			const openHomeReport = market.openHomesReport ? {
				inactive: true,
				lead,
				report: market.openHomesReport,
			} : undefined;
			const marketReport = market.marketReport ? {
				inactive: true,
				lead,
				report: market.marketReport,
			} : undefined;
			const result: MarketSubscription =  {
				itemNumber: count++,
				lead,
				listingReport,
				openHomeReport,
				marketReport,
			}
			return result;
		}
	}
	
	
}

