import React from "react";
import Fuse from "fuse.js";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { FullPageCard } from "component/shared/full-page-card";
import { JavaIframe } from "component/shared/iframe/java";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import * as env from "shared/env"
import { RootState } from "redux/store";
import { getMarkets } from "redux/selector";
import { getUser } from "redux/selector";
import { styles } from "./styles";
import { Table } from "component/shared/table";
import { Market, NewMarket } from "model/market";
import { urls } from "routes/urls";
import { updateMarket, createMarket } from "redux/slice/markets";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { DeleteMarketDialog } from "./delete-dialog";
import MuiAlert from "@material-ui/lab/Alert";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { NewMarketDialog } from "./new-market-dialog";
import { LinkButton } from "./link-button";
import { fetchMarketReports } from "redux/slice/market-reports";

const mapStateToProps = (state: RootState) => {
	const user = getUser(state);
	const markets = getMarkets(state);
	const options: Fuse.IFuseOptions<Market> = {
		shouldSort: true,
		threshold: 0.5,
		location: 0,
		distance: 100,
		minMatchCharLength: 1,
		keys: ["id", "name"],
	};
	return {
		user,
		markets,
		fuse: new Fuse(markets, options),
		loading: state.markets.loading,
		authLoading: state.authentication.loading,
		authError: state.authentication.error,
	}
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateMarket,
	createMarket,
	fetchMarketReports,
}, dispatch);

interface Props extends
	PageProps, Router.RouteComponentProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
}

interface State {
	term: string;
	deleteMarketDialogIsOpen: boolean;
	marketToDelete: Market | null;
	marketToAdd: boolean;
	newMarketName: string;
	snackBarMessage?: string;
	copiedId: string;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props)
		this.state = {
			term: "",
			deleteMarketDialogIsOpen: false,
			marketToDelete: null,
			marketToAdd: false,
			newMarketName: "",
			snackBarMessage: "",
			copiedId: "",
		}
	}	

	private showAddMarketDialog = () => {
		this.setState({ marketToAdd: true });
	}

	private closeAddMarketDialog = () => {
		this.setState({ marketToAdd: false });
	}

	private showDeleteMarketDialog = (market: Market) => {
		this.setState({
			marketToDelete: market,
			deleteMarketDialogIsOpen: true,
		});
	};

	private hideDeleteMarketDialog = () =>
		this.setState({
			marketToDelete: null,
			deleteMarketDialogIsOpen: false,
		});

	private deleteMarket = () => {
		const { updateMarket } = this.props;
		const { marketToDelete } = this.state;
		if (marketToDelete) {
			updateMarket({ market: {
				...marketToDelete,
				inactive: true,
			}})
			this.hideDeleteMarketDialog();
		}
		this.openSnackBar("Market Deleted")
	}

	private copyMarket = async (newMarket: Market) => {
		const { createMarket, fetchMarketReports } = this.props;
		const market: NewMarket = {
			...newMarket,
			name: `${newMarket.name} (copy)`,
		};
		await createMarket({market});
		fetchMarketReports();
		this.openSnackBar("Market Duplicated")
	}

	private openSnackBar(message: string) {
		this.setState({snackBarMessage: undefined}, () => {
			setTimeout(() => {
				this.setState({snackBarMessage: message});
			}, 250);
		});
	}

	public render() {
		const { user, classes, fuse, loading, authLoading, authError } = this.props;
		let { markets } = this.props
		let activeMarkets = markets.filter((market) => !market.inactive);
		const {
			term,
			marketToAdd,
			marketToDelete,
			deleteMarketDialogIsOpen,
			snackBarMessage,
			copiedId,
		} = this.state;
		const title = user.admin ? "Markets" : "Manage Subscribers";
		if (term) {
			activeMarkets = fuse.search(term).map((result) => result.item)
		}
		return (
			<>
				{marketToAdd && (
					<NewMarketDialog
						user={user}
						closeAddMarketDialog={() => this.closeAddMarketDialog()}
						helperTextStyle={classes.helperTextStyle}
					/>
				)}
				{marketToDelete && (
					<DeleteMarketDialog
						market={marketToDelete}
						deleteMarket={this.deleteMarket}
						deleteMarketDialogIsOpen={deleteMarketDialogIsOpen}
						hideDeleteMarketDialog={() => this.hideDeleteMarketDialog()}
					/>
				)}
				<DashboardLayout
					permitted={user.permissions.markets || (user.type.agent && user.permissions.modifySubscriptions)}
					title={title}
					header={
						<Mui.Grid container justifyContent="flex-start" alignItems="center">
							<Mui.Grid item xs={3}>
								<Mui.Typography variant="h1">
									<FeatherIcon>
										<Icons.MapPin />
									</FeatherIcon>
									{title}
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Hidden xsDown>
								{env.marketsDev && (
									<Mui.Grid item xs={6}>
										<Mui.InputBase
											placeholder="Search for market"
											fullWidth
											onChange={(event) => {
												this.setState({
													term: event.target.value,
												});
											}}
											value={term}
											className={classes.searchBar}
											/>
									</Mui.Grid>
								)}
							</Mui.Hidden>
						</Mui.Grid>
					}
					iframe={!env.marketsDev ? <JavaIframe url="/hotSheets" /> : null}
				>
					{env.marketsDev && (
						<FullPageCard>
							<Mui.Grid container direction="column" spacing={4}>
								{loading && (
									<Mui.Grid item>
										<LoadingIndicator />
									</Mui.Grid>
								)}
								<Mui.Grid item>
									<Mui.Grid
										container
										item
										alignItems="center"
										justifyContent="space-between"
										spacing={4}
									>
										<Mui.Grid item>
											<Mui.Grid container spacing={3}>
												<Mui.Grid item>
													<Mui.Button
														variant="contained"
														color="secondary"
														onClick={() => this.showAddMarketDialog()}
													>
														<Mui.Typography>Add New</Mui.Typography>
													</Mui.Button>
												</Mui.Grid>
											</Mui.Grid>
										</Mui.Grid>
									</Mui.Grid>
									<Table
										items={activeMarkets.sort((a, b) => a.name.localeCompare(b.name))}
										columns={[
											{
												id: "label",
												hidden: false,
												title: "Name",
												value: (market) => {
													const url = urls.market(market);
													return (
														<Router.Link 
															to={{pathname: url, state: {fromMarketName: true}}}
															className={classes.marketLink}
														>
															<Mui.Typography 
																color="secondary"
																className={classes.marketText}
															>{market.name}</Mui.Typography>
														</Router.Link>
													);
												}
											},
											{
												id: "id",
												hidden: false,
												title: "ID",
												value: (market) => {
													return (
														<>
															{market.id && (
																<CopyToClipboard 
																	text={`${market.id}`}
																	onCopy={(id) => this.setState({copiedId: id})}
																>
																	<Mui.Tooltip title={copiedId !== `${market.id}` ? `Copy ${market.id}` : "Copied!"}>
																		<Mui.Button 
																			className={classes.idButton}
																			variant="text"
																		>
																			{market.id}
																		</Mui.Button>
																	</Mui.Tooltip>
																</CopyToClipboard>
															)}
														</>
													);
												}
											},
											{
												id: "subscribers",
												hidden: false,
												title: "Subscribers",
												value: (market) => {
													const marketReportSubscriptions = market.marketReportSubscriptions || [];
													const count = Array.from(new Set(marketReportSubscriptions.map((item) => item.lead.id))).length;
													const url = urls.marketTab(market, "subscribers");
													const isManual = market && market.criteria ? !!market.criteria.find((criterion) => criterion.name === "ids") : false;
													return (
														<>
														{!isManual ? (
															<Router.Link 
																to={{pathname: url, state: {fromMarketName: false}}}
																className={classes.marketLink}
															>
															<Mui.Typography color="secondary">{count}</Mui.Typography>
															</Router.Link>
														): 
															<Mui.Tooltip 
																title="Alerts and report pages are not available for Markets using manual listing numbers, date ranges or open-only fields."
															>
																<Icons.AlertCircle className={classes.alertCircle}/>
															</Mui.Tooltip>
														}
														</>
													);
												}
											},
											{
												id: "links",
												hidden: false,
												title: "Links",
												value: (market) => {
													return (
														<LinkButton market={market} />
													);
												}
											},
											{
												id: "duplicate-delete",
												hidden: false,
												showOnHover: true,
												value: (market) => {
													return (
														<Mui.Grid container justifyContent="space-around">
															<Mui.Grid item>
																<Mui.Tooltip title="Duplicate">
																	<Mui.IconButton
																		onClick={() => this.copyMarket(market)}
																		>
																		<FeatherIcon>
																			<Icons.Copy />
																		</FeatherIcon>
																	</Mui.IconButton>
																</Mui.Tooltip>
															</Mui.Grid>
															<Mui.Grid item>
																<Mui.Tooltip title="Delete">
																	<Mui.IconButton
																		onClick={() => this.showDeleteMarketDialog(market)}
																		>
																		<FeatherIcon>
																			<Icons.Trash />
																		</FeatherIcon>
																	</Mui.IconButton>
																</Mui.Tooltip>
															</Mui.Grid>
														</Mui.Grid>
													)
												}
											}
										]}
									/>
								</Mui.Grid>
							</Mui.Grid>
							<Mui.Snackbar
								open={!!snackBarMessage && !authLoading && !authError}
								message={snackBarMessage}
								autoHideDuration={6000}
								onClose={() => this.setState({ snackBarMessage: undefined })}
								action={
									<Mui.IconButton
										size="small"
										aria-label="close"
										color="inherit"
										onClick={() => this.setState({ snackBarMessage: undefined })}
									>
										<Icons.X fontSize="small" />
									</Mui.IconButton>
								}
							>
								<MuiAlert severity="info">
									<Mui.Typography>{snackBarMessage}</Mui.Typography>
								</MuiAlert>
							</Mui.Snackbar>
						</FullPageCard>
					)}
				</DashboardLayout>
			</>
		);
	}
}

export const MarketsPage = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);