import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import * as kestrelEmbedCode from "shared/kestrel-embed-code";
import * as yup from "yup";
import { validate } from "shared/yup";
import { getMarkets } from "redux/selector";
import { WidgetConfigLayout } from "../widget-config-layout";
import { getUser } from "redux/selector";
import { ListingSortType } from "type/listing-sort";

const mapStateToProps = (state: RootState) => {
	return {
		user: getUser(state),
		markets: getMarkets(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
}

interface State {
	rows?: number;
	navigation: boolean;
	nav: string;
	auto: boolean;
	interval?: number;
	maxResults: number;
	status: string;
	sort: string;
	featured: boolean;
	effect: string;
	marketId: string;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props) 
		this.state = {
			navigation: true,
			nav: "top",
			auto: true,
			maxResults: 25,
			status: "active",
			sort: "",
			featured: true,
			effect: "slide",
			marketId: "",
			rows: 1,
		}
	}

	private validationSchema = {
		marketId: yup
			.string()
			.test(
				"check-featued-market",
				"Market is Required.",
				(marketId) => {
					if(!this.state.featured) {
						if(marketId) {
							return true;	
						} else {
							return false;
						}
					} else {
						return true;
					}
				}
			),
		maxResults: yup
		.number()
			.required("Max Results is required."),
	};

	public render() {
		const { user, markets } = this.props; 
		const errors = validate(this.validationSchema, this.state);
		const { 
			rows,
			navigation,
			nav,
			auto,
			interval,
			maxResults,
			status,
			sort,
			featured,
			effect,
			marketId,
		} = this.state;
		const embedCode = kestrelEmbedCode.gallerySliderWidget(
			user.websitePlatform,
			rows ? rows : undefined,
			navigation,
			nav,
			auto,
			interval,
			maxResults,
			status,
			sort ? sort : undefined,
			featured,
			effect,
			marketId ? parseInt(marketId) : undefined,
		);
		return (
			<WidgetConfigLayout user={user} embedCode={!errors ? embedCode : undefined}>
				<Mui.Grid container spacing={2} direction="row" alignItems="center">
					<Mui.Grid item xs={12}>
						<Mui.Typography variant="h4">
							Widget Options
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item xs={12} sm={featured ? 12 : 4} xl={featured ? 12 : 3}>
						<Mui.RadioGroup 
							row
							value={featured} 
							onChange={() => this.setState({
								featured: !featured,
								marketId: featured ? "" : marketId,
							})}
						>
							<Mui.FormControlLabel value={true} control={<Mui.Radio />} label="Featured" />
							<Mui.FormControlLabel value={false} control={<Mui.Radio />} label="Market" />
						</Mui.RadioGroup>
					</Mui.Grid>
						
					{!featured && 
						<Mui.Grid item xs={12} sm={featured ? 12 : 8} xl={featured ? 12 : 9}>
						
							<Mui.FormControl variant="outlined" fullWidth >
								<Mui.TextField
									select
									required
									fullWidth
									variant="outlined"
									onChange={(event) => this.setState({marketId: event.target.value})}
									label="Market"
								>
									{markets.map((value, index) => 
										<Mui.MenuItem key={index} value={value.id}>{value.name}</Mui.MenuItem>)
									}	
									
								</Mui.TextField>
							</Mui.FormControl>
						
						</Mui.Grid>
					}
					<Mui.Grid item xs={12} md={4}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								type="number"
								variant="outlined"
								value={rows}
								onChange={(event) => this.setState({rows: parseInt(event.target.value)})}
								InputProps={{ inputProps: { min: 1 }}}
								label="Rows"
							/>
						</Mui.FormControl>
					</Mui.Grid>
					<Mui.Grid item xs={12} md={4}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								select
								fullWidth
								variant="outlined"
								value={nav}
								onChange={(event) => this.setState({nav: event.target.value})}
								label="Navigation Position"
							>
								<Mui.MenuItem value={"top"}>Top</Mui.MenuItem>
								<Mui.MenuItem value={"bottom"}>Bottom</Mui.MenuItem>
								<Mui.MenuItem value={"sides"}>Sides</Mui.MenuItem>
							</Mui.TextField>
						</Mui.FormControl>
					</Mui.Grid>
					<Mui.Grid item xs={12} md={4}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								select
								fullWidth
								variant="outlined"
								value={effect}
								onChange={(event) => this.setState({effect: event.target.value})}
								label="Effect"
							>
								<Mui.MenuItem value={"slide"}>Slide</Mui.MenuItem>
								<Mui.MenuItem value={"fade"}>Fade</Mui.MenuItem>
							</Mui.TextField>
						</Mui.FormControl>
					</Mui.Grid>
					<Mui.Grid item xs={12} sm={!auto ? 12 : 4} xl={auto ? 3 : 12}>
						<Mui.FormControlLabel 
							label="Auto Advance"
							control ={
								<Mui.Checkbox 
									checked={auto}
									onChange={() => this.setState({auto: !auto})}
								/>
							} 
						/>	
					</Mui.Grid>
					{auto &&
						<Mui.Grid item xs={12} sm={auto ? 8 : 12} xl={auto ? 9 : 12}>
							<Mui.FormControl variant="outlined" fullWidth>
								<Mui.TextField
									type="number"
									variant="outlined"
									onChange={(event) => this.setState({interval: parseInt(event.target.value)})}
									InputProps={{ inputProps: { min: 1 }}}
									label="Auto Advance Speed in Seconds"
								/>
							</Mui.FormControl>
						</Mui.Grid>
					}
					<Mui.Grid item xs={12} md={4}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								select
								fullWidth
								variant="outlined"
								value={status}
								onChange={(event) => this.setState({status: event.target.value})}
								label="Status"
							>
								<Mui.MenuItem value={"active"}>Active</Mui.MenuItem>
								<Mui.MenuItem value={"sold"}>Sold</Mui.MenuItem>
							</Mui.TextField>
						</Mui.FormControl>
					</Mui.Grid>
					<Mui.Grid item xs={12} md={4}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								select
								fullWidth
								variant="outlined"
								value={sort}
								onChange={(event) => this.setState({sort: event.target.value})}
								label="Sort"
							>
								{ListingSortType.values().map((value, index) => 
									<Mui.MenuItem key={index} value={value.id}>{value.label}</Mui.MenuItem>)
								}	
							</Mui.TextField>
						</Mui.FormControl>
					</Mui.Grid>
					<Mui.Grid item xs={12} md={4}>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								required
								type="number"
								variant="outlined"
								value={maxResults}
								onChange={(event) => this.setState({maxResults: parseInt(event.target.value)})}
								InputProps={{inputProps: { min: 1 }}}
								label="Max Results"
							/>
						</Mui.FormControl>
					</Mui.Grid>
				</Mui.Grid>
			</WidgetConfigLayout>			
		);
	}
}

export const GallerySliderConfig = (
	connect(mapStateToProps, mapDispatchToProps)(Component)
);