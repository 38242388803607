export const live = process.env.REACT_APP_LIVE === "true";
export const eurekaUrl = process.env.REACT_APP_EUREKA_URL as string;
export const kestrelUrl = process.env.REACT_APP_KESTREL_URL as string;
export const javaUrl = process.env.REACT_APP_JAVA_URL;
export const coldFusionUrl = process.env.REACT_APP_COLDFUSION_URL;
export const oneCrmUrl = process.env.REACT_APP_ONE_CRM_URL;
export const reactAppKestrelUrl = process.env.REACT_APP_KESTREL_URL;
export const apiUrl = javaUrl;
export const googleAnalytics = process.env.REACT_APP_GOOGLE_ANALYTICS;
export const nylasClientId = process.env.REACT_APP_NYLAS_CLIENT_ID;
export const oneApiUrl = process.env.REACT_APP_ONE_API_URL;
export const leadRanking = process.env.REACT_APP_LEAD_RANKING === "true";
export const cloudinaryCloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
export const cloudinaryUploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
export const mapboxAccessToken = "pk.eyJ1IjoiaWhvbWVmaW5kZXIiLCJhIjoiR1JFZzQyYyJ9.0g0cCGrEpv3B4zuYl2lBGw";
export const leadImportDev = process.env.REACT_APP_IMPORT_LEADS_DEV === "true"
export const agentAdminDev = process.env.REACT_APP_AGENT_ADMIN_DEV === "true"
export const marketsDev = process.env.REACT_APP_MARKETS_DEV === "true"
export const emailBlastDev = process.env.REACT_APP_EMAIL_BLAST_DEV === "true"
export const darkModeDev = process.env.REACT_APP_DARK_MODE_DEV === "true"
export const agentSelectorDev = process.env.REACT_APP_AGENT_SELECTOR_DEV === "true"