import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Dialog } from "component/shared/dialog";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { Agent } from "model/agent";
import { createAgent, resetError} from "redux/slice/agents";
import { getBoardsForUser } from "redux/selector";
import { getOffices } from "redux/selector";
import { RootState, getPayload } from "redux/store";
import { styles } from "./style";
import { updateRoundRobinRoutes } from "redux/slice/round-robin-routes";
import { NewRoundRobinRoute } from "model/round-robin-route";
import MuiAlert, { Color } from "@material-ui/lab/Alert";
import {getLanguages} from "redux/selector";
import * as env from "shared/env";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Row } from "./row";
import * as yup from "yup";
import { getUser } from "redux/selector";
import Checkbox from '@material-ui/core/Checkbox';
import { validate } from "shared/yup";
import * as Router from "react-router-dom";
import { urls } from "routes/urls";

interface Props extends
	OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles> {
		//user: User;
}

interface OwnProps {
	agents: Agent[];
	onClose: () => void;
	open: boolean;
}

interface State {
	lastName: string;
	firstName: string;
	email: string;
	username: string;
	password: string;
	submitted: boolean;
	openSnackbar: boolean;
	snackbarMessage: string;
	snackbarSeverity?: Color;
	roundRobin: boolean
	createdAgent: Agent;
}

const mapStateToProps = (state: RootState) => {
	return {
		loading: state.agentBoards.loading || state.boards.loading,
		boards: getBoardsForUser(state),
		offices: getOffices(state),
		languages: getLanguages(state),
		agentError: state.agents.error,
		user : getUser(state),
		usernameConflict: state.agents.error === "conflict",
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
			updateRoundRobinRoutes,
            createAgent,
            resetError,
        },
        dispatch
    );

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			lastName: "",
			firstName: "",
			username: "",
			password: "",
			email: "",
			submitted: false,
			openSnackbar: false,
			snackbarMessage: "",
			roundRobin: false,
			createdAgent:{
				id:0,
				label:"",
				name: "",
				firstName: "",
				username: "",
				password: "",
				email: "",
				hasOwnSite:false,
				showOnSignupDropDown:false,
				languageIds:[]
			},
		};
	}

	public componentDidUpdate(prevProps: Props) {
		if(prevProps.usernameConflict !== this.props.usernameConflict) {
			if(this.props.usernameConflict) {
				this.setState({
					openSnackbar: true,
					snackbarMessage: "Please fix the username errors and try again",
					snackbarSeverity: "error",
				})
			}
		}
	}

	private submit = async () => {
		const { onClose, history, user } = this.props;
		const {
			lastName,
			firstName,
			email,
			createdAgent
		} = this.state;
		const errors = validate(this.validationSchema, {...this.state});
		const roundRobinPermission = user.roundRobinRouting && user.permissions.roundRobinRouting;
		this.setState({ submitted: true });
		if (!!!errors && email) {
			const agentResource = getPayload(await this.props.createAgent( { agent: 
				{
					firstName: firstName,
					lastName: lastName,
					email: email,
					username: "",
					password: "",
					showOnSignupDropDown: false,
					hasOwnSite: false,
					languageIds: [],
				}
			}));
			if(agentResource.id) {
				history.push(urls.settingsAgentById(agentResource.id));
				onClose();
			}
			const roundRobinRoutes: NewRoundRobinRoute[] = []
			if (roundRobinPermission) {
				createdAgent.id = agentResource.id
				createdAgent.email = agentResource.email
				roundRobinRoutes.push({
					agent: createdAgent,
				})
				this.props.updateRoundRobinRoutes({ roundRobinRoutes })	
			}
			
		}
	};

	private cancel = () => {
		if (
			window.confirm(
				"Are you sure you want to close this dialog? You'll lose any unsaved progress."
			)
		) {
			this.props.onClose();
		}
	};

	private validationSchema = {
		email: yup.string().email("Invalid email.").required("Email is required."),
		username: yup
			.string()
			.test(
				"username-length",
				"Username must be 4-75 characters",
				(username) => {
					const correctLength = username && (username.length >= 4 && username.length <= 75);
					if (env.agentAdminDev) {
						return !!!username || !!correctLength;
					} else {
						return true;
					}
				}
			)
			.test(
				"username-required",
				"A Username is required",
				(username) => {
					if (env.agentAdminDev) {
						return yup.string().nullable().trim().required().isValidSync(username);
					} else {
						return true;
					}
				}
			),
		password: yup
			.string()
			.test(
				"password-length",
				"Password must be 4-75 characters",
				(password) => {
					const correctLength = password && (password.length >= 4 && password.length <= 75);
					return !!!password || !!correctLength;
				}
			)
			.test(
				"password-username-required",
				"A Username is required when setting a password",
				(password) => {
					if (env.agentAdminDev) {	
						return !!!password || !!this.state.username;
					} else {
						return true;
					}
				}
			),
	};
	
	render() {
		const { classes, loading, onClose, open, usernameConflict, user } = this.props;
		const {
			lastName,
			firstName,
			email,
			username,
			password,
			submitted,
			openSnackbar,
			snackbarMessage,
			snackbarSeverity,
			roundRobin,
			
		} = this.state;
		const errors = validate(this.validationSchema, {...this.state});
		const roundRobinPermission = user.roundRobinRouting && user.permissions.roundRobinRouting;
		return (
			<Dialog
				disableBackdropClick
				maxWidth="md"
				onClose={onClose}
				open={open}
				scroll="paper"
			>
				<Mui.DialogTitle>
					<Mui.Grid container direction="column">
						<Mui.Grid item>
							<Mui.Typography>
								Add Agent
							</Mui.Typography>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.IconButton
						className={classes.closeButton}
						onClick={() => this.cancel()}
					>
						<Icons.X />
					</Mui.IconButton>
				</Mui.DialogTitle>
				<Mui.DialogContent>
				<Mui.Grid container direction="column" spacing={2} className={classes.container}>
						{loading ? (
								<Mui.Grid item alignItems="center">
									<LoadingIndicator enable />
								</Mui.Grid>
						) : (
							<>
								<Mui.Grid item xs={12}>
									<Row>
										<Mui.Grid container spacing={1}>
											<Mui.Grid item xs={6}>
												<Mui.TextField
													fullWidth
													margin="dense"
													onChange={(event) =>
														this.setState({ firstName: event.target.value })
													}
													label="First Name"
													value={firstName}
													variant="outlined"
												/>
											</Mui.Grid>
											<Mui.Grid item xs={6}>
												<Mui.TextField
													fullWidth
													margin="dense"
													onChange={(event) =>
														this.setState({ lastName: event.target.value })
													}
													label="Last Name"
													value={lastName}
													variant="outlined"
												/>
											</Mui.Grid>
										</Mui.Grid>
									</Row>
								</Mui.Grid>
								<Mui.Grid item xs={12}>
									<Row>
										<Mui.TextField
											error={submitted && !!errors && !!errors.email}
											FormHelperTextProps={{
												className: classes.errorMessage,
											}}
											fullWidth
											helperText={submitted && errors && errors.email}
											margin="dense"
											onChange={(event) =>
												this.setState({ email: event.target.value })
											}
											label="Email"
											required
											value={email}
											variant="outlined"
										/>
									</Row>
								</Mui.Grid>
								{env.agentAdminDev && (
									<Mui.Grid item xs={12}>
										<Row>
											<Mui.TextField
												error={(submitted && !!usernameConflict) || !!(submitted && errors && errors.username)}
												fullWidth
												helperText={
													!!usernameConflict ? "Please choose a different username and try again" :
													submitted && errors && errors.username
												}
												inputProps={{
													autoComplete: "off",
												}}
												label="Username"
												margin="dense"
												onChange={(event) => {
													if(usernameConflict) {
														this.props.resetError();
													}	
													this.setState({ username: event.target.value })
												}}
												value={username}
												variant="outlined"
											/>
										</Row>
									</Mui.Grid>
								)}
								<Mui.Grid item xs={12}>
									<Row>
										<Mui.Grid item xs={12}>
											<Mui.TextField
												fullWidth
												margin="dense"
												onChange={(event) =>
													this.setState({ password: event.target.value })
												}
												label="Password"
												value={password}
												variant="outlined"
											/>
										</Mui.Grid>
									</Row>
								</Mui.Grid>
								{
								roundRobinPermission &&
								<Mui.Grid item xs={12}>
									<Row>
										<Mui.Grid item xs={12}>
										<FormControlLabel
													control={<Checkbox
														checked={roundRobin}
														name="roundRobin"
														onChange={(event) => {
															this.setState({ roundRobin: event.target.checked })
														}}
													/>}
													label="Add to Round Robin List"
										/>

										</Mui.Grid>

									</Row>
								</Mui.Grid>
								}

								<Mui.Grid item>
									<Mui.Grid container justifyContent="center" spacing={2}>
										<Mui.Grid item>
											<Mui.Button
												className={classes.button}
												variant="contained"
												color="secondary"
												onClick={() => this.submit()}
												disabled={submitted && !!errors}
											>
												Save & Continue
											</Mui.Button>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Button
												variant="outlined"
												color="secondary"
												onClick={() => this.cancel()}
											>
												Cancel
											</Mui.Button>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							</>
						)}
					</Mui.Grid>
					<Mui.Snackbar
						action={
							<Mui.IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={() =>
									this.setState({
										openSnackbar: false,
									})
								}
							>
								<Icons.Link fontSize="small" />
							</Mui.IconButton>
						}
						autoHideDuration={6000}
						onClose={() =>
							this.setState({
								openSnackbar: false,
							})
						}
						open={openSnackbar}
					>
						<MuiAlert severity={snackbarSeverity}>
							<Mui.Typography>{snackbarMessage}</Mui.Typography>
						</MuiAlert>
					</Mui.Snackbar>
				</Mui.DialogContent>
			</Dialog>
		);
	}
}

export const AddEditAgentDialog = Router.withRouter(Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
));
