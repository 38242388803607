import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		errorMessage: {
			color: theme.palette.error.main,
			fontSize: "0.75rem",
			fontWeight: "bold",
		},
		iconContainer: {
			display: "flex",
			flex: "0 0 50px",
			justifyContent: "center",
		},
		actionContainer: {
			display: "flex",
			flex: "0 0 50px",
			justifyContent: "center",
		},
		error: {
			color: "#f44336",
			margin: 0,
			fontSize: "0.75rem",
			marginTop: 3,
			textAlign: "left",
		}
	})
};