import React from "react";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";import * as Mui from "@material-ui/core";
import { styles } from "../../style";
import { Dialog } from "component/shared/dialog";
import { getLeadsById } from "redux/selector";
import { CSVLink } from "react-csv";
import { getTagsForLeads } from "redux/selector";
import { getAnniversariesForSelectedLead } from "redux/selector";
import { LeadAnniversaryType } from "type/lead-anniversary";
import { monthDayFormatter } from "shared/date-formatter";
import { getUserUnsafe } from "redux/selector";

const mapStateToProps = (state: RootState, ownProps: any) => {
	return {
		leads: getLeadsById(state, ownProps.leadIds),
		tags: getTagsForLeads(state, ownProps.leadIds),
		anniversaries: getAnniversariesForSelectedLead(state, ownProps.leadIds),
		user: getUserUnsafe(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
	open: boolean, 
	onClose: Function,
	leadIds: number[],
}

interface State {
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {};
	}

	private onClose() {
		this.props.onClose();
	}

	private createCSVHeaders = () => {
		const { user } = this.props;
		const headers = [
			{
				label: "Name",
				key: "name",
			},
			{
				label: "Email", 
				key: "email" 
			},
			{
				label: "Phone", 
				key: "phone" 
			},
			{
				label: "Date Last Active", 
				key: "active" 
			},
			{
				label: "Subscribed", 
				key: "subscribed" 
			},
			{
				label: "Address", 
				key: "address" 
			},
			{
				label: "Cty", 
				key: "city" 
			},
			{
				label: "State", 
				key: "state" 
			},
			{
				label: "Zip", 
				key: "zip" 
			},
			{
				label: "Date Added", 
				key: "createdOn" 
			},
			{
				label: "Status", 
				key: "status" 
			},
		];
		if(user && user.permissions.crmPipeline) {
			headers.push(
				{
					label: "Pipeline Stage", 
					key: "stage" 
				}
			)
		} 
		if(user && user.permissions.crmTags) {
			headers.push(
				{
					label: "Bookmarked", 
					key: "bookmark" 
				},
				{
					label: "Type", 
					key: "type" 
				},
				{
					label: "Tags", 
					key: "tags" 
				},
			);
		} 
		if(user && user.permissions.crmAnniversary) {
			headers.push(
				{
					label: "Birthday", 
					key: "birthday" 
				},
				{
					label: "Transaction Anniversary", 
					key: "transaction" 
				}
			);
		} 
		return headers;
	}

	private createCSVData = () => {
		const { leads, tags, anniversaries, user } = this.props;
		return leads.map((lead) => {
			let leadData: {
				[key: string]: any
			 } = {
				name: lead.name,
				email: lead.email, 
				address: lead.address, 
				city: lead.city,
				state: lead.state,
				zip: lead.zip,
				phone: lead.phone, 
				createdOn: lead.createdOn.format(),
				active: lead.lastActiveOn && lead.lastActiveOn.format(),
				subscribed: !!lead.subscribed, 
				status: lead.status.label,
			};
			if(user && user.permissions.crmPipeline) {
				leadData.stage = lead.pipeline && lead.pipeline.type.label;
			} 
			if(user && user.permissions.crmTags) {
				const leadTags = tags.filter(tag => tag.leadId === lead.id);
				const typeTags = leadTags.filter(tag => tag.defaultType && tag.defaultType.type).map(tag => tag.text);
				const bookmarkTag = leadTags.find(tag => tag.defaultType && tag.defaultType.bookmarked);
				const customTags = leadTags.filter(tag => !tag.defaultType).map(tag => tag.text);

				leadData.bookmark = !!bookmarkTag;
				leadData.tags = customTags;
				leadData.type = typeTags;
			} 
			if(user && user.permissions.crmAnniversary) {
				const leadAnniversaries = anniversaries.filter(anniversary => anniversary.lead.id === lead.id)
				const birthday = leadAnniversaries.find(anniversary => anniversary.type === LeadAnniversaryType.BIRTHDAY);
				const transaction = leadAnniversaries.filter(anniversary => anniversary.type === LeadAnniversaryType.TRANSACTION_ANNIVERSARY)
					.map(tag => monthDayFormatter(tag.startDate));

				leadData.birthday = birthday && monthDayFormatter(birthday.startDate);
				leadData.transaction = transaction;
			} 
			return leadData;
		})
	}

    render() {
		const { open, leadIds, classes } = this.props;
		const headers = this.createCSVHeaders();
		const data = this.createCSVData();
		
        return (
			<Dialog
				open={open}
				onClose={() => this.onClose()}
				fullWidth={true}
				maxWidth="xs" 
			>
				<Mui.DialogTitle>Export to CSV</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<Mui.DialogContentText className={classes.dialogText}>
						You have selected {leadIds.length} records to export to a csv file.
					</Mui.DialogContentText>
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
					<CSVLink 
						data={data} 
						headers={headers}
						filename="SubscriberStats.csv"
						style={{textDecoration: "none"}}
					>
						<Mui.Button
							onClick={() => this.onClose()}
							variant="contained"
							color="secondary"
						>
							Export CSV
						</Mui.Button>
					</CSVLink>
					<Mui.Button 
						onClick={() => this.onClose()} 
						variant="outlined"
					>
						Cancel
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
		)
	}
}

export const BulkExportCSVDialog = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);