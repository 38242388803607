import React from "react"
import * as Mui from "@material-ui/core";
import { styles } from "./style";


interface Props extends
	Mui.WithStyles<typeof styles>
{
	label: string,
	value: boolean | undefined,
	default?: boolean,
	onOff?: boolean,
	onChange: (value: boolean) => void,
	sidebarNote?: React.ReactNode,
	saving?: boolean,
	onClick?: () => void,
}

class Component extends React.Component<Props> {
	public render() {
		const { classes, onOff, saving } = this.props;
		const value = (this.props.value !== undefined) ? this.props.value : (this.props.default || false);
		return (
			<Mui.Grid container direction="row" spacing={2}>
				<Mui.Grid item xs>
					<Mui.Grid container spacing={3}>
						<Mui.Grid item xs={3}>
							<Mui.Typography className={classes.label}>{this.props.label}</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={2}>
							<Mui.RadioGroup row={true} value={value} onChange={event => this.props.onChange((event.target.value === 'true'))}>
								<Mui.FormControlLabel
									label={onOff ? "On": "Yes"}
									control={<Mui.Radio />}
									value={true} />
								<Mui.FormControlLabel
									label={onOff ? "Off": "No"}
									control={<Mui.Radio />}
									value={false} />
							</Mui.RadioGroup>
						</Mui.Grid>
						<Mui.Grid item xs={7}>
						<Mui.Button
							color="secondary"
							disabled={saving}
							variant="contained"
							onClick={this.props.onClick}
						>
							Save
						</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				
			</Mui.Grid>
		);
	}
}

export const YesNoRadioFieldButton = Mui.withStyles(styles)(Component);