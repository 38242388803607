import React from "react";
import * as Mui from "@material-ui/core";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Icons from "react-feather";
import { styles } from "./style";
import * as yup from "yup";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { getPayload, RootState } from "redux/store";
import { getLeadById, getActiveLeadEmails, getNonActiveLeadEmails } from "redux/selector";
import { Avatar } from "component/shared/avatar";
import { getAgents } from "redux/selector";
import { validate } from "shared/yup";
import { Dialog } from "component/shared/dialog";
import { LeadPhoneType } from "type/lead-phone";
import { NewLeadPhone } from "model/lead-phone";
import { LeadAnniversaryType } from "type/lead-anniversary";
import { LeadTagType } from "type/lead-tag";
import { Select } from "component/shared/select";
import { User } from "model/user";
import { deleteLeadTag } from "redux/slice/lead-tags";
import moment from "moment";
import { createLead } from "redux/slice/leads";
import { createLeadPhone } from "redux/slice/lead-phones";
import { createLeadAnniversary } from "redux/slice/lead-anniversaries";
import { createLeadTag } from "redux/slice/lead-tags";
import { createLeadPipeline } from "redux/slice/lead-pipelines";
import { LeadStatus } from "type/lead-status";
import { LeadPipelineType } from "type/lead-pipeline";

const mapStateToProps = (state: RootState) => {
	return {
		loading: state.leads.loading,
		lastLead: getLeadById(state, state.leads.lastSavedId),
		agents: getAgents(state),
		activeLeadEmails: getActiveLeadEmails(state),
		nonActiveLeadEmails: getNonActiveLeadEmails(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	deleteLeadTag,
	createLead,
	createLeadPhone,
	createLeadAnniversary,
	createLeadTag,
	createLeadPipeline,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{
	open: boolean;
	onClose: Function;
	user: User;
}

interface State {
	submitted: boolean;
	name: string;
	email: string;
	email2: string;
	phone: string;
	leadPhones: NewLeadPhone[];
	address: string;
	city: string;
	state: string;
	zip: string;
	addEmail2: boolean;
	agentId: number;
	birthday: Date | null;
	transactionAnniversary: Date | null;
	typeIds: string[];
}

const initialState: State = {
	submitted: false,
	name: "",
	email: "",
	email2: "",
	phone: "",
	leadPhones: [],
	address: "",
	city: "",
	state: "",
	zip: "",
	addEmail2: false,
	agentId: 0,
	birthday: null,
	transactionAnniversary: null,
	typeIds: [],
};

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}

	public componentDidUpdate(prevProps: Props) {
		if (!prevProps.open && this.props.open) {
			this.setState(initialState);
		}
	}

	private addLeadPhone = () => {
		let leadPhones = [...this.state.leadPhones];
		leadPhones.push({
			deleted: false,
			value: "",
			type: LeadPhoneType.MOBILE,
			leadId: 0,
		});
		this.setState({ leadPhones });
	};

	private removeLeadPhone = (index: number) => {
		let leadPhones = [...this.state.leadPhones];
		leadPhones[index].deleted = true;
		this.setState({ leadPhones });
	};

	private validationSchema = {
		addEmail2: yup.boolean(),
		email: yup
			.string()
			.email("Invalid email.")
			.required("Email is required.")
			.test(
				"check-for-existing-active-email",
				"A lead with that email already exists.",
				(email) => {
					let pass = true;	
					if(email && this.props.activeLeadEmails.includes(email)) {
						pass = false;
					}
					return pass;
				}
			)
			.test(
				"check-for-existing-non-active-email",
				"A deleted or inactive lead with that email already exists. You can reactivate that lead.",
				(email) => {
					let pass = true;
					if(email && this.props.nonActiveLeadEmails.includes(email)) {
						pass = false;
					}
					return pass;
				}
			),
		email2: yup.string().when("addEmail2", {
			is: true,
			then: yup.string().email("Invalid email."),
		}),
	};

	private onChangePhone = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>,index: number
	) => {
		let leadPhones = [...this.state.leadPhones];
		leadPhones[index].value = event.target.value as string;
		this.setState({ leadPhones });
	};

	private onChangeBirthday = (date: Date | null) => {	
		const birthday = date;
		if (date !== null) {
			this.setState({ birthday });
		}
	};

	private onChangeTransactionAnniversary = (date: Date | null) => {
		const transactionAnniversary = date;
		if (date !== null) {
			this.setState({ transactionAnniversary });
		}
	};

	private getLeadTypeTags() {
		const { typeIds } = this.state;
		return typeIds.map((typeId) => {
			const result= {
				text: typeId,
			};
			return result;
		});
	}

	private async submit() {
		const { onClose, agents, createLead, createLeadPhone, createLeadAnniversary, createLeadTag, createLeadPipeline } = this.props;
		const {
			name,
			email,
			email2,
			phone,
			leadPhones,
			address,
			city,
			state,
			zip,
			birthday,
			transactionAnniversary,
			agentId,
		} = this.state;
		this.setState({ submitted: true });
		const errors = validate(this.validationSchema, this.state);
		const agent = agents.find(agent => agent.id === agentId);
		const leadTypeTags = this.getLeadTypeTags();

		if (!errors && email) {
			const newLead = getPayload(await createLead({ lead: { 
				name, 
				email, 
				email2, 
				phone, 
				address, 
				city, 
				state, 
				zip, 
				agent,
				status: LeadStatus.ACTIVE,
				label: name 
			}}));
			if (newLead) {
				for (const phone of leadPhones) {
					createLeadPhone({ leadPhone: {
						leadId: newLead.id,
						type: phone.type,
						value: phone.value,
						deleted: phone.deleted,
					}})
				}
				if (birthday) {
					createLeadAnniversary({leadAnniversary: {
						startDate: moment(birthday),
						type: LeadAnniversaryType.BIRTHDAY,
						deleted: false,
						lead: newLead.id && newLead as any,
					}});
				}
				if (transactionAnniversary) {
					createLeadAnniversary({leadAnniversary: {
						startDate: moment(transactionAnniversary),
						type: LeadAnniversaryType.TRANSACTION_ANNIVERSARY,
						deleted: false,
						lead: newLead.id && newLead as any,
					}});
				}
				for (const tag of leadTypeTags) {
					createLeadTag({ leadTag: {
						leadId: newLead.id,
						text: tag.text,
					} });
				}
				createLeadPipeline({leadPipeline: {
					leadId: newLead.id,
					type: LeadPipelineType.UNCONTACTED,
				}});
			}
			onClose()
		}
	};

	public render() {
		const { open, onClose, agents, user, classes } = this.props;
		const {
			submitted,
			name,
			email,
			email2,
			phone,
			leadPhones,
			address,
			city,
			state,
			zip,
			addEmail2,
			birthday,
			transactionAnniversary,
			typeIds,
		} = this.state;
		const errors = validate(this.validationSchema, this.state);
		return (
			<Dialog open={open} onClose={() => onClose()} scroll="paper">
				<Mui.DialogTitle>
					<Mui.Grid container direction="column">
						<Mui.Grid item>
							<Mui.Typography>
								"Add Contact"
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container justifyContent="center">
								<Mui.Grid item>
									<Avatar diameter={80} phrase={this.state.name || this.state.email} />
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogTitle>
				<Mui.DialogContent>
					<Mui.Grid container direction="column">
						<Mui.Grid container item>
							<Mui.Grid container alignItems="center" spacing={1}>
								<Mui.Grid container item xs={1} justifyContent="center">
									<FeatherIcon>
										<Icons.User />
									</FeatherIcon>
								</Mui.Grid>
								<Mui.Grid item xs={10}>
									<Mui.TextField
										variant="outlined"
										margin="dense"
										fullWidth
										value={name}
										onChange={event => this.setState({ name: event.target.value })}
										placeholder="Name"
									/>
								</Mui.Grid>
								<Mui.Grid item xs={1} />
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid container item>
							<Mui.Grid container alignItems="center" spacing={1}>
								<Mui.Grid container item xs={1} justifyContent="center">
									<FeatherIcon>
										<Icons.Mail />
									</FeatherIcon>
								</Mui.Grid>
								<Mui.Grid item xs={10}>
									<Mui.TextField
										variant="outlined"
										margin="dense"
										fullWidth
										value={email}
										type="text"
										onChange={event => this.setState({ email: event.target.value })}
										error={submitted && !!errors && !!errors.email}
										helperText={submitted && errors && errors.email}
										FormHelperTextProps={{
											className: classes.errorMessage,
										}}
										placeholder="Email"
									/>
								</Mui.Grid>
								<Mui.Grid item xs={1}>
									{!addEmail2 && (
										<Mui.IconButton
											size="small"
											onClick={() => this.setState({ addEmail2: true })}
										>
											<FeatherIcon>
												<Icons.PlusCircle />
											</FeatherIcon>
										</Mui.IconButton>
									)}
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
						{addEmail2 && (
							<Mui.Grid container item>
								<Mui.Grid container alignItems="center" spacing={1}>
									<Mui.Grid item xs={1} />
									<Mui.Grid item xs={10}>
										<Mui.TextField
											variant="outlined"
											margin="dense"
											fullWidth
											value={email2}
											type="email"
											required
											onChange={event => this.setState({ email2: event.target.value })}
											helperText={submitted && errors && errors.email2}
											placeholder="Email"
										/>
									</Mui.Grid>
									<Mui.Grid item xs={1}>
										<Mui.IconButton
											size="small"
											onClick={() => this.setState({ addEmail2: false })}
										>
											<FeatherIcon>
												<Icons.MinusCircle />
											</FeatherIcon>
										</Mui.IconButton>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						)}
						<Mui.Grid container item>
							<Mui.Grid container alignItems="center" spacing={1}>
								<Mui.Grid container item xs={1} justifyContent="center">
									<FeatherIcon>
										<Icons.Phone />
									</FeatherIcon>
								</Mui.Grid>
								<Mui.Grid item xs={10}>
									<Mui.TextField
										variant="outlined"
										margin="dense"
										fullWidth
										value={phone}
										onChange={event => this.setState({ phone: event.target.value })}
										placeholder="Phone Number"
									/>
								</Mui.Grid>
								<Mui.Grid item xs={1}>
									{user.permissions.crmPhones && leadPhones.filter(phone => !phone.deleted).length < 3 && (
										<Mui.IconButton size="small" onClick={() => this.addLeadPhone()}>
											<FeatherIcon>
												<Icons.PlusCircle />
											</FeatherIcon>
										</Mui.IconButton>
									)}
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
						{user.permissions.crmPhones && leadPhones.map((phone, index) => (
							!phone.deleted && 
							<Mui.Grid container item key={index}>
								<Mui.Grid container alignItems="center" spacing={1} key={index}>
									<Mui.Grid container item xs={1} justifyContent="center" />
									<Mui.Grid item xs={6}>
										<Mui.TextField
											variant="outlined"
											margin="dense"
											fullWidth
											value={phone.value || ""}
											onChange={event => this.onChangePhone(event, index)}
											placeholder="Phone Number"
										/>
									</Mui.Grid>
									<Mui.Grid item xs={4}>
										<Select
											variant="outlined"
											margin="dense"
											fullWidth
											placeholder="Phone Type"
											value={phone.type}
											options={LeadPhoneType.values()}
											valueExtractor={type => type.id}
											labelExtractor={type => type.label}
											optionRenderer={type => <Mui.ListItemText primary={type.label} />}
											onChange={value => {
												let leadPhones = [...this.state.leadPhones];
												leadPhones[index].type = LeadPhoneType.getById(value.id) || LeadPhoneType.MOBILE;
												this.setState({ leadPhones });
											}}
										/>
									</Mui.Grid>
									<Mui.Grid item xs={1}>
										<Mui.IconButton
											size="small"
											onClick={() => this.removeLeadPhone(index)}
										>
											<FeatherIcon>
												<Icons.MinusCircle />
											</FeatherIcon>
										</Mui.IconButton>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						))}
						<Mui.Grid container item>
							<Mui.Grid container alignItems="center" spacing={1}>
								<Mui.Grid container item xs={1} justifyContent="center">
									<FeatherIcon>
										<Icons.MapPin />
									</FeatherIcon>
								</Mui.Grid>
								<Mui.Grid item xs={10}>
									<Mui.TextField
										variant="outlined"
										margin="dense"
										fullWidth
										value={address}
										onChange={event => this.setState({ address: event.target.value })}
										placeholder="Street Address"
									/>
								</Mui.Grid>
								<Mui.Grid item xs={1} />
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid container item>
							<Mui.Grid container alignItems="center" spacing={1}>
								<Mui.Grid item xs={1} />
								<Mui.Grid item xs={10}>
									<Mui.TextField
										variant="outlined"
										margin="dense"
										fullWidth
										value={city}
										onChange={event => this.setState({ city: event.target.value })}
										placeholder="City"
									/>
								</Mui.Grid>
								<Mui.Grid item xs={1} />
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid container item>
							<Mui.Grid container alignItems="center" spacing={1}>
								<Mui.Grid item xs={1} />
								<Mui.Grid item xs={10}>
									<Mui.Grid container spacing={1}>
										<Mui.Grid item xs={6}>
											<Mui.TextField
												variant="outlined"
												margin="dense"
												fullWidth
												value={state}
												onChange={event => this.setState({ state: event.target.value })}
												placeholder="State"
											/>
										</Mui.Grid>
										<Mui.Grid item xs={6}>
											<Mui.TextField
												variant="outlined"
												margin="dense"
												fullWidth
												defaultValue={zip}
												onChange={event => this.setState({ zip: event.target.value })}
												placeholder="Postal Code"
											/>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
								<Mui.Grid item xs={1} />
							</Mui.Grid>
						</Mui.Grid>
						{user.permissions.crmAnniversary && (
							<>
								<Mui.Grid container item>
									<Mui.Grid container alignItems="center" spacing={1}>
										<Mui.Grid container item xs={1} justifyContent="center">
											<FeatherIcon>
												<Icons.Gift />
											</FeatherIcon>
										</Mui.Grid>
										<Mui.Grid item xs={10}>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<KeyboardDatePicker
													autoOk
													disableToolbar
													variant="inline"
													inputVariant="outlined"
													InputProps={{
														className: classes.adornedEnd,
													}}
													format="MM/dd"
													fullWidth
													margin="dense"
													label="Birthday"
													placeholder="mm/dd"
													value={birthday}
													onChange={date => this.onChangeBirthday(date)}
													KeyboardButtonProps={{
														"aria-label": "change date",
														size: "small",
													}}
													keyboardIcon={<Icons.Calendar />}
													views={["date"]}
												/>
											</MuiPickersUtilsProvider>
										</Mui.Grid>
										<Mui.Grid item xs={1} />
									</Mui.Grid>
								</Mui.Grid>
								<Mui.Grid container item>
									<Mui.Grid container alignItems="center" spacing={1}>
										<Mui.Grid container item xs={1} justifyContent="center">
											<FeatherIcon>
												<Icons.Key />
											</FeatherIcon>
										</Mui.Grid>
										<Mui.Grid item xs={10}>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<KeyboardDatePicker
													autoOk
													disableToolbar
													variant="inline"
													inputVariant="outlined"
													InputProps={{
														className: classes.adornedEnd,
													}}
													format="MM/dd"
													fullWidth
													margin="dense"
													label="Transaction Anniversary"
													placeholder="mm/dd"
													value={transactionAnniversary}
													onChange={date => this.onChangeTransactionAnniversary(date)}
													KeyboardButtonProps={{
														"aria-label": "change date",
														size: "small",
													}}
													keyboardIcon={<Icons.Calendar fontSize="small" />}
													views={["date"]}
												/>
											</MuiPickersUtilsProvider>
										</Mui.Grid>
										<Mui.Grid item xs={1} />
									</Mui.Grid>
								</Mui.Grid>
							</>
						)}
						{user && user.permissions.broker && (
							<Mui.Grid container item>
								<Mui.Grid container alignItems="center">
									<Mui.Grid item xs={1}>
										<FeatherIcon>
											<Icons.Award />
										</FeatherIcon>
									</Mui.Grid>
									<Mui.Grid item xs={10}>
										<Select
											fullWidth
											variant="outlined"
											margin="dense"
											placeholder="Assign an agent"
											value={this.state.agentId}
											options={Array.from(agents)}
											valueExtractor={agent => agent.id}
											labelExtractor={agent => agent.name}
											optionRenderer={agent => <Mui.ListItemText primary={agent.name} />}
											onChange={value => this.setState({ agentId: value })}
										/>
									</Mui.Grid>
									<Mui.Grid item xs={1} />
								</Mui.Grid>
							</Mui.Grid>
						)}
						{user && user.permissions.crmTags && (
							<Mui.Grid container item>
								<Mui.Grid container alignItems="center">
									<Mui.Grid item xs={1}>
										<FeatherIcon>
											<Icons.Target />
										</FeatherIcon>
									</Mui.Grid>
									<Mui.Grid item xs={10}>
										<Select
											multiple
											fullWidth
											variant="outlined"
											margin="dense"
											placeholder="Assign a type"
											value={typeIds}
											options={LeadTagType.leadTypeValues()}
											valueExtractor={type => type.id}
											labelExtractor={type => type.label}
											optionRenderer={type => <Mui.ListItemText primary={type.label} />}
											onChange={values => this.setState({ typeIds: values })}
										/>
									</Mui.Grid>
									<Mui.Grid item xs={1} />
								</Mui.Grid>
							</Mui.Grid>
						)}
						<Mui.Grid container item style={{ marginTop: 16, marginBottom: 12 }}>
							<Mui.Grid item xs={1} />
							<Mui.Grid container item xs={10}>
								<div style={{ flex: 1 }} />
								<Mui.Button
									className={classes.button}
									style={{ marginRight: 8 }}
									variant="contained"
									color="secondary"
									onClick={() => this.submit()}
									disabled={submitted && !!errors}
								>
									Save
								</Mui.Button>
								<Mui.Button
									className={classes.button}
									variant="outlined"
									color="secondary"
									onClick={() => onClose()}
								>
									Cancel
								</Mui.Button>
							</Mui.Grid>
							<Mui.Grid item xs={1} />
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
			</Dialog>
		);
	}
}

export const AddLeadDialog = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);