import { Moment } from "moment";
import { normalize, schema } from "normalizr";
import { arrayToByForeignId } from "redux/normalize";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { CriterionResource } from "api/resource/criterion";
import { TextMessageActivityResource } from "api/resource/lead-activities";

export interface LeadActivitiesState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: string]: LeadActivityState;
	};
	byLeadId: {
		[key: number]: string[];
	};
};

export interface LeadActivityState {
	id: string;
	leadId: number;
	typeId: number;
	createdOn: string;
	dailyActivity?: Moment[];
	message?: string;
	preferableOn?: string;
	alternativeOn?: string;
	listing?: string;
	criteria?: CriterionResource[];
	textMessageActivity?: TextMessageActivityResource;
};

const initialState: LeadActivitiesState = {
	byId: {},
	byLeadId: {},
};

const fetchLeadActivities = createAsyncThunk("leadActivities/fetchLeadActivities", async (payload: {leadId?: number}, thunkAPI) => {
	thunkAPI.dispatch(leadActivitiesLoading());
	const result = api.getLeadActivities(payload.leadId);
	result
		.then((leadActivities) => thunkAPI.dispatch(leadActivitiesLoaded(leadActivities)))
		.catch(error => thunkAPI.dispatch(leadActivitiesFailed(error)));
	return result;
});

export const leadActivitiesSlice = createSlice({
	name: "leadActivities",
	initialState,
	reducers: {
		leadActivitiesLoading: (state) => {
			state.loading = true;
			state.error = null;
		},
		leadActivitiesLoaded: (state, action: PayloadAction<any[]>) => {
			const listing = new schema.Entity("listings");
			const leadActivity = new schema.Entity("leadActivities", {
				listing,
			});
			const leadActivities = new schema.Array(leadActivity);
			const normalized = normalize(action.payload, leadActivities);
			const byId = normalized.entities.leadActivities || {};
			const byLeadId = arrayToByForeignId(action.payload, "id", "leadId");
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...byId,
			};
			state.byLeadId = byLeadId;
		},
		leadActivitiesFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
            return state;
        }
    },
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});

export const { leadActivitiesFailed, leadActivitiesLoaded, leadActivitiesLoading } = leadActivitiesSlice.actions;
export { fetchLeadActivities };