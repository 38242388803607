import React from "react"
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { createMarket } from "redux/slice/markets";
import { Criterion } from "model/criterion";
import { getBoardsForUser } from "redux/selector";
import { RootState } from "redux/store";
import { createListingId, listingIdToParts } from "shared/listing-id";
import { getMarketListings } from "redux/selector";
import { ListingSearchFieldType } from "type/listing-search-field-type";
import { Board } from "model/board";
import { styles } from "./styles";

interface Props extends
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapDispatchToProps>,
	ReturnType<typeof mapStateToProps>
{
	criteria?: Criterion[];
	onUpdate: (criterion: Criterion) => void;
}

interface State {
	boardId: number | undefined;
	listingNumber: string,
	listingIds: string[];
	error: boolean;
}

const mapStateToProps = (state: RootState) => {
	const boards = getBoardsForUser(state);
	const marketListings = getMarketListings(state);
	return {
		boards,
		marketListings,
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createMarket,
}, dispatch);

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props)
		this.state = {
			boardId: undefined,
			listingNumber: "",
			listingIds: [],
			error: false,
		}
	}
	private listingNumberElement = React.createRef<HTMLDivElement>();
	
	public deleteId = (id: any) => {
		const { listingIds } = this.state;
		const index = listingIds.indexOf(id)
		if (index > -1) {
			listingIds.splice(index, 1)
			this.setState({listingIds})
			this.update()
		}
	}

	public popId = () => {
		const { listingIds } = this.state;
		listingIds.pop()
		this.setState({listingIds})
		this.update()
	}

	public componentDidUpdate(prevProps: Props) {
		if(this.props.criteria !== prevProps.criteria) {
			this.setState({listingIds: []});
		}
	}

	private marketListingIds() {
		const { criteria } = this.props;
		let result: string[] = [];
			let idsCriterion = criteria?.find((criterion) => criterion.name === ListingSearchFieldType.IDS.id) ;
			if(idsCriterion) {
				result = idsCriterion.value as string[];
			}
		return result;
	}

	private getBoard() {
		const { boards } = this.props;
		let board: Board | undefined = this.props.boards[0];
		if(boards.length > 1) {
			board = boards.find((value) => this.state.boardId && this.state.boardId === value.id);
		}
		return board;
	}

	private addListingId(...listingNumbers: string[]) {
		const { listingIds } = this.state;
		const board = this.getBoard();
		if(board) {
				const newListingIds = listingNumbers.map(listingNumber => listingNumber && createListingId(listingNumber, board));
				this.setState({listingIds: [...listingIds, ...newListingIds]}, () => {
					this.setState({listingNumber: ""});
					this.update();
				})
		}
	}

	private update() {
		const { listingIds } = this.state;
		const { onUpdate } = this.props;
		if(listingIds) {
			onUpdate({
				label: ListingSearchFieldType.IDS.id, 
				name: ListingSearchFieldType.IDS.id,
				value: [
					...this.marketListingIds(), 
					...listingIds,
				].filter(value => value !== ""),
			});
		}
	}

	public render() {
		const { boards, classes } = this.props;
		const { listingIds, listingNumber, boardId } = this.state;
		return (
			<Mui.FormControl fullWidth size="small">
				<Mui.Grid container spacing={2} direction="column">
					<Mui.Grid item>
						<Mui.Grid container spacing={2} direction="row" wrap="nowrap">
							{boards && (
								<Mui.Grid item>
									<Mui.FormControl fullWidth variant="outlined" size="small">
										<Mui.InputLabel>
											{boardId ? "Board" : "Select a Board"}
										</Mui.InputLabel>
										<Mui.Select
											required
											label="Board"
											value={boardId ? boardId : ""}
											style={{minWidth: 200}}
											onChange={(event) => {
												this.setState({boardId: parseInt(event.target.value as string)});
											}}
										>
											{boards.map((board, index) => 
												<Mui.MenuItem key={index} value={board.id}>
													{board.shortName}
												</Mui.MenuItem>
											)}		
										</Mui.Select>
									</Mui.FormControl>
								</Mui.Grid>
							)}
							{(boardId && boards.length > 0) && (
								<Mui.Grid item>
									<Mui.Grid container alignItems="center">
										<Mui.Grid container direction="row" alignItems="center">
											<Mui.Grid item 
												style={{maxHeight: listingIds.length > 0 ? undefined : 40.5}}	
											>
												<Mui.Grid container direction="column">
													<Mui.Grid item>
														<Mui.Tooltip 
															title="Use the space, comma or enter key to seperate listings"
															placement="top-start"
															arrow
														>
															<Mui.TextField 
																label="Add Listing Number(s)"
																value={listingNumber}
																ref={this.listingNumberElement}
																onChange={(event) => this.setState({listingNumber: event.currentTarget.value})}
																size="small"
																variant="outlined"
																disabled={boards.length > 1 && !boardId}
																onClick={() => this.setState({error: boards.length > 1 && !boardId})}
																onKeyUp={(event) => {
																	if(event.key === "Enter" && listingNumber) {
																		this.addListingId(listingNumber);
																		event.preventDefault();
																	} else if ((event.key === " " || event.key === ",") && listingNumber) {
																		if (!listingNumber.substring(0, listingNumber.length - 1)) {
																			this.setState({listingNumber: ""})
																		} else {
																			this.addListingId(listingNumber.substring(0, listingNumber.length - 1))
																			event.preventDefault()
																		}
																	}
																}}
																onKeyDown={(event) => {
																	if (event.code === "Backspace" && listingIds.length && !this.state.listingNumber) {
																		this.popId()
																	}
																}}
																onPaste={(event) => {
																	event.preventDefault()
																	let nums = event.clipboardData.getData("text/plain").split(/[, ]+/);
																	this.addListingId(...nums)
																}}
																onBlur={() => {
																	this.addListingId(listingNumber)
																}}
																style={{maxWidth: 300}}
																/>
														</Mui.Tooltip>
													</Mui.Grid>
													{listingIds.length > 0 && (
														<Mui.Grid item className={classes.chipContainer}>
															{listingIds.map((listingId, index) => {
																const parts = listingIdToParts(listingId);
																return parts ? (
																	<Mui.Chip 
																		key={index} 
																		label={parts.listingNumber} 
																		onDelete={() => this.deleteId(listingId)}
																		style={{marginRight: 10}}
																		color="primary"
																		className={classes.numberChip}
																		size="small"
																	/>
																) : null}
															)}
														</Mui.Grid>	
													)}
												</Mui.Grid>
											</Mui.Grid>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							)}
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</Mui.FormControl>
		)
	}
}

export const Manual = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));
