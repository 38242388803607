import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { arrayToById } from "redux/normalize";
import { BoardResource } from "api/resource/boards";

export interface BoardsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: BoardResource;
	};
};

const initialState: BoardsState = {
	byId: {},
};

const fetchBoards = createAsyncThunk("boards/fetchBoards", async (payload, thunkAPI) => {
	thunkAPI.dispatch(boardsLoading())
	const result = api.getBoards();
	result 
		.then((boards) => thunkAPI.dispatch(boardsLoaded(boards)))
		.catch((error) => thunkAPI.dispatch(boardsFailed(error)));
	return result;
});

export const boardSlice = createSlice({
	  name: "boards",
	  initialState,
	  reducers: {
		boardsLoading: (state) => {
			state.loading = true;
		},
		boardsLoaded: (state, action: PayloadAction<BoardResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
		},
		boardsFailed: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
		default: (state) => {
            return state;
        }
	},
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});
const { boardsLoading, boardsLoaded, boardsFailed } = boardSlice.actions;
export { fetchBoards };