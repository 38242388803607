import { arrayToById } from "redux/normalize";
import * as api from "api/api";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { loginReset } from "redux/slice/authentication";
import { SiteLinkResource } from "api/resource/site-link";

export interface SiteLinksState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: string]: SiteLinkResource;
	};
};

const initialState: SiteLinksState = {
	byId: {},
};

const fetchSiteLinks = createAsyncThunk("siteLinks/fetchSiteLinks", async (payload, thunkAPI) => {
	thunkAPI.dispatch(siteLinksLoading());
	const result = api.getSiteLinks();
	result
		.then((siteLinks) => thunkAPI.dispatch(siteLinksLoaded(siteLinks)))
		.catch(error => thunkAPI.dispatch(siteLinksFailed(error)));
	return result;
});	

export const siteLinksSlice = createSlice({
	name: "siteLinks",
	initialState,
	reducers: {
		siteLinksLoading: (state) => {
			state.loading = true;
		},
		siteLinksLoaded: (state, action: PayloadAction<SiteLinkResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
		},
		siteLinksFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});

export const { siteLinksLoading, siteLinksLoaded, siteLinksFailed } = siteLinksSlice.actions;
export { fetchSiteLinks };