import { arrayToById } from "redux/normalize";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { CampaignSubscriptionStatResource } from "api/resource/campaign-subscription-stat";

export interface CampaignSubscriptionStatsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: CampaignSubscriptionStatResource;
	};
};

const initialState: CampaignSubscriptionStatsState = {
	byId: {},
};

const fetchCampaignSubscriptionStats = createAsyncThunk("campaignSubscriptionStats/fetchCampaignSubscriptionStats", async (payload, thunkAPI) => {
	thunkAPI.dispatch(campaignSubscriptionStatsLoading());
	const result = api.getCampaignSubscriptionStats();
	result
		.then((campaignSubscriptionStats) => thunkAPI.dispatch(campaignSubscriptionStatsLoaded(campaignSubscriptionStats)))
		.catch(error => thunkAPI.dispatch(campaignSubscriptionStatsFailed(error)));
	return result;
});

export const campaignSubscriptionStatsSlice = createSlice({
	name: "campaignSubscriptionStats",
	initialState,
	reducers: {
		campaignSubscriptionStatsLoading: (state) => {
			state.loading = true;
		},
		campaignSubscriptionStatsLoaded: (state, action: PayloadAction<CampaignSubscriptionStatResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
		},
		campaignSubscriptionStatsFailed: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});

export const { campaignSubscriptionStatsFailed, campaignSubscriptionStatsLoaded, campaignSubscriptionStatsLoading } = campaignSubscriptionStatsSlice.actions;
export { fetchCampaignSubscriptionStats };