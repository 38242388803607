import { resellers } from "./resellers";

export const clientUser = {
	clientId: 61034,
	firstName: "John",
	lastName: "Washington Doe",
	username: "johndoe",
	email: "j.doe@realfuse.com",
	company: "Fuse Realty International",
	website: "http://eureka-demo.com",
	textMessageAreaCode: "",
	textMessageCallForwarding: "",
	tcpaCompliantTimeZone: "",
	textMessageOptIn: false,
	colorScheme: "#428bca",
	darkMode: 1,
	reseller: resellers[0],
	activationToken: "ec91b15d-1734-4e10-b8df-8ea32ad0f723",
	websitePlatform: "squarespace",
	baseUrl: "http://eureka-demo.com",
	permissions: {
		version: 10,
		chat: true,
		broker: true,
		plugin: false,
		standalone: true,
		omnipress: true,
		facebookApp: true,
		mobileSite: true,
		campaigns: true,
		blastCampaigns: true,
		markets: true,
		listingReport: true,
		openHomeReport: true,
		marketReport: true,
		geographicRouting: true,
		roundRobinRouting: true,
		roundRobinPosition: 1,
		listingOwner: false,
		eurekaSearch: true,
		listingSearch: true,
		listingMapSearch: true,
		listingPolygonSearch: true,
		listingResults: true,
		listingDetail: true,
		neighborhoods: true,
		leadSaveSearch: true,
		leadSaveListing: true,
		leadEmailUpdates: true,
		leadPropertyOrganizer: true,
		leadRequestMoreInfo: true,
		leadScheduleShowing: true,
		leadUserRegistration: true,
		leadForceUserRegistration: false,
		leadValuationRequest: true,
		leadContactForm: true,
		soldPendingListings: true,
		supplementalListings: true,
		reporting: true,
		billing: true,
		sidebarWidgets: true,
		oneCrm: false,
		crmNotes: true,
		crmTags: true,
		crmTasks: true,
		crmPhones: true,
		crmAnniversary: true,
		crmPipeline: true,
		listingDetailPageStyle: true,
		emailCalendarIntegration: true,
		embedVirtualTours: false,
		accountPending: false,
		dripMms: true,
		embeddedIdxBeta: false,
		kestrelAll: true,
		googleLogin: true
	},
	boardIds: [1,3],
	contactName: "Baxter Doe",
	contactEmail: "webmaster@realfuse.com",
	contactPhone: "555-000-1234",
	companyAddress: "123 Main Street",
	companyCity: "Berkeley",
	companyState: "CA",
	companyZip: "55555",
	companyPhone: "555-000-0000",
	companyFax: "",
	defaultTerm: 1,
	defaultInterestRate: 0.2,
	downPaymentPercentage: 0.34,
	includeMonthlyInsurance: true,
	includeMonthlyTax: false,
	monthlyTaxPercentage: 0.56,
	monthlyInsurance: 0.78,
	kestrelVersion: 2,
	settingListingDetailPageStyle: "standard",
	settingShowDownPaymentAssistanceLinks: false,
	settingShowMapLink: true,
	settingShowMortgageCalculator: true,
	settingShowScheduleShowing: true,
	settingShowSchools: true,
	settingShowWalkScore: true,
	listingDetailSidebarPosition: "right",
	listingDetailStickyContent: true,
	listingDetailContiguous: false,
	listingDetailSimiliarListings: true,
	activeResultsSortId: "ln",
	eurekaResultsSortId: "listingNumber",
	automatedFeaturedSolds: false,
	displayMapGridResults: false,
	displayMapListResults: false,
	featuredSortCodeId: "ln",
	hideSoldSearch: false,
	listingResultsDisplayId: "list",
	limitSoldResultsByMonthsId: 12,
	showClientListingsFirst: false,
	featuredPendingResultsId: 2,
	soldsOnFeatured: true,
	showSupplementalPropertiesOnFeatured: false,
	listingDetailCarouselThumbnails: false,
	listingDetailCarouselCenterMode: false,
	listingDetailEmbedVirtualTour: true,
	emailUpdates: true,
	emailSignupPhoneRequired: true,
	showCaptchaOnForms: true,
	emailAlertPopup: true,
	trial: true,
	demo: true,
	pending: true,
	formHeaderMoreInfo: "",
	formHeaderScheduleShowing: "",
	formHeaderSaveListing: "",
	leadCaptureType: 0,
	searchPagePromptType: 0,
	searchPagePromptVisits: 3,
	searchResultsPromptType: 0,
	searchResultsPromptVisits: 3,
	searchDetailPromptType: 0,
	searchDetailPromptVisits: 3,
	hotsheetsInResultsLimit: false,
	registrationFormHeader: "",
	registrationFormFooter: "",
	displayDrawOnMap: true,
	mapSearchCenterAddress: "",
	mapSearchMapTypeId: "ROADMAP",
	mapSearchZoom: 7,
	eurekaEnabled: true,
	eurekaSearchHeight: 12,
	shadowDomCss: "shadowDomCss",
	shadowDomHtml: "shadowDomHtml",
	cssOverride: "cssOverride",
	autoDisableDelete: true,
	autoDisableEmailClient: true,
	autoDisableEmailRange: 0,
	autoDisableEmailSummary: true,
	autoDisableLoginRange: 30,
	autoDisableSignupRange: 60,
	requirePropertyOrganizerPassword: false,
	openHouseDisplayTypeId: 1,
	openHouseSearchTypeId: 2,
	brandingUrl: "http://www.example.com/images/banner.jpg",
	controlPanelUrl: "",
	defaultFooterHtml: "<FooterHtml />",
	defaultHeaderHtml: "<HeaderHtml />",
	textMessagePhoneNumber: "16096211341",
	geographicRouting: true,
	roundRobinRouting: true,
	listingOwner: false,
	roundRobinPosition: 1
};

const agentUser = {
	clientId: 61034,
	agentId: 1234,
	firstName: "Tamara",
	lastName: "Phillipe",
	email: "tphilli@realfuse.com",
	company: "Fuse Realty International",
	website: "http://eureka-demo.com",
	textMessageAreaCode: "",
	textMessageCallForwarding: "",
	tcpaCompliantTimeZone: "",
	textMessageOptIn: false,
	colorScheme: "",
	reseller: resellers.find(reseller => reseller.id === 511),
	activationToken: "ec91b15d-1734-4e10-b8df-8ea32ad0f723",
	websitePlatform: "wordpress",
	baseUrl: "http://eureka-demo.com",
	kestrelVersion: 0,
	permissions: {
		allowModifyBio: true,
		modifyCredentials: true,
		modifyListings: true,
		modifySubscriptions: true,
		version: 9,
		chat: true,
		broker: true,
		plugin: true,
		standalone: true,
		omnipress: true,
		facebookApp: true,
		mobileSite: true,
		campaigns: true,
		blastCampaigns: true,
		markets: true,
		listingReport: true,
		openHomeReport: true,
		marketReport: true,
		eurekaSearch: true,
		listingSearch: true,
		listingMapSearch: true,
		geographicRouting: true,
		roundRobinRouting: true,
		roundRobinPosition: 1,
		listingOwner :true,
		listingPolygonSearch: true,
		listingResults: true,
		listingDetail: true,
		neighborhoods: true,
		leadSaveSearch: true,
		leadSaveListing: true,
		leadEmailUpdates: true,
		leadPropertyOrganizer: true,
		leadRequestMoreInfo: true,
		leadScheduleShowing: true,
		leadUserRegistration: true,
		leadForceUserRegistration: false,
		leadValuationRequest: true,
		leadContactForm: true,
		soldPendingListings: true,
		supplementalListings: true,
		reporting: true,
		billing: true,
		sidebarWidgets: true,
		oneCrm: true,
		crmNotes: true,
		crmTags: true,
		crmTasks: true,
		crmPhones: true,
		crmAnniversary: true,
		crmPipeline: true,
		listingDetailPageStyle: true,
		emailCalendarIntegration: true,
		embedVirtualTours: false,
		accountPending: false,
		embeddedIdxBeta: true,
		kestrelAll: true,
		textMessagePhoneNumber: "16096211341",
		googleLogin: true
	},
};

export const user = true ? clientUser : agentUser;