import React from "react";
import * as Mui from "@material-ui/core";
import { RichTextEditor } from "component/shared/rich-text-editor";
import { AgentPageSettingsFields } from "../form-fields/agent-page-settings-fields";
import { Row } from "../row";

interface Props	extends OwnProps {}

interface OwnProps {
	fields: AgentPageSettingsFields;
	onChange: (fields: AgentPageSettingsFields) => void;
}

interface State {
	fields: AgentPageSettingsFields;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			fields: props.fields,
		};
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		if(prevState.fields !== this.state.fields) {
			this.props.onChange(this.state.fields);
		}
	}
	
	render() {
		const { fields } = this.state;
		return (
			<Mui.Grid container	alignItems="center"	justifyContent="center"	spacing={2}>
				<Mui.Grid item xs={12}>
					<Row>
						<Mui.Typography>
							Page Header
						</Mui.Typography>
						<RichTextEditor
							onChange={(text) =>
								this.setState({ fields: {...fields, head: text} })
							}
							toolbarId="head"
							value={fields.head}
						/>
					</Row>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Row>
						<Mui.Typography>
						Body Text
						</Mui.Typography>
						<RichTextEditor
							onChange={(text) =>
								this.setState({ fields: {...fields, body: text} })
							}
							toolbarId="body"
							value={fields.body}
						/>
					</Row>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Row>
						<Mui.Grid
							container
							alignItems="center"
							justifyContent="space-between"
						>
							<Mui.Grid item>
								<Mui.Typography>Allow Agent to Modify Own Bio</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Switch
									checked={fields.allowModifyBio}
									onChange={(event) =>
										this.setState({ fields: {...fields, allowModifyBio: event.target.checked} })
									}
								/>
							</Mui.Grid>
						</Mui.Grid>
					</Row>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Row>
						<Mui.Grid container direction="column">
							<Mui.Grid item>
								<Mui.Grid
									container
									alignItems="center"
									justifyContent="space-between"
								>
									<Mui.Grid item>
										<Mui.Typography>Redirect to Agent's Website?</Mui.Typography>
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Switch
											checked={fields.hasOwnSite}
											onChange={(event) =>
												this.setState({ fields: {...fields, hasOwnSite: event.target.checked} })
											}
										/>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Collapse in={fields.hasOwnSite}>
									<Mui.TextField
										fullWidth
										margin="dense"
										onChange={(event) =>
											this.setState({ fields: {...fields, redirectUrl: event.target.value} })
										}
										label="Redirect URL"
										required
										value={fields.redirectUrl}
										variant="outlined"
									/>
								</Mui.Collapse>
							</Mui.Grid>
						</Mui.Grid>
					</Row>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Row>
						<Mui.Grid
							container
							alignItems="center"
							justifyContent="space-between"
						>
							<Mui.Grid item>
								<Mui.Typography>Enable Link on Agent Roster</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Switch
									checked={fields.enableAgentLink}
									onChange={(event) =>
										this.setState({ fields: {...fields, enableAgentLink: event.target.checked} })
									}
								/>
							</Mui.Grid>
						</Mui.Grid>
					</Row>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const AgentPageSettings = (Component);
