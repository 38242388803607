import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as yup from "yup";
import { FeatherIcon } from "component/shared/feather-icon";
import { PhotoUploadDialogButton } from "component/shared/photo-upload-dialog-button";
import { Select } from "component/shared/select";
import { Agent } from "model/agent";
import { resetError} from "redux/slice/agents";
import { getBoardsForUser } from "redux/selector";
import { getOffices } from "redux/selector";
import { RootState } from "redux/store";
import { validate } from "shared/yup";
import { AgentStaffType } from "type/agent-staff";
import { styles } from "./style";
import { Color } from "@material-ui/lab/Alert";
import { getLanguages } from "redux/selector";
import { BasicInfoFields } from "../form-fields/basic-Info-fields";
import * as env from "shared/env";
import { Avatar } from "component/shared/avatar";
import { Row } from "../row";

interface Props	extends OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {}

interface OwnProps {
	agent: Agent;
	submitted: boolean;
	fields: BasicInfoFields;
	onChange: (fields: BasicInfoFields, errors: boolean) => void;
}
interface State {
	openSnackbar: boolean;
	snackbarMessage: string;
	snackbarSeverity?: Color;
	mlsSelectorError: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		boards: getBoardsForUser(state),
		offices: getOffices(state),
		languages: getLanguages(state),
		usernameConflict: state.agents.error === "conflict",
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{
			resetError,
		},
		dispatch
	);
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.emailField = React.createRef();
		this.state = {
			openSnackbar: false,
			snackbarMessage: "",
			mlsSelectorError: false,
		};
	}

	private emailField: React.RefObject<HTMLDivElement>;

	public componentDidMount() {
		const { usernameConflict, resetError } = this.props;
		if(usernameConflict) {
			resetError();
		}
	}

	private validationSchema = {
		designation: yup
			.string()
			.test(
				"designation-max-length",
				"Designation must be less than 50 characters.",
				(designation) => !designation || designation.length <= 50
			),
		email: yup.string().email("Invalid email.").required("Email is required."),
		username: yup
			.string()
			.test(
				"username-length",
				"Username must be 4-75 characters",
				(username) => {
					const correctLength = username && (username.length >= 4 && username.length <= 75);
					return !!!username || !!correctLength;
				}
			)
			.test(
				"username-required",
				"A Username is required",
				(username) => {
					let result = true;
					if(this.props.agent && this.props.agent.username ) {
						if(!!!username) {
							result = false;
						}
					}
					return result;
				}
			),
		password: yup
			.string()
			.test(
				"password-length",
				"Password must be 4-75 characters",
				(password) => {
					const correctLength = password && (password.length >= 4 && password.length <= 75);
					return !!!password || !!correctLength;
				}
			)
			.test(
				"password-username-required",
				"A Username is required when setting a password",
				(password) => {
					return !!!password || !!this.props.fields.username;
				}
			),
	};
	
	render() {
		const { 
			fields, 
			onChange, 
			agent, 
			classes, 
			offices, 
			boards, 
			languages, 
			usernameConflict, 
			submitted 
		} = this.props;
		const errors = validate(this.validationSchema, fields);
		return (
			<>
				<Mui.Grid container alignItems="center" spacing={1}>
					<Mui.Grid item xs={12}>
						<Row icon={<Icons.User />}>
							<Mui.Grid container spacing={1}>
								<Mui.Grid item xs={6}>
									<Mui.TextField
										fullWidth
										margin="dense"
										onChange={(event) => {
											fields.firstName = event.target.value;
											onChange(fields, !!errors);
										}}
										label="First Name"
										value={fields.firstName}
										variant="outlined"
									/>
								</Mui.Grid>
								<Mui.Grid item xs={6}>
									<Mui.TextField
										fullWidth
										margin="dense"
										onChange={(event) => {
											fields.lastName = event.target.value;
											onChange(fields, !!errors);
										}}
										label="Last Name"
										value={fields.lastName}
										variant="outlined"
									/>
								</Mui.Grid>
							</Mui.Grid>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								error={submitted && !!errors && !!errors.designation}
								FormHelperTextProps={{
									className: classes.errorMessage,
								}}
								fullWidth
								helperText={submitted && errors && errors.designation}
								margin="dense"
								onChange={(event) => {
									fields.designation = event.target.value;
									onChange(fields, !!errors);
								}}
								label="Designation/Title"
								value={fields.designation}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<div ref={this.emailField}>
								<Mui.TextField
									error={submitted && !!errors && !!errors.email}
									FormHelperTextProps={{
										className: classes.errorMessage,
									}}
									fullWidth
									helperText={submitted && errors && errors.email}
									margin="dense"
									onChange={(event) => {
										fields.email = event.target.value;
										onChange(fields, !!validate(this.validationSchema, fields));
									}}
									label="Email"
									required
									value={fields.email}
									variant="outlined"
								/>
							</div>
						</Row>
					</Mui.Grid>
					{!env.agentAdminDev && (
						<Mui.Grid item xs={12}>
							<Row>
								<Mui.TextField
									error={(submitted && !!usernameConflict) || !!(errors && errors.username)}
									fullWidth
									helperText={
										!!usernameConflict ? "Please choose a different username and try again" :
										submitted && errors && errors.username
									}
									inputProps={{
										autoComplete: "off",
									}}
									label="Username"
									margin="dense"
									onChange={(event) => {
										if(usernameConflict) {
											this.props.resetError();
										}	
										fields.username = event.target.value;
										onChange(fields, !!errors);	
									}}
									value={fields.username}
									variant="outlined"
								/>
							</Row>
						</Mui.Grid>
					)}
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								error={!!errors && !!errors.password}
								FormHelperTextProps={{
									className:
										!!errors && !!errors.password
											? classes.errorMessage
											: undefined,
								}}
								fullWidth
								helperText={errors && errors.password}
								inputProps={{
									autoComplete: "new-password",
								}}
								label="Password"
								margin="dense"
								onChange={(event) => {
									fields.password = event.target.value;
									onChange(fields, !!errors);
								}}
								type="password"
								value={fields.password}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row icon={<Icons.Phone />}>
							<Mui.TextField
								fullWidth
								margin="dense"
								onChange={(event) => {
									fields.homePhone = event.target.value;
									onChange(fields, !!errors);
								}}
								label="Home Phone"
								value={fields.homePhone}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								fullWidth
								margin="dense"
								onChange={(event) => {
									fields.officePhone = event.target.value;
									onChange(fields, !!errors);
								}}
								label="Office Phone"
								value={fields.officePhone}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								fullWidth
								margin="dense"
								onChange={(event) => {
									fields.homeFax = event.target.value;
									onChange(fields, !!errors);
								}}
								label="Home Fax"
								value={fields.homeFax}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								fullWidth
								margin="dense"
								onChange={(event) => {
									fields.officeFax = event.target.value;
									onChange(fields, !!errors);
								}}
								label="Office Fax"
								value={fields.officeFax}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								fullWidth
								margin="dense"
								onChange={(event) => {
									fields.cellPhone = event.target.value;
									onChange(fields, !!errors);
								}}
								label="Cell Phone"
								value={fields.cellPhone}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Select
								fullWidth
								labelExtractor={(type) => type.label}
								margin="dense"
								onChange={(id) => {
									const value = AgentStaffType.getById(id);
									fields.staffType = value;
									onChange(fields, !!errors);
								}}
								options={AgentStaffType.values().map((value) => value)}
								optionRenderer={(type) => (
									<Mui.ListItemText primary={type.label} key={type.id} />
								)}
								label="Agent/Staff Type"
								value={fields.staffType && fields.staffType.id}
								valueExtractor={(type) => type.id}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								fullWidth
								label="Agent Code"
								margin="dense"
								onChange={(event) => {
									fields.code = event.target.value;
									onChange(fields, !!errors);
								}}
								placeholder="Agent Code (optional)"
								value={fields.code}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row icon={<Icons.MapPin />}>
							<Mui.TextField
								fullWidth
								margin="dense"
								onChange={(event) => {
									fields.officeAddress = event.target.value;
									onChange(fields, !!errors);
								}}
								label="Office Address"
								value={fields.officeAddress}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								fullWidth
								margin="dense"
								onChange={(event) => {
									fields.officeCity = event.target.value;
									onChange(fields, !!errors);
								}}
								label="Office City"
								value={fields.officeCity}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								fullWidth
								margin="dense"
								onChange={(event) => {
									fields.officeState = event.target.value;
									onChange(fields, !!errors);
								}}
								label="Office State"
								value={fields.officeState}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								fullWidth
								helperText="*If the address fields are left blank, the company address will be displayed."
								margin="dense"
								onChange={(event) => {
									fields.officeZip = event.target.value;
									onChange(fields, !!errors);
								}}
								label="Office Zip"
								value={fields.officeZip}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							{offices.length > 0 && (
								<Select
									fullWidth
									valueExtractor={(office) => office && office.id}
									labelExtractor={(office) => office && office.name}
									margin="dense"
									onChange={(value) => {
										fields.officeId = parseInt(value);
										onChange(fields, !!errors);
									}}
									options={offices}
									optionRenderer={(office) => (
										<Mui.ListItemText primary={office.name} key={office.id} />
									)}
									label="Office"
									value={fields.officeId && fields.officeId}
									variant="outlined"
								/>
							)}
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Select
								fullWidth
								labelExtractor={(type) => type.label}
								margin="dense"
								onChange={(event) => {
									fields.photoSource = event.target.value;
									onChange(fields, !!errors);
								}}
								options={[
									{
										id: "photoUrl",
										label: "Photo URL",
									},
									{
										id: "uploadPhoto",
										label: "Upload Photo",
									},
								]}
								optionRenderer={(type) => (
									<Mui.ListItemText primary={type.label} key={type.id} />
								)}
								label="Agent Photo"
								value={fields.photoSource}
								valueExtractor={(type) => type.id}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							{fields.photoSource === "photoUrl" && (
								<Mui.TextField
									fullWidth
									inputProps={{ maxLength: 100 }}
									label="Enter Photo URL"
									margin="dense"
									onChange={(event) => {
										fields.photoUrl = event.target.value;
										onChange(fields, !!errors);
									}}
									placeholder="Enter Photo URL (include http://)"
									value={fields.photoUrl}
									variant="outlined"
								/>
							)}
							{fields.photoSource === "uploadPhoto" && (
								<PhotoUploadDialogButton
									multiple={false}
									callback={(uploads) => {
										const upload = uploads.find((upload) => upload.url);
										if (upload && upload.url) {
											fields.photoSource = "photoUrl";
											fields.photoUrl = upload.url;											
											onChange(fields, !!errors);											
											this.setState({
												openSnackbar: true,
												snackbarMessage: "Upload Successful",
												snackbarSeverity: "success",
											});
										} else {
											this.setState({
												openSnackbar: true,
												snackbarMessage: "Error",
												snackbarSeverity: "error",
											});
										}
									}}
									label="Upload Agent Photo"
									style={{ marginBottom: 4, marginTop: 8 }}
									resourceType="image"
								/>
							)}
						</Row>
					</Mui.Grid>
					{fields.photoUrl && (
						<Mui.Grid item xs={12}>
							<Row>
								<Avatar
									diameter={80}
									phrase=""
									src={fields.photoUrl}
								/>
							</Row>
						</Mui.Grid>
					)}
					<Mui.Grid item xs={12}>
						<Row>
							<Select
								fullWidth
								valueExtractor={(lang) => lang && lang.id}
								labelExtractor={(lang) => lang && lang.name}
								margin="dense"
								multiple
								onChange={(languageIds) => {
									fields.languageIds = languageIds;
									onChange(fields, !!errors);
								}}
								options={languages}
								optionRenderer={(lang) => (
									<Mui.ListItemText primary={lang.name} key={lang.id} />
								)}
								label="Languages"
								value={fields.languageIds && fields.languageIds}
								variant="outlined"
								placeholder={languages.filter(language => fields.languageIds.includes(language.id)).map(language => language.name).join(', ')}
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.Grid item xs={12}>
								<Mui.Typography>Agent MLS IDs</Mui.Typography>
							</Mui.Grid>
						</Row>
					</Mui.Grid>
					{fields.agentBoards.filter((agentBoard) => agentBoard.agentId).map((agentBoard, index) => {
						return (
							<Mui.Grid item xs={12} key={index}>
								<Row action={
									<Mui.IconButton
										onClick={() => {
											agentBoard.agentId = 0;
											onChange(fields, !!errors);
										}}
										size="small"
									>
										<FeatherIcon>
											<Icons.Trash />
										</FeatherIcon>
									</Mui.IconButton>
								}>
									<Mui.Grid container spacing={1}>
										<Mui.Grid item xs={6}>
											<Select
												fullWidth
												labelExtractor={(board) => board && board.shortName ? board.shortName : "Error - Select an MLS"}
												margin="dense"
												options={boards.sort((a, b) =>
													a.shortName > b.shortName ? 1 : a.shortName < b.shortName ? -1 : 0
												)}
												optionRenderer={(board) => (
													<Mui.ListItemText primary={board && board.shortName ? board.shortName : "Error - Select an MLS"} />
												)}
												onChange={(boardId) => {
													agentBoard.boardId = boardId;
													onChange(fields, !!errors);	
												}}
												placeholder="Select an MLS"
												value={agentBoard.boardId}
												valueExtractor={(board) => board.id}
												variant="outlined"
											/>
										</Mui.Grid>
										<Mui.Grid item xs={6}>
											<Mui.TextField
												fullWidth
												margin="dense"
												onChange={(event) => {
													agentBoard.agentCode = event.target.value;
													onChange(fields, !!errors);
												}}
												placeholder="Agent ID"
												value={agentBoard.agentCode}
												variant="outlined"
											/>
										</Mui.Grid>
									</Mui.Grid>
								</Row>
							</Mui.Grid>
						)
					})}
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.Button
								onClick={() => {
									fields.agentBoards.push({id: 0, boardId: 0, agentCode: "", agentId: agent.id});
									onChange(fields, !!errors);
								}}
								size="small"
								startIcon={<Icons.PlusCircle />}
							>
								Add MLS Id
							</Mui.Button>
						</Row>
					</Mui.Grid>
					<Mui.Grid item />	
				</Mui.Grid>
			</>
		);
	}
}

export const BasicInfoForm = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
