import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import { urls } from "routes/urls";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { getCampaignById, getCampaignSubscriptionsForCampaign, getCampaignEmailsByCampaignId, getCampaignEmailsStat } from "redux/selector";
import { getLeadsById } from "redux/selector";
import { LoadingPage } from "../loading";
import { CampaignCopyButton } from "component/shared/campaign-copy-button";
import { ColumnLabel } from "component/shared/column-label";
import { timeDateFormatter } from "shared/date-formatter";

const mapStateToProps = (state: RootState, ownProps: Router.RouteComponentProps<RouteParams>) => {
	const campaignId = getId(ownProps);
	const campaign = getCampaignById(state, campaignId);
	const campaignEmails = getCampaignEmailsByCampaignId(state, campaignId);
	const campaignEmailsStat = getCampaignEmailsStat(campaignEmails);
	const campaignSubscriptions = getCampaignSubscriptionsForCampaign(state, campaignId);
	const leadIds = campaignSubscriptions.map(campaignSubscription => campaignSubscription.lead.id);
	const leads = getLeadsById(state, leadIds);
	return {
		campaign,
		campaignEmails,
		campaignEmailsStat,
		leads,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends
	PageProps,
	Router.RouteComponentProps<RouteParams>,
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
}

interface RouteParams {
	id: string;
}

const getId = (props: Router.RouteComponentProps<RouteParams>) => {
	return parseInt(props.match.params.id);
};

class Component extends React.Component<Props> {

	private iframe: HTMLIFrameElement | null = null; 

	private get data() {
		let campaign = null;
		let campaignCampaignMessage = null;
		let campaignMessage = null;
		if(this.props.campaign) {
			campaign = this.props.campaign;
		}
		if(campaign) {
			campaignCampaignMessage = campaign.campaignCampaignMessages[0];
		}
		if(campaignCampaignMessage && campaignCampaignMessage.campaignMessage) {
			campaignMessage = campaignCampaignMessage.campaignMessage;
		}
		return { campaign, campaignCampaignMessage, campaignMessage };
	}

	public componentDidMount() {
		setInterval(() => {
			const { iframe } = this;
			if(iframe) {
				const document = iframe.contentDocument;
				if(document && document.body) {
					let height = document.body.offsetHeight;
					iframe.style.setProperty("height", `${height}px`);
				}
			}
		}, 500);
	}

	public render() {
		const { classes, user, campaignEmails, campaignEmailsStat, leads } = this.props;
		const { campaign, campaignCampaignMessage, campaignMessage } = this.data;
		if(!campaign || !campaignCampaignMessage || !campaignMessage) {
			return <LoadingPage />;
		}
		const title = "Sent Emails";
		return (
			<DashboardLayout
				permitted={user && user.permissions.blastCampaigns}
				fullScreen={false}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Send />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<Mui.Grid container direction="column" spacing={4}>
					<Mui.Grid item>
						<Mui.Card>
							<Mui.CardContent>
								<Mui.Grid container alignItems="center" spacing={4}>
									<Mui.Grid item xs={6}>
										<Mui.Grid container direction="column" spacing={3}>
											<Mui.Grid item>
												<Mui.Grid container>
													<Mui.Grid item>
														<ColumnLabel className={classes.label}>
															Subject Line
														</ColumnLabel>
													</Mui.Grid>
													<Mui.Grid item>
														<Mui.Typography>
															{campaignMessage.subject}
														</Mui.Typography>
													</Mui.Grid>
												</Mui.Grid>
											</Mui.Grid>
											<Mui.Grid item>
												<Mui.Grid container>
													<Mui.Grid item>
														<ColumnLabel className={classes.label}>
															Date Sent
														</ColumnLabel>
													</Mui.Grid>
													<Mui.Grid item>
														<Mui.Typography>
															{timeDateFormatter(campaign.dateModified || campaign.dateCreated)}
														</Mui.Typography>
													</Mui.Grid>
												</Mui.Grid>
											</Mui.Grid>
										</Mui.Grid>
									</Mui.Grid>
									<Mui.Grid item xs={6}>
										<Mui.Grid container direction="column" spacing={3}>
											<Mui.Grid item>
												<Mui.Grid container>
													<Mui.Grid item>
														<ColumnLabel className={classes.label}>
															% Opens
														</ColumnLabel>
													</Mui.Grid>
													<Mui.Grid item>
														<Mui.Typography>
															{(campaignEmailsStat.openRate * 100).toFixed()}%
														</Mui.Typography>
													</Mui.Grid>
												</Mui.Grid>
											</Mui.Grid>
											<Mui.Grid item>
												<Mui.Grid container>
													<Mui.Grid item>
														<ColumnLabel className={classes.label}>
															% Click-Thrus
														</ColumnLabel>
													</Mui.Grid>
													<Mui.Grid item>
														<Mui.Typography>
															{(campaignEmailsStat.clickRate * 100).toFixed()}%
														</Mui.Typography>
													</Mui.Grid>
												</Mui.Grid>
											</Mui.Grid>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.CardContent>
							<Mui.CardActions>
								<Mui.Grid container spacing={2}>
									<Mui.Grid item>
										<CampaignCopyButton campaign={campaign} />
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Button
											component={RouterLinkWrapper}
											to={urls.campaignSentEmails}
											variant="outlined"
											color="primary"
										>
											Close
										</Mui.Button>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.CardActions>
						</Mui.Card>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.ExpansionPanel>
							<Mui.ExpansionPanelSummary
								expandIcon={
									<FeatherIcon>
										<Icons.ChevronDown />
									</FeatherIcon>
								}
							>
								<Mui.Typography variant="h3">
									Leads sent to: {leads.length}
								</Mui.Typography>
							</Mui.ExpansionPanelSummary>
							<Mui.ExpansionPanelDetails>
								<Mui.Grid container spacing={2}>
									{leads.map((lead) => {
										const campaignEmail = campaignEmails.find(campaignEmail => campaignEmail.leadId === lead.id);
										return (
											<Mui.Grid key={lead.id} item xs={12} md={4}>
												<Mui.Grid container spacing={1}>
													<Mui.Grid item>
														<Mui.Link
															component={RouterLinkWrapper}
															to={urls.lead(lead)}
														>
															{lead.label}
														</Mui.Link>
													</Mui.Grid>
													{campaignEmail && campaignEmail.status.failed && (
														<Mui.Grid item>
															<Mui.Typography className={classes.failedText}>
																Bounced
															</Mui.Typography>
														</Mui.Grid>
													)}
												</Mui.Grid>
											</Mui.Grid>
										);
									})}
								</Mui.Grid>
							</Mui.ExpansionPanelDetails>
						</Mui.ExpansionPanel>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Card>
							<Mui.CardContent>
								<iframe
									ref={iframe => this.iframe = iframe}
									className={classes.iframe}
									title="Preview of email content"
									srcDoc={campaignMessage.htmlContent}
								/>
							</Mui.CardContent>
						</Mui.Card>
					</Mui.Grid>
				</Mui.Grid>
			</DashboardLayout>
		);
	}

}

export const CampaignSentEmailPage = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);