import React from "react";
import { CustomAutocomplete } from "component/shared/auto-complete";
import { WidgetType } from "type/widget-type";
import { User } from "model/user";

interface Props
{
	user: User;
	onSelect: (widget: WidgetType) => void;
}

interface State {
	widgetId: string;
	inputValue: string;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			widgetId: "",
			inputValue: "",
		}
	}

	private userPermittedWidgets() {
		const { user } = this.props;
		return WidgetType.values().filter((widget) => {
			switch (widget) {
				case WidgetType.GALLERY_SLIDER:
					return true;
				case WidgetType.FEATURED_LISTINGS:
					return true;
				case WidgetType.MARKETS:
					return user.permissions.markets;
				case WidgetType.MARKET_REPORT:
					return true;
				case WidgetType.QUICK_SEARCH:
					return user.permissions.listingSearch;
				case WidgetType.LISTING_SEARCH:
					return user.permissions.listingSearch;
				case WidgetType.MARKET_REPORT_SIGNUP:
					return user.permissions.report;
				case WidgetType.REGISTRATION_FORM:
					return user.permissions.leadUserRegistration;
				case WidgetType.VALUATION_FORM:
					return user.permissions.leadValuationRequest;
				case WidgetType.CONTACT_FORM:
					return user.permissions.leadContactForm;
				case WidgetType.LOGIN:
					return user.permissions.leadPropertyOrganizer;
				case WidgetType.AGENT_LISTINGS:
					return user.permissions.broker;
				case WidgetType.OFFICE_LISTINGS:
					return user.permissions.broker;
				default:
					return true;
			}
		});
	}

	public render() {
		const { onSelect } = this.props;
		const { inputValue } = this.state;
		return (
			<CustomAutocomplete
				fullWidth
				placeholder="Select a widget"
				inputValue={inputValue}
				options={this.userPermittedWidgets()}
				optionLabelExtractor={(option) => option.label}
				disableClearable={false}
				onChange={(event, option) => {
					if(option && option.label) {
						this.setState({inputValue: option.label, widgetId: option.id});
						const widget = WidgetType.getById(option.id)
						if(widget) {
							onSelect(widget);
						}
					} else {
						this.setState({widgetId: ""});
					}
				}}
				onInputChange={(event, value, reason) => {
					if(reason !== "reset") {
						this.setState({inputValue: value});
					}
				}}
			/>
		);
	}
}

export const WidgetSelector = Component;
