import React from "react";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";import * as Mui from "@material-ui/core";
import { styles } from "../../style";
import { Dialog } from "component/shared/dialog";
import { Select } from "component/shared/select";
import { LeadPipelineType } from "type/lead-pipeline";
import { createLeadPipeline } from "redux/slice/lead-pipelines";
import * as yup from "yup";
import { validate } from "shared/yup";

const mapStateToProps = (state: RootState) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createLeadPipeline
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
	open: boolean, 
	onClose: Function,
	leadIds: number[],
}

interface State {
	pipelineId?: number;
	submitted: boolean;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			pipelineId: undefined,
			submitted: false,
		};
	}

	private validationSchema = {
		pipelineId: yup
			.string()
			.required("Please select a stage."),
	};

	private updateStage() {
		const { leadIds, createLeadPipeline } = this.props;
		const { pipelineId } = this.state;

		const errors = validate(this.validationSchema, this.state);

		if(!errors && pipelineId) {
			leadIds.forEach((id) => {
				const leadPipeline = {
					leadId: id,
					type: LeadPipelineType.getByIdOrDefault(pipelineId),
				}
				createLeadPipeline({leadPipeline});
			});
			this.onClose();
		} else {
			this.setState({ submitted: true });
		}
	}

	private onClose() {
		this.props.onClose();
		this.setState({ pipelineId: undefined, submitted: false });
	}

    render() {
		const { open, classes } = this.props;
		const { pipelineId, submitted } = this.state;
		const errors = validate(this.validationSchema, this.state);
		
        return (
			<Dialog
				open={open}
				onClose={() => this.onClose()}
				fullWidth={true}
				maxWidth="xs" 
			>
				<Mui.DialogTitle>Update Pipeline Stage</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<Mui.Grid container alignItems="center" justifyContent="center" spacing={1}>
						<Mui.Grid item xs={12}>
							<Mui.DialogContentText className={classes.dialogText}>Select a Pipeline Stage to be applied to all selected records.</Mui.DialogContentText>
						</Mui.Grid>
						<Mui.Grid container item xs={12} justifyContent="center">
							<Mui.FormControl className={classes.formControl}>
								<Select
									placeholder="Select a Stage"
									value={pipelineId}
									options={LeadPipelineType.values()}
									valueExtractor={(stage) => stage.id}
									labelExtractor={(stage) => stage && stage.label}
									optionRenderer={(stage) => (
										<Mui.ListItemText primary={stage && stage.label} />
									)}
									onChange={(value) => {
										this.setState({pipelineId: value as number})
									}}
									helperText={submitted && errors && errors.pipelineId.length ? errors.pipelineId : undefined}
								/>
							</Mui.FormControl>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
					<Mui.Button
						onClick={() => {
							this.updateStage()
						}}
						variant="contained"
						color="secondary"
						disabled={submitted && !pipelineId}
					>
						Save
					</Mui.Button>
					<Mui.Button 
						onClick={() => this.onClose()} 
						variant="outlined"
					>
						Cancel
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
		)
	}
}

export const BulkPipelineStageDialog = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);