import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { connect } from "react-redux";
import { RootState } from "redux/store";
import { getAgentById, getUser } from "redux/selector";
import { Agent } from "model/agent";
import { styles } from "./styles";
import { Profile } from "component/shared/profile";

interface Props extends PageProps, 
	Router.RouteComponentProps<RouteParams>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
}

interface RouteParams {
	id: string;
}

const getId = (props: Router.RouteComponentProps<RouteParams>) => {
	return parseInt(props.match.params.id);
}

const mapStateToProps = (state: RootState, ownProps: Router.RouteComponentProps<RouteParams>) => {
	const user = getUser(state);
	const id = getId(ownProps);
	let agent: Agent | undefined;
	if(id) {
		agent = getAgentById(state, id);
	} else {
		agent = getAgentById(state, user.agentId);
	}
	return {
		agent,
		user,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

class Component extends React.Component<Props> {

	public render() {
		const { user, agent } = this.props;
		const title = "My Profile";
		if (!agent) {
			return null;
		}
		return (
			<DashboardLayout
				permitted={user && user.permissions.broker}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{agent.name ? agent.name : title}
					</Mui.Typography>
				}
			>
				<Profile agent={agent}/>
			</DashboardLayout>
		);
	}

}

export const SettingsProfilePage = Mui.withStyles(styles)(Router.withRouter(connect(mapStateToProps, mapDispatchToProps)(Component)));