import React from "react";
import * as Mui from "@material-ui/core";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { fetchLeadActivities } from "redux/slice/lead-activities";
import { getLeadActivitiesChronological } from "redux/selector";
import { ActivityCard } from "component/page/lead/activity-tab/activity-card";
import { urls } from "routes/urls";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { withMediaQuery, WithMediaQuery } from "component/shared/with-media-query";
import { RecentMonthlyActivity } from "component/page/lead-activity/recent-monthly-stats";
import { User } from "model/user";

interface Props extends ReturnType<typeof mapStateToProps> {
	user: User;
}

const mapStateToProps = (state: RootState) => {
	return {
		leadActivities: getLeadActivitiesChronological(state),
		loading: state.leadActivities.loading,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	fetchLeadActivities
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	WithMediaQuery {}

class Component extends React.Component<Props> {

	public componentDidMount() {
		this.props.fetchLeadActivities({});
	}

	public render() {
		const { user, leadActivities, loading, mediaQuery } = this.props;
		const hundredLeadActivities = leadActivities.slice(0, 100);
		return (
			<Mui.Grid
				container
				spacing={4}
				direction={mediaQuery ? "column-reverse" : "row"}
			>
				<Mui.Grid container item xs={12} md={7}>
					<Mui.Grid
						container
						item
						spacing={4}
						direction="row"
						justifyContent="space-between"
					>
						<Mui.Grid item xs={12}>
							<Mui.Grid container direction="column" spacing={4} >
								<Mui.Grid item>
									<Mui.Typography variant="h3">Latest Activity</Mui.Typography>
								</Mui.Grid>
								<Mui.Grid item>
									{!!loading &&
										<Mui.Card>
											<Mui.CardContent>
												<Mui.Grid container justifyContent="center">
													<Mui.Grid item>
														<LoadingIndicator />
													</Mui.Grid>
												</Mui.Grid>
											</Mui.CardContent>
										</Mui.Card>
									}
									{!loading && !hundredLeadActivities.length &&
										<Mui.Card>
											<Mui.CardContent>
												<Mui.Grid container justifyContent="center">
													<Mui.Grid item>
														<Mui.Typography align="center">Lead activity has not yet been recorded on your site.</Mui.Typography>
													</Mui.Grid>
												</Mui.Grid>
											</Mui.CardContent>
										</Mui.Card>
									}
									{!loading && !!hundredLeadActivities.length && 
										<Mui.Grid container direction="column" spacing={4}>
											{hundredLeadActivities.map((activity, index) => (
												<Mui.Grid key={index} item>
													<ActivityCard activity={activity} showLeadName />
												</Mui.Grid>
											))}
											<Mui.Grid item style={{textAlign: "center"}}>
												<Mui.Link
													component={RouterLinkWrapper}
													to={urls.leadActivity}
												>
												</Mui.Link>
											</Mui.Grid>
										</Mui.Grid>
									}
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				{user && user.admin &&
					<RecentMonthlyActivity />
				}
			</Mui.Grid>
		);
	}

}

export const LeadActivity = withMediaQuery("(max-width:960px)")(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
