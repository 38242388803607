import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState, getPayload } from "redux/store";
import { urls } from "routes/urls";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import Unlayer, { HtmlExport, Design } from "react-email-editor";
import { updateCampaign, createCampaign } from "redux/slice/campaigns";
import { getAnniversaryCampaignByType } from "redux/selector"
import { updateCampaignMessage, createCampaignMessage } from "redux/slice/campaign-messages";
import { createCampaignCampaignMessage } from "redux/slice/campaign-campaign-messages";
import { EmailMarketingType } from "type/email-marketing-type";
import { validate } from "shared/yup";
import * as yup from "yup";
import { Campaign } from "model/campaign";
import { CampaignStatus } from "type/campaign-status";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { styles } from "./style";
import { unlayerOptions } from "shared/unlayer";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		campaign: getAnniversaryCampaignByType(state, ownProps.campaignType),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createCampaign,
	updateCampaign,
	updateCampaignMessage, 
	createCampaignMessage,
	createCampaignCampaignMessage,
}, dispatch);

interface OwnProps {
	campaignType: EmailMarketingType;
}

interface Props extends
	OwnProps,
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
}

interface State {
	subject: string;
	maxCharacters: boolean;
	status: string;
	submitted: boolean;
}

const MAX_CHARACTERS = 256;

class Component extends React.Component<Props, State> {

	private unlayer: Unlayer | null = null;

	public constructor(props: Props) {
		super(props);
		this.state = {
			subject: "",
			status: "Inactive",
			submitted: false,
			maxCharacters: false,
		};
	}

	public componentDidMount() {
		const { campaign } = this.props;
		if (campaign) {
			this.set(campaign);
		}
	}

	public componentDidUpdate(prevProps: Props) {
		const { campaign } = this.props;
		if((!prevProps.campaign && campaign) ) {
			this.set(campaign);
		}
	}

	private set(campaign: Campaign) {
		const status = campaign.status ? campaign.status.id : CampaignStatus.INACTIVE.id;
		let subject = "";
		const { campaignMessage } = this.data;
		if(campaignMessage) {
			subject = campaignMessage.subject ? campaignMessage.subject : "";
		}
		this.setState({ status, subject });
	}

	private onLoad() {
		const interval = setInterval(() => {
			const { campaignMessage } = this.data;
			const { unlayer } = this;
			if(unlayer && campaignMessage && campaignMessage.jsonContent) {
				const design = JSON.parse(campaignMessage.jsonContent) as Design;
				unlayer.loadDesign(design);
				clearInterval(interval);
			}
		}, 500);
	}

	private get data() {
		let campaign = null;
		let campaignCampaignMessage = null;
		let campaignMessage = null;
		if(this.props.campaign) {
			campaign = this.props.campaign;
		}
		if(campaign) {
			campaignCampaignMessage = campaign.campaignCampaignMessages[0];
		}
		if(campaignCampaignMessage && campaignCampaignMessage.campaignMessage) {
			campaignMessage = campaignCampaignMessage.campaignMessage;
		}
		return { campaign, campaignCampaignMessage, campaignMessage };
	}

	private disableButton() {
		this.setState({ submitted: true }, () => this.save());
	}

	private save() {
		const { saveType } = this; 
		const errors = validate(this.validationSchema, this.state);
		if(!errors) {
			if(saveType === "create") { 
				this.create();
			} else if(saveType === "update") {
				this.update();
			}
		}
	}
	
	private async create() {
		const { campaignType, createCampaignMessage, createCampaignCampaignMessage } = this.props;
		const { subject, status } = this.state;
		const unlayer = this.unlayer;
		if(unlayer) {
			const data = await new Promise<HtmlExport>(resolve => unlayer.exportHtml(resolve));
			const campaign =  getPayload(await createCampaign({ campaign: {
				name: subject,
				emailMarketingType: campaignType,
				status: CampaignStatus.getById(status),
			}}));
			if(!campaign.id) {
				throw new Error();
			}
			const campaignMessage = getPayload(await createCampaignMessage({ campaignMessage: {
				name: subject,
				subject,
				htmlContent: data.html,
				jsonContent: JSON.stringify(data.design),
				ownedByReseller: false,
				isInactiveYn: false,
			}}));
			if(campaign.id && campaignMessage.id) {
				getPayload(await createCampaignCampaignMessage({ campaignCampaignMessage: {
					campaignId: campaign.id,
					campaignMessageId: campaignMessage.id,
					messageOrder: 0,
				}}));
				this.setState({submitted: false})
			}
		}
	}
	
	private async update() {
		const { updateCampaign, updateCampaignMessage } = this.props;
		let { subject, status } = this.state;
		const { campaign, campaignCampaignMessage, campaignMessage } = this.data;
		if(!campaign || !campaignCampaignMessage || !campaignMessage) {
			throw new Error();
		}
		if(!subject && campaignMessage.subject) {
			subject = campaignMessage.subject;
		}
		const { unlayer } = this;
		if(unlayer) {
			const data = await new Promise<HtmlExport>(resolve => unlayer.exportHtml(resolve));
			getPayload(await updateCampaign({ campaign: {
				...campaign,
				status: CampaignStatus.getById(status),
			}}));
			await getPayload(updateCampaignMessage({ campaignMessage: {
				...campaignMessage,
				name: subject,
				subject,
				htmlContent: data.html,
				jsonContent: JSON.stringify(data.design),
			}}));
			this.setState({submitted: false})
		}
	}

	private handleRadioClike(status: "Active" | "Inactive") {
		this.setState({status});
	}

	private get saveType() {
		const { campaign } = this.props;
		if(campaign) {
			return "update";
		} 
		return "create";
	}

	private validationSchema = {
		subject: yup
			.string()
			.required("Subject line is required")
			.test(
				"check-max-subject-line-characters",
				"Subject line cannot exceed 256 characters.",
				(subject) => {
					let pass = true;
					if(subject && subject.length >= (MAX_CHARACTERS)) {
						pass = false;
					}
					return pass;
				}
			)
	};

	public render() {
		let { subject, status, submitted, maxCharacters } = this.state;
		const { campaignType, classes } = this.props;
		const errors = validate(this.validationSchema, this.state);
		const { campaign, campaignCampaignMessage, campaignMessage } = this.data;
		if(!campaign || !campaignCampaignMessage || !campaignMessage) {
			return <LoadingIndicator />;
		}
		return (
			<Mui.Grid container direction="column" spacing={4}>
				<Mui.Grid item>
					<Mui.Card>
						<Mui.CardContent>
							<Mui.Grid container direction="column" spacing={2}>
								<Mui.Grid item>
									<Mui.TextField
										required
										label="Subject Line"
										placeholder="Enter subject line here"
										value={subject}
										onChange={event => {
											const input = event.target.value;
											if(subject.length < MAX_CHARACTERS || input.length < subject.length) {
												this.setState({ subject: input, maxCharacters: false });
											} else {
												this.setState({ maxCharacters: true });
											}
											
										}}
										error={maxCharacters && !!errors && !!errors.subject}
										helperText={submitted && errors ? errors.subject : (maxCharacters && errors && errors.subject) }
										fullWidth
										margin="dense"
										variant="outlined"
									/>
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Grid 
										container
										direction="row"
										alignItems="center"
										spacing={2}
									>
										<Mui.Grid item>
											<Mui.Typography>Automatically send {campaignType.label} Emails</Mui.Typography>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.RadioGroup
												row
												value={status}
												onChange={event => this.handleRadioClike(event.target.value as any)}
											>
												<Mui.FormControlLabel
													value="Active"
													control={<Mui.Radio color="primary" />}
													label="On"
													labelPlacement="end"
													disabled={!!(submitted && errors && errors.subject)}
												/>
												<Mui.FormControlLabel
													value="Inactive"
													control={<Mui.Radio color="primary" />}
													label="Off"
													labelPlacement="end"
													disabled={!!(submitted && errors && errors.subject)}
												/>
											</Mui.RadioGroup>
											
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.CardContent>
						<Mui.CardActions>
							<Mui.Grid container spacing={2} justifyContent="space-between">
								<Mui.Grid item>
									<Mui.Grid container spacing={2}>
										<Mui.Grid item>
											<Mui.Button
												variant="contained"
												color="secondary"
												onClick={() => this.disableButton()}
												disabled={submitted || !!(submitted && errors && errors.subject)}
												className={classes.button}
											>
												{!submitted && "Save"}
												{!!submitted && "Saving"}
											</Mui.Button>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Button
												component={RouterLinkWrapper}
												to={urls.campaignDraftEmails}
												variant="outlined"
												color="primary"
											>
												Cancel
											</Mui.Button>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.CardActions>
					</Mui.Card>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Card>
						<Mui.CardContent>
							<Unlayer
								ref={unlayer => this.unlayer = unlayer}
								options={unlayerOptions}
								onReady={() => this.onLoad()}
							/>
						</Mui.CardContent>
					</Mui.Card>
				</Mui.Grid>
			</Mui.Grid>
		);
	}

}

export const AnniversaryEdit = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);