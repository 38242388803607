import { arrayToById } from "redux/normalize";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { IntegrationCalendarResource } from "api/resource/integration-calendar";

export interface IntegrationCalendarsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: string]: IntegrationCalendarResource;
	};
};

const initialState: IntegrationCalendarsState = {
	byId: {},
};

const fetchIntegrationCalendars = createAsyncThunk("integrationCalendars/fetchIntegrationCalendars", async (payload: { integrationId: number }, thunkAPI) => {
	thunkAPI.dispatch(integrationCalendarsLoading());
	const result = api.getIntegrationCalendars(payload.integrationId);
	result
		.then((integrationCalendars) => thunkAPI.dispatch(integrationCalendarsLoaded(integrationCalendars)))
		.catch(error => thunkAPI.dispatch(integrationCalendarsFailed(error)));
	return result;
});

export const integrationCalendarsSlice = createSlice({
	name: "integrationCalendars",
	initialState,
	reducers: {
		integrationCalendarsLoading: (state) => {
			state.loading = true;
		},
		integrationCalendarsLoaded: (state, action: PayloadAction<IntegrationCalendarResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
		},
		integrationCalendarsFailed: (state, action: PayloadAction<Error>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
        default: (state) => {
            return state;
        }
	},
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});

export const { integrationCalendarsLoading, integrationCalendarsLoaded, integrationCalendarsFailed } = integrationCalendarsSlice.actions;
export { fetchIntegrationCalendars };