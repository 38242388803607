import React from "react"
import * as Mui from "@material-ui/core";
import { bindActionCreators, Dispatch } from "redux";
import { Market } from "model/market";
import { connect } from "react-redux";
import { updateMarket } from "redux/slice/markets";
import { updateMarketReport } from "redux/slice/market-reports";
import { SaveButton } from "component/shared/save-button";
import { RootState } from "redux/store";
import { styles } from "./style";

interface Props extends 
	ReturnType<typeof mapDispatchToProps>,
	ReturnType<typeof mapStateToProps>,
	Mui.WithStyles<typeof styles> {
		market: Market;
	}

interface State {
	description: string, 
	showOnIndex: boolean, 
	sortOrder: string,
	saved: boolean,
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateMarket,
	updateMarketReport,
}, dispatch);

const mapStateToProps = (state: RootState) => {
	return {
		loading: state.markets.loading 
	};
}

class Component extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			description: this.props.market.description ? this.props.market.description : "",
			showOnIndex: this.props.market.showOnIndex ? this.props.market.showOnIndex : true,
			sortOrder: this.props.market.sortOrder ? this.props.market.sortOrder.toString() : "",
			saved: false,
		}
	}

	private save() {
		const { market } = this.props;
		const { description, showOnIndex, sortOrder } = this.state;
		if(market.listingReport) {
			this.props.updateMarket({ market: {
				...market,
				description: description,
				sortOrder: !!sortOrder ? parseInt(sortOrder) : undefined,
				showOnIndex: showOnIndex,
			}});
		}
	}

	render() {
		const { loading, classes } = this.props;
		const { description, showOnIndex, sortOrder, saved } = this.state;
		return (
			<Mui.Grid container direction='column' spacing={2}>
				<Mui.Grid item >
					<Mui.Grid container direction='row' justifyContent="space-between" wrap="nowrap">
						<Mui.Grid item xs={2}>
							<Mui.Typography>Short Description</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={9}>
							<Mui.TextField
								variant="outlined"
								fullWidth
								size='small'
								value={description}
								onChange={(event) => {
									this.setState({description: event.target.value})
								}}
							/>
							<Mui.Typography>(appears on Markets Index page)</Mui.Typography>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Grid container direction='row' justifyContent="space-between" wrap="nowrap">
						<Mui.Grid item xs={2}>
							<Mui.Typography>Sort Order</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={9}>
						<Mui.TextField
								variant="outlined"
								type="number"
								fullWidth
								size='small'
								value={sortOrder}
								onChange={(event) => {
									this.setState({sortOrder: event.target.value})
								}}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Grid container direction='row' justifyContent="space-between" wrap="nowrap">
						<Mui.Grid item xs={2}>
							<Mui.Typography>Show on Saved Search Index Page</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={9}>
							<Mui.Checkbox
								checked={showOnIndex}
								onChange={(event) => {
									this.setState({showOnIndex: !showOnIndex})
								}}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item className={classes.saveRow}>
					<SaveButton 
						loading={!!loading && saved} 
						label={{
							primary: "Save",
							inProgress: "Saving...",
							completed: "Saved",
						}}
						snackBar={true}
						onClick={() => {
							this.setState({saved: true});
							this.save();
						}}
						onReset={() => this.setState({saved: false})}
					/>
				</Mui.Grid>
			</Mui.Grid>
		)
	}
}

export const GeneralContent = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));