import React from "react";
import * as Mui from "@material-ui/core";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import moment from "moment";
import { TaskCard } from "./task-card";
import { Task } from "model/task";
import { Select } from "component/shared/select";
import { getFilteredTasks } from "redux/selector";
import { getTasksPageFiltersCount, getTasksPageFilters } from "redux/selector";
import { setTasksPageFilters } from "redux/slice/tasks-page";
import { AddEditTaskDialog } from "component/shared/add-edit-task-dialog";
import Fuse from "fuse.js";
import { styles } from "./styles";
import { FilterDialog } from "./filter-dialog";
import queryString from "query-string";
import { TasksPageFilters } from "model/tasks-page-filters";
import { TaskFilterType } from "type/task-filter";
import { bindActionCreators, Dispatch } from "redux";
import { TasksPageFiltersState } from "redux/slice/tasks-page"; 

const mapStateToProps = (state: RootState) => {
	const tasks = getFilteredTasks(state);
	const filterCount = getTasksPageFiltersCount(state);
	const filters = getTasksPageFilters(state);
	const options: Fuse.IFuseOptions<Task> = {
		shouldSort: true,
		threshold: 0.5,
		location: 0,
		distance: 100,
		minMatchCharLength: 1,
		keys: [ 
			"title",
			"description"
		],
	};
	return {
		loading: state.tasks.loading,
		tasks,
		filterCount,
		filters,
		fuse: new Fuse(tasks, options),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	setTasksPageFilters
}, dispatch);

interface Props extends ReturnType<typeof mapStateToProps>,
	PageProps,
	Router.RouteComponentProps,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{}

interface State {
	editTaskDialogIsOpen: boolean;
	tasksFilterDialogIsOpen: boolean;
	task: Task | null;
	term: string;
	sortTypeId: number;
}

interface TaskByDate {
	[date: string]: Task[];
}

interface TaskByFirstLetter {
	[letter: string]: Task[];
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			editTaskDialogIsOpen: false,
			tasksFilterDialogIsOpen: false,
			task: null,
			term: "",
			sortTypeId: 1
		};
	}

	public componentDidMount() {
		const filters = this.getFiltersFromParams();
		if(Object.keys(filters).length) {
			this.props.setTasksPageFilters(filters);
		}
	}

	public componentDidUpdate(prevProps: Props) {
		if(prevProps.filters !== this.props.filters) {
			this.updateUrl(this.props.filters);
		}
	}

	private getTasksByDate = (tasks: Task[]) => {
		let tasksByDate: TaskByDate = {
			0: [], // Overdue tasks
		};
		tasks.forEach(task => {
			const date = task.endOn;
			// Group all overdue tasks together
			if (date < moment()) {
				tasksByDate[0].push(task);
			} else {
				// Group all other tasks by date
				const dateKey = date.format("YYYYMMDD");
				if (tasksByDate[dateKey]) {
					tasksByDate[dateKey].push(task);
				} else {
					tasksByDate[dateKey] = [task];
				}
			}
		});
		return tasksByDate;
	};

	private getTasksByFirstLetter = (tasks: Task[]) => {
		let tasksByFirstLetter: TaskByFirstLetter = {};
		tasks.forEach(task => {
			const firstLetter = task.title[0].toUpperCase();
			const charCode = firstLetter.charCodeAt(0);

			// Is alphanumeric
			if (
				("0".charCodeAt(0) <= charCode && charCode <= "9".charCodeAt(0)) ||
				("A".charCodeAt(0) <= charCode && charCode <= "Z".charCodeAt(0))
			) {
				if (tasksByFirstLetter[firstLetter]) {
					tasksByFirstLetter[firstLetter].push(task);
				} else {
					tasksByFirstLetter[firstLetter] = [task];
				}
			}
			// Is non-alphanumeric
			else {
				if (tasksByFirstLetter["#"]) {
					tasksByFirstLetter["#"].push(task);
				} else {
					tasksByFirstLetter["#"] = [task];
				}
			}
		});
		return tasksByFirstLetter;
	};

	private getFiltersFromParams = (): TasksPageFilters => {
		const query = queryString.parse(this.props.location.search);
		const filters: TasksPageFiltersState = {};
		Object.keys(query).forEach((name) => {
			let value: any = query[name];
			const type = TaskFilterType.getById(name);
			switch (type) {
				case TaskFilterType.TYPE: {
					if(Array.isArray(value)) {
						filters.type = value.map((val: string) => parseInt(val));
					} else {
						filters.type = [parseInt(value)];
					}
					break;
				}
				case TaskFilterType.STATUS: {
					if(Array.isArray(value)) {
						filters.status = value.map((val: string) => parseInt(val));
					} else {
						filters.status = [parseInt(value)];
					}
					break;
				}
				case TaskFilterType.PRIORITY: {
					filters.priority = value === "true";
					break;
				}
				case TaskFilterType.TODAY: {
					filters.today = value === "true";
					break;
				}
				case TaskFilterType.LEAD: {
					filters.lead = parseInt(value);
					break;
				}
			}
		});
		return filters;
	}

	private updateUrl = (filters: TasksPageFilters) => {
		let queryParams: {
			[key: string]: any
		} = {
			...this.getOtherQueryParams(),
		};
		Object.keys(filters).forEach((name) => {
			// @ts-ignore
			const value = filters[name];
			queryParams[name] = value;
		});
		const query = queryString.stringify(queryParams);
		this.props.history.push({search: query});
	}

	private getOtherQueryParams = () => {
		const query = queryString.parse(this.props.location.search);
		const queryParams: {
			[key: string]: any
		} = {};
		Object.keys(query).forEach((key) => {
			if(!TaskFilterType.getById(key)) {
				queryParams[key] = query[key];
			}
		});
		return queryParams;
	}

	private showTasksFilterDialog = () =>
		this.setState({ tasksFilterDialogIsOpen: true });

	private hideTasksFilterDialog = () =>
		this.setState({ tasksFilterDialogIsOpen: false });

	private onTermChange = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) =>
		this.setState({ term: event.target.value });

	private renderNoTasksMessage = () => {
		return (
			<Mui.Grid container item justifyContent="center" style={{ marginTop: 45 }}>
				<Mui.Typography variant="h3" align="center">
					You have no tasks at the moment. Click the Add New button to create a
					new task.
				</Mui.Typography>
			</Mui.Grid>
		)
	}

	private renderSectionsByDueDate = () => {
		const { tasks } = this.props;
		let todayAndPreviousDates: string[] = [];
		let upcomingDates: string[] = [];
		const tasksByDate = this.getTasksByDate(tasks);

		Object.keys(tasksByDate)
			.sort()
			.forEach(date => {
				if(parseInt(moment().format("YYYYMMDD")) - parseInt(date) >= 0) {
					todayAndPreviousDates.push(date);
				} else {
					upcomingDates.push(date);
				}
			});

		const renderSectionByDueDate = (header: string, dates: string[]) => {
			let { tasks, fuse } = this.props;
			const { term } = this.state;
			if (term) {
				tasks = fuse.search(term).map(result => result.item);
			}
			const tasksByDate = this.getTasksByDate(tasks);
			if(!dates.some(date => tasksByDate[date] && !!tasksByDate[date].length)) {
				return null;
			}
			return (
				<>
					{this.renderSectionHeader(header)}
					{dates.map((date, index) => {
						const tasks = tasksByDate[date] || [];
						if(tasks.length) {
							const dateTimestamp = tasks[0].endOn;
							let subHeader = "";
							if(date === "0") {
								subHeader = "Overdue";
							} else if( // If Overdue subheader shows and if date is today
								tasksByDate["0"].length &&
								dateTimestamp.format("YYYYMMDD") === moment().format("YYYYMMDD")
							) {
								subHeader = "Today";
							} else if( // If Upcoming
								dateTimestamp.format("YYYYMMDD") !== moment().format("YYYYMMDD") &&
								dateTimestamp.unix() - moment().unix() > 0
							) {
								subHeader = dateTimestamp.format("dddd, MMMM Do");
							}
							return this.renderSectionBody(subHeader, tasks, index);
						}
						return null;
					})}
				</>
			);
		};

		return (
			<>
				{renderSectionByDueDate("Due Today", todayAndPreviousDates)}
				{renderSectionByDueDate("Upcoming", upcomingDates)}
			</>
		);
	};

	private renderSectionsAlphabetically = () => {
		let { tasks, fuse } = this.props;
		const { term } = this.state;
		if(term) {
			tasks = fuse.search(term).map(result => result.item);
		}
		const sortedTasks = this.getTasksByFirstLetter(tasks);
		return Object.keys(sortedTasks).map((letter, index) =>
			this.renderSectionBody(letter, sortedTasks[letter], index)
		);
		/*for(let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {
			const subHeader = String.fromCharCode(i);
			return this.renderSectionBody(subHeader, sortedTasks[i], i)
		}*/
	};

	private renderSectionHeader = (header: string) => {
		return (
			<>
				<Mui.Grid item xs={2} />
				<Mui.Grid item xs={10}>
					<Mui.Typography
						variant="h3"
						style={{ textTransform: "uppercase" }}
					>
						{header}
					</Mui.Typography>
				</Mui.Grid>
			</>
		);
	};

	private renderSectionBody = (
		subHeader: string,
		tasks: Task[],
		index: number
	) => (
		<Mui.Grid container item spacing={2} key={index}>
			<Mui.Hidden mdUp>
				<Mui.Grid item xs={12}>
					<Mui.Typography variant="h5" >{subHeader}</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					{tasks.map((task, index) => {
						return (
							<TaskCard
								task={task}
								key={index}
								onClick={() => this.setState({ editTaskDialogIsOpen: true, task })}
							/>
						);
					})}
				</Mui.Grid>
			</Mui.Hidden>
			<Mui.Hidden smDown>
				<Mui.Grid container item spacing={4} key={index}>
					<Mui.Grid item xs={2}>
						<Mui.Typography align="right" variant="h5" >{subHeader}</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item xs={10}>
						{tasks.map((task, index) => {
							return (
								<div key={index}>
									<TaskCard
										task={task}
										onClick={() => this.setState({ editTaskDialogIsOpen: true, task })}
									/>
									<Mui.Divider light />
								</div>
							);
						})}
					</Mui.Grid>
				</Mui.Grid>
			</Mui.Hidden>
		</Mui.Grid>
	);

	public render() {
		const { loading, user, tasks, classes, filterCount } = this.props;
		const {
			editTaskDialogIsOpen,
			task,
			tasksFilterDialogIsOpen,
			sortTypeId
		} = this.state;
		const title = "To Do";
		
		return (
			<DashboardLayout
				permitted={user && user.permissions.crmTasks}
				title={title}
				header={
					<Mui.Grid container justifyContent="space-between" alignItems="center">
						<Mui.Grid item xs={3}>
							<Mui.Typography variant="h1">
								<FeatherIcon>
									<Icons.User />
								</FeatherIcon>
								{title}
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={6}>
							<Mui.InputBase
								placeholder="Search Tasks"
								fullWidth
								onChange={this.onTermChange}
								className={classes.searchBar}
							/>
						</Mui.Grid>
						<Mui.Grid item xs={3} className={classes.filter}>
							<Mui.Button
								variant="contained"
								color="secondary"
								onClick={() => {
									this.showTasksFilterDialog();
								}}
							>
								Filters {filterCount ? `(${filterCount})` : ""}
							</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
				}
			>
				<FilterDialog
					open={tasksFilterDialogIsOpen}
					onClose={this.hideTasksFilterDialog}
					count={this.props.tasks.length}
				/>
				<AddEditTaskDialog
					open={editTaskDialogIsOpen}
					onClose={() => this.setState({ editTaskDialogIsOpen: false })}
					task={task}
				/>
				<Mui.Grid container spacing={3} direction="row">
					<Mui.Grid container item xs={12} justifyContent="space-between">
						<Mui.Button
							variant="contained"
							color="secondary"
							onClick={() => this.setState({ editTaskDialogIsOpen: true, task: null })}
						>
							<Mui.Typography>Add New</Mui.Typography>
						</Mui.Button>
						<Select
							placeholder="Sort by"
							value={sortTypeId}
							options={[
								{
									id: 1,
									label: "By Due Date",
								},
								{
									id: 2,
									label: "Alphabetically",
								}
							]}
							valueExtractor={type => type.id}
							labelExtractor={type => type.label}
							optionRenderer={type => <Mui.ListItemText primary={type.label} />}
							onChange={value => this.setState({ sortTypeId: value || 1 })}
						/>
					</Mui.Grid>
					{!loading && !tasks.length && this.renderNoTasksMessage()}
					{!!tasks.length && sortTypeId === 1 && this.renderSectionsByDueDate()}
					{!!tasks.length && sortTypeId === 2 && this.renderSectionsAlphabetically()}
				</Mui.Grid>
			</DashboardLayout>
		);
	}
}

export const TasksPage = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
