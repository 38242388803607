import React from "react";
import * as Mui from "@material-ui/core";
import { LeadNoteCard } from "./lead-note-card";
import { Lead } from "model/lead";
import { LeadNote } from "model/lead-note";
import { LeadNoteType } from "type/lead-note-type";
import { styles } from "./style";
import { RootState } from "redux/store";
import { getActiveLeadNotesForLeadChronological } from "redux/selector";
import { LeadNoteDialog } from "./lead-note-dialog";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FeatherIcon } from "component/shared/feather-icon";
import { Select } from "component/shared/select";
import { LoadingIndicator } from "component/shared/loading-indicator";

const mapStateToProps = (state: RootState, ownProps: any) => {
	return {
		leadNotes: getActiveLeadNotesForLeadChronological(state, ownProps.lead.id),
		loading: state.leadNotes.loading
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
	lead: Lead;
}

interface State {
	selectedLeadNoteTypes: Set<LeadNoteType>;
	leadNoteDialogIsOpen: boolean;
}

class Component extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			selectedLeadNoteTypes: new Set(LeadNoteType.values()),
			leadNoteDialogIsOpen: false
		};
	}

	handleChange(typeIds: number[]) {
		const selectedLeadNoteTypes = new Set<LeadNoteType>();
		typeIds.forEach((id) => {
			const leadNoteType = LeadNoteType.getById(id);
			if(leadNoteType) {
				selectedLeadNoteTypes.add(leadNoteType);
			}
		});
		this.setState({ 
			selectedLeadNoteTypes,
		});
	}

	public render() {
		const { lead, loading } = this.props;
		let { leadNotes } = this.props;
		const { selectedLeadNoteTypes } = this.state;
		const { leadNoteDialogIsOpen } = this.state;
		const filteredLeadNotes = leadNotes.filter((leadNote) => {
			return selectedLeadNoteTypes.has(leadNote.type);
		});
		const leadNoteTypes = new Set(leadNotes.map(leadNote => leadNote.type));
		const selectedLeadNoteTypeIds = Array.from(selectedLeadNoteTypes).map(type => type.id);
		if(!!loading) {
			return (
				<Mui.Grid container justifyContent="center">
					<Mui.Grid item>
						<LoadingIndicator />
					</Mui.Grid>
				</Mui.Grid>
			)
		} else if(!lead.note && !leadNotes.length && !loading) {
			return (
				<>
					<LeadNoteDialog
						open={leadNoteDialogIsOpen}
						onClose={() => this.setState({leadNoteDialogIsOpen: false})}
						lead={lead}
					/>
					<Mui.Grid container justifyContent="space-between">
						<Mui.Grid item></Mui.Grid>
						<Mui.Grid item>
							<Mui.Button
								variant="contained"
								color="secondary"
								onClick={() => this.setState({leadNoteDialogIsOpen: true})}
							>
								<Mui.Typography>Add New</Mui.Typography>
							</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Typography align="center" style={{padding: "30px 50px"}}>This lead does not have any notes yet.</Mui.Typography>
				</>
			);
		}
		return (
			<>
				<LeadNoteDialog
					open={leadNoteDialogIsOpen}
					onClose={() => this.setState({leadNoteDialogIsOpen: false})}
					lead={lead}
				/>
				<Mui.Grid container direction="column" spacing={2}>
					<Mui.Grid item>
						<Mui.Grid container justifyContent="space-between">
							<Mui.Grid item>
								<Mui.FormControl>
									<Select
										multiple
										placeholder="Filters"
										value={selectedLeadNoteTypeIds}
										options={Array.from(leadNoteTypes)}
										valueExtractor={(type) => type.id}
										labelExtractor={(type) => type.label}
										optionRenderer={(type) => (
											<>
												<Mui.ListItemIcon>
													<FeatherIcon>
														<type.icon />
													</FeatherIcon>
												</Mui.ListItemIcon>
												<Mui.ListItemText primary={type.label} />
											</>
										)}
										onChange={(values) => this.handleChange(values)}
									/>
								</Mui.FormControl>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Button
									variant="contained"
									color="secondary"
									onClick={() => this.setState({leadNoteDialogIsOpen: true})}
								>
									<Mui.Typography>Add New</Mui.Typography>
								</Mui.Button>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					{filteredLeadNotes.map((leadNote, index) => (
						<Mui.Grid key={index} item>
							<LeadNoteCard lead={lead} leadNote={leadNote} />
						</Mui.Grid>
					))}
					{lead.note && (
						<Mui.Grid item>
							<LeadNoteCard
								lead={lead}
								leadNote={
									{ text: lead.note, type: LeadNoteType.NOTE } as LeadNote
								}
								isExistingNote
							/>
						</Mui.Grid>
					)}
					{filteredLeadNotes.length === 0 && !lead.note &&
						<Mui.Grid item>
							<Mui.Typography align="center">Select a filter to see results.</Mui.Typography>
						</Mui.Grid>
					}
				</Mui.Grid>
			</>
		);
	}
}

export const HistoryTab = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
