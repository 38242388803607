import React from "react";
import * as Mui from "@material-ui/core";
import { Chart } from "./chart";
import { connect } from "react-redux";
import { RootState } from "redux/store";
import { getLatestLeadPipelineForLead, getLeadsByUnassignedAgent } from "redux/selector";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Icons from "react-feather";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { urls } from "routes/urls";
import { getActiveLeads, getActiveLeadsByAgentId } from "redux/selector";
import { LeadPipeline } from "model/lead-pipeline";
import { LeadPipelineType } from "type/lead-pipeline";
import moment from "moment";
import { styles } from "./style";
import { LoadingIndicator } from "component/shared/loading-indicator";

const mapStateToProps = (state: RootState) => {
	const agentId = state.agents.value
	const activeLeadsIds = getActiveLeads(state).map(lead => lead.id);
	const activeLeadsIdsByAgent = getActiveLeadsByAgentId(state, agentId).map(lead => lead.id);
	const latestLeadPipelines = activeLeadsIds
		.map(leadId => getLatestLeadPipelineForLead(state, leadId))
		.filter(Boolean) as LeadPipeline[];
	const latestLeadPipelinesByAgent = activeLeadsIdsByAgent
		.map(leadId => getLatestLeadPipelineForLead(state, leadId))
		.filter(Boolean) as LeadPipeline[];
	const unassigned = localStorage.getItem("agentId") === "unassigned";
	return {
		loading: state.leadPipelines.loading,
		latestLeadPipelines,
		latestLeadPipelinesByAgent,
		agentId,
		leadsByUnassigned: getLeadsByUnassignedAgent(state),
		unassigned
	};
};

interface Props extends ReturnType<typeof mapStateToProps>, Mui.WithStyles {}

class Component extends React.Component<Props> {
	private is80PercentUncontacted = (leadPipelines: LeadPipeline[]) => {
		const uncontactedCount = leadPipelines.filter(
			pipeline => pipeline.type === LeadPipelineType.UNCONTACTED
		).length;
		return uncontactedCount / leadPipelines.length >= 0.8;
	};

	public render() {
		const { latestLeadPipelines, loading, classes, latestLeadPipelinesByAgent, agentId, unassigned, leadsByUnassigned } = this.props;
		const leadPipelines = !unassigned ? latestLeadPipelines : latestLeadPipelines.filter(pipeline => leadsByUnassigned.some(lead => lead.id === pipeline.leadId));
		const currentNonDeadPipelines = (!agentId ? leadPipelines : latestLeadPipelinesByAgent).filter(stage => {
			if (stage.type.closed && stage.createdOn < moment().subtract(1, "y")) {
				return false;
			} else if (stage.type === LeadPipelineType.DEAD) {
				return false;
			}
			return true;
		});

		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Mui.Grid container justifyContent="space-between">
						<Mui.Grid item>
							<Mui.Typography variant="h3">Pipeline Status</Mui.Typography>
						</Mui.Grid>
						{this.is80PercentUncontacted(!agentId ? latestLeadPipelines : latestLeadPipelinesByAgent) && (
							<Mui.Grid item>
								<Mui.Tooltip
									classes={{ tooltip: classes.tooltip }}
									title={
										<RouterLinkWrapper to={urls.leads()} className={classes.text}>
											This chart displays all your leads by stage. You can track lead
											progress by updating their pipeline status.
										</RouterLinkWrapper>
									}
									placement="bottom-end"
									interactive
								>
									<Mui.IconButton
										size="small"
										component={RouterLinkWrapper}
										to={urls.leads()}
									>
										<FeatherIcon>
											<Icons.AlertTriangle />
										</FeatherIcon>
									</Mui.IconButton>
								</Mui.Tooltip>
							</Mui.Grid>
						)}
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Card>
						<Mui.CardContent style={{ height: 350 }}>
							{!!loading && (
								<Mui.Grid container justifyContent="center" alignItems="center" direction="row"> 
									<Mui.Grid item>
										<LoadingIndicator />
									</Mui.Grid>
								</Mui.Grid>
							)}
							{!loading && !currentNonDeadPipelines.length && (
								<Mui.Grid container alignItems="center" style={{ height: "100%" }}>
									<Mui.Grid item>
										<Mui.Typography align="center">Add Pipeline Statuses to your Leads to see this chart.</Mui.Typography>
									</Mui.Grid>
								</Mui.Grid>
							)}
							{!!currentNonDeadPipelines.length && (
								<Chart leadPipelines={!agentId ? latestLeadPipelines : latestLeadPipelinesByAgent} />
							)}
						</Mui.CardContent>
					</Mui.Card>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const PipelineStatus = Mui.withStyles(styles)(
	connect(mapStateToProps)(Component)
);
