export const formatPhoneNumber = (phoneNumberString: string) => {
	let result = phoneNumberString;
	const cleaned = phoneNumberString.replace(/\D/g, "");
	if (cleaned.length === 10) {
		const match = cleaned.match(/(\d{3})(\d{3})(\d{4})/);
		if (match) {
			result = "(" + match[1] + ") " + match[2] + "-" + match[3];
		}
	}
	return result;
};

export const normalizePhoneNumberInput = (
	value?: string,
	previousValue?: string
) => {
	if (!value || !previousValue) {
		return value;
	}

	const currentValue = value.replace(/[^\d]/g, "");
	const cvLength = currentValue.length;

	if (!previousValue || value.length > previousValue.length) {
		if (cvLength < 4) {
			return currentValue;
		}
		if (cvLength < 7) {
			return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
		}
		return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
			3,
			6
		)}-${currentValue.slice(6, 10)}`;
	}
};