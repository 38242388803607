import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "../style";
import { YesNoRadioFieldButton } from "component/shared/yes-no-radio-field-button";
import { saveUser } from "redux/slice/authentication";
import { bindActionCreators, Dispatch } from "redux";
import { UserPartial } from "model/user";
import { connect } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import * as Icons from "react-feather";
import { getUserUnsafe } from "redux/selector";
import { RootState } from "redux/store";



interface State {
	saving: boolean;
	roundRobinRouting?:boolean | undefined
}

interface Props
	extends 
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		Mui.WithStyles<typeof styles> {
	}


const mapStateToProps = (state: RootState) => {
	const user = getUserUnsafe(state);
	return {
		user,
		authLoading: state.authentication.loading,
		authError: state.authentication.error
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			saveUser,
		},
		dispatch
	);


class Component extends React.Component<Props, State> {
	
	public constructor(props: Props) {
		
		super(props);
		this.state = {...props.user, 
			saving: false, 
		}
	}

	public save() {
		const { saveUser } = this.props;
		const user: UserPartial = {
			...this.state,
		}
		saveUser({user});
		this.setState({ saving: true });
	}

	public render() {
		const { classes,  authError, authLoading} = this.props;
		const { roundRobinRouting, saving } = this.state

		return (
			<Mui.Grid container spacing={2}>
			<Mui.Grid item xs={12}>
				<Mui.Grid container>
					<Mui.Grid item className={classes.radioGroup} xs={10}>
									<YesNoRadioFieldButton
										label="Round Robin Routing"
										onOff={true}
										value={roundRobinRouting}
										default={true}
										onChange={(value) =>
											this.setState({ roundRobinRouting: value })
										}
										saving={saving}
										onClick={()=> this.save()}
									/>
								</Mui.Grid>		
								
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid container spacing={1} style={{ marginTop: 24 }}>
						
						</Mui.Grid>
						<Mui.Snackbar
							open={saving && !authLoading && !authError}
							message="Settings saved"
							autoHideDuration={4000}
							onClose={() => this.setState({ saving: false })}
							action={
								<Mui.IconButton
									size="small"
									aria-label="close"
									color="inherit"
									onClick={() => this.setState({ saving: false })}
								>
									<Icons.X fontSize="small" />
								</Mui.IconButton>
							}
						>
							<MuiAlert severity="info">
								<Mui.Typography>Settings saved</Mui.Typography>
							</MuiAlert>
						</Mui.Snackbar>
				</Mui.Grid>
			
		);
	}
}

export const RoundRobinSetting = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);