import { Theme, createStyles } from "@material-ui/core";
export const styles = (theme: Theme) => {
    return createStyles({
        radioLabel: {
			marginRight: "30px",
			paddingTop: "14px"
		},
		radioGroup: {
			display: "flex"
		}
    });
};
