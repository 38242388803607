import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import * as kestrelEmbedCode from "shared/kestrel-embed-code";
import * as yup from "yup";
import { validate } from "shared/yup";
import { getAgents } from "redux/selector";
import { WidgetConfigLayout } from "../widget-config-layout";
import { getUser } from "redux/selector";

const mapStateToProps = (state: RootState) => {
	return {
		user: getUser(state),
		agents: getAgents(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
}

interface State {
	agentId: string;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props) 
		this.state = {
			agentId: "",
		}
	}

	private validationSchema = {
		agentId: yup
			.string()
			.required("Agent is required.")
	};

	public render() {
		const { user, agents } = this.props;
		const { agentId } = this.state;
		const errors = validate(this.validationSchema, this.state);
		const embedCode = kestrelEmbedCode.agentListingWidget(
			user.websitePlatform,
			parseInt(agentId),
		);
		return (
			<WidgetConfigLayout user={user} embedCode={!errors ? embedCode : undefined}>
				<Mui.Grid container spacing={2} direction="column">
					<Mui.Grid item xs={12}>
						<Mui.Typography variant="h4">
							Widget Options
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.FormControl variant="outlined" fullWidth>
							<Mui.TextField
								select
								fullWidth
								required
								variant="outlined"
								id="style"
								value={agentId}
								onChange={(event) => this.setState({agentId: event.target.value})}
								label="Agent"
								placeholder="Select an Agent"
							>
								{agents.map((agent, index) => <Mui.MenuItem key={index} value={agent.id}>{agent.name}</Mui.MenuItem>)}	
							</Mui.TextField>
						</Mui.FormControl>
					</Mui.Grid>
				</Mui.Grid>
			</WidgetConfigLayout>
		);
	}
}

export const AgentListingsConfig = connect(mapStateToProps, mapDispatchToProps)(Component);