import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		cardContent: {
			alignItems: "center",
			display: "flex",
			flexDirection: "row",
			"&:hover .delete": {
				visibility: "visible"
			},
			"& .delete": {
				visibility: "hidden"
			}
		},
		cardActionArea: {
			textOverflow: "ellipsis",
			overflow: "hidden",
			width: "100%"
		},
		leadLink: {
			fontWeight: "bold",
			color: theme.palette.secondary.main,
			textDecoration: "none",
			"&:hover": {
				textDecoration: "underline"
			}
		},
		checkbox: {
			height: 48,
			width: 48,
			margin: 10
		},
		title: {
			color: "#000",
		},
		description: {
			color: "#888",
			fontSize: 16
		},
		flexRow: {
			display: "flex",
			flexDirection: "row"
		},
		forText: {
			fontWeight: "bold",
			margin: "0 4px"
		},
		typeChip: {
			marginRight: 10,
			color: "#fff",
		},
		date: {
			fontSize: 13,
			margin: "9px 0",
		},
		priorityAndTrashContainer: {
			display: "flex",
			flexDirection: "row",
			margin: 10
		}
	});
