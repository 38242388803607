import { WebsitePlatformType } from "type/website-platform";
import { html } from "js-beautify";
import * as env from "shared/env";

export const getSiteEmbedCode = (websitePlatform: WebsitePlatformType, activationToken: string) => {
	let result = `
		<script src="${env.reactAppKestrelUrl}"></script>
		<script>
			window.ihfKestrel = window.ihfKestrel || {};
			ihfKestrel.config = {
				platform: "${websitePlatform.id}",
				activationToken: "${activationToken}",
			};
		</script>
	`;
	result = html(result, {
		indent_with_tabs: true,
	});
	return result;
};

export const page = (websitePlatform: WebsitePlatformType) => {
	return getEmbedCode(websitePlatform);
}

export const listingsWidget = (
	websitePlatform: WebsitePlatformType,
	cityId: number,
	propertyTypes: string,
	status: string,
	sort: string,
	resultsPerPage: number,
	bed?: number,
	bath?: number,
	minPrice?: number,
	maxPrice?: number,
) => {
	return getEmbedCode(websitePlatform, {
		component: "propertiesGalleryWidget",
		cityId,
		propertyTypes,
		status,
		sort,
		resultsPerPage,
		bed,
		bath,
		minPrice,
		maxPrice,
	});
}

export const featuredListingWidget = (
	websitePlatform: WebsitePlatformType,
	propertyType: string,
	status: string,
	sort: string,
	resultsPerPage: number,
) => {
	return getEmbedCode(websitePlatform, {
		component: "featuredListingSearchWidget",
		propertyType,
		status,
		sort,
		resultsPerPage,
	});
}

export const marketsWidget = (
	websitePlatform: WebsitePlatformType,
) => {
	return getEmbedCode(websitePlatform, {
		component: "marketsWidget",
	});
}

export const marketReportWidget = (
	websitePlatform: WebsitePlatformType,
	id: number,
	marketReportTypeId: number,
) => {
	return getEmbedCode(websitePlatform, {
		component: "marketReportWidget",
		id,
		marketReportTypeId,
	});
}

export const gallerySliderWidget = (
	websitePlatform: WebsitePlatformType,
	rows?: number,
	navigation?: boolean,
	nav?: string,
	auto?: boolean,
	interval?: number,
	maxResults?: number,
	status?: string,
	sort?: string,
	featured?: boolean,
	effect?: string,
	id?: number,
) => {
	return getEmbedCode(websitePlatform, {
		component: "gallerySliderWidget",
		rows,
		navigation,
		nav,
		auto,
		interval,
		maxResults,
		status,
		sort,
		featured,
		effect,
		id,
	});
}

export const quickSearchWidget = (
	websitePlatform: WebsitePlatformType,
	style: string,
	propertyType?: boolean
) => {
	return getEmbedCode(websitePlatform, {
		component: "quickSearchWidget",
		style: style,
		propertyType: propertyType,
	});
}

export const listingSearchWidget = (
	websitePlatform: WebsitePlatformType,
) => {
	return getEmbedCode(websitePlatform, {
		component: "listingSearchWidget",
	});
}

export const marketReportSignupWidget = (
	websitePlatform: WebsitePlatformType,
	id: number,
	marketReportTypeId: number,
) => {
	return getEmbedCode(websitePlatform, {
		component: "marketReportSignupWidget",
		id,
		marketReportTypeId,
	});
}

export const registrationFormWidget = (
	websitePlatform: WebsitePlatformType,
	redirectUrl: string,
	buttonText: string,
) => {
	return getEmbedCode(websitePlatform, {
		component: "registrationFormWidget",
		redirectUrl,
		buttonText,
	});
}

export const valuationFormWidget = (
	websitePlatform: WebsitePlatformType,
	style: string,
) => {
	return getEmbedCode(websitePlatform, {
		component: "valuationFormWidget",
		style,
	});
}

export const contactFormWidget = (
	websitePlatform: WebsitePlatformType,
) => {
	return getEmbedCode(websitePlatform, {
		component: "contactFormWidget",
	});
}

export const loginWidget = (
	websitePlatform: WebsitePlatformType,
	style: string,
) => {
	return getEmbedCode(websitePlatform, {
		component: "loginWidget",
		style,
	});
}

export const agentListingWidget = (
	websitePlatform: WebsitePlatformType,
	id: number
) => {
	return getEmbedCode(websitePlatform, {
		component: "agentListingsWidget",
		id,
	});
}

export const officeListingsWidget = (
	websitePlatform: WebsitePlatformType,
	id: number,
) => {
	return getEmbedCode(websitePlatform, {
		component: "officeListingsWidget",
		id,
	});
}

const getEmbedCode = (websitePlatform: WebsitePlatformType, config?: Object) => {
	const json = config ? JSON.stringify(config) : "";
	let result = `ihfKestrel.render(${json})`;
	if(!websitePlatform.textEmbedCode) {
		result = `
			<script>
				document.currentScript.replaceWith(${result});
			</script>
		`;
		result = html(result, {
			indent_with_tabs: true,
		});
	}
	return result;
};