import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { styles } from "./style";
import { ControlPanelSettingsFields } from "../form-fields/control-panel-settings-fields";
import * as yup from "yup";
import { validate } from "shared/yup";
import { resetError} from "redux/slice/agents";
import { Agent } from "model/agent";
import * as env from "shared/env";
import { Row } from "../row";
import { getUser } from "redux/selector";

interface Props	extends OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {}

interface OwnProps {
	fields: ControlPanelSettingsFields;
	onChange: (fields: ControlPanelSettingsFields) => void;
	submitted: boolean;
	agent?: Agent;
}

interface State {
	fields: ControlPanelSettingsFields;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const user = getUser(state);
	return {
		usernameConflict: state.agents.error === "conflict",
		user,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{
			resetError,
		},
		dispatch
	);
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			fields: props.fields,
		};
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		if(prevState.fields !== this.state.fields) {
			this.props.onChange(this.state.fields);
		}
	}

	private validationSchema = {
		username: yup
			.string()
			.test(
				"username-length",
				"Username must be 4-75 characters",
				(username) => {
					const correctLength = username && (username.length >= 4 && username.length <= 75);
					return !!!username || !!correctLength;
				}
			)
			.test(
				"username-required",
				"A Username is required",
				(username) => {
					let result = true;
					if(this.props.agent && this.props.agent.username ) {
						if(!!!username) {
							result = false;
						}
					}
					return result;
				}
			)
			.test(
				"username-conflict",
				"Username already exists",
				(username) => {
					let result = true;
					if(this.props.usernameConflict) {
						result = false;
					}
					return result;
				}
			),
		password: yup
			.string()
			.test(
				"password-length",
				"Password must be 4-75 characters",
				(password) => {
					const correctLength = password && (password.length >= 4 && password.length <= 75);
					return !!!password || !!correctLength;
				}
			)
			.test(
				"password-username-required",
				"A Username is required when setting a password",
				(password) => {
					return !!!password || !!this.state.fields.username;
				}
			),
	};
	
	render() {
		const { fields } = this.state;
		const { usernameConflict, classes, agent, user } = this.props;
		const errors = validate(this.validationSchema, {...this.state.fields});
		return (
			<Mui.Grid container alignItems="center" justifyContent="center"	spacing={2}>
				{env.agentAdminDev && 
					<>
						<Mui.Grid item xs={12}>
							<Row>
								<Mui.TextField
									error={(!!usernameConflict) || !!(errors && errors.username)}
									fullWidth
									helperText={
										!!usernameConflict ? "Please choose a different username and try again" :
										errors && errors.username
									}
									inputProps={{
										autoComplete: "off",
									}}
									label="Username"
									margin="dense"
									onChange={(event) => {
										if(usernameConflict) {
											this.props.resetError();
										}	
										this.setState({ fields: {...fields, username: event.target.value} })
									}}
									value={fields.username}
									variant="outlined"
								/>
							</Row>
						</Mui.Grid>
						<Mui.Grid item xs={12}>
							<Row>
								<Mui.TextField
									error={!!errors && !!errors.password}
									FormHelperTextProps={{
										className:
											!!errors && !!errors.password
												? classes.errorMessage
												: undefined,
									}}
									fullWidth
									helperText={errors && errors.password}
									inputProps={{
										autoComplete: "new-password",
									}}
									label={fields.username ? "Update Password" : "Password"}
									margin="dense"
									onChange={(event) =>
										this.setState({ fields: {...fields, password: event.target.value} })
									}
									type="password"
									value={fields.password}
									variant="outlined"
								/>
							</Row>
						</Mui.Grid>
						{agent && (user.agentId !== agent.id) && (
							<Mui.Grid item xs={12}>
								<Row>
									<Mui.Grid 
										container
										alignItems="center"
										justifyContent="space-between"
									>
										<Mui.Grid item>
											<Mui.Typography>
												Agent is an Admin User
											</Mui.Typography>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Switch
												checked={fields.admin}
												onChange={(event) =>
													this.setState({ fields: {...fields, admin: event.target.checked} })
												}
											/>
										</Mui.Grid>
									</Mui.Grid>
								</Row>
							</Mui.Grid>
						)}
					</>
				}
				<Mui.Grid item xs={12}>
					<Row>
						<Mui.Grid 
							container
							alignItems="center"
							justifyContent="space-between"
						>
							<Mui.Grid item>
								<Mui.Typography>
									Allow Agent to Modify Own Username & Password
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Switch
									checked={fields.allowModifyUserPass}
									onChange={(event) =>
										this.setState({ fields: {...fields, allowModifyUserPass: event.target.checked} })
									}
								/>
							</Mui.Grid>
						</Mui.Grid>
					</Row>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Row>
						<Mui.Grid 
							container
							alignItems="center"
							justifyContent="space-between"
						>
							<Mui.Grid item>
								<Mui.Typography>
									Allow Agent to Modify Own Listings
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Switch
									checked={fields.allowModifyListings}
									onChange={(event) =>
										this.setState({ fields: {...fields, allowModifyListings: event.target.checked} })
									}
								/>
							</Mui.Grid>
						</Mui.Grid>
					</Row>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Row>
						<Mui.Grid 
							container
							alignItems="center"
							justifyContent="space-between"
						>
							<Mui.Grid item>
								<Mui.Typography>
									Allow Agent to Manage Leads Receiving Email Updates
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Switch
									checked={fields.allowModifySubscribers}
									onChange={(event) =>
										this.setState({ fields: {...fields, allowModifySubscribers: event.target.checked} })
									}
								/>
							</Mui.Grid>
						</Mui.Grid>
					</Row>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const ControlPanelSettings = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
