import React from "react";
import * as Mui from "@material-ui/core";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { getLeadActivitiesChronological, getLeadsByUnassignedAgent } from "redux/selector";
import { ActivityCard } from "component/page/lead/activity-tab/activity-card";
import { urls } from "routes/urls";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { getLeadsByAgentId } from "redux/selector";

const mapStateToProps = (state: RootState) => {
	const agentId = state.agents.value;
	const unassigned = localStorage.getItem("agentId") === "unassigned";
	return {
		leadActivities: getLeadActivitiesChronological(state),
		loading: state.leadActivities.loading,
		leadsByAgent: getLeadsByAgentId(state, agentId),
		agentId,
		leadsByUnassigned: getLeadsByUnassignedAgent(state),
		unassigned,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
}

class Component extends React.Component<Props> {

	public render() {
		const { leadActivities, loading, leadsByAgent, agentId, leadsByUnassigned, unassigned } = this.props;
		let fiveLeadActivities;
		if (unassigned) {
			fiveLeadActivities = leadActivities.filter(activity => leadsByUnassigned.some(lead => lead.id === activity.leadId)).slice(0, 5);
		} else {
			fiveLeadActivities = agentId ? leadActivities.filter(activity => leadsByAgent.some(lead => lead.id === activity.leadId)).slice(0, 5) : leadActivities.slice(0, 5);
		}

		return (
			<Mui.Grid container direction="column" spacing={4} >
				<Mui.Grid item>
					<Mui.Typography variant="h3">Lead Activity</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item>
					{!!loading &&
						<Mui.Card>
							<Mui.CardContent>
								<Mui.Grid container justifyContent="center">
									<Mui.Grid item>
										<LoadingIndicator />
									</Mui.Grid>
								</Mui.Grid>
							</Mui.CardContent>
						</Mui.Card>
					}
					{!loading && !fiveLeadActivities.length &&
						<Mui.Card>
							<Mui.CardContent>
								<Mui.Grid container justifyContent="center">
									<Mui.Grid item>
										<Mui.Typography align="center">Lead activity has not yet been recorded on your site.</Mui.Typography>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.CardContent>
						</Mui.Card>
					}
					{!loading && !fiveLeadActivities.length &&
						<Mui.Card>
							<Mui.CardContent>
								<Mui.Grid container justifyContent="center">
									<Mui.Grid item>
										<Mui.Typography align="center">Lead activity has not yet been recorded on your site.</Mui.Typography>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.CardContent>
						</Mui.Card>
					}
					{!loading && !!fiveLeadActivities.length && 
						<Mui.Grid container direction="column" spacing={4}>
							{fiveLeadActivities.map((activity, index) => (
								<Mui.Grid key={index} item>
									<ActivityCard activity={activity} showLeadName />
								</Mui.Grid>
							))}
							<Mui.Grid item style={{textAlign: "center"}}>
								<Mui.Link
									component={RouterLinkWrapper}
									to={urls.leadActivity}
								>
									<Mui.Button 
										color="secondary" 
										variant="contained"
									>
										View All
									</Mui.Button>
								</Mui.Link>
							</Mui.Grid>
						</Mui.Grid>
					}
				</Mui.Grid>
			</Mui.Grid>
		);
	}

}

export const LeadActivity = connect(mapStateToProps, mapDispatchToProps)(Component);