import React from 'react'
import * as Mui from "@material-ui/core";
import Fuse from "fuse.js";
import { Table } from 'component/shared/table';
import { MarketReportSubscription, NewMarketReportSubscription }  from "model/market-report-subscription";
import { MarketReportType } from "type/market-report-type";
import { styles } from "./styles";
import { Market } from "model/market";
import { MarketSubscription } from "./market-subscription";
import { marketSubscriptionsFactory } from "./market-subscriptions-factory";
import { SaveButton } from 'component/shared/save-button';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { bindActionCreators, Dispatch } from "redux";

interface Props extends
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
		market: Market,
		save: (subscriptions: (MarketReportSubscription | NewMarketReportSubscription)[]) => void;
}

interface State {
	marketSubscriptions: MarketSubscription[];
	queue: (MarketReportSubscription | NewMarketReportSubscription)[];
	term: string;
	saved: boolean;
}

type Key = "listingReport" | "openHomeReport" | "marketReport" | undefined;

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);


const mapStateToProps = (state: RootState) => {
	const loading = state.marketReportSubscriptions.loading;
	return {
		loading,
	}
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			marketSubscriptions: [],
			queue: [],
			term: "",
			saved: false,
		};
	}

	public componentDidMount() {
		this.setState({marketSubscriptions: this.marketSubscriptions()});
	}

	public componentDidUpdate(prevProps: Props, prevState: State): void {
		if(this.props.market.marketReportSubscriptions && prevProps.market.marketReportSubscriptions) {
			const currentLength = this.props.market.marketReportSubscriptions.length;
  			const prevLength = prevProps.market.marketReportSubscriptions.length;
			this.props.market.marketReportSubscriptions.forEach((currentValue, index) => {
				const prevValue = prevProps.market.marketReportSubscriptions && prevProps.market.marketReportSubscriptions[index];
				if(prevValue && (currentValue.inactive !== prevValue.inactive || currentLength !== prevLength)) {
					const marketSubscriptions = this.marketSubscriptions();
					this.setState({marketSubscriptions});
				}
			});
		}
	}

	private key (marketReportType: MarketReportType): Key {
		if(marketReportType === MarketReportType.OPEN_HOMES) {
			return "openHomeReport";
		}
		if(marketReportType === MarketReportType.MARKET) {
			return "marketReport";
		}
		if(marketReportType === MarketReportType.LISTING) {
			return "listingReport";
		}
	}

	private marketSubscriptions() {
		const { marketReportSubscriptions } = this.props.market;
		const marketSubscriptions: MarketSubscription[] = [];
		const factory = marketSubscriptionsFactory();
		if(marketReportSubscriptions) {
			marketReportSubscriptions.forEach((marketReportSubscription) => {
				let marketSubscription: MarketSubscription;
				const i = marketSubscriptions.findIndex((item => item.lead.id === marketReportSubscription.lead.id));
				const k = this.key(marketReportSubscription.report.type);
				if(k && i >= 0) {
					marketSubscriptions[i][k] = {...marketReportSubscription};
				} else {
					if(k && marketReportSubscription.lead) {
						marketSubscription = factory.create(marketReportSubscription.lead, this.props.market);
						marketSubscription[k] = marketReportSubscription;
						marketSubscriptions.push(marketSubscription);
					}
				}
			});
		}
		return marketSubscriptions.filter((value) => {
			return !(value.listingReport?.inactive && value.openHomeReport?.inactive && value.marketReport?.inactive);
		});
	}

	private update(marketSubscription: MarketSubscription, k: Key) {
		const marketSubscriptions = [...this.state.marketSubscriptions];
		const i = marketSubscriptions.findIndex(value => value.itemNumber === marketSubscription.itemNumber);
		const queue = [...this.state.queue];
		const marketReportSubscription = k && marketSubscriptions[i][k];
		if(marketReportSubscription) {
			marketReportSubscription.inactive = !marketReportSubscription.inactive;
			const i = queue.findIndex((value) => 
				(value.lead && value.lead.id === marketReportSubscription.lead.id) && 
				(value.report &&value.report.type === marketReportSubscription.report.type));
			if(i >= 0) {
				queue[i] = marketReportSubscription;
			} else {
				queue.push(marketReportSubscription);
			}
			this.setState({marketSubscriptions: marketSubscriptions, queue});
		}
	}

	private marketSubscriptionsFuse() {
		const options: Fuse.IFuseOptions<MarketSubscription> = {
			shouldSort: true,
			threshold: 0.5,
			location: 0,
			distance: 100,
			minMatchCharLength: 1,
			keys: ["lead.email"],
		};

		return new Fuse(this.state.marketSubscriptions, options);
	}

	render() {
		const { market, loading } = this.props;
		const { term, saved } = this.state;
		let marketSubscriptions = [...this.state.marketSubscriptions];
		if(term) {
			marketSubscriptions = this.marketSubscriptionsFuse().search(term).map((result) => result.item);
		}
		return (	
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Mui.Grid container justifyContent='space-between'>
						<Mui.Grid item>
							<Mui.Grid item>
								<Mui.Typography variant='h3'>{`Subscriber List: ${marketSubscriptions.length}`}</Mui.Typography>
							</Mui.Grid>
						</Mui.Grid>
						{this.state.marketSubscriptions.length > 0 && (
							<Mui.Grid item xs={5}>
								<Mui.TextField 
									variant='outlined' 
									size='small' 
									fullWidth
									placeholder='Search Subscriptions'
									onChange={event => this.setState({term: event.target.value})}
								/>
							</Mui.Grid>
						)}
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					<Table
						items={marketSubscriptions}
						columns={[
							{
								id: "email",
								hidden: false,
								title: "Email Address",
								value: (marketSubscription) => {
									return (
										<Mui.Typography>
											{marketSubscription.lead.email}
										</Mui.Typography>
									);
								}
							},
							{
								id: "name",
								hidden: false,
								title: "Name",
								value: (marketSubscription) => {
									return (
										<Mui.Typography>
											{marketSubscription.lead.name}
										</Mui.Typography>
									);
								}
							},
							{
								id: "listing-report",
								title: "Listing Report",
								hidden: !market.listingReportSupported,
								value: (marketSubscription) => {
									const marketReportSubscription = marketSubscription.listingReport;
									return (
										<Mui.Checkbox 
											checked={marketReportSubscription && !marketReportSubscription.inactive}
											onChange={(event) => {
												if(marketReportSubscription) {
													this.update(marketSubscription, this.key(marketReportSubscription.report.type));
												}
											}}
										/>
									)
								}
							},
							{
								id: "open-home-reports",
								title: "Open Home Reports",
								hidden: !market.openHomesReportSupported,
								value: (marketSubscription) => {
									const marketReportSubscription = marketSubscription.openHomeReport;
									return (
										<Mui.Checkbox 
											checked={marketReportSubscription && !marketReportSubscription.inactive}
											onChange={(event) => {
												if(marketReportSubscription) {
													this.update(marketSubscription, this.key(marketReportSubscription.report.type));
												}
											}}
										/>
									)
								}
							},
							{
								id: "market-reports",
								title: "Market Reports",
								hidden: !market.marketReportSupported,
								value: (marketSubscription) => {
									const marketReportSubscription = marketSubscription.marketReport;
									return (
										<Mui.Checkbox 
											checked={marketReportSubscription && !marketReportSubscription.inactive}
											onChange={(event) => {
												if(marketReportSubscription) {
													this.update(marketSubscription, this.key(marketReportSubscription.report.type));
												}
											}}
										/>
									)
								}
							},
						]}
					/>
				</Mui.Grid>
				<Mui.Grid item sm={5}>
					<Mui.Grid item>
						<SaveButton 
							onClick={() => {
								const marketReportSubscriptions = this.props.market.marketReportSubscriptions;
								const { queue } = this.state;
								let results: (MarketReportSubscription | NewMarketReportSubscription)[] = marketReportSubscriptions ? [...marketReportSubscriptions] : [];
								results.forEach((result, index) => {
									const queueIndex = queue.findIndex((enqueued) => {
										if("id" in enqueued && "id" in result)  {
											const e = enqueued as MarketReportSubscription;
											const r = result as MarketReportSubscription;
											return e.id === r.id
										} 
										return false;
									});
									if(queueIndex >= 0) {
										results[index] = queue[queueIndex];
									}
								});
								const newResults = queue.filter((enqueued) => !("id" in enqueued))
								results = [...results, ...newResults];
								this.setState({saved: true});
								this.props.save(results);
							}} 
							loading={!!loading && saved} 
							label={{
								primary: "Save Subscriptions", 
								inProgress: "Saving...", 
								completed: "Saved" 
							}}
							snackBar
							duration={3000}
							onReset={() => this.setState({saved: false})}
						/>
					</Mui.Grid>
				</Mui.Grid>
			</Mui.Grid>
		)
	}
}

export const SubscriptionsTable = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component))