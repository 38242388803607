import * as yup from "yup";

type schema = {
	[key: string]: yup.SchemaOf<any>;
};

/**
 * returns an object with error message for each key of the schema
 * 
 * @example
 * {
 * 	email: "Email is required",
 * }
 * 
 */
export function validate<T extends schema>(schema: T, form: object) {
	type Result = {
		[key in keyof typeof schema]: string;
	};
	let result: Result | undefined;
	try {
		yup.object().shape(schema).validateSync(form, {
			abortEarly: false,
		});
	} catch(e) {
		const errors = e as yup.ValidationError;
		if(errors.inner) {
			errors.inner.forEach((error) => {
				result = result || ({} as Result);
				const name = error.path as keyof Result;
				result[name] = error.message;
			});
		}
	}
	return result;
}