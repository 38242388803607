import { createTheme } from "@material-ui/core";
import * as Colors from "@material-ui/core/colors";
import { getContrastRatio } from "@material-ui/core/styles";

export const getTheme = (primaryColor?: string, secondaryColor?: string) => {
	if(!primaryColor) {
		primaryColor = "#3C4252";
	}
	if(!secondaryColor) {
		secondaryColor = Colors.lightBlue[600];
	}
	const theme = createTheme({
		palette: {
			primary: {
				main: primaryColor,
			},
			secondary: {
				main: secondaryColor,
			},
			text: {
				primary: Colors.grey[700]
			},
		},
		typography: {
			fontFamily: "Muli",
			h1: {
				fontSize: "2rem",
			},
			h2: {
				fontSize: "1.75rem",
			},
			h3: {
				fontSize: "1.5rem",
			},
			h4: {
				fontSize: "1.15rem",
			},
			h5: {
				fontSize: "1rem",
			},
			h6: {
				fontSize: "1rem",
			},
			body1: {
				fontSize: "1rem",
			},
			body2: {
				fontSize: "1rem",
			},
			subtitle1: {
				fontSize: "0.625",
			},
		},
		overrides: {
			MuiDialogTitle: {
				root: {
					backgroundColor: primaryColor,
					color: getContrastRatio(primaryColor, "#000") >= 3 ? "#000" : "#fff",
				},
			},
			MuiDialogContent: {
				root: {
					padding: "24px 24px",
				},
			},
			MuiLink: {
				root: {
					color: secondaryColor,
					textDecoration: "none !important",
				},
			},
			MuiCardContent: {
				root: {
					"&:last-child": {
						paddingBottom: undefined,
					},
				},
			},
			MuiCardActions: {
				root: {
					padding: 16,
					paddingTop: 0,
				},
			},
			MuiExpansionPanelActions: {
				root: {
					padding: 16,
					paddingTop: 0,
				},
			},
			MuiTab: {
				root: {
					fontSize: "1rem",
					"@media (min-width: 960px)": {
						fontSize: "1rem",
					},
				},
			},
			MuiButton: {
				root: {
					fontSize: "1rem",
				},
			},
			MuiListItemIcon: {
				root: {
					minWidth: 40,
				},
			},
			MuiTableCell: {
				sizeSmall: {
					padding: 8,
				},
			},
		},
	});
	return theme;
}