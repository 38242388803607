import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { getActiveLeads } from "redux/selector"
import { getTasksPageFilters } from "redux/selector";
import { setTasksPageFilters } from "redux/slice/tasks-page";
import { TaskStatus } from "type/task-status";
import { TaskType } from "type/task-type";

const mapStateToProps = (state: RootState) => {
	const filters = getTasksPageFilters(state);
	const leads = getActiveLeads(state);
	return {
		filters,
		leads
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	setTasksPageFilters,
}, dispatch);

interface State { 
}

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles>
{
}

class Component extends React.Component<Props, State> {

	public getLeadName = (leadId: number) => {
		const lead = this.props.leads.find((lead) => lead.id === leadId);
		if (lead) {
			return lead.name;
		}
	};

	public render() {
		const { classes, filters, setTasksPageFilters } = this.props;
		const filtersType: number[] | undefined = filters.type;
		const filtersStatus: number[] | undefined = filters.status;
		return (
			<>
			
				{filters.type && filtersType && filtersType.map((value, index, values) => {
					const filterType = TaskType.getById(value);
					return <Mui.Chip
						key={index}
						label={filterType ? filterType.name : ""}
						onDelete={() => {
							setTasksPageFilters({
								...filters,
								type: values.filter(item => item !== value),
							});
						}}
						deleteIcon={<Icons.XCircle color="#616161" />}
						className={classes.chip}
					/>
					})}
				{filters.status && filtersStatus && filtersStatus.map((value, index, values) => {
					const statusType = TaskStatus.getById(value);
					if(!statusType) {
						return null;
					}
					return (
						<Mui.Chip
							key={index}
							label={statusType.name}
							onDelete={() => {
								setTasksPageFilters({
									...filters,
									status: values.filter(item => item !== value),
								});
							}}
							deleteIcon={<Icons.XCircle color="#616161" />}
							className={classes.chip}
						/>
					);
				})}
				{filters.priority && 
					<Mui.Chip
						label="Priority"
						onDelete={() => {
							setTasksPageFilters({
								...filters,
								priority: false,
							});
						}}
						deleteIcon={<Icons.XCircle color="#616161" />}
						className={classes.chip}
					/>
				}
				{filters.today && 
					<Mui.Chip
						label="Due Today"
						onDelete={() => {
							setTasksPageFilters({
								...filters,
								today: false,
							});
						}}
						deleteIcon={<Icons.XCircle color="#616161" />}
						className={classes.chip}
					/>
				}
				{filters.lead && 
					<Mui.Chip
						label={
							this.getLeadName(filters.lead)
						}
						onDelete={() => {
							setTasksPageFilters({
								...filters,
								lead: undefined,
							});
						}}
						deleteIcon={<Icons.XCircle color="#616161" />}
						className={classes.chip}
					/>
				}
				
			</>
		);
	}

}



export const FiltersChips = Mui.withStyles(styles)(
	Router.withRouter(
		connect(mapStateToProps, mapDispatchToProps)(Component)
	),
);