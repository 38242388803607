import { normalize, schema } from "normalizr";
import { createSlice } from "@reduxjs/toolkit";
import { emailClicksLoaded } from "redux/slice/email-clicks";
import { leadActivitiesLoaded } from "redux/slice/lead-activities";
import { marketListingLoaded, marketListingsLoaded } from "redux/slice/market-listings";
import { savedListingLoaded, savedListingsLoaded } from "redux/slice/saved-listings";
import { loginReset } from "redux/slice/authentication";
import { ListingResource } from "api/resource/listing";

export interface ListingsState {
	byId: {
		[key: string]: ListingResource,
	}
};

const initialState: ListingsState = {
	byId: {},
};

export const listingsSlice = createSlice({
	name: "listings",
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(emailClicksLoaded, (state, action) => {
			const listing = new schema.Entity("listings");
			const emailClick = new schema.Entity("emailClicks", {
				listing,
			});
			const emailClicks = new schema.Array(emailClick);
			const normalized = normalize(action.payload, emailClicks);
			const byId = normalized.entities.listings || {};
			return {
				byId: {
					...state.byId,
					...byId,
				},
			};
		});
		builder.addCase(leadActivitiesLoaded, (state, action) => {
			const listing = new schema.Entity("listings");
			const leadActivity = new schema.Entity("leadActivities", {
				listing,
			});
			const leadActivities = new schema.Array(leadActivity);
			const normalized = normalize(action.payload, leadActivities);
			const byId = normalized.entities.listings || {};
			return {
				byId: {
					...state.byId,
					...byId,
				},
			};
		});
		builder.addCase(marketListingsLoaded, (state, action) => {
			const listing = new schema.Entity("listings");
			const marketListing = new schema.Entity("marketListings", {
				listing,
			});
			const marketListings = new schema.Array(marketListing);
			const normalized = normalize(action.payload, marketListings);
			const byId = normalized.entities.listings || {};
			return {
				byId: {
					...state.byId,
					...byId,
				},
			};
		});
		builder.addCase(marketListingLoaded, (state, action) => {
			const listing = new schema.Entity("listings");
			const marketListing = new schema.Entity("marketListings", {
				listing,
			});
			const normalized = normalize(action.payload, marketListing);
			const byId = normalized.entities.listings || {};
			return {
				byId: {
					...state.byId,
					...byId,
				},
			};
		});
		builder.addCase(savedListingsLoaded, (state, action) => {
			const listing = new schema.Entity("listings");
			const savedListing = new schema.Entity("savedListings", {
				listing,
			});
			const savedListings = new schema.Array(savedListing);
			const normalized = normalize(action.payload, savedListings);
			const byId = normalized.entities.listings || {};
			return {
				byId: {
					...state.byId,
					...byId,
				},
			};
		});
		builder.addCase(savedListingLoaded, (state, action) => {
			const listing = new schema.Entity("listings");
			const savedListing = new schema.Entity("savedListings", {
				listing,
			});
			const normalized = normalize(action.payload, savedListing);
			const byId = normalized.entities.listings || {};
			return {
				byId: {
					...state.byId,
					...byId,
				},
			};
		});
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});