import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		image: {
			height: 200,
		},
		truncatedMessage: {
			display: "inline-block", 
			maxWidth: 500, 
			overflow: "hidden", 
			textOverflow: "ellipsis", 
			whiteSpace: "nowrap", 
			verticalAlign: "top", 
			WebkitLineClamp: 1
		},
		textResponded: {
			paddingBottom: 20,
		},
		card: {
			overflowWrap: "anywhere",
		},
		agentLink: {
			paddingLeft: 5
		},
		unassignedIcon: {
			border: "3px solid #BDBDBD",
			borderRadius: 50,
			backgroundColor: "#BDBDBD",
			color: "#FAFAFA",
		},
		iconGrid: {
			marginTop: 5
		}
	});
};
