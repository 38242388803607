import { User } from "model/user";

const key = "eb46529c1fb35d7b213c8a9b11816059";

const load = new Promise<void>((resolve, reject) => {
	(window as any).profitwell = {
		q: [],
	};
	const script = document.createElement("script");
	script.src = "https://public.profitwell.com/js/profitwell.js";
	script.addEventListener("load", () => resolve());
	script.addEventListener("error", () => reject());
	document.head.appendChild(script);
})

const profitwell = (...params: any) => {
	load.then(() => {
		const profitwell = (window as any).profitwell;
		profitwell(...params);
	})
};

export const start = (user: User) => {
	if(user && user.reseller.direct) {
		profitwell("auth_token", key);
		profitwell("user_id", user.clientId);
	}
};

