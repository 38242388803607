import * as React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import * as env from "shared/env";
import { createCriteria, createSearchProfile } from "./translators";
import { EurekaElement } from "./types"
import { BaseProps } from "../types";

interface State {
}

interface Props extends BaseProps, Mui.WithStyles<typeof styles> {
}

class Component extends React.Component<Props, State> {

	static scriptLoaded: boolean;

	private container = React.createRef<HTMLDivElement>();

	public componentDidMount() {
		const {
			user,
			onSaveSearch,
			onCriteriaChange,
			onSaveListing,
			onUnsaveListing,
			savedListings,
			criteria,
		} = this.props;
		let searchProfile;
		if(criteria) {
			searchProfile = createSearchProfile(criteria);
		}
		if(!env.javaUrl) {
			throw new Error();
		}
		if(this.container.current) {
			{
				const container = this.container.current;
				const eureka = document.createElement("ihf-search") as EurekaElement;
				eureka.config = {
					host: env.javaUrl,
					clientId: user.clientId,
					permissionOverride: true,
					leadCapture: false,
					showSaveSearchButton: !!onSaveSearch,
					showSaveListingButton: !!onSaveListing,
					onSaveSearch: (criteria) => {
						if(onSaveSearch) {
							const results = createCriteria(criteria);
							onSaveSearch(results);
						}
					},
					onCriteriaChange: (criteria) => {
						if(onCriteriaChange) {
							const results = createCriteria(criteria);
							onCriteriaChange(results);
						}
					},
					onSaveListing: (Listing) => {
						if(onSaveListing) {
							onSaveListing(Listing.id);
						}
					},
					onUnsaveListing: (Listing) => {
						if(onUnsaveListing) {
							onUnsaveListing(Listing.id);
						}
					},
					savedListings,
					searchProfile,
				};
				container.appendChild(eureka);
			}		
			this.loadScripts();
		}
	}

	public loadScripts = () => {
		if(!Component.scriptLoaded) {
            Component.scriptLoaded = true;
            let scripts: string[];
            if(env.eurekaUrl.includes("localhost")) {
                scripts = [
                    `${env.eurekaUrl}/runtime.js`,
                    `${env.eurekaUrl}/polyfills.js`,
                    `${env.eurekaUrl}/styles.js`,
                    `${env.eurekaUrl}/scripts.js`,
                    `${env.eurekaUrl}/main.js`,
                ];
            } else {
                scripts = [
                    `${env.eurekaUrl}/eureka/ihf-eureka.js`,
                ];
            }
            scripts.forEach((script) => {
                const element = document.createElement("script");
                element.src = script;
                document.body.appendChild(element);
            });
        }
	}

	public render() {
		const { classes } = this.props;
		return (
			<div ref={this.container} className={classes.container}></div>
		);
		
	}

}
export const Eureka = Mui.withStyles(styles)(Component);
