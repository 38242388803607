import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { login, loginReset } from "redux/slice/authentication";
import * as api from "api/api";
import { UserAuth } from "model/user-auth";

export interface UserAuthState {
	loading?: boolean;
	error?: string | null;
};

const initialState: UserAuthState = {
	loading: false,
};

const saveUserAuth = createAsyncThunk("userAuth/saveAuthUser", async (payload: {userAuth: UserAuth}, thunkAPI) => {
	thunkAPI.dispatch(userAuthLoading());
	let password = payload.userAuth.password;
	const result = api.saveUserAuth(payload.userAuth);
	result
		.then(userAuthResponse => {
			thunkAPI.dispatch(userAuthLoaded(userAuthResponse));
			if (password !== undefined && password !== "") {
				let username = userAuthResponse.username;
				thunkAPI.dispatch(login({username, password}));
			}
		})
		.catch(error => thunkAPI.dispatch(userAuthFailed(error)));
	return result;
});

export const userAuthSlice = createSlice({
	name: "userAuth",
	initialState,
	reducers: { 
		userAuthLoading:(state) => {
			state.loading = true;
			return state;
		},
		userAuthLoaded:(state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = null;
			return state;
		},
		userAuthFailed:(state, action: PayloadAction<any>) => {
			state.error = action.payload.message;
			state.loading = false;
			return state;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});

const { userAuthLoading, userAuthLoaded, userAuthFailed } = userAuthSlice.actions;
export { saveUserAuth };