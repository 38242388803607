import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "api/api";
import { Campaign, NewCampaign } from "model/campaign";
import { loginReset } from "redux/slice/authentication";
import { arrayToById, objectToById } from "redux/normalize";
import { CampaignResource } from "api/resource/campaign";

export interface CampaignsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: CampaignResource;
	};
};

const initialState: CampaignsState = {
	byId: {},
};

const fetchCampaigns = createAsyncThunk("campaigns/fetchCampaigns", async (payload, thunkAPI) => {
	thunkAPI.dispatch(campaignsLoading());
	const result = api.getCampaigns();
	result
		.then((campaigns) => thunkAPI.dispatch(campaignsLoaded(campaigns)))
		.catch(error => thunkAPI.dispatch(campaignsFailed(error)));
	return result;
});

const createCampaign = createAsyncThunk("campaigns/createCampaign", async (payload: {campaign: NewCampaign}, thunkAPI) => {
	thunkAPI.dispatch(campaignsLoading());
	const result = api.createCampaign(payload.campaign);
	result
		.then(campaign => thunkAPI.dispatch(campaignLoaded(campaign)))
		.catch(error => thunkAPI.dispatch(campaignsFailed(error)));
	return result;
});

const updateCampaign = createAsyncThunk("campaigns/updateCampaign", async (payload: {campaign: Campaign}, thunkAPI) => {
	thunkAPI.dispatch(campaignsLoading());
	const result = api.updateCampaign(payload.campaign);
	result
		.then(campaign => thunkAPI.dispatch(campaignLoaded(campaign)))
		.catch(error => thunkAPI.dispatch(campaignsFailed(error)));
	return result;
});

export const campaignSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
	campaignsLoading: (state) => {
		state.loading = true;
	},
	campaignsLoaded: (state, action: PayloadAction<CampaignResource[]>) => {
		state.loading = false;
		state.error = null;
		state.byId = arrayToById(action.payload, "id");
	},
	campaignsFailed: (state, action: PayloadAction<any>) => {
		state.loading = false;
		state.error = action.payload.message;
	},
	campaignLoaded: (state, action: PayloadAction<CampaignResource>) => {
		const byId = objectToById(action.payload, "id");
		state.loading = false;
		state.error = null;
		state.byId = { 
			...state.byId,
			...byId,
		};
	},
	default:() => {
		return;
	}
  }, 
  extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});

const { campaignsFailed, campaignsLoaded, campaignsLoading, campaignLoaded } = campaignSlice.actions;
export { fetchCampaigns, createCampaign, updateCampaign };