import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { styles } from "./style";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { createLeadTag, deleteLeadTag } from "redux/slice/lead-tags";
import { getBookmarkTagForLead } from "redux/selector";
import { LeadTagType } from "type/lead-tag";

interface OwnProps {
	leadId: number,
}

interface State {
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		bookmarkTag: getBookmarkTagForLead(state, ownProps.leadId),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createLeadTag,
	deleteLeadTag,
}, dispatch);


interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> 
{
	leadId: number;
}

interface OwnProps {
	leadId: number;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = { 
			bookmarked: false,
		};
	}

	public toggleBookmark = () => {
		if(this.props.bookmarkTag) {
			this.props.deleteLeadTag({leadTag: this.props.bookmarkTag});
		} else {
			this.props.createLeadTag({ leadTag: {
				leadId: this.props.leadId,
				text: LeadTagType.BOOKMARKED.id,
			}});
		}
	}

	public render() {
		const { bookmarkTag, classes } = this.props;
		return (
			<>
				<Mui.Button onClick={() => this.toggleBookmark()}>
					<FeatherIcon>
						<Icons.Bookmark className={bookmarkTag ? classes.activeBookmark : classes.bookmark} />
					</FeatherIcon>
				</Mui.Button>
			</>
		);
	}

}

export const Bookmark = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);