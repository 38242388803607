import React from "react";
import * as Mui from "@material-ui/core";
import { Chart } from "./chart";
import { connect } from "react-redux";
import { RootState } from "redux/store";
import { getActiveLeads, getLeadsByUnassignedAgent } from "redux/selector";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { getLeadsByAgentId } from "redux/selector";

const mapStateToProps = (state: RootState) => {
	const agentId = state.agents.value;
	const unassigned = localStorage.getItem("agentId") === "unassigned"
	return {
		leads: getActiveLeads(state),
		loading: state.leads.loading,
		leadsByAgent: getLeadsByAgentId(state, agentId),
		agentId,
		leadsByUnassigned: getLeadsByUnassignedAgent(state),
		unassigned
	};
};

interface Props extends ReturnType<typeof mapStateToProps> {}

class Component extends React.Component<Props> {
	public render() {
		const { loading, leadsByAgent, agentId, leadsByUnassigned, unassigned } = this.props;
		const leads = unassigned ? leadsByUnassigned : this.props.leads;
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Mui.Typography variant="h3">% Subscribed</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Card>
						<Mui.CardContent style={{ height: 200 }}>
							{!!loading && (
								<Mui.Grid container justifyContent="center" alignItems="center" direction="row"> 
									<Mui.Grid item>
										<LoadingIndicator />
									</Mui.Grid>
								</Mui.Grid>
							)}
							{!loading && leads.length ?
								<Chart leads={!agentId ? leads : leadsByAgent} />
							: 
								<Mui.Grid container alignItems="center" style={{ height: "100%" }}>
									<Mui.Grid item>
										<Mui.Typography align="center">Start adding leads to see this chart.</Mui.Typography>
									</Mui.Grid>
								</Mui.Grid>
							}
						</Mui.CardContent>
					</Mui.Card>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const PercentSubscribed = connect(mapStateToProps)(Component);
