import React from "react";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";import * as Mui from "@material-ui/core";
import { styles } from "../../style";
import { Dialog } from "component/shared/dialog";
import { getCustomTagTexts, getTagsForLeads } from "redux/selector";
import { LeadTagType } from "type/lead-tag";
import { Select } from "component/shared/select";
import { deleteLeadTag } from "redux/slice/lead-tags";
import * as yup from "yup";
import { validate } from "shared/yup";

const mapStateToProps = (state: RootState, ownProps: any) => {
	return {
		customTags: getCustomTagTexts(state),
		leadsTags: getTagsForLeads(state, ownProps.leadIds)
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	deleteLeadTag,
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
	open: boolean, 
	onClose: Function,
	leadIds: number[],
}

interface State {
	tags: string;
	submitted: boolean;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			tags: "", 
			submitted: false,
		};
	}

	private get options() {
		const { customTags } = this.props;
		let texts = [
			...customTags,
			...LeadTagType.values().map(type => type.label),
		];
		texts = Array.from(new Set(texts));
		texts = texts.sort();
		const options = texts.map(text => ({
			value: text,
			label: text,
		}));
		return options;
	}

	private validationSchema = {
		tags: yup
			.string()
			.required("Please select a tag."),
	};

	private removeTag() {
		const { leadsTags, leadIds, deleteLeadTag } = this.props;
		const { tags } = this.state;

		const errors = validate(this.validationSchema, this.state);

		if(!errors) {
			leadIds.forEach((id) => {
				const tagToRemove = leadsTags.find((tag) => ((tag.text.toLowerCase() === tags.toLowerCase()) && tag.leadId === id)); 
				if (tags && tagToRemove) {
					deleteLeadTag({ leadTag: {
						...tagToRemove
					}});
				}
			});
			this.onClose();
		} else {
			this.setState({ submitted: true });
		}
	}

	private onClose() {
		this.props.onClose();
		this.setState({ tags: "", submitted: false });
	}

    render() {
		const { open, classes } = this.props;
		const { tags, submitted } = this.state;
		const { options } = this;
		const errors = validate(this.validationSchema, this.state);
		
        return (
			<Dialog
				open={open}
				onClose={() => {
					this.onClose()
				}}
				fullWidth={true}
				maxWidth="xs" 
			>
				<Mui.DialogTitle>Remove Tags</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<Mui.Grid container alignItems="center" spacing={1}>
						<Mui.Grid item xs={12}>
							<Mui.DialogContentText className={classes.dialogText}>Select tag(s) to remove from the selected records.</Mui.DialogContentText>
						</Mui.Grid>
						{!!tags.length &&
							<Mui.Grid item xs={12}>
								<Mui.Grid container alignItems="center">
									<Mui.Grid item>
										<Mui.Chip 
											label={tags}
											className={classes.tagChip}
											onDelete={(event) => {
												this.setState({ tags: "" });
											}}
										/>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						}
						<Mui.Grid container item xs={12} justifyContent="center">
							<Select
								margin="dense"
								placeholder="Select tag(s)"
								value={tags}
								options={options}
								valueExtractor={type => type.label}
								labelExtractor={type => type.label}
								optionRenderer={type => <Mui.ListItemText primary={type.label} />}
								onChange={value => {
									this.setState({ tags: value });
								}}
								helperText={submitted && errors && errors.tags.length ? errors.tags : undefined}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
					<Mui.Button
						onClick={() => {
							this.removeTag()
						}}
						variant="contained"
						color="secondary"
						disabled={submitted && tags.trim().length === 0}
					>
						Save
					</Mui.Button>
					<Mui.Button 
						onClick={() => {
							this.onClose()
						}} 
						variant="outlined"
					>
						Cancel
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
		)
	}
}

export const BulkRemoveTagsDialog = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);