import { objectToById } from "redux/normalize";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { ClientResource } from "api/resource/client";

export interface ClientsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: ClientResource;
	};
};

const initialState: ClientsState = {
	byId: {},
};

const fetchClient = createAsyncThunk("clients/fetchClient", async (payload: {clientId: number}, thunkAPI) => {
	thunkAPI.dispatch(clientLoading());
	const result = api.getClient(payload.clientId);
	result
		.then((client) => thunkAPI.dispatch(clientLoaded(client)))
		.catch(error => thunkAPI.dispatch(clientFailed(error)));
	return result;
});

export const clientsSlice = createSlice({
	name: "clients",
	initialState,
	reducers: {
		clientLoading: (state) => {
			state.loading = true;
		},
		clientLoaded: (state, action: PayloadAction<ClientResource>) => {
			const byId = objectToById(action.payload, "id");
			state.loading = false;
			state.error = null;
			state.byId = { 
				...state.byId,
				...byId,
			};
		},
		clientFailed: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});

export const {clientFailed, clientLoaded, clientLoading} = clientsSlice.actions;
export { fetchClient };