import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { YesNoRadioField } from "component/shared/yes-no-radio-field";
import { saveUser } from "redux/slice/authentication";
import { bindActionCreators, Dispatch } from "redux";
import { UserPartial } from "model/user";
import { connect } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import * as Icons from "react-feather";
import { getUserUnsafe } from "redux/selector";
import { RootState } from "redux/store";


interface State {
	saving: boolean;
	listingAgentRouting?:boolean | undefined,
	geographicRouting?:boolean | undefined,
	roundRobinRouting?:boolean | undefined
}

interface Props
	extends 
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		Mui.WithStyles<typeof styles> {
			postalCodesCount:number
	}


const mapStateToProps = (state: RootState) => {
	const user = getUserUnsafe(state);
	return {
		user,
		authLoading: state.authentication.loading,
		authError: state.authentication.error
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			saveUser,
		},
		dispatch
	);


class Component extends React.Component<Props, State> {
	
	public constructor(props: Props) {
		
		super(props);
		this.state = {...props.user, 
			saving: false, 
		}
	}

	public save() {
		const { saveUser } = this.props;
		const user: UserPartial = {
			...this.state,
		}
		saveUser({user});
		this.setState({ saving: true });
	}

	public render() {
		const { classes,  authError, authLoading, postalCodesCount} = this.props;
		const { listingAgentRouting, geographicRouting, roundRobinRouting, saving } = this.state
		const sPostalCodes = postalCodesCount > 1 ? 's' : ''
		return (
				<Mui.Grid container item xs={12} md={12}>

					<Mui.Grid
						container
						item
						spacing={4}
						direction="row"
						justifyContent="space-between"
					>
						<Mui.Grid item xs={12}>
							<Mui.Grid container direction="column">
								<Mui.Grid item className={classes.radioGroup}>
									<YesNoRadioField
										label="Assign Featured Property leads to listing agent"
										onOff={true}
										sidebarNote={'When on, all leads for a Featured Property will go to the listing agent'}
										value={listingAgentRouting}
										default={true}
										onChange={(value) =>
											this.setState({ listingAgentRouting: value })
										}
									/>
								</Mui.Grid>
								<Mui.Grid item className={classes.radioGroup}>
								<YesNoRadioField
										label="Geographic Routing"
										onOff={true}
										sidebarNote={'You have rules configured for '+ String(postalCodesCount) +' postal code'+ sPostalCodes}
										value={geographicRouting}
										default={true}
										onChange={(value) =>
											this.setState({ geographicRouting: value })
										}
									/>
								</Mui.Grid>
								<Mui.Grid item className={classes.radioGroup}>
									<YesNoRadioField
										label="Round Robin Lead Routing"
										onOff={true}
										value={roundRobinRouting}
										default={true}
										onChange={(value) => {
											this.setState({ roundRobinRouting: value })
										}}
									/>
								</Mui.Grid>
							
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid container spacing={1} style={{ marginTop: 24 }}>
							<Mui.Grid item>
								<Mui.Button
									color="secondary"
									disabled={saving}
									variant="contained"
									onClick={() => this.save()}
									style={{ marginRight: 32 }}
								>
									Save Changes
								</Mui.Button>
								<Mui.Button
									variant="contained"
									onClick={() =>
										this.setState({
										
										})
									}
								>
									Cancel
								</Mui.Button>
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Snackbar
							open={saving && !authLoading && !authError}
							message="Settings saved"
							autoHideDuration={4000}
							onClose={() => this.setState({ saving: false })}
							action={
								<Mui.IconButton
									size="small"
									aria-label="close"
									color="inherit"
									onClick={() => this.setState({ saving: false })}
								>
									<Icons.X fontSize="small" />
								</Mui.IconButton>
							}
						>
							<MuiAlert severity="info">
								<Mui.Typography>Settings saved</Mui.Typography>
							</MuiAlert>
						</Mui.Snackbar>
				</Mui.Grid>
			
		);
	}
}

export const Settings = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);