import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { styles } from "./style";
import { connect } from "react-redux";
import { validate } from "shared/yup";
import * as yup from "yup";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { saveUserAuth } from "redux/slice/user-auth";
import { User } from "model/user";
import { getUserAuth } from "redux/selector";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		userAuthLoading: state.userAuth.loading,
		userAuthError: state.userAuth.error,
		userAuth: getUserAuth(state),
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveUserAuth,
}, dispatch);

interface State {
	username: string;
	password?: string;
	verifyPassword?: string;
	credentialInputChanged: boolean;
	passwordConfirm?: string;
	showValidation: boolean;
	saving: boolean;
}

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
	user: User;
}

interface OwnProps {
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		const username = (this.props.userAuth) ? this.props.userAuth.username : "";
		this.state = {username, showValidation: false, saving: false, credentialInputChanged: false};
	}


	private validationSchema = {
		username: yup.string()
			.min(5, "Username must be at least 5 characters")
			.required("Username is required")
			.test("username-unique", "Username already taken", value => this.props.userAuthError !== "Username already taken"),
		password: yup.string().min(5, "Password must be at least 5 characters").required("Password is required"),
		verifyPassword: yup.string()
			.required("Enter your current password")
			.test("password-verify-correct", "Incorrect credentials", value => this.props.userAuthError !== "Incorrect credentials" || this.state.credentialInputChanged),
		passwordConfirm: yup.string().oneOf([yup.ref("password"), undefined], "Passwords must match").required("Password must be re-entered"),
	};


	public handleSave = (event: any) => {
		const { saveUserAuth } = this.props;
		const errors = validate(this.validationSchema, this.state);
		this.setState({showValidation: true})
		if(!errors) {
			const userAuth = {...this.state};
			saveUserAuth({userAuth});
			this.setState({saving: true, credentialInputChanged: false});
		}
	}

	public handleCancel = (event: any) => {
		const username = (this.props.userAuth) ? this.props.userAuth.username : "";
		this.setState({username, password: undefined, verifyPassword: undefined, passwordConfirm: undefined, showValidation: false, saving: false});
	}

	public handleSaveMessageClose = (event: any) => {
		this.setState({ saving: false})
	}

	public handleChangeVerifyPassword = (event: any) => {
		this.setState({ verifyPassword: event.target.value, credentialInputChanged: true });
	}

	public render() {
		const { user, userAuthLoading, userAuthError } = this.props;
		const { showValidation, saving } = this.state;
		const title = "Change Username & Password";
		const errors = validate(this.validationSchema, this.state);

		return (
			/**
			 * This is a legacy setting which will be replaced with the agent and user page "Change Password".
			 * once all logins are agent, we should no longer need this page. 
			 */
			<DashboardLayout
				permitted={user.admin}
				title={title}
				fullScreen={false}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<Mui.Card>
					<Mui.CardContent>
						<Mui.Grid container spacing={2}>
							<Mui.Grid item xs={12}>
								<Mui.TextField 
									label="Username"
									fullWidth
									variant="outlined"
									helperText={showValidation && errors && errors.username}
									error={showValidation && errors && !!errors.username}
									value={(this.state.username) ? this.state.username : ""}
									onChange={event => this.setState({username: event.target.value})}
									InputLabelProps={{ shrink: true }} />
							</Mui.Grid>
							<Mui.Grid item xs={12}>
								<Mui.TextField
									label="Current Password"
									fullWidth
									type="password"
									placeholder="Enter current password"
									variant="outlined"
									helperText={showValidation && errors && errors.verifyPassword}
									error={showValidation && errors && !!errors.verifyPassword}
									value={(this.state.verifyPassword) ? this.state.verifyPassword : ""}
									onChange={this.handleChangeVerifyPassword}
									InputLabelProps={{ shrink: true }} />
							</Mui.Grid>
							<Mui.Grid item container spacing={1}>
								<Mui.Grid item xs={6}>
									<Mui.TextField 
										label="New Password"
										fullWidth
										type="password"
										placeholder="Enter new password"
										variant="outlined"
										helperText={showValidation && errors && errors.password}
										error={showValidation && errors && !!errors.password}
										value={(this.state.password) ? this.state.password : ""}
										onChange={event => this.setState({password: event.target.value, credentialInputChanged: true})}
										InputLabelProps={{ shrink: true }} />
								</Mui.Grid>
								<Mui.Grid item xs={6}>
									<Mui.TextField 
										label="Confirm Password"
										fullWidth
										type="password"
										placeholder="Re-enter new password"
										variant="outlined"
										helperText={showValidation && errors && errors.passwordConfirm}
										error={showValidation && errors && !!errors.passwordConfirm}
										value={(this.state.passwordConfirm) ? this.state.passwordConfirm : ""}
										onChange={event => this.setState({passwordConfirm: event.target.value, credentialInputChanged: true})}
										InputLabelProps={{ shrink: true }}
										/>
								</Mui.Grid>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Button
									color="secondary"
									variant="contained"
									style={{ marginRight: 32 }}
									onClick={this.handleSave}
									>Save Changes</Mui.Button>
								<Mui.Button 
									variant="contained"
									onClick={this.handleCancel}
									>Cancel</Mui.Button>
							</Mui.Grid>
							<Mui.Snackbar 
								open={saving && !userAuthLoading && !userAuthError} 
								message="Username and password saved" 
								autoHideDuration={6000}
								onClose={this.handleSaveMessageClose}
								action={
									<Mui.IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSaveMessageClose}>
										<Icons.X fontSize="small" />
									</Mui.IconButton>
								}
							/>
						</Mui.Grid>
					</Mui.CardContent>
				</Mui.Card>
			</DashboardLayout>
		);
	}

}

export const ClientPassword = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
