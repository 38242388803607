import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { RootState } from "redux/store";
import { updateRoundRobinRoutes } from "redux/slice/round-robin-routes";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Dialog } from "component/shared/dialog";
import { RoundRobinRoute } from "model/round-robin-route";

const mapStateToProps = (state: RootState) => {
	return {
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateRoundRobinRoutes,
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> 
{
	onClose: Function;
	roundRobinRoute: RoundRobinRoute;
	roundRobinRoutes: RoundRobinRoute[]; 
}

interface State {
}

class Component extends React.Component<Props, State> {

	private submit() {
		const { onClose, roundRobinRoute, updateRoundRobinRoutes } = this.props;
		const roundRobinRoutes = this.props.roundRobinRoutes
			.filter(_roundRobinRoute => _roundRobinRoute.id !== roundRobinRoute.id)
			.map(roundRobinRoute => ({
				agent: roundRobinRoute.agent,
			}));
		updateRoundRobinRoutes({ roundRobinRoutes });
		onClose();
	}

	public render() {
		const { onClose, classes } = this.props;
		return (
			<Dialog
				open={true}
				onClose={() => onClose()}
			>
				<Mui.DialogTitle>Delete Round Robin Entry</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<Mui.DialogContentText>
						Are you sure you want to delete this round robin entry ?
					</Mui.DialogContentText>
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
					<Mui.Button
						onClick={() => this.submit()}
						variant="contained"
						color="secondary"
					>
						Yes
					</Mui.Button>
					<Mui.Button onClick={() => onClose()} variant="outlined">
						No
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
		);
	}
}

export const DeleteDialog = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
