import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Router from "react-router-dom";
import { DashboardLayout } from "component/layout/dashboard";
import { styles } from "../style";
import { RootState, getPayload } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { PageProps } from "shared/page-props";
import { urls } from "routes/urls";
import { validate } from "shared/yup";
import * as yup from "yup";
import { createCampaign } from "redux/slice/campaigns";
import { createCampaignMessage } from "redux/slice/campaign-messages";
import { createCampaignCampaignMessage, fetchCampaignCampaignMessages } from "redux/slice/campaign-campaign-messages";
import { resetLeadsPageFilters } from "redux/slice/leads-page";
import { getCampaignById } from "redux/selector";
import { CampaignMessageType } from "type/campaign-message";
import "emoji-mart/css/emoji-mart.css"
import { Picker } from "emoji-mart";
import { PhotoUploadDialogButton } from "component/shared/photo-upload-dialog-button";
import { Upload } from "model/upload";
import MuiAlert, { Color } from "@material-ui/lab/Alert";
import { CampaignMessageAttachment } from "model/campaign-message-attachment";
import { CampaignTextMessageMergeTags } from "type/campaign-text-message-merge-tags";

interface Props extends
	PageProps,
	Router.RouteComponentProps<RouteParams>,
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{}

interface RouteParams {
	id: string;
}

interface State {
	openSnackbar: boolean;
	snackbarMessage: string;
	snackbarSeverity?: Color;
	subject: string;
	name: string;
	message: string;
	maxCharacters: boolean;
	submitted: boolean;
	characterCount: number;
	emojiAnchorEl: HTMLElement | null;
	mergeTagSelectAnchorEl: HTMLElement | null;
	attachments: CampaignMessageAttachment[];
}

const MAX_CHARACTERS = 190;

const getId = (props: Router.RouteComponentProps<RouteParams>) => {
	return parseInt(props.match.params.id);
};

const mapStateToProps = (state: RootState, routerProps: Router.RouteComponentProps<RouteParams>) => {
	const campaign = getCampaignById(state, getId(routerProps));
	return {
		campaign,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createCampaign, 
	createCampaignMessage,
	createCampaignCampaignMessage, 
	resetLeadsPageFilters,
	fetchCampaignCampaignMessages,
}, dispatch);

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			openSnackbar: false,
			snackbarMessage: "",
			subject: "",
			name: "",
			message: "",
			maxCharacters: false,
			submitted: false,
			characterCount: 0,
			emojiAnchorEl: null,
			mergeTagSelectAnchorEl: null,
			attachments: [],
		};
		
	}

	private input = React.createRef<HTMLInputElement>();
	private maxAttachments = 4;

	public componentDidMount() {
		this.props.fetchCampaignCampaignMessages();
	}

	private validationSchema = {
		name: yup
			.string()
			.required("Name is required"),
		message: yup
			.string()
			.required("Message is required")
	};

	private async save() {
		const { 
			history, 
			campaign, 
			createCampaignMessage, 
			createCampaignCampaignMessage, 
		} = this.props;
		const {name, message, attachments} = this.state;
		const errors = validate(this.validationSchema, this.state);
		if(campaign && !errors && this.state.submitted) {
			const messagesOrdered = campaign.campaignCampaignMessages.sort((a, b) => {
				return b.messageOrder - a.messageOrder
			});
			const currentMessageOrder = messagesOrdered.length > 0 ? messagesOrdered[0].messageOrder + 1 : 1;
			let campaignMessageResource = getPayload(await createCampaignMessage({ campaignMessage: {
				name: name,
				htmlContent: message,
				isInactiveYn: false,
				messageType: CampaignMessageType.TEXT,
				attachments: attachments,
				ownedByReseller: false,	
			}}));			
			createCampaignCampaignMessage({ campaignCampaignMessage: {
				campaignId: campaign.id,
				campaignMessageId: campaignMessageResource.id,
				messageOrder: currentMessageOrder,
			}});
			history.push(urls.campaign(campaign));
		}		
	}

	private handleCancel() {
		const {campaign, history } = this.props;
		if(campaign && window.confirm("Discard changes?")) {
			history.push(urls.campaign(campaign));
		}
	}

	private insert(value: string) {
		const { message } = this.state;
		this.setState({
			mergeTagSelectAnchorEl: null,
			emojiAnchorEl: null,
		});
		const position = this.input.current && this.input.current.selectionStart ? this.input.current.selectionStart : 0;
		const left = message.substring(0, position)
		const right = message.substring(position)
		this.setState({message: left + value + right}, () => {
			const insertPosition = position + value.length
			if(this.input.current) {
				this.input.current.focus();
				this.input.current.setSelectionRange(insertPosition, insertPosition);
			}	
		});
	}

	private deleteAttachment(url: string) {
		this.setState({attachments: this.state.attachments.filter((upload) => upload.url !== url)})
	}

	private setAttachments(uploads: Upload[]) {
		const savedAttachments = [
			...this.state.attachments,
			...uploads.map((upload) => {
				return {
					url: upload.url,
					name: upload.name,
				}
			}),
		];
		if(uploads.length === 0) {
			this.setState({
				openSnackbar: true,
				snackbarMessage: "Error attaching attachments. Try again." ,
				snackbarSeverity: "error",
			})
		} else {
			if(savedAttachments.length > 0 && savedAttachments.length <= this.maxAttachments) {
				this.setState({
					attachments: savedAttachments,
					openSnackbar: true,
					snackbarMessage: savedAttachments.length > 0 ? `${savedAttachments.length} ${this.pluralize(savedAttachments.length, "upload", "attachments")} attached` : "No attachments were uploaded",
					snackbarSeverity: savedAttachments ? "success" : "error",
				})
			} 
			if(savedAttachments.length > 4) {
				this.setState({
					openSnackbar: true,
					snackbarMessage: `You exceeded 4 attachments. You can only attach ${this.maxAttachments - this.state.attachments.length} more.` ,
					snackbarSeverity: "error",
				})
			}
		}
	}

	private pluralize(count: number, singular: string, plural: string) {
		if(count > 1) {
			return plural;
		} else {
			return singular;
		}
	}

	private renderSubject() {
		const { classes, campaign } = this.props;
		const { name, submitted } = this.state;
		const errors = validate(this.validationSchema, this.state);
		return (				
			<Mui.Card>
				<Mui.CardContent>
					<Mui.Grid container direction="column" spacing={2}>
						<Mui.Grid item>
							<Mui.TextField
								label="Name"
								required
								error={submitted && !!errors && !!errors.name}
								helperText={submitted && errors && errors.name}
								placeholder="Enter name of message here"
								FormHelperTextProps={{
									className: classes.errorMessage,
								}}
								InputLabelProps={{ shrink: true }}
								value={name}
								onChange={event => {
									const input = event.target.value;
									this.setState({ name: input, maxCharacters: false });
									
								}}
								fullWidth
								margin="dense"
								variant="outlined"
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.CardContent>
				<Mui.CardActions>
					<Mui.Grid container spacing={2} justifyContent="space-between">
						<Mui.Grid item>
							<Mui.Grid container spacing={2}>
								<Mui.Grid item>
								<Mui.Button
									variant="contained"
									color="secondary"
									onClick={() => {
										this.setState({submitted: true}, 
											() => this.save()
										);
									}}
									className={classes.button}
								>
									Save
								</Mui.Button>
								</Mui.Grid>
								<Mui.Grid item>
									{campaign && 
										<Mui.Button
											variant="outlined"
											color="primary"
											onClick={() => this.handleCancel()}
										>
											Cancel
										</Mui.Button>
									}
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.CardActions>
			</Mui.Card>
		);
	}

	public render() {
		const { 
			message, 
			submitted, 
			emojiAnchorEl, 
			mergeTagSelectAnchorEl, 
			attachments, 
			snackbarMessage, 
			snackbarSeverity, 
			openSnackbar
		} = this.state;
		const { 
			classes, 
		} = this.props;
		const errors = validate(this.validationSchema, this.state);
		const title = "Add New Text Message";
		return (
			<DashboardLayout
				permitted={true}
				fullScreen={false}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Edit />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				
				<Mui.Grid container direction="column" spacing={4}>
					<Mui.Grid item>
						{this.renderSubject()}
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Card>
							<Mui.CardContent>
								<Mui.Grid container direction="column" spacing={1}>
									<Mui.Grid item>
										<Mui.Tooltip title="Emoji" aria-label="emoji" placement="top">
											<Mui.IconButton 
												onClick={(event) => {
													this.setState({emojiAnchorEl: emojiAnchorEl ? null : event.currentTarget});
												}}
												size="small"
											>
												<FeatherIcon>
													<Icons.Smile />
												</FeatherIcon>
											</Mui.IconButton >
										</Mui.Tooltip>
										<Mui.Tooltip title="Merge a field" aria-label="merge-field" placement="top">
											<Mui.IconButton 
												onClick={(event) => {
													this.setState({mergeTagSelectAnchorEl: mergeTagSelectAnchorEl ? null : event.currentTarget});
												}}
												size="small"
											>
												<FeatherIcon>
													<Icons.Hash />
												</FeatherIcon>
											</Mui.IconButton>
										</Mui.Tooltip>
										<Mui.Tooltip title="Link to a recommended listing" aria-label="limilar-listing" placement="top">
											<Mui.IconButton 
												onClick={(event) => {
													this.insert(CampaignTextMessageMergeTags.RECOMMENDED_LISTING.tag);
												}}
												size="small"
											>
												<FeatherIcon>
													<Icons.Home />
												</FeatherIcon>
											</Mui.IconButton >
										</Mui.Tooltip>
										<Mui.Tooltip title="Attachment" aria-label="attachment" placement="top">
											<PhotoUploadDialogButton
												icon={
													<Icons.Paperclip />
												}
												multiple={true}
												maxFiles={this.maxAttachments}
												disabled={this.state.attachments.length >= this.maxAttachments}
												callback={(uploads) => this.setAttachments(uploads)}
												style={{ marginBottom: 4, marginTop: 8 }}
											/>
										</Mui.Tooltip>
										<Mui.Popper
											anchorEl={emojiAnchorEl}
											open={!!emojiAnchorEl}
											disablePortal
											placement="bottom-start"
											className={classes.popper}
										>	
											<Mui.ClickAwayListener
												onClickAway={() => this.setState({
													emojiAnchorEl: null,
												})}
											>
												<Picker 
													showPreview
													onSelect={(emoji) => {
														//@ts-ignore
														this.insert(emoji.native)
													}} 
												/>
											</Mui.ClickAwayListener>
										</Mui.Popper>
										<Mui.Popper
											anchorEl={mergeTagSelectAnchorEl}
											open={!!mergeTagSelectAnchorEl}
											disablePortal
											placement="bottom-start"
											className={classes.popper}
										>	
											<Mui.ClickAwayListener
												onClickAway={() => this.setState({
													mergeTagSelectAnchorEl: null,
												})}
											>
												<Mui.Paper style={{padding: 15}}>
													<Mui.Typography>Select a Merge Tag</Mui.Typography>
													<Mui.MenuList  id="merge-tag-menu" >
														{CampaignTextMessageMergeTags.values().map((mergeTag, index) => (
															<div key={index}>
																{(mergeTag.type === "lead" || mergeTag.type === "client") &&
																	<Mui.MenuItem 
																		onClick={() => {
																			this.insert(mergeTag.tag)
																		}}
																	>
																		{mergeTag.label}
																	</Mui.MenuItem>	
																}
															</div>
														))}
													</Mui.MenuList>
												</Mui.Paper>
											</Mui.ClickAwayListener>
										</Mui.Popper>
										<Mui.TextField
											id="outlined-multiline-static"
											inputRef={this.input}
											required
											error={submitted && !!errors && !!errors.message}
											helperText={submitted && errors && errors.message}
											value={message}
											onChange={(event) => {
												this.setState({
													message: event.target.value,
												})
											}}
											multiline
											fullWidth
											minRows={4}
											placeholder="Add message here..."
											variant="outlined"
											inputProps={{ maxLength: MAX_CHARACTERS }}
										/>
										
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Grid container direction="row">
											<Mui.Grid item xs={6}>
												{attachments.length > 0 && 
													<>
														{attachments.map((upload, index) => (
															<Mui.Chip 
																key={index}
																style={{margin: 2}} 
																variant="outlined" 
																size="small" 
																label={upload.name}
																icon={<Icons.Paperclip style={{height: 16, width: 16, marginLeft: 5}} />}
																onDelete={()=> this.deleteAttachment(upload.url)}
															/>
														))}	
													</>
												}
												{attachments.length >= 4 && 
													<Mui.Typography style={{color: "#aaa"}}>
														Maximum of 4 Attachments
													</Mui.Typography>
												}
											</Mui.Grid>
											<Mui.Grid item xs={6}>
												<Mui.Typography align="right" style={{color: message.length >= MAX_CHARACTERS ? "red" : undefined}}>
													Characters left: {MAX_CHARACTERS - message.length}
												</Mui.Typography>
											</Mui.Grid>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.CardContent>
						</Mui.Card>
					</Mui.Grid>													
				</Mui.Grid>		
					
				
				<Mui.Snackbar
					action={
						<Mui.IconButton
							size="small"
							aria-label="close"
							color="inherit"
							onClick={() => this.setState({
								openSnackbar: false,
							})}
						>
							<Icons.Link fontSize="small" />
						</Mui.IconButton>
					}
					autoHideDuration={8000}
					onClose={() => this.setState({
						openSnackbar: false,
					})}
					open={openSnackbar}
				>
					<MuiAlert severity={snackbarSeverity}>
						<Mui.Typography>{snackbarMessage}</Mui.Typography>
					</MuiAlert>
				</Mui.Snackbar>
			</DashboardLayout>		
		);
	}
}

export const CampaignNewTextMessagePage = Mui.withStyles(styles)(
	Router.withRouter(
		connect(mapStateToProps, mapDispatchToProps)(Component)
	)
);