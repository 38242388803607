import React from "react"
import * as Mui from "@material-ui/core";
import { bindActionCreators, Dispatch } from "redux";
import { Market } from "model/market";
import { connect } from "react-redux";
import { updateMarketReport } from "redux/slice/market-reports";
import { SaveButton } from "component/shared/save-button";
import { RootState } from "redux/store";
import { styles } from "./style";

interface Props extends 
	ReturnType<typeof mapDispatchToProps>,
	ReturnType<typeof mapStateToProps>,
	Mui.WithStyles<typeof styles> {
		market: Market;
	}

interface State {
	webIntroText: string, 
	emailIntroText: string, 
	navigationDisplay: boolean,
	saved: boolean;
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateMarketReport,
}, dispatch);

const mapStateToProps = (state: RootState) => {
	return {
		loading: state.marketReports.loading 
	};
}

class Component extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		const { market } = this.props
		const openHomesReport = market.openHomesReport;
		this.state = {
			webIntroText: openHomesReport && openHomesReport.webpageIntroText ? openHomesReport.webpageIntroText : "",
			emailIntroText: openHomesReport && openHomesReport.emailIntroText ? openHomesReport.emailIntroText : "",
			navigationDisplay: openHomesReport && openHomesReport.displayInNavigation ? openHomesReport.displayInNavigation : false,
			saved: false,
		}
	}

	private save() {
		const { market } = this.props;
		const { emailIntroText, navigationDisplay, webIntroText } = this.state;
		if(market.openHomesReport) {
			this.props.updateMarketReport({ marketReport: {
				...market.openHomesReport,
				displayInNavigation: navigationDisplay,
				emailIntroText: emailIntroText,
				webpageIntroText: webIntroText,
			}});
		}
	}

	render() {
		const { market, loading, classes } = this.props;
		const { emailIntroText, navigationDisplay, saved, webIntroText } = this.state;
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item >
					<Mui.Grid container direction="row" justifyContent="space-between" wrap="nowrap">
						<Mui.Grid item xs={2}>
							<Mui.Typography>Page URL</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={9}>
							<Mui.Link
								href={market.marketReport?.pageUrl}
								target="_blank"
							>{market.marketReport?.pageUrl}</Mui.Link>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item >
					<Mui.Grid container direction="row" justifyContent="space-between" wrap="nowrap">
						<Mui.Grid item xs={2}>
							<Mui.Typography>Webpage Intro Text</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={9}>
							<Mui.TextField
								multiline
								minRows={5}
								variant="outlined"
								fullWidth
								value={webIntroText}
								onChange={(event) => {
									this.setState({webIntroText: event.target.value})
								}}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Grid container direction="row" justifyContent="space-between" wrap="nowrap">
						<Mui.Grid item xs={2}>
							<Mui.Typography>Email Intro Text</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={9}>
							<Mui.TextField
								multiline
								minRows={5}
								variant="outlined"
								fullWidth
								value={emailIntroText}
								onChange={(event) => {
									this.setState({emailIntroText: event.target.value})
								}}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Grid container direction="row" justifyContent="space-between" wrap="nowrap">
						<Mui.Grid item xs={2}>
							<Mui.Typography>Display in Navigation</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={9}>
							<Mui.Checkbox
								checked={navigationDisplay}
								onChange={(event) => {
									this.setState({navigationDisplay: !navigationDisplay})
								}}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item className={classes.saveRow}>
					<SaveButton 
						loading={!!loading && saved} 
						label={{
							primary: "Save", 
							inProgress: "Saving...", 
							completed: "Saved" 
						}}
						snackBar={true}
						onClick={() => {
							this.setState({saved: true});
							this.save();
						}}
						onReset={() => this.setState({saved: false})} 
					/>
				</Mui.Grid>
			</Mui.Grid>
		)
	}
}

export const MarketReportContent = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));