import React from 'react';
import * as Mui from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Agent } from "model/agent";
import { updateAgent, resetError } from "redux/slice/agents"
import { RootState, getPayload } from "redux/store";
import { SaveButton } from "component/shared/save-button";
import { validate } from "shared/yup";
import * as yup from "yup";

interface Props extends OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
	}

interface OwnProps {
	agent: Agent;
	onClose: () => void;
}

interface State {
	name: string;
	title: string;
	email: string;
	homePhone: string;
	officePhone: string;
	cellPhone: string;
	submitted: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		loading: state.agents.loading,
		resetError,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{
			updateAgent,
		},
		dispatch
	);
};

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			name: this.props.agent.name,
			title: this.props.agent.designation || "",
			email: this.props.agent.email || "",
			homePhone: this.props.agent.homePhone || "",
			officePhone: this.props.agent.officePhone || "",
			cellPhone: this.props.agent.cellPhone || "",
			submitted: false,
		};
	}

	private async save() {
		const { updateAgent, agent, onClose } = this.props;
		const { cellPhone, email, homePhone, name, officePhone, title} = this.state;
		const errors = validate(this.validationSchema, this.state);
		if(!errors) {
			const agentResource = getPayload( await updateAgent( { agent: {
				...agent,
				cellPhone, 
				email, 
				homePhone, 
				name, 
				officePhone, 
				designation: title,
			}}));
			if(agentResource) {
				onClose();
			}
		}
	}

	private validationSchema = {
		designation: yup
			.string()
			.test(
				"designation-max-length",
				"Designation must be less than 50 characters.",
				(designation) => !designation || designation.length <= 50
			),
		email: yup.string().email("Invalid email.").required("Email is required."),
	};

	render() {
		const { name, title, email, homePhone, officePhone, cellPhone, submitted } = this.state;
		const { loading } = this.props;
		const errors = validate(this.validationSchema, this.state);
		return (
			<Mui.Grid container direction="column" spacing={1}>
				<Mui.Grid item>
					<Mui.TextField
						fullWidth
						margin="dense"
						name="name"
						onChange={(event) => this.setState({name: event.target.value})}
						label="Name"
						value={name}
						variant="outlined"
					/>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.TextField
						fullWidth
						margin="dense"
						name="title"
						onChange={(event) => this.setState({title: event.target.value})}
						label="Title/Designation"
						value={title}
						variant="outlined"
					/>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.TextField
						fullWidth
						error={submitted && !!errors && !!errors.email}
						helperText={submitted && errors && errors.email}
						margin="dense"
						name="email"
						onChange={(event) => this.setState({email: event.target.value})}
						label="Email"
						value={email}
						variant="outlined"
					/>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.TextField
						fullWidth
						margin="dense"
						name="homePhone"
						onChange={(event) => this.setState({homePhone: event.target.value})}
						label="Home Phone"
						value={homePhone}
						variant="outlined"
					/>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.TextField
						fullWidth
						margin="dense"
						name="officePhone"
						onChange={(event) => this.setState({officePhone: event.target.value})}
						label="Office Phone"
						value={officePhone}
						variant="outlined"
					/>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.TextField
						fullWidth
						margin="dense"
						name="cellPhone"
						onChange={(event) => this.setState({cellPhone: event.target.value})}
						label="Cell Phone"
						value={cellPhone}
						variant="outlined"
					/>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Grid container spacing={2}>
						<Mui.Grid item>
							<SaveButton 
								loading={submitted && !!loading}
								error={submitted && errors ? "Please fix the highlighted errors" : undefined} 
								label={{
									primary: "Save",
									inProgress: "Saving...",
									completed: "Saved",
								}}
								snackBar={true}
								onClick={() => {
									this.setState({submitted: true});
									this.save();
								}}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Button 
								variant="contained"
								onClick={() => this.props.onClose() }
							>
								Cancel
							</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const PersonalInfo = connect(mapStateToProps, mapDispatchToProps)(Component);