import { arrayToById, objectToById } from "redux/normalize";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { IntegrationPartial } from "model/integration";
import { loginReset } from "redux/slice/authentication";

export interface IntegrationsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: IntegrationState;
	};
};

export interface IntegrationState {
	id: number;
	integrationTypeId: number;
	name?: string;
	createdOn: string;
	errored?: boolean;
	data?: {
		[key: string]: string | number | boolean | undefined;
	};
};

const initialState: IntegrationsState = {
	byId: {},
};

const fetchIntegrations = createAsyncThunk("integrations/fetchIntegrations", async (payload, thunkAPI) => {
	thunkAPI.dispatch(integrationsLoading());
	const result = api.getIntegrations();
	result
		.then((integrations) => thunkAPI.dispatch(integrationsLoaded(integrations)))
		.catch(error => thunkAPI.dispatch(integrationsFailed(error)));
	return result;
});

const saveIntegration = createAsyncThunk("integrations/saveIntegration", async (payload: { integration: IntegrationPartial }, thunkAPI) => {
	thunkAPI.dispatch(integrationsLoading());
	const result = api.saveIntegration(payload.integration);
	result
		.then((integration) => thunkAPI.dispatch(integrationLoaded(integration)))
		.catch(error => thunkAPI.dispatch(integrationsFailed(error)));
	return result;
});

const deleteIntegration = createAsyncThunk("integrations/deleteIntegration", async (payload: { integration: IntegrationPartial }, thunkAPI) => {
	thunkAPI.dispatch(integrationsLoading());
	const result = api.deleteIntegration(payload.integration);
	result
		.then((integration) => thunkAPI.dispatch(integrationDeleted(integration)))
		.catch(error => thunkAPI.dispatch(integrationsFailed(error)));
	return result;
});

const testIntegration = createAsyncThunk("integrations/testIntegration", async (payload: { integration: IntegrationPartial }, thunkAPI) => {
	api.testIntegration(payload.integration);
});

export const integrationsSlice = createSlice({
	name: "integrations",
	initialState,
	reducers: {
		integrationsLoading: (state) => {
			state.loading = true;
		},
		integrationsLoaded: (state, action: PayloadAction<any[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
		},
		integrationLoaded: (state, action: PayloadAction<any>) => {
			const byId = objectToById(action.payload, "id");
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...byId,
			};
		},
		integrationDeleted: (state, action: PayloadAction<any>) => {
			const id = action.payload.id;
			const result = {
				...state,
				loading: false,
				error: null,
				byId: {
					...state.byId,
				}
			}
			delete result.byId[id];
			return result;
		},
		integrationsFailed: (state, action: PayloadAction<Error>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
        default: (state) => {
            return state;
        }
	},
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});

export const { integrationDeleted, integrationLoaded, integrationsFailed, integrationsLoaded, integrationsLoading } = integrationsSlice.actions;
export { fetchIntegrations, saveIntegration, deleteIntegration, testIntegration };