import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { LeadActivity } from "./latest-activity";

interface Props extends PageProps, Router.RouteComponentProps {
}

class Component extends React.Component<Props> {

	public render() {
		const { user } = this.props;
		const title = user.admin ? "Lead Activity" : `Welcome, ${user.name}`;
		return (
			<DashboardLayout
				permitted={user && user.permissions.leads}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.User />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<LeadActivity user={user} />
			</DashboardLayout>
		);
	}

}

export const LeadActivityPage = Component;