import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./styles";
import { HexColorPicker } from "react-colorful";

interface Color {
	value: string;
	label: string;
}

const colors: Color[] = [
	{
		value: "#f44336",
		label: "Red",
	},
	{
		value: "#e91e63",
		label: "Pink",
	},
	{
		value: "#9c27b0",
		label: "Purple",
	},
	{
		value: "#673ab7",
		label: "Deep Purple",
	},
	{
		value: "#3f51b5",
		label: "Indigo",
	},
	{
		value: "#2196f3",
		label: "Blue",
	},
	{
		value: "#03a9f4",
		label: "Light Blue",
	},
	{
		value: "#00bcd4",
		label: "Cyan",
	},
	{
		value: "#009688",
		label: "Teal",
	},
	{
		value: "#4caf50",
		label: "Green",
	},
	{
		value: "#8bc34a",
		label: "Light Green",
	},
	{
		value: "#cddc39",
		label: "Lime",
	},
	{
		value: "#ffeb3b",
		label: "Yellow",
	},
	{
		value: "#ffc107",
		label: "Amber",
	},
	{
		value: "#ff9800",
		label: "Orange",
	},
	{
		value: "#ff5722",
		label: "Deep Orange",
	},
	{
		value: "#795548",
		label: "Brown",
	},
	{
		value: "#9e9e9e",
		label: "grey",
	},
	{
		value: "#607d8b",
		label: "Blue Grey",
	},
	{
		value: "#000000",
		label: "Black",
	},
];

interface OwnProps {
	value: string;
	onChange: (value: string) => void;
	errors: Object | undefined;
}

interface Props extends OwnProps, Mui.WithStyles<typeof styles>, Mui.WithTheme {}

interface State {
	anchorEl: Element | null;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			anchorEl: null,
		};
	}

		public render() {
		const { classes, theme, value, onChange, errors } = this.props;
		const { anchorEl } = this.state;
		let color;
		let textColor;
		if(!errors) {
			color = value || "#fff";
			textColor = theme.palette.getContrastText(color);
		}

		return (
			<Mui.ClickAwayListener
				onClickAway={() => this.setState({
					anchorEl: null,
				})}
			>
				<div className={classes.wrapper}>
					<Mui.TextField
						variant="outlined"
						fullWidth
						label="Button Color"
						placeholder="Enter a hex code or choose a color"
						value={value}
						error={!!errors}
						helperText={errors}
						onChange={(event) => {
							let value = event.target.value;
							if(value && !value.startsWith("#")) {
								value = "#" + value;
							}
							onChange(value)
						}}
						onFocus={(event) => this.setState({
							anchorEl: event.target,
						})}
						InputLabelProps={{
							variant: "filled",
							style: {
								color: textColor,
							},
						}}
						InputProps={{
							notched: false,
							style: {
								backgroundColor: color,
								color: textColor,
							},
							inputProps: {
								className: classes.input,
								minLength: 4,
								maxLength: 7,
							},
						}}
					/>
					<Mui.Popper
						anchorEl={anchorEl}
						open={!!anchorEl}
						disablePortal
						placement="bottom-start"
						className={classes.popper}
					>
						<Mui.Card className={classes.card}>
							<Mui.CardContent>
								<Mui.Grid container spacing={2}>
									<Mui.Grid item>
										<HexColorPicker
											color={color}
											onChange={(value) => onChange(value)}
										/>
									</Mui.Grid>
									<Mui.Grid item xs>
										<Mui.Grid container spacing={1}>
											{colors.map((color, i) => (
												<Mui.Grid key={i} item>
													<Mui.Button
														className={classes.button}
														style={{
															backgroundColor: color.value,
														}}
														variant="contained"
														onClick={() => {
															onChange(color.value);
															this.setState({
																anchorEl: null,
															});
														}}
														title={color.label}
														aria-label={color.label}
													/>
												</Mui.Grid>
											))}
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.CardContent>
						</Mui.Card>
					</Mui.Popper>
				</div>
			</Mui.ClickAwayListener>
		);
	}
}

export const ColorPicker = Mui.withStyles(styles)(
	Mui.withTheme(
		Component
	)
);
