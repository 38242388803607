import { arrayToById, objectToById } from "redux/normalize";
import * as api from "api/api";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { loginReset } from "redux/slice/authentication";
import { Office, NewOffice } from "model/office";
import { OfficeResource } from "api/resource/office";

export interface OfficesState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: OfficeResource;
	};
};

const initialState: OfficesState = {
	byId: {},
};

const fetchOffices = createAsyncThunk("offices/fetchOffices", async (payload, thunkAPI) => {
	thunkAPI.dispatch(officesLoading());
	const result = api.getOffices();
	result
		.then((offices) => thunkAPI.dispatch(officesLoaded(offices)))
		.catch(error => thunkAPI.dispatch(officesFailed(error)));
	return result;
});

const createOffice = createAsyncThunk("offices/createOffice", async (payload: { office: NewOffice }, thunkAPI) => {
	thunkAPI.dispatch(officesLoading());
	const result = api.createOffice(payload.office);
	result
		.then((office) => thunkAPI.dispatch(officeLoaded(office)))
		.catch(error => thunkAPI.dispatch(officesFailed(error)));
	return result;
});

const updateOffice = createAsyncThunk("offices/updateOffice", async (payload: { office: Office }, thunkAPI) => {
	thunkAPI.dispatch(officesLoading());
	const result = api.updateOffice(payload.office);
	result
		.then((office) => thunkAPI.dispatch(officeLoaded(office)))
		.catch(error => thunkAPI.dispatch(officesFailed(error)));
	return result;
});

const deleteOffice = createAsyncThunk("offices/deleteOffice", async (payload: { office: Office }, thunkAPI) => {
	thunkAPI.dispatch(officeDeleted(payload.office));
	const result = api.deleteOffice(payload.office);
	result
		.then(() => thunkAPI.dispatch(officeDeleted(payload.office)))
		.catch((error) => thunkAPI.dispatch(officesFailed(error)));
	return result;
});

export const officesSlice = createSlice({
	name: "offices",
	initialState,
	reducers: {
		officesLoading(state) {
			state.loading = true;
		},
		officesLoaded(state, action: PayloadAction<any[]>) {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
		},
		officeLoaded(state, action: PayloadAction<any>) {
			const byId = objectToById(action.payload, "id");
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...byId
			};
		},
		officeDeleted(state, action: PayloadAction<any>) {
			const id = action.payload.id;
			const result = {
				...state,
				loading: false,
				error: null,
				byId: {
					...state.byId,
				},
			};
			delete result.byId[id];
			return result;
		},
		officesFailed(state, action: PayloadAction<any>) {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
            return state;
        }
    },
    extraReducers(builder) {
        builder.addCase(loginReset, () => {
            return initialState;
        });
    }
});

export const { officeDeleted, officeLoaded, officesFailed, officesLoaded, officesLoading } = officesSlice.actions;
export { fetchOffices, createOffice, updateOffice, deleteOffice };
