import { arrayToById } from "redux/normalize";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { LanguageResource } from "api/resource/language";

export interface LanguagesState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: LanguageResource;
	};
};

const initialState: LanguagesState = {
	byId: {},
};

const fetchLanguages = createAsyncThunk("languages/fetchLanguages", async (payload, thunkAPI) => {
	thunkAPI.dispatch(languagesLoading());
	const result = api.getLanguages();
	result
		.then((languages) => thunkAPI.dispatch(languagesLoaded(languages)))
		.catch(error => thunkAPI.dispatch(languagesFailed(error)));
	return result;
});

export const languagesSlice = createSlice({
	name: "languages",
	initialState,
	reducers: {
		languagesLoading: (state) => {
			state.loading = true;
		},
		languagesLoaded: (state, action: PayloadAction<LanguageResource[]>) => {
			state.byId = arrayToById(action.payload, "id");
			state.loading = false;
			state.error = null;
		},
		languagesFailed: (state, action: PayloadAction<Error>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
		default: (state) => {
            return state;
        }
	},	
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return initialState;
		});
	},
});

export const { languagesLoading, languagesLoaded, languagesFailed } = languagesSlice.actions;
export { fetchLanguages };
